import { requestBackend } from '@eva/emf/app/utils/request';
import { getQueryVariables } from '@eva/emf/app/shared/functions';
import type { StaticPageDto } from '@eva/types/dto';
import { logError } from 'shared/functions';

let isSetPiToken = false;

const getHostName = () => {
  if (!window.location.hostname) {
    return;
  }

  const result = window.location.hostname.split('.') || [''];
  return result[0].trim();
};

export const setPiToken = async ({ content, isAllowedOperation }) => {
  if (isSetPiToken) {
    return;
  }

  if (!process.env.PI_URL) {
    return;
  }

  if (!isAllowedOperation('piDashboard-viewAllowed')) {
    return;
  }

  if (!content.includes(process.env.PI_URL)) {
    return;
  }

  const piLoginUrl = `${process.env.PI_URL}pi/auth/embeddedTokenLogin`;

  try {
    const { token } = (await requestBackend('/bi/pi/sign-in', {
      method: 'POST',
    })) as { token: string };

    await fetch(piLoginUrl, {
      credentials: 'include',
      headers: {
        Authorization: `bearer ${token}`,
        'eva-referrer': getHostName(),
      },
    });

    isSetPiToken = true;
  } catch (err) {
    logError(err);
  }
};

export const getStaticPageContent = (staticPage: any, { userId = null } = {}) => {
  try {
    const content = staticPage?.content;

    if (!content) {
      return '<div />';
    }

    if (!userId) {
      return content;
    }

    return content.replaceAll('{{userId}}', userId).replaceAll('{{ userId }}', userId);
  } catch (err) {
    logError(err, {
      staticPage,
      userId,
    });
    return '<h4 class="text-danger text-center">The page is invalid</h4>';
  }
};

export const getStaticPageIdFromUrl = (staticPages: StaticPageDto[], defaultId?: number) => {
  const query = getQueryVariables(null);

  const staticPageIdFromQuery = Number(query.staticPageId);

  const staticPageFromQuery = staticPages.find(({ staticPageId }) => staticPageId === staticPageIdFromQuery);

  return staticPageFromQuery?.staticPageId ?? defaultId;
};

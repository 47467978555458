import PropTypes from 'prop-types';
import React from 'react';

const EventSelector = ({ availabilityTypes, selectedEvent, selectEvent }) => {
  const onRadioClick = (eventSelected) => () => selectEvent(eventSelected);
  return (
    <fieldset id="EventSelector" className="pull-left">
      <div className="legend-body">
        {availabilityTypes
          .filter((eventType) => !eventType.special)
          .map((eventType) => (
            <div className="radio-item" key={eventType.event}>
              <label className="badge" style={{ backgroundColor: eventType.color }} htmlFor={eventType.event}>
                <input
                  id={eventType.event}
                  type="radio"
                  name="eventSelect"
                  checked={selectedEvent.event === eventType.event}
                  onChange={onRadioClick(eventType)}
                />
                &nbsp;
                {eventType.event}
              </label>
            </div>
          ))}
      </div>
    </fieldset>
  );
};

EventSelector.propTypes = {
  availabilityTypes: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  selectEvent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default EventSelector;

import PropTypes from 'prop-types';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

class Notification extends AIUIComponent {
  render() {
    return <Spinner />;
  }
}

// @ts-expect-error
Notification.propTypes = {
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default Notification;

import {
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_SUCCESS,
  LOAD_USER_DETAILS_ERROR,
  LOAD_CHATS,
  LOAD_CHATS_SUCCESS,
  LOAD_CHATS_ERROR,
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  LOAD_MESSAGES_ERROR,
  LOAD_JOB,
  LOAD_JOB_SUCCESS,
  LOAD_JOB_ERROR,
  LOAD_PIPELINES,
  LOAD_PIPELINES_SUCCESS,
  LOAD_PIPELINES_ERROR,
  MARK_PIPELINES_READ,
  MARK_PIPELINES_READ_SUCCESS,
  MARK_PIPELINES_READ_ERROR,
  LOAD_CANDIDATES_FILTER,
  LOAD_CANDIDATES_FILTER_SUCCESS,
  LOAD_CANDIDATES_FILTER_ERROR,
  SEARCH_CANDIDATES,
  SEARCH_CANDIDATES_SUCCESS,
  SEARCH_CANDIDATES_ERROR,
  DELETE_CANDIDATE,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_ERROR,
  LOAD_EDIT_OPTIONS,
  LOAD_EDIT_OPTIONS_SUCCESS,
  LOAD_EDIT_OPTIONS_ERROR,
  MARK_LOCAL_READ,
  CHAT_SELECTED,
  SEND_MESSAGE,
  NEW_MESSAGE,
  MESSAGE_DELETED,
  CONTENT_UPDATED,
  UPDATING_PROFILE,
  PROFILE_UPDATED,
  PIPELINE_UPDATED,
  CHANGE_STATUS,
  AI_PAUSED,
  MESSAGE_UPDATED,
  CANDIDATE_PROFILE_UPDATED,
  CONVERSATION_WORKFLOW_CHANGED,
  OMNICHAT_UPDATED,
  CANDIDATE_REGISTRATION_COMPLETED,
} from './constants';

/**
 * Chats
 */

export function loadChats(startingFrom, chat) {
  return {
    type: LOAD_CHATS,
    startingFrom,
    chat,
  };
}

export function loadChatsSuccess(payload) {
  return {
    type: LOAD_CHATS_SUCCESS,
    payload,
  };
}

export function loadChatsError(error) {
  return {
    type: LOAD_CHATS_ERROR,
    error,
  };
}

/**
 * User details
 */

export function loadUserDetails() {
  return {
    type: LOAD_USER_DETAILS,
  };
}

export function loadUserDetailsSuccess(payload) {
  return {
    type: LOAD_USER_DETAILS_SUCCESS,
    payload,
  };
}

export function loadUserDetailsError(error) {
  return {
    type: LOAD_USER_DETAILS_ERROR,
    error,
  };
}

/**
 * Search candidates
 */

export function searchCandidates(searchQuery, offset) {
  return {
    type: SEARCH_CANDIDATES,
    searchQuery,
    offset,
  };
}

export function searchCandidatesSuccess(payload, searchQuery) {
  return {
    type: SEARCH_CANDIDATES_SUCCESS,
    payload,
    searchQuery,
  };
}

export function searchCandidatesError(error) {
  return {
    type: SEARCH_CANDIDATES_ERROR,
    error,
  };
}

/**
 * Load messages
 */

export function loadMessages(chat, preceding, companyName) {
  return {
    type: LOAD_MESSAGES,
    chat,
    preceding,
    companyName,
  };
}

export function loadMessagesSuccess(messages, channelTypes, chat, preceding) {
  return {
    type: LOAD_MESSAGES_SUCCESS,
    messages,
    channelTypes,
    chat,
    preceding,
  };
}

export function loadMessagesError(error) {
  return {
    type: LOAD_MESSAGES_ERROR,
    error,
  };
}

/**
 * Job
 */

export function loadJob(jobId) {
  return {
    type: LOAD_JOB,
    jobId,
  };
}

export function loadJobSuccess(payload) {
  return {
    type: LOAD_JOB_SUCCESS,
    payload,
  };
}

export function loadJobError(error) {
  return {
    type: LOAD_JOB_ERROR,
    error,
  };
}

/**
 * Pipelines
 */

export function loadPipelines() {
  return {
    type: LOAD_PIPELINES,
  };
}

export function loadPipelinesSuccess(payload) {
  return {
    type: LOAD_PIPELINES_SUCCESS,
    payload,
  };
}

export function loadPipelinesError(error) {
  return {
    type: LOAD_PIPELINES_ERROR,
    error,
  };
}

/**
 * Pipeline mark as read
 */

export function markPipelinesRead(pipelinesIds) {
  return {
    type: MARK_PIPELINES_READ,
    pipelinesIds,
  };
}

export function markPipelinesReadSuccess() {
  return {
    type: MARK_PIPELINES_READ_SUCCESS,
  };
}

export function markPipelinesReadError(error) {
  return {
    type: MARK_PIPELINES_READ_ERROR,
    error,
  };
}

/**
 * Load candidates filter
 */

export function loadCandidatesFilter() {
  return {
    type: LOAD_CANDIDATES_FILTER,
  };
}

export function loadCandidatesFilterSuccess(payload) {
  return {
    type: LOAD_CANDIDATES_FILTER_SUCCESS,
    payload,
  };
}

export function loadCandidatesFilterError(error) {
  return {
    type: LOAD_CANDIDATES_FILTER_ERROR,
    error,
  };
}

/**
 * Delete candidate
 */

export function deleteCandidate(chat, candidateId) {
  return {
    type: DELETE_CANDIDATE,
    chat,
    candidateId,
  };
}

export function deleteCandidateSuccess(chat, payload) {
  return {
    type: DELETE_CANDIDATE_SUCCESS,
    chat,
    payload,
  };
}

export function deleteCandidateError(error) {
  return {
    type: DELETE_CANDIDATE_ERROR,
    error,
  };
}

/**
 * Edit options
 */

export function loadEditOptions() {
  return {
    type: LOAD_EDIT_OPTIONS,
  };
}

export function loadEditOptionsSuccess(payload) {
  return {
    type: LOAD_EDIT_OPTIONS_SUCCESS,
    payload,
  };
}

export function loadEditOptionsError(error) {
  return {
    type: LOAD_EDIT_OPTIONS_ERROR,
    error,
  };
}

/**
 * IO
 */

export function onNewMessage(chat, payload) {
  return {
    type: NEW_MESSAGE,
    chat,
    payload,
  };
}

export function onMessageDeleted({ conversationId, messageId }) {
  return {
    type: MESSAGE_DELETED,
    conversationId,
    messageId,
  };
}

export function onContentUpdated(chat, message, content) {
  return {
    type: CONTENT_UPDATED,
    chat,
    message,
    content,
  };
}

export function onUpdatingProfile(chat) {
  return {
    type: UPDATING_PROFILE,
    chat,
  };
}

export function onProfileUpdated(profile) {
  return {
    type: PROFILE_UPDATED,
    profile,
  };
}

export function onPipelineUpdated(pipeline) {
  return {
    type: PIPELINE_UPDATED,
    pipeline,
  };
}

/**
 * Utils
 */

export function markLocalRead(chat) {
  return {
    type: MARK_LOCAL_READ,
    chat,
  };
}

export function chatSelected(chat) {
  return {
    type: CHAT_SELECTED,
    chat,
  };
}

export function sendMessage(chat, payload) {
  return {
    type: SEND_MESSAGE,
    chat,
    payload,
  };
}

export function onChangeStatus(payload) {
  return {
    type: CHANGE_STATUS,
    payload,
  };
}

export function aiPaused(payload) {
  return {
    type: AI_PAUSED,
    payload,
  };
}

export function messageUpdated(payload) {
  return {
    type: MESSAGE_UPDATED,
    payload,
  };
}

export function onCandidateProfileUpdated(candidateId, updatedSections) {
  return {
    type: CANDIDATE_PROFILE_UPDATED,
    candidateId,
    updatedSections,
  };
}

export function onConversationWorkflowChanged(conversationId, workflowState) {
  return {
    type: CONVERSATION_WORKFLOW_CHANGED,
    conversationId,
    workflowState,
  };
}

export function onOmnichatUpdated(omnichat) {
  return {
    type: OMNICHAT_UPDATED,
    omnichat,
  };
}

export function onCandidateRegistrationCompleted() {
  return {
    type: CANDIDATE_REGISTRATION_COMPLETED,
  };
}

import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import get from 'lodash/get';

import { getWorkLocationPreferenceOptions } from '@eva/emf/app/shared/functions';
import { salaryRateLabel } from 'shared/functions';
import { TextArea } from '@eva/emf/app/shared/ui/TextArea/TextArea';
import { RenderSelect } from '@eva/emf/app/shared/ui/RenderSelect';
import { Input } from '@eva/emf/app/shared/ui/Input';

class FormLookingFor extends React.Component<any, any> {
  render() {
    const { settings } = this.context;
    const { jobEmploymentType, handleSubmit, editOptions } = this.props;

    const currencyValue = get(settings, 'currency.value', '£');
    const currencyPer = `${translate(currencyValue)} ${translate('per')}`;

    return (
      <div className="section" onSubmit={handleSubmit}>
        <h4 className="text-primary">{translate('Looking for')}</h4>
        <div className="row">
          <div className="col-xs-12">
            <Field
              name="desiredPositions.description"
              component={TextArea}
              minRows={2}
              label={translate('Desired positions')}
              multi
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              name="employmentTypes.items"
              component={RenderSelect}
              label={translate('Preferred employment')}
              options={editOptions.employmentTypes}
              valueKey="code"
              labelKey="title"
              multi
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <label className="control-label">
              {translate('Min {{salaryLabel}}', {
                salaryLabel: salaryRateLabel(jobEmploymentType),
              })}
            </label>
            <div className="row group-fields">
              <div className="col-xs-7">
                <Field
                  component={Input}
                  name="desiredSalary.salary.value"
                  labelClass="hide"
                  type="number"
                  nullify
                  step="0.1"
                  min="0"
                  postfix={currencyPer}
                />
              </div>
              <div className="col-xs-5">
                <Field
                  name="desiredSalary.salary.period"
                  component={RenderSelect}
                  options={editOptions.paymentPeriods}
                  valueKey="code"
                  labelKey="name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              label={translate('Work Location Preference')}
              name="workLocationPreference.workLocationPreferenceTypes"
              component={RenderSelect}
              options={getWorkLocationPreferenceOptions()}
              valueKey="code"
              multi
            />
          </div>
        </div>
      </div>
    );
  }
}
// @ts-expect-error
FormLookingFor.contextTypes = {
  settings: PropTypes.object.isRequired,
};
// @ts-expect-error
FormLookingFor.propTypes = {
  jobEmploymentType: PropTypes.string,
  editOptions: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  // eslint-disable-line no-class-assign
  form: 'form-looking-for',
  // @ts-expect-error
})(FormLookingFor);

import React from 'react';

import type { SkillTaxonomySection } from '@eva/types/dto';
import { PageSpinner } from '@eva/emf/app/shared/ui/Spinner';
import { removePathsFromError, stringifyError } from '@eva/emf/app/shared/functions';

import { getSkillProficiencies } from '../functions';
import { useFetchSkillTaxonomyOptions } from '../../hooks';

import { SkillTaxonomyItem } from './SkillTaxonomyItem';

export const SkillTaxonomyPreview: React.FC<{ skillTaxonomies: SkillTaxonomySection[] }> = ({ skillTaxonomies }) => {
  const { data, isLoading, isError, error } = useFetchSkillTaxonomyOptions();

  if (isLoading) {
    return <PageSpinner />;
  }

  if (isError) {
    const errorWithoutPath = removePathsFromError(error);
    return <h1>{stringifyError(errorWithoutPath)}</h1>;
  }

  return (
    data &&
    data.skillTaxonomies && (
      <div>
        {skillTaxonomies.map((taxonomy) => (
          <SkillTaxonomyItem
            key={taxonomy.skillTaxonomyId}
            name={taxonomy.name}
            skills={taxonomy.skills}
            skillProficiencies={getSkillProficiencies(taxonomy.skillTaxonomyId, data.skillTaxonomies)}
          />
        ))}
      </div>
    )
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form/immutable';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { linkifyError } from 'shared/functions';

import FormEmploymentCore from './FormEmploymentCore';

class FormCurrentlyWorking extends React.Component<any, any> {
  render() {
    const { submitting, error } = this.props;

    return (
      <div>
        <h3 className="text-primary">{translate('My work profile')}</h3>
        <h4 className="text-primary">{translate('Currently working')}</h4>
        <FormEmploymentCore {...this.props} />
        <div>
          {error && <div className="alert-danger alert">{linkifyError(error)}</div>}
          <div className="text-center">{submitting && <Spinner />}</div>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
FormCurrentlyWorking.propTypes = {
  jobEmploymentType: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  switchEditMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  enableReinitialize: true,
  // @ts-expect-error
})(FormCurrentlyWorking);

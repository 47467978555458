import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm, Field } from 'redux-form/immutable';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { TextArea } from '@eva/emf/app/shared/ui/TextArea/TextArea';

const FormCoverLetter = (props) => (
  <div onSubmit={props.handleSubmit}>
    <h3 className="text-primary">{translate('Cover letter')}</h3>
    <div>{props.submitting && <Spinner />}</div>
    <Field className="form-control" component={TextArea} name="coverLetter.coverLetter" labelClass="hide" minRows={3} />
  </div>
);

FormCoverLetter.propTypes = {
  editOptions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  switchEditMode: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  // eslint-disable-line no-class-assign
  form: 'cover-letter',
})(FormCoverLetter);

import { requestBackend } from '@eva/emf/app/utils/request';

type updateEntityTreeType = (data: { skillTaxonomyId: number; skills: any[] }) => Promise<
  {
    skillId: number;
    skillProficiencyValue: null | number;
  }[]
>;

type updateSkillTaxonomyNoteType = (data: { skillTaxonomyId: number; note: string }) => Promise<{
  skillTaxonomies: {
    skillTaxonomyId: number;
    note: string;
  }[];
}>;

export const updateEntityTree: updateEntityTreeType = ({ skillTaxonomyId, skills }) =>
  requestBackend(`/my/candidate/skill-taxonomies/${skillTaxonomyId}/skills`, {
    method: 'PUT',
    body: JSON.stringify({
      skills,
    }),
  });

export const updateSkillTaxonomyNote: updateSkillTaxonomyNoteType = ({ skillTaxonomyId, note }) =>
  requestBackend(`/my/candidate/skill-taxonomies/${skillTaxonomyId}/note`, {
    method: 'PUT',
    body: JSON.stringify({
      note,
    }),
  });

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { reduxForm, Field } from 'redux-form/immutable';

import { Input } from '@eva/emf/app/shared/ui/Input';
import { socialProviders } from 'shared/constants';
import { linkifyError } from 'shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

class FormSocialLinks extends React.Component<any, any> {
  state = {
    showNotes: {},
  };

  UNSAFE_componentWillMount() {
    const { entity } = this.props;
    this.setState(
      socialProviders.reduce(
        (prev, cur) => ({
          ...prev,
          [`${cur}Hard`]: get(entity, `socialMediaLinks.${cur}.hard`),
        }),
        {},
      ),
    );
  }

  render() {
    const { submitting, handleSubmit, error } = this.props;

    return (
      <div onSubmit={handleSubmit}>
        <h3 className="text-primary">{translate('Social links')}</h3>
        {socialProviders.map((socialProvider) => (
          <Field
            key={socialProvider}
            name={`socialMediaLinks.${socialProvider}.url`}
            component={Input}
            disabled={this.state[`${socialProvider}Hard`]}
            label={socialProvider}
            nullify
          />
        ))}
        <div>
          {error && <div className="alert-danger alert">{linkifyError(error)}</div>}
          {submitting && <Spinner />}
        </div>
      </div>
    );
  }
}

// @ts-expect-error
FormSocialLinks.propTypes = {
  entity: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  jobEmploymentType: PropTypes.string,
  submitting: PropTypes.bool,
  dropzone: PropTypes.object,
  error: PropTypes.string,
  switchEditMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  enableReinitialize: true,
  // @ts-expect-error
})(FormSocialLinks);

/* eslint-disable no-restricted-properties */
import get from 'lodash/get';
import { useCallback, useMemo } from 'react';

import { windowLocationReload, wipeStorage } from '@eva/emf/app/shared/functions';

import { restartWorkflowCodes } from './constants';
import { getSignInFlowConfig } from './functions';
import SignInFormView from './SignInFormView';

interface SignInProps {
  user: {
    userId?: number;
  };
  userDetails: any;
}

const SignInForm: React.FC<SignInProps> = ({ user, userDetails }) => {
  const authTypes = localStorage.authTypes ? JSON.parse(localStorage.authTypes) : [];

  const candidateFlowStateCode = useMemo(() => get(userDetails, 'rw.workflow_state.code'), [userDetails]);

  const resetLocalStorageAndReload = useCallback(() => {
    Object.keys(localStorage).forEach((localStorageKey) => delete localStorage[localStorageKey]);
    windowLocationReload();
  }, []);

  const renderSignInLink = useMemo(
    () => (
      <a id="sign-in-submit" href="/auth/sign-in" onClick={() => wipeStorage()}>
        {translate('Sign in')}{' '}
      </a>
    ),
    [],
  );

  const workFlowConfig = getSignInFlowConfig(renderSignInLink, resetLocalStorageAndReload);

  const config = useMemo(() => {
    if (!user.userId) {
      return workFlowConfig.hasNotUser;
    }

    if (restartWorkflowCodes.includes(candidateFlowStateCode)) {
      return !!authTypes.length ? workFlowConfig.hasAuthMethods : workFlowConfig.hasNotAuthMethods;
    }
  }, [
    authTypes.length,
    candidateFlowStateCode,
    user.userId,
    workFlowConfig.hasAuthMethods,
    workFlowConfig.hasNotAuthMethods,
    workFlowConfig.hasNotUser,
  ]);

  return <SignInFormView img={config.img} title={config.title} body={config.body} />;
};

// eslint-disable-next-line import/no-default-export
export default SignInForm;

import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import { requestBackend } from '@eva/emf/app/utils/request';
import { providersLabels, providersTypes } from 'shared/constants';
import { copy, padTwo, prepareHighlights } from 'shared/functions';

import { eventTypes, profilesSourcesIds } from './constants';

const parsedFromResume = () => <span>{translate('Parsed from resume')}</span>;

export const getProfilesSourcesDescriptions = () => ({
  [profilesSourcesIds.sovren]: parsedFromResume(),
  [profilesSourcesIds.catsResume]: parsedFromResume(),
  [profilesSourcesIds.catsWebhook]: (
    <span>
      {translate('Received from {{source}}', {
        source: 'CATS',
      })}
    </span>
  ),
  [profilesSourcesIds.candidate]: <span>{translate('Confirmed by candidate')}</span>,
  [profilesSourcesIds.agent]: <span>{translate('Entered by agent')}</span>,
  [profilesSourcesIds.totaljobs]: (
    <span>
      {translate('Received from {{source}}', {
        source: providersLabels[providersTypes.totaljobs],
      })}
    </span>
  ),
  [profilesSourcesIds.jobsite]: (
    <span>
      {translate('Received from {{source}}', {
        source: providersLabels[providersTypes.jobsite],
      })}
    </span>
  ),
  [profilesSourcesIds.eMedCareers]: (
    <span>
      {translate('Received from {{source}}', {
        source: providersLabels[providersTypes.eMedCareers],
      })}
    </span>
  ),
});

export const confirmSection = (section) => {
  // @ts-expect-error
  const { profile } = this.state;
  requestBackend(`/candidates/${profile.userId}/confirm`, {
    method: 'PUT',
    body: JSON.stringify([section]),
  }).then(
    (res) =>
      // @ts-expect-error
      this.setState({
        profile: {
          ...profile,
          // @ts-expect-error
          ...res,
        },
      }),
    // @ts-expect-error
    () => this.setState({ error: `Failed to update section ${section}` }),
  );
};

export const yearAndMonthToDate = (date) => {
  if (!date) {
    return null;
  } else if (!date.month) {
    return date.year;
  }
  return `${date.year}-${padTwo(date.month)}`;
};

//***************************************************************************************
// Interim work history
//***************************************************************************************

export const updateInterimWorkHistory = (initialInterimWorkHistory, eventType, eventData) => {
  const interimWorkHistory = copy(initialInterimWorkHistory);
  const findInterimItemIndex = (interimWorkHistoryId, throwError = true) => {
    const itemIndex = interimWorkHistory.items.findIndex((item) => item.interimWorkHistoryId === interimWorkHistoryId);
    if (itemIndex === -1 && throwError) {
      throw new Error(`interimWorkHistory item with id ${interimWorkHistoryId} not found!`);
    }
    return itemIndex;
  };
  const interimItemIndex = findInterimItemIndex(eventData.interimWorkHistoryId, false);
  if (eventType === eventTypes.interimWorkHistoryCreated) {
    if (interimItemIndex === -1) {
      interimWorkHistory.items.unshift(eventData);
    } else {
      interimWorkHistory.items[interimItemIndex] = eventData;
    }
  } else if (interimItemIndex > -1) {
    if (eventType === eventTypes.interimWorkHistoryDeleted) {
      interimWorkHistory.items.splice(interimItemIndex, 1);
    } else if (eventType === eventTypes.interimWorkHistoryUpdated) {
      interimWorkHistory.items[interimItemIndex] = eventData;
    } else {
      throw new Error(`Unknown updateInterimWorkHistory event type ${eventType}`);
    }
  }
  return interimWorkHistory;
};

export const loadInterimWorkHistory = (highlight, interimWorkHistory, shortCount = 3) => {
  let totalExperienceHours = 0;
  const fullHistory = interimWorkHistory.items
    .sort((prev, next) => (prev.startedAt > next.startedAt ? -1 : 1))
    .map((item) => {
      item.startedAt = item.startedAt.substr(0, 10);
      item.endedAt = item.endedAt.substr(0, 10);
      if (!item.startedAt) {
        item.period = translate('No start date');
      } else if (!item.endedAt && !item.isCurrent) {
        item.period = translate('No end date and not current');
      } else {
        item.daysDiff = moment(item.endedAt).diff(moment(item.startedAt), 'days') || 1;
        item.period = moment.duration(item.daysDiff, 'days').humanize();
      }
      totalExperienceHours += item.hoursWorked;
      return item;
    });
  const currentHistory = fullHistory.slice(0, shortCount);
  const whMoreHours = fullHistory.slice(shortCount).reduce((prev, cur) => prev + (cur.hoursWorked || 0), 0);

  return {
    fullHistory,
    currentHistory,
    totalExperienceHours,
    whPeriodMore: whMoreHours ? `${whMoreHours} hrs` : '',
    confirmingSection: false,
    highlights: {
      ...prepareHighlights(highlight, 'interimWorkHistory.items.title'),
      ...prepareHighlights(highlight, 'interimWorkHistory.items.employer'),
      ...prepareHighlights(highlight, 'interimWorkHistory.items.description'),
    },
  };
};

//***************************************************************************************
// Permanent work history
//***************************************************************************************

const dateToYear = (date) => {
  if (date && typeof date === 'string') {
    if (date.length === 4) {
      return date;
    }
    return `${moment(date).year()}`;
  }
  return date;
};
const dateToYearAndMonth = (date) => ({
  year: dateToYear(date),
  month: typeof date === 'string' && date.length > 4 ? moment(date).month() + 1 : null,
});
const workHistoryItemInitialValues = (item) => ({
  ...item,
  initiallyEmptyTitle: !item.title,
  startDate: dateToYearAndMonth(item.startDate),
  endDate: dateToYearAndMonth(item.endDate),
});
export const loadWorkHistory = (workHistory, highlight) => {
  const workHistoryCopy = copy(workHistory.items).sort((prev, next) => {
    if (prev.isCurrent !== next.isCurrent) {
      return prev.isCurrent ? -1 : 1;
    }
    return prev.endedAtCalculated > next.endedAtCalculated ? -1 : 1;
  });

  const calculateGaps = (periods) => {
    const getMinMaxDate = () => {
      const minDate = moment(_.min(periods.map((p) => p.start))).startOf('month');
      const maxDate = moment(_.max(periods.map((p) => p.end))).endOf('month');

      return {
        minDate,
        maxDate,
      };
    };
    const getTimeline = ({ minDate, maxDate }) => {
      let length = moment(maxDate).diff(minDate, 'month');
      if (length < 0) {
        length = 0;
      }
      const timeline = _.fill(new Array(length), 0);
      _.each(periods, ({ start, end }) => {
        const startIndex = moment(start).diff(minDate, 'month');
        const length = moment(end).diff(moment(start), 'month');
        _.fill(timeline, 1, startIndex, startIndex + length);
      });
      return timeline;
    };

    const minMaxDate = getMinMaxDate();
    const initialDate = minMaxDate.minDate;
    const timeline = getTimeline(minMaxDate);
    const totalWorkedMonths = _.compact(timeline).length;

    let gapIndex = 0;
    const gapMap = {};
    while ((gapIndex = timeline.indexOf(0, gapIndex + 1)) !== -1) {
      const initGap = gapIndex;
      gapIndex = timeline.indexOf(1, gapIndex);

      const dateBeforeGap = moment(initialDate).add(initGap, 'month').endOf('month').format('yyyy-MM-DD');

      if (gapIndex === -1) {
        gapMap[dateBeforeGap] = timeline.length - initGap;
        break;
      }
      gapMap[dateBeforeGap] = gapIndex - initGap;
    }
    return [gapMap, totalWorkedMonths];
  };

  const now = moment().format('yyyy-MM-DD HH:MM:ss');
  const [gapMap, totalWorkedMonths] = calculateGaps(
    workHistoryCopy.map((whItem) => ({
      start: whItem.startedAtCalculated,
      end: whItem.endedAtCalculated || now,
    })),
  );

  const fullHistory = workHistoryCopy
    .reduce((prev, cur, index) => {
      const item = prepareWhItem(cur);
      item.initialValues = workHistoryItemInitialValues(item);
      const nextItem = workHistoryCopy[index + 1] || {};
      const nextEndedMoment = moment(nextItem.endedAtCalculated);
      const nextEndedString = nextEndedMoment.endOf('month').format('yyyy-MM-DD');
      const gap = nextEndedMoment ? gapMap[nextEndedString] : null;

      return [
        ...prev,
        ...(gap > 1
          ? [
              item,
              {
                gap: moment.duration(gap, 'months').humanize(),
                date: nextEndedString,
              },
            ]
          : [item]),
      ];
    }, [])
    .filter((whItem, index, self) =>
      whItem.date ? index === self.findIndex((value) => value.date === whItem.date) : true,
    );
  const metadata = workHistory.metadata || {};

  const highlights = {
    ...prepareHighlights(highlight, 'workHistory.items.title'),
    ...prepareHighlights(highlight, 'workHistory.items.employer'),
    ...prepareHighlights(highlight, 'workHistory.items.description'),
  };

  return {
    fullHistory,
    totalExperience: moment.duration(totalWorkedMonths, 'months').humanize(),
    currentHistory: fullHistory,
    outdated: metadata.isOutdated || metadata.isTrustedSource === false,
    confirmingSection: false,
    highlights,
  };
};
export const updateWorkHistory = (initialWorkHistory, eventType, eventData) => {
  const workHistory = {
    ...copy(initialWorkHistory),
    initialValues: workHistoryItemInitialValues(initialWorkHistory),
  };
  const findItemIndex = (workHistoryId, throwError = true) => {
    const itemIndex = workHistory.items.findIndex((item) => item.workHistoryId === workHistoryId);
    if (itemIndex === -1 && throwError) {
      throw new Error(`workHistory item with id ${workHistoryId} not found!`);
    }
    return itemIndex;
  };
  const itemIndex = findItemIndex(eventData.workHistoryId, false);
  if (eventType === eventTypes.workHistoryCreated) {
    if (itemIndex === -1) {
      workHistory.items.unshift(eventData);
    } else {
      workHistory.items[itemIndex] = eventData;
    }
  } else if (itemIndex > -1) {
    if (eventType === eventTypes.workHistoryDeleted) {
      workHistory.items.splice(itemIndex, 1);
    } else if (eventType === eventTypes.workHistoryUpdated) {
      workHistory.items[itemIndex] = eventData;
    } else {
      throw new Error(`Unknown updateWorkHistory event type ${eventType}`);
    }
  }
  return workHistory;
};
const formatWorkHistoryDate = (date, dash?) => {
  let formattedDate;
  if (`${date}`.length === 4) {
    formattedDate = date;
  } else if (date) {
    formattedDate = moment(date).format('MMM YYYY');
  } else {
    return '';
  }
  return `${formattedDate}${dash ? ' - ' : ''}`;
};
const formatWorkHistoryPeriod = ({ startDate, endDate, startMoment, endMoment, isCurrent }) => {
  const period: any = {};
  if (startDate && startDate === endDate) {
    period.label = formatWorkHistoryDate(startDate);
    period.diff = startDate.length === 4 ? 'a year' : 'a month';
  } else if (!startDate || (!endDate && !isCurrent)) {
    const someDate = startDate || endDate;
    period.diff = someDate ? formatWorkHistoryDate(someDate) : '';
  } else {
    const daysDiff = endMoment.diff(moment(startMoment), 'days') || 1;
    const secondDate = isCurrent ? translate('now') : formatWorkHistoryDate(endDate);
    period.label = `${formatWorkHistoryDate(startDate, secondDate)}${secondDate}`;
    period.diff = moment.duration(daysDiff, 'days').humanize();
  }
  return period;
};
export const prepareWhItem = (item) => {
  if (!item.startDate) {
    item.period = {
      label: '',
      diff: translate('No start date'),
    };
  } else if (!item.endDate && !item.isCurrent) {
    item.period = {
      label: '',
      diff: translate('No end date and not current'),
    };
  } else {
    item.startMoment = moment(item.startedAtCalculated);
    item.endMoment = moment(item.endedAtCalculated || undefined);
    item.daysDiff = item.endMoment.diff(item.startMoment, 'days') || 1;
    item.period = formatWorkHistoryPeriod(item);
  }
  return item;
};

//***************************************************************************************
// Education
//***************************************************************************************
const getEducationDateFormat = (date) => {
  if (!date) {
    return null;
  }

  return date.split('-')[1] != null ? 'MMM YYYY' : 'YYYY';
};

export const preparePeriod = (startDate, endDate) => {
  const startMoment = startDate ? moment(startDate) : null;
  const endMoment = endDate ? moment(endDate) : moment();
  const startDateFormat = getEducationDateFormat(startDate);
  const endDateFormat = getEducationDateFormat(endDate);

  let label;
  let years;
  let months;
  if (startMoment && startMoment.isValid() && endMoment.isValid()) {
    const startLabel = startMoment.format(startDateFormat);
    const endLabel = endMoment.format(endDateFormat);
    label = startLabel === endLabel ? `${translate('in')} ${endLabel}` : `${startLabel} - ${endLabel}`;
    const diff = endMoment.diff(startMoment);
    const duration = moment.duration(diff, 'ms');
    years = duration.years();
    months = duration.months();
  } else if (endMoment.isValid()) {
    label = `${translate('until')} ${endMoment.format(endDateFormat)}`;
  } else if (startMoment && startMoment.isValid()) {
    label = `${translate('from')} ${startMoment.format(startDateFormat)}`;
  }
  return {
    label,
    years,
    months,
  };
};

export const prepareEducationItem = (item) => {
  const structuredStartDate = dateToYearAndMonth(item.startDate);
  const structuredEndDate = dateToYearAndMonth(item.endDate);

  item.initialValues = {
    ...item,
    institutionId: item.institution || {
      title: item.schoolName,
    },
    startDate: structuredStartDate,
    endDate: structuredEndDate,
  };

  return {
    ...item,
    period: preparePeriod(item.startDate, item.endDate),
  };
};
export const updateEducation = (initialEducation, eventType, eventData) => {
  const education = prepareEducationItem(copy(initialEducation));
  const findItemIndex = (educationId, throwError = true) => {
    const itemIndex = education.degrees.findIndex((item) => item.educationId === educationId);
    if (itemIndex === -1 && throwError) {
      throw new Error(`education item with id ${educationId} not found!`);
    }
    return itemIndex;
  };
  const itemIndex = findItemIndex(eventData.educationId, false);
  if (eventType === eventTypes.educationCreated) {
    if (itemIndex === -1) {
      education.degrees.unshift(eventData);
    } else {
      education.degrees[itemIndex] = eventData;
    }
  } else if (itemIndex > -1) {
    if (eventType === eventTypes.educationDeleted) {
      education.degrees.splice(itemIndex, 1);
    } else if (eventType === eventTypes.educationUpdated) {
      education.degrees[itemIndex] = eventData;
    } else {
      throw new Error(`Unknown updateEducation event type ${eventType}`);
    }
  }
  return education;
};
const stepstonePrefixes = ['caterer', 'jobsite', 'e-med-careers', 'reed', 'totaljobs-group', 'totaljobs'];
export const isStepstoneCandidate = (compositeId) =>
  stepstonePrefixes.find((stepstonePrefix) => compositeId.startsWith(`${stepstonePrefix}/`));
const stateBadge = (state) => (
  <span
    className="badge show-label"
    style={{
      background: state.color,
    }}
  >
    <span>{state.name}</span>
  </span>
);
export const showPipelineStateChangedPopup = (
  normalizedPipelineStates,
  fromWorkflowStateId,
  toWorkflowStateId,
  entitiesLabel,
) => {
  const fromState = normalizedPipelineStates[fromWorkflowStateId];
  const toState = normalizedPipelineStates[toWorkflowStateId] || {};
  window.setNotificationComponent(
    <div className="text-left">
      <span className="notification-icon pull-left margin-right">
        <i className="lnr lnr-bullhorn" />
        <i className="ll-icon ll-logo-eva-small fa-2x text-primary" />
      </span>
      <h4 className="text-success margin-left pull-left">Success!</h4>
      <div className="clearfix" />
      {entitiesLabel} successfully moved
      {fromState ? <span>from state {stateBadge(fromState)}</span> : ''} to state {stateBadge(toState)}
    </div>,
  );
  setTimeout(() => window.setNotificationComponent(null), 3000);
};

import PropTypes from 'prop-types';
import React from 'react';

import { requestBackend } from '@eva/emf/app/utils/request';
import { replaceUrl, stringifyError } from 'shared/functions';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

// eslint-disable-next-line import/no-default-export
export default class CardSettings extends React.PureComponent<any, any> {
  state = {};

  forgetMe() {
    this.setState({
      forgetting: true,
      error: '',
    });
    requestBackend('/my/candidate-profile/forget', {
      method: 'POST',
    }).then(
      () => {
        Object.keys(localStorage).forEach((localStorageKey) => delete localStorage[localStorageKey]);
        replaceUrl('/candidate');
      },
      (err) => {
        this.setState({
          forgetting: false,
          error: stringifyError(err),
        });
      },
    );
  }

  render() {
    // @ts-expect-error
    const { forgetMode, forgetting, error } = this.state;

    return (
      <div>
        <h3 className="text-primary">{translate('Settings')}</h3>
        {error && <div className="text-danger">{error}</div>}
        <hr className="dotted" />
        {!forgetMode && (
          <button
            type="submit"
            className="btn btn-sm btn-tool-box text-danger no-padding"
            onClick={() => this.setState({ forgetMode: true })}
          >
            <i className="lnr lnr-cross" /> {translate('Forget me')}
          </button>
        )}
        {forgetMode && (
          <div>
            <p className="margin-bottom text-danger">
              {translate('This action will remove all of your information and records from the system.')}
            </p>
            <button
              type="submit"
              className="btn btn-sm btn-danger margin-right"
              disabled={forgetting}
              onClick={() => this.forgetMe()}
            >
              {forgetting && <SpinnerSmall />} {translate('Forget me')}
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-default"
              disabled={forgetting}
              onClick={() => this.setState({ forgetMode: false })}
            >
              {translate('Cancel')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
CardSettings.propTypes = {
  entity: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
};

/* eslint-disable no-restricted-properties */
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import throttle from 'lodash/throttle';

import { acceptCvExtensions } from '@eva/emf/app/shared/constants';
import { getQueryVariables } from '@eva/emf/app/shared/functions';
import { leftMenuModes, typingDelay } from 'shared/constants';
import {
  addXhrListeners,
  getConversationId,
  getMessageOptions,
  guid,
  getParsedUser,
  showStickyDate,
} from 'shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

import ModalAlert from 'containers/sharedModals/ModalAlert';
import Message from 'containers/Message';

import BottomForm from './BottomForm';

import './style.css';
import './social-buttons.css';

const eventTypes = {
  typing: 'typing',
  webchatMessageSent: 'webchat-message-sent',
  omnichatMessageSent: 'omnichat-message-sent',
};

const disableOutput = [
  '<address',
  '<checkboxes',
  '<dateselector',
  '<multiselect',
  '<notification',
  '<radiobuttons',
  '<salaryandperiod',
  '<tnc',
  '<uploadfile',
  '<over',
  '<email',
  '<skillswidget',
  '<skilltaxonomywidget',
];
const bottomElements = [
  '<address',
  '<checkboxes',
  '<multiselect',
  '<radiobuttons',
  '<salaryandperiod',
  '<uploadfile',
  '<email',
  '<tnc',
  '<skillswidget',
  '<skilltaxonomywidget',
];

const allowedExtension = '.doc,.docx,.dot,.rtf,.pdf,.odt,.txt,.htm,.html,.jpg,.jpeg,.png';
const smallWrapper = (text) => (
  <div>
    <small className="text-muted">{text}</small>
  </div>
);
const aiSelectionMode = (message, preLast?) =>
  message && message.ai && (preLast || disableOutput.find((item) => message.content.includes(item)));

function handleClickOutside(evt) {
  if (evt.which !== 3 && this.messageMenu && !this.messageMenu.contains(evt.target)) {
    this.setState({ showMessageOptions: 0 });
  }
  return true;
}

function handleScrollMessages() {
  const { settings, chat, messages, loadingMessages, dispatchLoadMessages } = this.props;

  if (!this.messagesContainer) {
    return;
  } else if (this.messagesScrollTop === -1) {
    this.messagesScrollTop = this.messagesContainer.scrollTop;
    return;
  }
  const scrolledDown =
    this.messagesContainer.scrollTop + this.messagesContainer.clientHeight >= this.messagesContainer.scrollHeight;

  showStickyDate();

  if (this.messagesContainer.scrollTop > this.messagesScrollTop) {
    // If scrolled to bottom - glue it
    if (scrolledDown) {
      this.glueScroll = true;
    }
    // Scroll height wasn't changed = scrolled by user
  } else if (this.messagesContainer.scrollHeight === this.messagesHeight) {
    if (!this.messagesContainer.scrollTop) {
      // If scrolled to the top - try to load more messages
      const scrollingEnabled = this.messagesContainer.clientHeight !== this.messagesContainer.scrollHeight;
      if (!chat.loaded && !loadingMessages[chat.conversationId] && scrollingEnabled) {
        this.setState({
          heightBeforeLoad: this.messagesContainer.scrollHeight,
        });
        dispatchLoadMessages(
          chat.conversationId,
          messages[0].messageId || messages[0].omnichatMessageId,
          settings.general.companyName,
        );
      }
    }
    if (!scrolledDown && this.messagesScrollTop !== this.messagesContainer.scrollTop) {
      this.glueScroll = false;
    }
  }
  this.messagesScrollTop = this.messagesContainer.scrollTop;
  this.setState({
    showScrollDown: !scrolledDown,
  });
}

class MessengerChat extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    content: '',
    showMessageOptions: 0,
    editMessageId: 0,
    editMessageContent: '',
    acceptExtensions: allowedExtension,
    bottomMode: false,
    typingUsers: {},
  };
  windowFocusedHandler: () => void;
  throttledScrollDown: any;
  windowResizedHandler: () => void;
  outsideClickHandler: (this: Document, ev: MouseEvent) => any;
  scrollMessagesHandler: any;
  glueScroll: boolean;
  unmounted: any;
  messagesContainer: any;
  messageMenu: any;
  heightChangeCouner: any;
  textareaHolder: any;
  textarea: null;
  warmingShown: any;
  modalAlert: ModalAlert;
  static contextTypes: { settings: PropTypes.Validator<object> };
  messagesHeight: any;
  messagesContainerHeight: any;
  oldMessagesContainer: any;

  UNSAFE_componentWillMount() {
    const { socket, toggleLeft, mobileMode } = this.props;

    const query = getQueryVariables();
    // @ts-expect-error
    this.messagesScrollTop = -1;
    this.checkBottomMode();
    window.sendGAEvent('LoadChat', 'pageload', 'EvaChat-1-Initial');
    if (socket) {
      this.connectSocketEvents(socket);
    }
    if (mobileMode) {
      if (query.rp) {
        toggleLeft(leftMenuModes.menu);
      } else if (query.email) {
        toggleLeft(leftMenuModes.signIn);
      }
    }
  }

  componentDidMount() {
    const { dispatchMarkLocalRead, chat, messages } = this.props;

    if (messages.length) {
      dispatchMarkLocalRead(getConversationId(chat));
      this.delayScrollDown();
    }

    this.scrollMessagesHandler = handleScrollMessages.bind(this);

    this.outsideClickHandler = handleClickOutside.bind(this);
    this.windowFocusedHandler = () => this.delayScrollDown();
    this.throttledScrollDown = throttle(this.delayScrollDown.bind(this), 10, {
      leading: true,
      trailing: false,
    });
    this.windowResizedHandler = () => {
      setTimeout(() => this.throttledScrollDown(), 10);
      if (this.props.mobileMode) {
        setTimeout(() => this.throttledScrollDown(), 200);
      }
    };
    document.addEventListener('click', this.outsideClickHandler);
    window.addEventListener('focus', this.windowFocusedHandler, true);
    window.addEventListener('resize', this.windowResizedHandler, true);
    this.glueScroll = true;

    addXhrListeners.bind(this)();
    this.scrollMessagesHandler();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, socket, chat, messages, dispatchMarkLocalRead } = nextProps;

    const conversationIdChanged = this.props.chat && this.props.chat.conversationId !== chat.conversationId;
    if ((!this.unmounted && !user.userId) || conversationIdChanged) {
      this.glueScroll = true;
      this.setState({ typingUsers: {} });
    }

    if (this.props.messages !== messages) {
      if (messages.length) {
        dispatchMarkLocalRead(getConversationId(chat));
        this.checkBottomMode(nextProps);
      } else {
        showStickyDate(false);
        this.setState(
          {
            selectionMode: true,
            bottomMode: false,
            lastMessage: {},
          },
          () => this.updateMessagesHeight(),
        );
      }
    }

    if (!this.props.socket && socket) {
      this.connectSocketEvents(socket);
    } else if (this.props.socket && !socket) {
      this.disconnectSocket();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { chat, loadingMessages } = this.props;
    // @ts-expect-error
    const { heightBeforeLoad, content } = this.state;

    if (
      this.messagesContainer &&
      (!this.scrollMessagesHandler || this.oldMessagesContainer !== this.messagesContainer)
    ) {
      this.oldMessagesContainer = this.messagesContainer;
      this.messagesContainer.removeEventListener('scroll', this.scrollMessagesHandler, true);
      this.messagesContainer.addEventListener('scroll', this.scrollMessagesHandler, true);
    }
    // New messages loaded - update scroll
    if (heightBeforeLoad && prevProps.loadingMessages[chat.conversationId] && !loadingMessages[chat.conversationId]) {
      this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight - heightBeforeLoad - 50;
    }

    if (content !== prevState.content) {
      this.updateMessagesHeight();
    }
    if (prevProps.messages !== this.props.messages) {
      this.setAcceptedExtensions();
    }
    this.delayScrollDown();
  }

  componentWillUnmount() {
    this.unmounted = true;
    document.removeEventListener('click', this.outsideClickHandler);
    window.removeEventListener('onfocus', this.windowFocusedHandler, true);
    window.removeEventListener('resize', this.windowResizedHandler, true);
    if (this.messagesContainer) {
      this.messagesContainer.removeEventListener('scroll', this.scrollMessagesHandler, true);
    }
    this.disconnectSocket();
  }

  onTyping = (payload) => {
    // @ts-expect-error
    this.setTypingUsers(payload);
  };

  onNewMessage = (payload) => {
    const { chat } = this.props;

    if (this.unmounted) {
      return;
    }

    if (`${getConversationId(payload)}` === `${chat.conversationId || ''}`) {
      this.setState({ typingUsers: {} });
    }
  };

  setMessageMenu = (messageMenu) => {
    this.messageMenu = messageMenu;
  };

  setAcceptedExtensions = () => {
    const { messages } = this.props;

    const length = messages?.length;
    const lastMessage = length > 0 ? messages?.[length - 1] : null;

    const attachmentExpectedTypes = lastMessage?.options?.attachmentExpectedTypes;

    const acceptExtensions = attachmentExpectedTypes || allowedExtension;

    if (this.state.acceptExtensions !== acceptExtensions) {
      this.setState({
        acceptExtensions,
      });
    }
  };

  connectSocketEvents(socket) {
    socket.on(eventTypes.typing, this.onTyping);
    socket.on(eventTypes.webchatMessageSent, this.onNewMessage);
    socket.on(eventTypes.omnichatMessageSent, this.onNewMessage);
  }

  disconnectSocket() {
    const { socket } = this.props;

    if (socket) {
      socket.removeListener(eventTypes.typing, this.onTyping);
      socket.removeListener(eventTypes.webchatMessageSent, this.onNewMessage);
    }
  }

  setTypingUsers(event, time) {
    const { chat } = this.props;
    const { typingUsers } = this.state;

    const {
      user: {
        userId,
        name: { displayName },
      },
      conversationId,
    } = event;

    const anotherConversation = `${conversationId}` !== `${chat.conversationId}`;
    const sameUser = `${userId}` === `${getParsedUser().userId}`;
    if (anotherConversation || sameUser) {
      return;
    }

    if (time) {
      if (typingUsers[displayName] === time) {
        delete typingUsers[displayName];
      }
    } else {
      const added = new Date().getTime();
      typingUsers[displayName] = added;
      setTimeout(() => this.setTypingUsers(event, added), typingDelay);
    }
    if (!this.unmounted) {
      this.setState({ typingUsers });
    }
  }

  checkBottomMode(props = this.props) {
    const { user, userDetails, messages } = props;

    if (messages.length) {
      /** @deprecated TODO remove in DEV-25601 skillsSet logic */
      if (localStorage.fakeAIMessage && messages.length) {
        // eslint-disable-line no-constant-condition
        const currentLasMessage = messages[messages.length - 1];
        const newId = currentLasMessage.messageId + 999;
        const aiMessage = {
          ...currentLasMessage,
          isJob: false,
          sentOn: currentLasMessage.sentOn.add(-3, 'm'),
          sender_id: 8, // eslint-disable-line camelcase
          messageId: newId,
          content: 'This is a custom AI message for tests',
          kiki: true,
          ai: true,
          agentSide: true,
          options: {
            candidateCustomField: 'skillsSetQ2',
          },
        };
        messages.splice(messages.length - 2, 0, aiMessage);
        messages.splice(messages.length - 1, 0, {
          ...aiMessage,
          messageId: newId + 1,
        });
        messages.push({
          ...aiMessage,
          messageId: newId + 2,
        });
      }

      const registered = get(userDetails, 'rw.workflow_state.code') === 'registered';
      const lastMessage = messages[messages.length - 1];

      /** @deprecated TODO remove in DEV-25601 skillsSet logic */
      if (get(lastMessage, 'options.widget[0]') === 'candidateSkills') {
        lastMessage.content = `${lastMessage.content} <skilltaxonomywidget skillTaxonomyId=${lastMessage.options.skillTaxonomyId} />`;
      }

      const preLastMessage = messages.length > 1 ? messages[messages.length - 2] : { content: '' };
      const lastMessageContent = typeof lastMessage.content === 'string' ? lastMessage.content : '';

      // Define textarea and bottom mode behaviour
      const signUpMode = lastMessageContent.includes('<email');
      const awaitingEvaMode = aiSelectionMode(lastMessage);
      const selectionMode = awaitingEvaMode && !signUpMode;
      const awaitingAI =
        lastMessage.sender_id === user.userId &&
        preLastMessage.ai &&
        !preLastMessage.content.includes('A very warm welcome') &&
        Date.now();
      const bottomMode =
        !(registered && signUpMode) &&
        Boolean(
          lastMessageContent.includes('<notification') ||
            bottomElements.find((item) => lastMessageContent.includes(item)) ||
            getMessageOptions(lastMessage).hideTextarea,
        );
      this.setState(
        {
          selectionMode,
          bottomMode,
          awaitingAI,
          lastMessage,
          justRegistered: registered && signUpMode,
        },
        () => {
          this.updateMessagesHeight();
          if (awaitingAI) {
            setTimeout(() => {
              // @ts-expect-error
              if (!this.unmounted && Date.now() - this.state.awaitingAI > 4500) {
                this.setState({ awaitingAI: null }, () => {
                  this.updateMessagesHeight();
                  setTimeout(() => this.updateMessagesHeight(), 200);
                });
              }
            }, 5000);
          }
        },
      );
      if (selectionMode) {
        this.textareaHolder = null;
        this.textarea = null;
      }
    }
  }

  updateMessagesHeight = () => {
    if (this.messagesContainer) {
      const textareaHeight = this.textareaHolder ? this.textareaHolder.offsetHeight : 0;
      const newHeight = `${document.documentElement.clientHeight - 48 - 1 - textareaHeight}px`;
      const heightDiff = parseInt(this.messagesContainer.style.height, 0) - parseInt(newHeight, 0);
      if (!this.messagesContainer.style.height || (heightDiff && ![-1, -2].includes(heightDiff))) {
        this.heightChangeCouner = this.heightChangeCouner || 0;
        this.heightChangeCouner++;
        this.messagesContainer.style.height = newHeight;
      }
    }
  };

  delayScrollDown = () => {
    const { mobileMode } = this.props;
    const updateAndScroll = () => {
      this.updateMessagesHeight();
      this.scrollDown();
    };
    setTimeout(() => updateAndScroll());
    if (mobileMode) {
      setTimeout(updateAndScroll, 500);
    }
  };

  scrollDown = () => {
    if (this.messagesContainer) {
      // If scroll glued, messages container rendered and it's height changed
      const messagesHeightChanged = this.messagesContainer.scrollHeight !== this.messagesHeight;
      const messagesContainerHeightChanged = this.messagesContainer.style.height !== this.messagesContainerHeight;
      if (this.glueScroll && (messagesHeightChanged || messagesContainerHeightChanged)) {
        this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight;
      }
      this.messagesHeight = this.messagesContainer.scrollHeight;
      this.messagesContainerHeight = this.messagesContainer.style.height;
    }
  };

  defaultEvent() {
    const { chat } = this.props;

    return {
      conversationId: chat.conversationId,
    };
  }

  deleteMessage = (message) => {
    const { socket } = this.props;
    this.setState({ showMessageOptions: 0 });
    socket.emit('delete-message', {
      ...this.defaultEvent(),
      message,
    });
  };

  updateContent = (evt) => {
    evt.preventDefault();
    const { socket } = this.props;
    const { editMessageId, editMessageContent } = this.state;

    this.setState({
      editMessageId: 0,
    });

    socket.emit('update-content', {
      ...this.defaultEvent(),
      message: editMessageId,
      content: editMessageContent,
    });
  };

  emitSendMessageAndScroll = (...args) => {
    const { emitSendMessage } = this.props;

    emitSendMessage(...args);
    this.glueScroll = true;
    this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight;
  };

  setTopicsMode = (topicsMode, cb) => {
    this.setState(
      {
        topicsMode,
      },
      () => typeof cb === 'function' && cb(),
    );
  };

  render() {
    const { chat, messages, user, settings, newCandidate, loadingMessages, className, centralContent, mobileMode } =
      this.props;

    const {
      showMessageOptions,
      showScrollDown,
      bottomMode,
      justRegistered,
      selectionMode,
      awaitingAI,
      topicsMode,
      lastMessage,
      acceptExtensions,
      textareaFocused,
      typingUsers,
    } = this.state as any;

    const typingUsersKeys = Object.keys(typingUsers);

    if (centralContent) {
      return centralContent;
    }

    if (!Object.keys(chat).length) {
      return null;
    }

    const evaGreetingsReady = user.userId || !!settings.evaGreetings;

    if (!evaGreetingsReady) {
      if (!this.warmingShown) {
        const warmingShownID = guid();
        this.warmingShown = warmingShownID;
        // TODO implement notification about issue in DEV-27457
        // setTimeout(() => {
        //   if (this.warmingShown === warmingShownID) {
        //     window.bsNotify('Warming up message timeout reached', {
        //       warmingUpNotificationSeconds,
        //       evaGreetingsReady,
        //       suppressWarmingUp,
        //       userId: user.userId,
        //       evaGreetings: !!settings.evaGreetings,
        //     });
        //   }
        // }, ms(`${warmingUpNotificationSeconds}s`));
      }
      return (
        <h3 className="text-center" style={{ paddingTop: '20px' }}>
          <Spinner /> {translate('Warming up')}...
          {!settings.evaGreetings && smallWrapper('Loading greetings')}
        </h3>
      );
    }

    this.warmingShown = null;
    const loadingChatMessages = loadingMessages[getConversationId(chat)];

    return (
      <div className={className}>
        <ModalAlert key="modalAlert" ref={(ref) => (this.modalAlert = ref)} />
        <div
          key="messagesContainer"
          ref={(ref) => {
            if (ref && (!this.messagesContainer || this.messagesContainer !== ref)) {
              this.messagesContainer = ref;
              this.delayScrollDown();
            }
          }}
          className="conv-wrapper candidate"
        >
          <div className="chat active-chat">
            {!newCandidate && !!messages.length && loadingMessages[chat.conversationId] && (
              <div
                className="text-center"
                style={{
                  margin: '10px',
                }}
              >
                <Spinner />
              </div>
            )}

            {loadingChatMessages && (
              <div className="text-center text-muted margin-min-vertical">Loading messages...</div>
            )}

            {!messages.length && !loadingChatMessages && (
              <div className="text-center text-muted margin-min-vertical">There are no messages in this chat yet</div>
            )}

            {messages.map((message, index) => (
              <Message
                key={`${message.messageId || message.omnichatMessageId}${message.updatedAt}`}
                index={index}
                user={user}
                settings={settings}
                messages={messages}
                message={message}
                showOptionsMode={showMessageOptions === message.messageId}
                deleteMessage={this.deleteMessage}
                updateContent={this.updateContent}
                setMessageMenu={this.setMessageMenu}
                delayScrollDown={this.delayScrollDown}
                emitSendMessage={this.emitSendMessageAndScroll}
                isLastMessage={messages.length - 1 === index}
              />
            ))}

            <div
              style={{
                height: '25px',
                margin: '0 0 -20px 0',
              }}
            >
              {!!typingUsersKeys.length && (
                <div className="typing">
                  {typingUsersKeys.join(', ')} {translate('is typing')}{' '}
                  {[0, 1, 2].map((item) => (
                    <i key={item} className="fa fa-circle" />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {showScrollDown && (
          <button
            key="chevron-down"
            type="button"
            className="btn btn-default btn-bottom"
            onClick={() => {
              this.glueScroll = true;
              this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight;
            }}
          >
            <i className="fa fa-chevron-down" />
          </button>
        )}

        <div ref={(ref) => (this.textareaHolder = ref)}>
          <BottomForm
            chat={chat}
            messages={messages}
            lastMessage={lastMessage}
            acceptExtensions={acceptExtensions || acceptCvExtensions}
            setTopicsMode={this.setTopicsMode}
            delayScrollDown={this.delayScrollDown}
            emitSendMessageAndScroll={this.emitSendMessageAndScroll}
            // @ts-expect-error
            updateMessagesHeight={this.updateMessagesHeight}
            selectionMode={selectionMode}
            textareaFocused={textareaFocused}
            awaitingAI={awaitingAI}
            topicsMode={topicsMode}
            mobileMode={mobileMode}
            bottomMode={bottomMode || !!justRegistered}
            newCandidate={newCandidate}
          />
        </div>
      </div>
    );
  }
}

MessengerChat.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
MessengerChat.propTypes = {
  chat: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  newCandidate: PropTypes.bool.isRequired,
  suppressWarmingUp: PropTypes.bool.isRequired,
  messages: PropTypes.array,
  socket: PropTypes.object,
  loadingMessages: PropTypes.object.isRequired,
  mobileMode: PropTypes.bool.isRequired,
  centralContent: PropTypes.element,
  showSystemError: PropTypes.func,
  className: PropTypes.string.isRequired,
  toggleLeft: PropTypes.func.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  dispatchLoadMessages: PropTypes.func.isRequired,
  dispatchMarkLocalRead: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default MessengerChat;

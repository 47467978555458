import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';

import 'containers/WeekScheduler/react-week-scheduler.css';
import { availabilitySlotsMinutes, availabilitySlotsNumber, availabilityDayStart, zeroTime } from 'shared/constants';
import { copy, padTwo } from 'shared/functions';

import WeekScheduler from 'containers/WeekScheduler';

const eventTime = (minutes) => `${padTwo(Math.floor(minutes / 60))}:${padTwo(minutes % 60)}`;
const sameEvent = (dayEvent) => (slot) =>
  slot.startTime === zeroTime &&
  slot.availabilityType === dayEvent.availabilityType &&
  slot.overlap === dayEvent.overlap;
const eventEnd = (days, dayEvent, dayIndex, eventEndTime = dayEvent.endTime, counter = 0) => {
  if (['24:00', zeroTime].includes(eventEndTime)) {
    const nextDayIndex = dayIndex === 6 ? 0 : dayIndex + 1;
    const nextDayEvent = days[nextDayIndex].slots.find(sameEvent(dayEvent));
    if (counter < 7 && nextDayEvent) {
      return eventEnd(days, dayEvent, nextDayIndex, get(nextDayEvent, 'endTime') || eventEndTime, counter + 1);
    }
  }
  return eventEndTime;
};

// eslint-disable-next-line import/no-default-export
export default class PermanentAvailabilityEditor extends React.Component<any, any> {
  state = {
    events: [],
    currentSchedule: [],
    daysData: {},
    formatAvailabilityTime: (time) => time,
  };

  UNSAFE_componentWillMount() {
    this.initSettings();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { permanentAvailability, settings } = nextProps;
    if (this.props.settings !== settings) {
      this.initSettings(nextProps);
    } else if (this.props.permanentAvailability !== permanentAvailability) {
      this.eventsToSchedule(nextProps);
    }
  }

  initSettings(props = this.props) {
    const { settings } = props;
    this.setState(
      {
        formatAvailabilityTime: (workHour) =>
          workHour ? moment(workHour, 'HH:mm').format(settings.dateFormats.timeFormat) : '--',
      },
      () => this.eventsToSchedule(props),
    );
  }

  eventsToSchedule(props = this.props) {
    const {
      permanentAvailability: { days },
      availabilityTypes,
    } = props;
    const { formatAvailabilityTime } = this.state;
    const defaultSlot = availabilityTypes[0];
    const currentSchedule = [];
    const daysData = {};
    for (let i = 0; i < 7; i += 1) {
      const day = [];
      const dayEvents = days[i].slots;
      daysData[i] = Object.keys(days[i]).reduce(
        (prev, cur) =>
          cur === 'slots'
            ? prev
            : {
                ...prev,
                [cur]: days[i][cur],
              },
        {},
      );
      let dayEvent;
      for (let j = 0; j < availabilitySlotsNumber; j += 1) {
        const minutes = j * availabilitySlotsMinutes;
        const time = `${padTwo(Math.floor(minutes / 60))}:${padTwo(minutes % 60)}`;
        if (!dayEvent || (dayEvent.endTime !== zeroTime && dayEvent.endTime === time)) {
          dayEvent = dayEvents.find((item) => item.startTime === time);
        }
        if (dayEvent && dayEvent.availabilityType === -1) {
          dayEvent.availabilityType = 0;
        }
        const eventType = dayEvent && availabilityTypes[dayEvent.availabilityType];
        const timeInterval =
          dayEvent && j && dayEvent.startTime === time
            ? `${formatAvailabilityTime(dayEvent.startTime)} - ${formatAvailabilityTime(eventEnd(days, dayEvent, i))}`
            : '';
        day.push(
          dayEvent
            ? {
                ...eventType,
                timeInterval,
                color:
                  typeof dayEvent.overlap !== 'boolean' || dayEvent.overlap ? eventType.color : eventType.lightenColor,
              }
            : defaultSlot,
        );
      }
      currentSchedule.push(day);
    }

    const scheduleSlices = currentSchedule.reduce((prev, cur) => [...prev, cur.splice(0, availabilityDayStart)], []);
    scheduleSlices.push(scheduleSlices.shift());
    currentSchedule.forEach((scheduleDay, index) => Array.prototype.push.apply(scheduleDay, scheduleSlices[index]));

    this.setState({
      currentSchedule,
      daysData,
    });
  }

  updateEvents = (data) => {
    const {
      updateAvailability,
      permanentAvailability: { days },
      availabilityTypes,
    } = this.props;
    const { daysData } = this.state;
    const startEvent = (dayEvents, event, minutes) => {
      let availabilityType = availabilityTypes.indexOf(event);
      if (availabilityType === -1) {
        availabilityType = availabilityTypes.findIndex((eventType) => eventType.event === event.event);
      }
      if (availabilityType === -1) {
        throw new Error(`Unknown event type ${event.event}`);
      }
      dayEvents.push({
        availabilityType,
        startTime: eventTime(minutes),
      });
      return event.event;
    };
    const defaultSlot = availabilityTypes[0];
    const schedule = copy(days);
    const dataCopy = data.reduce((prev, cur) => [...prev, cur.slice()], []);

    const scheduleSlices = dataCopy.reduce(
      (prev, cur) => [...prev, cur.splice(availabilitySlotsNumber - availabilityDayStart)],
      [],
    );
    scheduleSlices.unshift(scheduleSlices.pop());
    dataCopy.forEach((scheduleDay, index) => scheduleDay.unshift(...scheduleSlices[index]));

    for (let i = 0; i < 7; i += 1) {
      Object.assign(schedule[i], daysData[i]);
      const dayEvents = schedule[i].slots;
      dayEvents.length = 0;
      let eventStarted = false;
      for (let j = 0; j < availabilitySlotsNumber; j += 1) {
        const minutes = j * availabilitySlotsMinutes;
        if (!eventStarted && dataCopy[i][j].event !== defaultSlot.event) {
          eventStarted = startEvent(dayEvents, dataCopy[i][j], minutes);
        } else if (eventStarted && dataCopy[i][j].event !== eventStarted) {
          dayEvents[dayEvents.length - 1].endTime = eventTime(minutes);
          if (dataCopy[i][j].event === defaultSlot.event) {
            // eslint-disable-line max-depth
            eventStarted = false;
          } else {
            eventStarted = startEvent(dayEvents, dataCopy[i][j], minutes);
          }
        }
      }
      if (eventStarted) {
        dayEvents[dayEvents.length - 1].endTime = zeroTime;
      }
    }
    updateAvailability(schedule);
  };

  updateDaysData = (daysData) => {
    const { updateAvailability, permanentAvailability } = this.props;
    this.setState({ daysData });
    updateAvailability(
      permanentAvailability.days.map((day, index) => ({
        ...day,
        ...daysData[index],
      })),
    );
  };

  render() {
    const { settings, availabilityTypes } = this.props;
    const { currentSchedule, daysData, formatAvailabilityTime } = this.state;

    return (
      <WeekScheduler
        settings={settings}
        availabilityTypes={availabilityTypes}
        currentSchedule={currentSchedule}
        daysData={daysData}
        updateEvents={this.updateEvents}
        updateDaysData={this.updateDaysData}
        formatAvailabilityTime={formatAvailabilityTime}
      />
    );
  }
}

// @ts-expect-error
PermanentAvailabilityEditor.propTypes = {
  settings: PropTypes.object.isRequired,
  permanentAvailability: PropTypes.object.isRequired,
  updateAvailability: PropTypes.func.isRequired,
  availabilityTypes: PropTypes.array.isRequired,
};

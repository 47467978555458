import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { requestBackend } from '@eva/emf/app/utils/request';
import { yearFirstDateFormat } from 'shared/constants';
import { stringifyError } from 'shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

const currentAvailabilityOptions = ['true', 'false'];

const shortenFollowUp = (availabilityFollowUp) =>
  availabilityFollowUp ? availabilityFollowUp.substr(0, 10) : availabilityFollowUp;

// eslint-disable-next-line import/no-default-export
export default class AvailabilityWidget extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  unmounted: any;
  static contextTypes: { isAllowedOperation: PropTypes.Validator<(...args: any[]) => any> };
  static propTypes: { entity: PropTypes.Validator<object> };

  UNSAFE_componentWillMount() {
    const { currentAvailability, availabilityFollowUp } = this.props.entity.availability;

    this.setState({
      currentAvailability: JSON.stringify(currentAvailability),
      availabilityFollowUp: shortenFollowUp(availabilityFollowUp),
      minFollowUp: moment().add(1, 'day').format(yearFirstDateFormat),
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  submitValues = () => {
    // @ts-expect-error
    const { currentAvailability, availabilityFollowUp } = this.state;

    this.setState({
      error: '',
    });

    requestBackend('/my/candidate-profile', {
      method: 'PUT',
      body: JSON.stringify({
        availability: {
          currentAvailability: JSON.parse(currentAvailability),
          availabilityFollowUp: availabilityFollowUp || null,
        },
      }),
    }).then(
      () => {
        if (this.unmounted) {
          return;
        }
        this.setState({
          submitting: false,
          // @ts-expect-error
          editMode: this.state.submitting ? false : this.state.editMode,
        });
      },
      (err) => {
        if (this.unmounted) {
          return;
        }
        this.setState({
          submitting: false,
          error: stringifyError(err),
        });
      },
    );
  };

  submitDate = () => {
    this.setState(
      {
        submitting: true,
      },
      this.submitValues,
    );
  };

  startEditMode = () => {
    const { availabilityFollowUp } = this.state as any;

    this.setState({
      editMode: true,
      initialAvailabilityFollowUp: availabilityFollowUp,
    });
  };

  cancelEditMode = () => {
    const { initialAvailabilityFollowUp } = this.state as any;

    this.setState({
      editMode: false,
      availabilityFollowUp: initialAvailabilityFollowUp,
    });
  };

  setAndSubmit = (update) => {
    const { editMode } = this.state as any;

    if (update.currentAvailability && update.currentAvailability !== 'false') {
      update.availabilityFollowUp = null;
      if (editMode) {
        this.cancelEditMode();
      }
    }
    this.setState(update, this.submitValues);
  };

  renderFollowUp() {
    const { isAllowedOperation } = this.context;
    const { currentAvailability, availabilityFollowUp, minFollowUp, submitting, editMode } = this.state as any;

    if (currentAvailability !== 'false') {
      return;
    } else if (submitting) {
      return (
        <div className="text-center margin-top">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="form-group row text-left no-margin">
        <label className="control-label col-xs-4 margin-top">{translate('Follow up')}</label>
        {!editMode && isAllowedOperation('myProfile-availability-update') && (
          <div className="col-xs-8 margin-min-vertical">
            <button
              type="button"
              className="btn-box-tool btn btn-sm pull-right pencil-edit-btn no-padding"
              onClick={this.startEditMode}
            >
              <i className="lnr lnr-pencil" />
            </button>
            {availabilityFollowUp}
          </div>
        )}
        {editMode && (
          <div className="col-xs-8">
            <div className="row">
              <div className="col-xs-8 no-padding">
                <input
                  className="form-control"
                  type="date"
                  min={minFollowUp}
                  value={availabilityFollowUp || ''}
                  onChange={(evt) => this.setState({ availabilityFollowUp: evt.target.value })}
                />
              </div>
              <div className="col-xs-4 text-nowrap">
                <button
                  type="button"
                  className="btn btn-sm btn-box-tool text-success no-padding"
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                  }}
                  onClick={this.submitDate}
                >
                  <i className="lnr lnr-checkmark-circle" />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-box-tool margin-left no-padding"
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                  }}
                  onClick={this.cancelEditMode}
                >
                  <i className="lnr lnr-cross-circle" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { isAllowedOperation } = this.context;
    const { currentAvailability, submitting, error } = this.state as any;

    const updateAllowed = isAllowedOperation('myProfile-availability-update');

    return (
      <div className="row no-margin">
        <div className="col-md-8 col-md-offset-2 col-xs-12" onClick={(evt) => evt.stopPropagation()}>
          {error && <div className="text-danger">{error}</div>}
          <div className="btn-group margin-bottom margin-top btn-group-justified">
            {currentAvailabilityOptions.map((currentAvailabilityOption) => (
              <div className="btn-group" key={currentAvailabilityOption}>
                <button
                  type="submit"
                  disabled={submitting || !updateAllowed}
                  className={`btn no-margin
                    btn-${currentAvailability === currentAvailabilityOption ? 'primary' : 'suggestion'}`}
                  onClick={() => this.setAndSubmit({ currentAvailability: currentAvailabilityOption })}
                >
                  {translate(
                    `I’m ${currentAvailabilityOption === currentAvailabilityOptions[0] ? '' : 'not '}available`,
                  )}
                </button>
              </div>
            ))}
          </div>
          {this.renderFollowUp()}
        </div>
      </div>
    );
  }
}

AvailabilityWidget.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

AvailabilityWidget.propTypes = {
  entity: PropTypes.object.isRequired,
};

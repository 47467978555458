import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';

import SalaryFormatter from '@eva/emf/app/shared/ui/SalaryFormatter';
import { valuesWithDelimiter } from 'shared/functions';

// eslint-disable-next-line react/prop-types
const AvailabilityBlock = ({ availability, dateFormat }) => {
  const formattedFollowUp = availability.availabilityFollowUp
    ? moment(availability.availabilityFollowUp).format(dateFormat)
    : '';
  if (availability.currentAvailability === true) {
    return (
      <span>
        <i className="fa fa-check text-success" /> {translate('Available')}
        {formattedFollowUp && `, Follow up ${formattedFollowUp}`}
      </span>
    );
  } else if (availability.currentAvailability === false) {
    return (
      <span>
        <i className="fa fa-times text-danger" /> {translate('Not available')}
        {formattedFollowUp && `, Follow up ${formattedFollowUp}`}
      </span>
    );
  } else if (availability.currentAvailability === null) {
    return (
      <span>
        <i className="fa fa-question text-muted" /> {translate('Unknown')}
      </span>
    );
  }
  return '#Missing data#';
};

const PreviewWorkProfile = (props, context) => {
  const { settings, isAllowedOperation } = context;
  const { userProfile } = props;

  const currencyValue = get(settings, 'currency.value', '£');
  const dateFormat = get(settings, 'dateFormats.dateFormat');

  return (
    <div>
      {valuesWithDelimiter([
        isAllowedOperation('frontend-myProfile-availabilityDetails-view') && (
          // @ts-expect-error
          <AvailabilityBlock availability={userProfile.availability} dateFormat={dateFormat} />
        ),
        get(userProfile.desiredPositions, 'description') &&
          translate('Desired positions: {{value}}', {
            value: userProfile.desiredPositions.description,
          }),
        get(userProfile, 'employmentTypes.items', []).length &&
          translate('Preferred employment: {{value}}', {
            value: userProfile.employmentTypes.items.map(({ title }) => title).join(', '),
          }),
        get(userProfile, 'desiredSalary.salary.value') && (
          <span>
            {translate('Desired salary')}:&nbsp;
            <SalaryFormatter salary={userProfile.desiredSalary.salary} currencyValue={currencyValue} withCurrency />
          </span>
        ),
      ])}
    </div>
  );
};

PreviewWorkProfile.contextTypes = {
  settings: PropTypes.object.isRequired,
  isAllowedOperation: PropTypes.func.isRequired,
};

PreviewWorkProfile.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default PreviewWorkProfile;

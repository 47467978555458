import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import moment from 'moment';

import PopperPopover from '@eva/emf/app/shared/ui/Popper/PopperPopover';
import { copy, endifyTime, noop, preparePermanentAvailability } from 'shared/functions';

import { timeTypes } from './constants';
import { mapSlotTimes, slotByTime } from './functions';
import ModalPermanentAvailabilityEditor from './ModalPermanentAvailabilityEditor';

const timeToMinutes = (time) => {
  if (!time) {
    throw new Error('Wrong time!');
  }
  const splittedTime = time.split(':');
  return splittedTime[0] * 60 + parseInt(splittedTime[1], 0);
};
const toSlotPercent = (value, scheduleLength) => Math.floor((value / scheduleLength) * 100);
const prepareEventSlot = (availabilityType, slotLength, scheduleLength, overlap) => ({
  availabilityType,
  overlap,
  weight: toSlotPercent(slotLength, scheduleLength),
});
const mergeDaySlots = (scheduleSlot, jobDaySlots, candidateDaySlots) => {
  const filterSlotsByPeriod = (daySlot) =>
    daySlot.startTime < endifyTime(scheduleSlot.end) && endifyTime(daySlot.endTime) > scheduleSlot.start;
  const jobSlots = copy(jobDaySlots.filter(filterSlotsByPeriod));
  const candidateSlots = copy(candidateDaySlots.filter(filterSlotsByPeriod));
  [jobSlots, candidateSlots].forEach((slots) => {
    if (slots.length) {
      const firstSlot = slots[0];
      const lastSlot = slots[slots.length - 1];
      firstSlot.startTime = firstSlot.startTime < scheduleSlot.start ? scheduleSlot.start : firstSlot.startTime;
      const slotsEndTime = endifyTime(lastSlot.endTime);
      const scheduleEndTime = endifyTime(scheduleSlot.end);
      lastSlot.endTime = slotsEndTime > scheduleEndTime ? scheduleEndTime : slotsEndTime;
    }
  });
  const slotsTimes = uniq([...jobSlots.flatMap(mapSlotTimes), ...candidateSlots.flatMap(mapSlotTimes)]).sort(
    (prev, cur) => (prev > cur ? 1 : -1),
  );
  const events = [];
  let currentJobSlot;
  let currentCandidateSlot;
  slotsTimes.forEach((slotTime) => {
    const startedJobSlot = slotByTime(jobSlots, timeTypes.startTime, slotTime);
    const startedCandidateSlot = slotByTime(candidateSlots, timeTypes.startTime, slotTime);
    const endedJobSlot = slotByTime(jobSlots, timeTypes.endTime, slotTime);
    const endedCandidateSlot = slotByTime(candidateSlots, timeTypes.endTime, slotTime);

    const currentEvent = events[events.length - 1];
    if (currentEvent) {
      currentEvent.endTime = slotTime;
    }
    currentJobSlot = endedJobSlot ? null : currentJobSlot;
    currentCandidateSlot = endedCandidateSlot ? null : currentCandidateSlot;
    currentJobSlot = startedJobSlot || currentJobSlot;
    currentCandidateSlot = startedCandidateSlot || currentCandidateSlot;
    const anyCurrentSlot = currentCandidateSlot || currentJobSlot;
    if (anyCurrentSlot) {
      const gap = !!currentJobSlot && !currentCandidateSlot;
      events.push({
        startTime: slotTime,
        availabilityType: gap ? 3 : anyCurrentSlot.availabilityType,
        overlap: !!currentJobSlot && !!currentCandidateSlot,
      });
    }
  });
  return events;
};

// eslint-disable-next-line import/no-default-export
export default class CardPermanentAvailability extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  weekdaysCodes: any;
  modalOpener: () => void;
  modalPermanentAvailabilityEditor: any;
  weekdaysShort: any;
  static contextTypes: { settings: PropTypes.Validator<object> };
  static propTypes: {
    profile: PropTypes.Validator<object>;
    selectedJob: PropTypes.Requireable<object>;
    jobPermanentAvailability: PropTypes.Requireable<object>;
    savePermanentAvailability: PropTypes.Validator<(...args: any[]) => any>;
    availabilityTypes: PropTypes.Validator<any[]>;
    availabilityLegendBlocks: PropTypes.Validator<any[]>;
    extended: PropTypes.Validator<boolean>;
  };

  UNSAFE_componentWillMount() {
    this.initPermanentAvailability();

    this.weekdaysShort = moment.weekdaysShort();

    this.weekdaysShort.push(this.weekdaysShort.shift());

    this.weekdaysCodes = moment.weekdays();
    this.weekdaysCodes.push(this.weekdaysCodes.shift());
    this.modalOpener = () => {
      const { settings } = this.context;
      const {
        profile: { pipelines },
      } = this.props;
      const { permanentAvailability } = this.state as any;

      this.modalPermanentAvailabilityEditor
        .open(settings, permanentAvailability, Array.isArray(pipelines) ? pipelines : undefined)
        .then((result) => this.initScheduledSlots(result), noop);
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { profile, jobPermanentAvailability } = nextProps;
    if (this.props.profile !== profile || this.props.jobPermanentAvailability !== jobPermanentAvailability) {
      this.initPermanentAvailability(nextProps);
    }
  }

  initScheduledSlots(permanentAvailability, props = this.props) {
    const {
      settings: {
        scheduleSlots,
        dateFormats: { timeFormat },
      },
    } = this.context;
    const { jobPermanentAvailability, availabilityTypes } = props;
    if (!scheduleSlots) {
      return;
    }

    const schedulesWeights = [];
    const preparedScheduleSlots = scheduleSlots.map((scheduleSlot) => {
      const scheduleSlotEnd = endifyTime(scheduleSlot.end);
      const scheduleStart = timeToMinutes(scheduleSlot.start);
      const scheduleEnd = timeToMinutes(scheduleSlotEnd);
      const scheduleLength = scheduleEnd - scheduleStart;
      schedulesWeights.push(Math.floor((scheduleLength / 24 / 60) * 100));
      return {
        ...scheduleSlot,
        scheduleSlotEnd,
        scheduleStart,
        scheduleEnd,
        scheduleLength,
      };
    });

    const formatSlotTime = (time) => moment(time, 'HH:mm').format(timeFormat);

    const scheduledAvailability = permanentAvailability.days.map((day, index) => ({
      comment: day.slots.map(
        (item) =>
          `${formatSlotTime(item.startTime)} - ${formatSlotTime(item.endTime)} - ` +
          `${availabilityTypes[item.availabilityType].event}`,
      ),
      slots: preparedScheduleSlots.map((scheduleSlot) => {
        const daySlots = mergeDaySlots(
          scheduleSlot,
          get(jobPermanentAvailability, `days[${index}].slots`, []),
          day.slots,
        );
        let currentDaySlotEnd = timeToMinutes(scheduleSlot.start);
        let daySlotEnd = scheduleSlot.scheduleStart;
        const periodSlots = daySlots.flatMap((daySlot) => {
          const daySlotStart = timeToMinutes(
            daySlot.startTime > scheduleSlot.start ? daySlot.startTime : scheduleSlot.start,
          );
          daySlotEnd = timeToMinutes(
            daySlot.endTime < scheduleSlot.scheduleSlotEnd ? daySlot.endTime : scheduleSlot.scheduleSlotEnd,
          );
          const daySlotGap = daySlotStart - currentDaySlotEnd;
          currentDaySlotEnd = daySlotEnd;
          return [
            // @ts-expect-error
            ...(daySlotGap ? [prepareEventSlot(0, daySlotGap, scheduleSlot.scheduleLength)] : []),
            prepareEventSlot(
              daySlot.availabilityType,
              daySlotEnd - daySlotStart,
              scheduleSlot.scheduleLength,
              daySlot.overlap,
            ),
          ];
        });
        const afterSlotsGap = scheduleSlot.scheduleEnd - daySlotEnd;
        return [
          ...periodSlots,
          // @ts-expect-error
          ...(afterSlotsGap ? [prepareEventSlot(0, afterSlotsGap, scheduleSlot.scheduleLength)] : []),
        ];
      }),
    }));

    this.setState({
      permanentAvailability,
      scheduledAvailability,
      schedulesWeights,
    });
  }

  initPermanentAvailability(props = this.props) {
    const { profile } = props;

    const permanentAvailability = profile.permanentAvailability.days
      ? preparePermanentAvailability(profile.permanentAvailability)
      : {
          holidayAllowance: false,
          description: '',
          days: this.weekdaysCodes.map((weekDay) => ({
            weekDay,
            flexibleHours: false,
            slots: [],
          })),
        };

    this.initScheduledSlots(permanentAvailability, props);
  }

  drawAvailabilityDay = (day, index) => {
    const {
      settings: { scheduleSlots },
    } = this.context;
    const { availabilityTypes } = this.props;
    if (!scheduleSlots) {
      return null;
    }
    const { scheduledAvailability, schedulesWeights } = this.state as any;
    const dayPeriodsSlots = scheduledAvailability[index];
    return (
      <div key={day.weekDay} id={`day ${day.weekDay}`} className="a-day">
        <small>{this.weekdaysShort[index]}</small>
        <PopperPopover
          placement="top"
          key={day.weekDay}
          overlay={
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {dayPeriodsSlots.comment.length ? dayPeriodsSlots.comment.join('\n') : availabilityTypes[0].event}
            </div>
          }
        >
          <div className="a-item">
            {dayPeriodsSlots.slots.map((dayPeriodsSlot, dayPeriodsSlotIndex) => (
              <span
                key={dayPeriodsSlotIndex}
                style={{
                  width: `${schedulesWeights[dayPeriodsSlotIndex]}%`,
                }}
              >
                {!dayPeriodsSlot.length && <span>&nbsp;</span>}
                {dayPeriodsSlot.map((periodSlot, periodSlotIndex) => (
                  <span
                    key={periodSlotIndex}
                    className={`${periodSlot.availabilityType === 0 ? 'with-shadow' : ''}`}
                    style={{
                      background:
                        availabilityTypes[periodSlot.availabilityType][periodSlot.overlap ? 'color' : 'lightenColor'],
                      width: `${periodSlot.weight}%`,
                      display: 'inline-block',
                    }}
                  >
                    &nbsp;
                  </span>
                ))}
              </span>
            ))}
          </div>
        </PopperPopover>
      </div>
    );
  };

  render() {
    const { settings } = this.context;
    const { extended, availabilityTypes, availabilityLegendBlocks, savePermanentAvailability, selectedJob } =
      this.props;
    const { permanentAvailability } = this.state as any;

    const holidayAllowance = get(settings, 'features.candidate.workProfile.preferredWorkingHours.holidayAllowance');

    return (
      <div>
        <ModalPermanentAvailabilityEditor
          key="modalPermanentAvailabilityEditor"
          ref={(ref) => (this.modalPermanentAvailabilityEditor = ref)}
          availabilityTypes={availabilityTypes}
          availabilityLegendBlocks={availabilityLegendBlocks}
          savePermanentAvailability={savePermanentAvailability}
          job={selectedJob}
        />
        <div key="collapse-panel" className="collapse-panel">
          <div>{permanentAvailability && permanentAvailability.days.map(this.drawAvailabilityDay)}</div>
          <div className="clearfix" />
          <div className={`${extended ? 'margin-top' : 'hide'}`}>
            {holidayAllowance && (
              <div className="row margin-bottom" id="holidayAllowance">
                <div className="col-xs-4 text-right">
                  <label className="control-label">{translate('Holiday allowance')}</label>
                </div>
                <div className="col-xs-8">{translate(permanentAvailability.holidayAllowance ? 'yes' : 'no')}</div>
              </div>
            )}
            <div className="row margin-bottom" id="permanent-availability-description">
              <div className="col-xs-4 text-right">
                <label className="control-label">{translate('Working hours note')}</label>
              </div>
              <div className="col-xs-8">{permanentAvailability.description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardPermanentAvailability.contextTypes = {
  settings: PropTypes.object.isRequired,
};

CardPermanentAvailability.propTypes = {
  profile: PropTypes.object.isRequired,
  selectedJob: PropTypes.object,
  jobPermanentAvailability: PropTypes.object,
  savePermanentAvailability: PropTypes.func.isRequired,
  availabilityTypes: PropTypes.array.isRequired,
  availabilityLegendBlocks: PropTypes.array.isRequired,
  extended: PropTypes.bool.isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';

import { socialProviders } from 'shared/constants';
import { renderSection } from '@eva/emf/app/shared/functions';

import './style.css';

const prepareSocialUrl = (url) => {
  if (typeof url === 'string' && !url.toLowerCase().startsWith('http')) {
    return `https://${url}`;
  }
  return url;
};

// eslint-disable-next-line import/no-default-export
export default class CardSocialLinks extends React.PureComponent<any, any> {
  state = {};

  render() {
    const {
      entity: { socialMediaLinks },
    } = this.props;
    // @ts-expect-error
    const { error } = this.state;

    return (
      <div>
        <h3 className="text-primary">{translate('Social links')}</h3>
        {error && <div className="text-danger">{error}</div>}
        {renderSection(
          '',
          <div className="social-links">
            {socialProviders.map((socialProvider) => {
              const url = socialMediaLinks[socialProvider].url;
              let spanClassName = `icon ${socialProvider.toLowerCase()}-icon margin-right`;
              if (!url) {
                spanClassName = `${spanClassName} icon icon-gray`;
              }
              return (
                <div key={socialProvider} className="margin-top">
                  <span className={spanClassName}>
                    <i className={`fa fa-${socialProvider.toLowerCase()}`} />
                  </span>
                  {url ? (
                    <a href={prepareSocialUrl(url)} target="_blank" rel="noopener noreferrer">
                      {url}
                    </a>
                  ) : (
                    <span className="text-muted">Not&nbsp;provided</span>
                  )}
                </div>
              );
            })}
          </div>,
        )}
      </div>
    );
  }
}

// @ts-expect-error
CardSocialLinks.propTypes = {
  entity: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
};

import { getQueryVariables } from 'shared/functions';
import { RedirectorToNewPath } from '@eva/emf/app/shared/ui/Redirector';

const extraQueryKeys = [
  'up',
  'userId',
  'email',
  'token',
  'firstName',
  'lastName',
  'userType',
  'redirect',
  'saml',
  'hash',
];

const getFilteredQuery = (query: any) =>
  Object.keys(query)
    .filter((queryKey) => !extraQueryKeys.includes(queryKey))
    .reduce(
      (prev, cur) => ({
        ...prev,
        [cur]: query[cur],
      }),
      {},
    );

const Redirector = () => {
  const query = getQueryVariables();
  const path = {
    pathname: '/candidate/chat',
    query: getFilteredQuery(query),
  };

  return <RedirectorToNewPath newPath={path} />;
};

// eslint-disable-next-line import/no-default-export
export default Redirector;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { prepareSelector, setQueryVariables } from 'shared/functions';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

import ProfileSection from 'containers/CandidateProfile/ProfileSection';
import { loadEditOptions } from 'containers/MessengerCandidate/actions';

import { getSelectedSection } from './functions';

class MenuMyProfile extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  candidateProfileSections: any;

  UNSAFE_componentWillMount() {
    const { getSectionsOptions } = this.context;
    const { mobileMode, editOptions, loadingEditOptions, dispatchLoadEditOptions } = this.props;

    if (!Object.keys(editOptions).length && !loadingEditOptions) {
      dispatchLoadEditOptions();
    }

    this.candidateProfileSections = getSectionsOptions();

    const defaultSection = mobileMode ? null : this.candidateProfileSections?.[0];

    const selectedSection = getSelectedSection(this.candidateProfileSections) || defaultSection;

    if (selectedSection) {
      this.selectSection(selectedSection);
    }
  }

  selectSection(activeSection) {
    const { setCentralContent, switchToChat, mobileMode } = this.props;

    this.setState({
      activeSection,
    });

    setQueryVariables({
      section: activeSection.sectionKey,
    });

    setCentralContent(
      <div className="job-central-panel">
        <ProfileSection switchToChat={switchToChat} activeSection={activeSection} mobileMode={mobileMode} />
      </div>,
    );
  }

  renderSection(section) {
    const { userProfile, editOptions } = this.props;
    // @ts-expect-error
    const { activeSection } = this.state;
    const { sectionKey, icon, label } = section;

    const { Preview } = section;
    const userProfileReady = !!Object.keys(userProfile).length && !!Object.keys(editOptions).length;

    return (
      <div
        id={sectionKey}
        key={sectionKey}
        className={`profile-menu-item media ${activeSection === section ? 'active' : ''}`}
        onClick={() => userProfileReady && this.selectSection(section)}
      >
        <div className="media-left">
          <i className={icon} />
        </div>
        <div className="media-body">
          <h3>{label}</h3>
          {!userProfileReady && (
            <div className="text-center">
              <SpinnerSmall />
            </div>
          )}
          {userProfileReady && <Preview userProfile={userProfile} editOptions={editOptions} />}
        </div>
      </div>
    );
  }

  render() {
    const { isAllowedOperation } = this.context;

    return (
      <div className="candidate-profile-menu">
        {this.candidateProfileSections
          .filter(({ viewPermission }) => !viewPermission || isAllowedOperation(viewPermission))
          .map((section) => this.renderSection(section))}
      </div>
    );
  }
}

// @ts-expect-error
MenuMyProfile.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
  getSectionsOptions: PropTypes.func.isRequired,
};

// @ts-expect-error
MenuMyProfile.propTypes = {
  userProfile: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  setCentralContent: PropTypes.func.isRequired,
  switchToChat: PropTypes.func.isRequired,
  mobileMode: PropTypes.bool,
  loadingEditOptions: PropTypes.bool.isRequired,
  dispatchLoadEditOptions: PropTypes.func.isRequired,
};

const prepareGlobalSelector = (value) => prepareSelector('global', value);
const prepareMessengerSelector = (value) => prepareSelector('candidate', value);
const mapStateToProps = createStructuredSelector({
  userProfile: prepareGlobalSelector('userProfile'),
  loadingEditOptions: prepareMessengerSelector('loadingEditOptions'),
  editOptions: prepareMessengerSelector('editOptions'),
});

const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error
  dispatchLoadEditOptions: (...args) => dispatch(loadEditOptions(...args)),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(MenuMyProfile);

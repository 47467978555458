import PropTypes from 'prop-types';
import React from 'react';
import startCase from 'lodash/startCase';
import moment from 'moment';

import { renderSection } from '@eva/emf/app/shared/functions';
import { acceptImagesExtensions } from 'shared/constants';

import { existsFields } from './constants';

const dropzoneModes = {
  uploadPhoto: 'uploadPhoto',
  createCandidate: 'createCandidate',
};
const dateFormat = 'MMM YYYY';

// eslint-disable-next-line import/no-default-export
export default class CardGeneralInformation extends React.PureComponent<any, any> {
  state = {
    failedPhoto: false,
    jobs: [],
    initialValues: {
      name: {
        firstName: '',
        lastName: '',
      },
    },
    acceptedExtensions: acceptImagesExtensions,
    dropzoneMode: dropzoneModes.uploadPhoto,
  };

  UNSAFE_componentWillMount() {
    const {
      entity: { dateOfBirth },
    } = this.props;

    this.setDateOfBirth(dateOfBirth);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      entity: { dateOfBirth },
    } = nextProps;
    if (this.props.entity.dateOfBirth !== dateOfBirth) {
      this.setDateOfBirth(dateOfBirth);
    }
  }

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  setDateOfBirth(dateOfBirth) {
    this.setState({ birthDate: dateOfBirth && dateOfBirth.dateOfBirth ? moment(dateOfBirth.dateOfBirth) : '' });
  }

  render() {
    const {
      settings: { features },
    } = this.context;
    const { entity } = this.props;
    // @ts-expect-error
    const { birthDate, deleted, error } = this.state;
    const { name = {}, isImporting, legacyData, nationality = {}, nationalInsuranceNumber } = entity;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        {deleted && <div className="alert alert-danger">{translate('This entity was deleted!')}</div>}
        {isImporting && (
          <div className="alert alert-info">{translate('Importing candidate, profile will be updated soon')}</div>
        )}

        {renderSection(
          translate('Personal information'),
          <div>
            <div className="form-group candidate-name">
              <label className="control-label">{translate('Full name')}</label>
              <div>{name.displayName}</div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="form-group birth-date">
                  <label className="control-label">{translate('Date of Birth')}</label>
                  <div>
                    {birthDate ? (
                      <span>
                        {birthDate.format(dateFormat)}
                        <small>
                          {' '}
                          ({moment().diff(birthDate, 'years')} {translate('y.o.')})
                        </small>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="form-group nationality">
                  <label className="control-label">{translate('Nationality')}</label>
                  <div>{nationality.name}</div>
                </div>
              </div>
            </div>

            <div className="row">
              {features.ui.candidateProfile.nationalInsuranceNumber && nationalInsuranceNumber && (
                <div className="col-xs-12 col-md-6">
                  <div className="form-group ni-number">
                    <label className="control-label">NI Number</label>
                    <div>{nationalInsuranceNumber.value}</div>
                  </div>
                </div>
              )}
              {existsFields
                .filter((existsField) => features.ui.candidateProfile[existsField])
                .map((existsField) => (
                  <div key={existsField} className="col-xs-12 col-md-6">
                    <div className={`form-group ${existsField}`}>
                      <label className="control-label">{startCase(existsField)}</label>
                      <div>
                        <div>
                          {typeof entity[existsField].exists === 'boolean' && (
                            <span>{entity[existsField].exists ? 'Yes' : 'No'}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {legacyData && !!legacyData.items.length && (
              <div className="form-group legacy-data">
                <label className="control-label">Legacy data</label>
                <div>{legacyData.items.map((item) => item.externalSystem.name).join(', ')}</div>
              </div>
            )}
          </div>,
        )}
      </div>
    );
  }
}

// @ts-expect-error
CardGeneralInformation.contextTypes = {
  settings: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

// @ts-expect-error
CardGeneralInformation.propTypes = {
  entity: PropTypes.object.isRequired,
};

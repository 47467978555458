import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

// eslint-disable-next-line import/no-default-export
export default class ModalAlert extends React.Component<any, any> {
  state = {
    show: false,
    title: '',
    body: '',
  };

  ok = (evt) => {
    evt.preventDefault();
    this.setState({ show: false });
    // @ts-expect-error
    return this.resolve();
  };

  cancel = () => {
    this.setState({ show: false });
  };

  open(body, title) {
    if (!body) {
      throw new Error('Body text not passed in ModalAlert');
    }
    this.setState({
      show: true,
      body,
      title: title || translate('Eva System'),
    });

    // @ts-expect-error
    this.promise = new Promise((resolve) => {
      // @ts-expect-error
      this.resolve = resolve;
    });
    // @ts-expect-error
    return this.promise;
  }

  render() {
    const { show, title, body } = this.state;
    const { size } = this.props;

    const bsSize = typeof size === 'string' ? size : 'small';

    return (
      <Modal show={show} onHide={this.cancel} bsSize={bsSize === '' ? undefined : bsSize}>
        <form onSubmit={this.ok}>
          <Modal.Header closeButton={Boolean(false)}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {typeof body === 'string' && (
              <div
                dangerouslySetInnerHTML={{ __html: body }}
                style={{
                  width: '100%',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              />
            )}
            {typeof body !== 'string' && body}
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={this.ok} className="btn btn-success btn-sm">
              {translate('OK')}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

// @ts-expect-error
ModalAlert.propTypes = {
  size: PropTypes.string,
};

import {
  SIGN_OUT,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  LOAD_USER_PROFILE,
  LOAD_USER_PROFILE_SUCCESS,
  LOAD_USER_PROFILE_ERROR,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOAD_PERMISSIONS,
  LOAD_PERMISSIONS_SUCCESS,
  LOAD_PERMISSIONS_ERROR,
  LOAD_SETTINGS,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_ERROR,
  UPDATE_SETTINGS,
  UPDATE_USER_PROFILE,
  CHANGE_LOCALE,
} from './constants';

/**
 * Sign out
 */

export function signOut(redirect) {
  return {
    type: SIGN_OUT,
    redirect,
  };
}

/**
 * Sign in
 */

export const signIn = (payload) => ({
  type: SIGN_IN,
  payload,
});

export const signInSuccess = (payload) => ({
  type: SIGN_IN_SUCCESS,
  payload,
});

export const signInError = (error) => ({
  type: SIGN_IN_ERROR,
  error,
});

/**
 * Sign up
 */

export const signUp = (payload) => ({
  type: SIGN_UP,
  payload,
});

export const signUpSuccess = (payload) => ({
  type: SIGN_UP_SUCCESS,
  payload,
});

export const signUpError = (error) => ({
  type: SIGN_UP_ERROR,
  error,
});

/**
 * User details
 */

export function loadUserProfile() {
  return {
    type: LOAD_USER_PROFILE,
  };
}

export function loadUserProfileSuccess(payload) {
  return {
    type: LOAD_USER_PROFILE_SUCCESS,
    payload,
  };
}

export function loadUserProfileError(error) {
  return {
    type: LOAD_USER_PROFILE_ERROR,
    error,
  };
}

/**
 * Restore password
 */

export const restorePassword = (payload) => ({
  type: RESTORE_PASSWORD,
  payload,
});

export const restorePasswordSuccess = (payload) => ({
  type: RESTORE_PASSWORD_SUCCESS,
  payload,
});

export const restorePasswordError = (error) => ({
  type: RESTORE_PASSWORD_ERROR,
  error,
});

/**
 * Reset password
 */

export const resetPassword = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordError = (error) => ({
  type: RESET_PASSWORD_ERROR,
  error,
});

/**
 * Load permissions
 */

export const loadPermissions = (payload) => ({
  type: LOAD_PERMISSIONS,
  payload,
});

export const loadPermissionsSuccess = (payload) => ({
  type: LOAD_PERMISSIONS_SUCCESS,
  payload,
});

export const loadPermissionsError = (error) => ({
  type: LOAD_PERMISSIONS_ERROR,
  error,
});

/**
 * Load settings
 */

export const loadSettings = (isAgent) => ({
  type: LOAD_SETTINGS,
  isAgent,
});

export const loadSettingsSuccess = (payload) => ({
  type: LOAD_SETTINGS_SUCCESS,
  payload,
});

export const loadSettingsError = (error) => ({
  type: LOAD_SETTINGS_ERROR,
  error,
});

/**
 * Other
 */

export const updateSettings = (payload, replace) => ({
  type: UPDATE_SETTINGS,
  payload,
  replace,
});

export const updateUserProfile = (payload) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});

export function changeLocale(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}

/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';

import globalReducer from 'containers/App/reducer';
import candidateReducer from 'containers/MessengerCandidate/reducer';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
// eslint-disable-next-line import/no-default-export
export default function createReducer(asyncReducers) {
  return combineReducers({
    global: globalReducer,
    candidate: candidateReducer,
    form,
    ...asyncReducers,
  });
}

import React from 'react';
import ReactDOM from 'react-dom';

import { appTypes } from '@eva/emf/app/shared/constants';
import { registerAnalyticsSession } from '@eva/emf/app/shared/functions-ts';
import { sentry } from '@eva/emf/app/utils/tracing';

import { App } from './app';

registerAnalyticsSession(appTypes.candidate);

window.React = React;

sentry.init();

const MOUNT_NODE = document.getElementById('root');

// https://github.com/ReactTraining/react-router/issues/6789#issuecomment-502490351
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  MOUNT_NODE,
);

import React from 'react';

import { localHostnames } from '@eva/emf/app/shared/constants';
import { notReadyTranslate } from '@eva/emf/app/shared/functions';
import { logError } from 'shared/functions';
import { environments } from 'shared/constants';

import ErrorViewSimple from 'containers/App/ErrorViewSimple';

window.translate = (translation, options) => notReadyTranslate(translation, options);

// Google Analytics
// @ts-expect-error
window.ga('create', 'UA-99159997-1', 'auto');

const disableGA = localHostnames.includes(window.location.hostname) || /^dev/.test(window.location.hostname);
window.sendGAEvent = (eventCategory: any, eventAction: any, eventLabel: any) => {
  if (!disableGA) {
    // @ts-expect-error
    window.ga('send', 'event', { eventCategory, eventAction, eventLabel });
  }
};

const statelessComponentsMap = new Map(); // original -> monkeypatched stateless functional components cache

const errorPlaceholder = (
  <span
    style={{
      background: 'red',
      color: 'white',
    }}
  >
    Render error!
  </span>
);

function logErrorUtils(Component: any, error: any) {
  const errorMsg = `Error while rendering component. Check render() method of component '${
    Component.displayName || Component.name || '[unidentified]'
  }'.`;

  // eslint-disable-next-line no-console
  console.error(errorMsg, error);

  logError(error);
}

class ErrorBoundary extends React.Component {
  state: any;

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);

    this.setState({ errorMessage: error?.message });
  }

  render() {
    const { hasError, errorMessage } = this.state;

    if (hasError) {
      if (!process.env.ENVIRONMENT || [environments.dev, environments.uat].includes(process.env.ENVIRONMENT)) {
        return (
          <div className="text-center">
            <h3 className="text-danger padding">Something went wrong.</h3>
            {errorMessage && <div className="text-danger padding">{errorMessage}</div>}

            <div>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload
              </button>
            </div>
          </div>
        );
      }

      return <h3 className="text-center padding text-danger">Something went wrong.</h3>;
    }

    return this.props.children;
  }
}

function monkeypatchRender(prototype: any) {
  if (prototype && prototype.render && !prototype.render.__handlingErrors) {
    const originalRender = prototype.render;

    prototype.render = function monkeyPatchedRender() {
      try {
        // @ts-expect-error
        return <ErrorBoundary FallbackComponent={ErrorViewSimple}>{originalRender.call(this)}</ErrorBoundary>;
      } catch (error) {
        logErrorUtils(prototype.constructor, error);

        return errorPlaceholder;
      }
    };

    prototype.render.__handlingErrors = true; // flag render method so it's not wrapped multiple times
  }
}

const originalCreateElement = React.createElement;

React.createElement = (Component: any, ...rest: any) => {
  if (typeof Component === 'function') {
    if (Component.prototype && typeof Component.prototype.render === 'function') {
      monkeypatchRender(Component.prototype);
    }

    // stateless functional component
    if (!Component.prototype || !Component.prototype.render) {
      const originalStatelessComponent = Component;
      if (statelessComponentsMap.has(originalStatelessComponent)) {
        // eslint-disable-next-line no-param-reassign
        Component = statelessComponentsMap.get(originalStatelessComponent);
      } else {
        // eslint-disable-next-line no-param-reassign
        Component = (...args: any) => {
          try {
            return originalStatelessComponent(...args);
          } catch (error) {
            logErrorUtils(originalStatelessComponent, error);

            return errorPlaceholder;
          }
        };

        // copy all properties like propTypes, defaultProps etc.
        Object.assign(Component, originalStatelessComponent);
        // save to cache, so we don't generate new monkeypatched functions every time.
        statelessComponentsMap.set(originalStatelessComponent, Component);
      }
    }
  }

  return originalCreateElement.call(React, Component, ...rest);
};

// allowing hot reload
const originalForceUpdate = React.Component.prototype.forceUpdate;
React.Component.prototype.forceUpdate = function monkeypatchedForceUpdate() {
  monkeypatchRender(this);
  originalForceUpdate.call(this);
};

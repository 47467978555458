import PropTypes from 'prop-types';
import React from 'react';

import Select from '@eva/emf/app/containers/Select';
import { requestBackend } from '@eva/emf/app/utils/request';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

import './style.css';

const reservedAttributes = ['multi'];

class MultiSelect extends AIUIComponent {
  state = {
    value: null,
  };

  UNSAFE_componentWillMount() {
    const { attributes } = this.props;
    const { storedtype } = attributes;
    if (storedtype) {
      requestBackend(`/utils/${storedtype}`).then((options) =>
        // @ts-expect-error
        this.unmounted
          ? null
          : this.setState({
              loading: false,
              multi: storedtype !== 'periods',
              options,
              value: null,
            }),
      );
    } else {
      const options = Object.keys(attributes)
        .filter((key) => !reservedAttributes.includes(key))
        .reduce(
          (prev, cur) => [
            ...prev,
            {
              id: cur,
              label: attributes[cur],
            },
          ],
          [],
        );
      const multi = !!attributes.multi;
      this.setState({
        loading: false,
        multi,
        options,
        value: multi ? null : options[0],
      });
    }
  }

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  submitResult() {
    const { value } = this.state;
    this.setState({ submitted: true });
    this.props.resultingContent(Array.isArray(value) ? value.map((item) => item.label).join(', ') : value.label);
  }

  render() {
    const { editable } = this.props;
    // @ts-expect-error
    const { loading, options, value, submitted, multi } = this.state;

    const emptyValue =
      value === null ||
      (Array.isArray(value) && !value.length) ||
      (typeof value === 'object' && !Object.keys(value).length);
    const disabled = !editable || submitted;

    if (disabled) {
      return null;
    }

    return (
      <div>
        <div className="col-sm-6 col-sm-offset-3 col-xs-12">
          <Select
            id="aiui-multi-select"
            value={value}
            valueKey="id"
            options={options}
            isLoading={loading}
            disabled={disabled}
            onChange={(val) => this.setState({ value: val })}
            placeholder={translate('Pick answer options...')}
            clearable={!!multi}
            multi={multi}
          />
        </div>
        <div className="col-sm-3 col-xs-12">
          <button
            type="submit"
            className="btn btn-primary pull-right hidden-xs"
            disabled={disabled || emptyValue}
            onClick={() => this.submitResult()}
          >
            <i className="ll-icon ll-send" />
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-block margin-top visible-xs"
            disabled={disabled || emptyValue}
            onClick={() => this.submitResult()}
          >
            {translate('Submit')}
          </button>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
MultiSelect.propTypes = {
  answer: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default MultiSelect;

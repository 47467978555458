export const LOAD_CHATS = 'eva/candidate/LOAD_CHATS';
export const LOAD_CHATS_SUCCESS = 'eva/candidate/LOAD_CHATS_SUCCESS';
export const LOAD_CHATS_ERROR = 'eva/candidate/LOAD_CHATS_ERROR';

export const LOAD_USER_DETAILS = 'eva/candidate/LOAD_USER_DETAILS';
export const LOAD_USER_DETAILS_SUCCESS = 'eva/candidate/LOAD_USER_DETAILS_SUCCESS';
export const LOAD_USER_DETAILS_ERROR = 'eva/candidate/LOAD_USER_DETAILS_ERROR';

export const SEARCH_CANDIDATES = 'eva/candidate/SEARCH_CANDIDATES';
export const SEARCH_CANDIDATES_SUCCESS = 'eva/candidate/SEARCH_CANDIDATES_SUCCESS';
export const SEARCH_CANDIDATES_ERROR = 'eva/candidate/SEARCH_CANDIDATES_ERROR';

export const LOAD_MESSAGES = 'eva/candidate/LOAD_MESSAGES';
export const LOAD_MESSAGES_SUCCESS = 'eva/candidate/LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_ERROR = 'eva/candidate/LOAD_MESSAGES_ERROR';

export const LOAD_JOB = 'eva/candidate/LOAD_JOB';
export const LOAD_JOB_SUCCESS = 'eva/candidate/LOAD_JOB_SUCCESS';
export const LOAD_JOB_ERROR = 'eva/candidate/LOAD_JOB_ERROR';

export const LOAD_PIPELINES = 'eva/candidate/LOAD_PIPELINES';
export const LOAD_PIPELINES_SUCCESS = 'eva/candidate/LOAD_PIPELINES_SUCCESS';
export const LOAD_PIPELINES_ERROR = 'eva/candidate/LOAD_PIPELINES_ERROR';

export const MARK_PIPELINES_READ = 'eva/candidate/MARK_PIPELINES_READ';
export const MARK_PIPELINES_READ_SUCCESS = 'eva/candidate/MARK_PIPELINES_READ_SUCCESS';
export const MARK_PIPELINES_READ_ERROR = 'eva/candidate/MARK_PIPELINES_READ_ERROR';

export const LOAD_CANDIDATES_FILTER = 'eva/candidate/LOAD_CANDIDATES_FILTER';
export const LOAD_CANDIDATES_FILTER_SUCCESS = 'eva/candidate/LOAD_CANDIDATES_FILTER_SUCCESS';
export const LOAD_CANDIDATES_FILTER_ERROR = 'eva/candidate/LOAD_CANDIDATES_FILTER_ERROR';

export const DELETE_CANDIDATE = 'eva/candidate/DELETE_CANDIDATE';
export const DELETE_CANDIDATE_SUCCESS = 'eva/candidate/DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_ERROR = 'eva/candidate/DELETE_CANDIDATE_ERROR';

export const LOAD_EDIT_OPTIONS = 'eva/candidate/LOAD_EDIT_OPTIONS';
export const LOAD_EDIT_OPTIONS_SUCCESS = 'eva/candidate/LOAD_EDIT_OPTIONS_SUCCESS';
export const LOAD_EDIT_OPTIONS_ERROR = 'eva/candidate/LOAD_EDIT_OPTIONS_ERROR';

export const MARK_LOCAL_READ = 'eva/candidate/MARK_LOCAL_READ';
export const CHAT_SELECTED = 'eva/candidate/CHAT_SELECTED';
export const SEND_MESSAGE = 'eva/candidate/SEND_MESSAGE';

export const NEW_MESSAGE = 'eva/candidate/NEW_MESSAGE';
export const MESSAGE_DELETED = 'eva/candidate/MESSAGE_DELETED';
export const CONTENT_UPDATED = 'eva/candidate/CONTENT_UPDATED';
export const UPDATING_PROFILE = 'eva/candidate/UPDATING_PROFILE';
export const PROFILE_UPDATED = 'eva/candidate/PROFILE_UPDATED';
export const PIPELINE_UPDATED = 'eva/candidate/PIPELINE_UPDATED';
export const CHANGE_STATUS = 'eva/candidate/CHANGE_STATUS';
export const AI_PAUSED = 'eva/candidate/AI_PAUSED';
export const MESSAGE_UPDATED = 'eva/candidate/MESSAGE_UPDATED';
export const CANDIDATE_PROFILE_UPDATED = 'eva/candidate/CANDIDATE_PROFILE_UPDATED';
export const CONVERSATION_WORKFLOW_CHANGED = 'eva/candidate/CONVERSATION_WORKFLOW_CHANGED';
export const OMNICHAT_UPDATED = 'eva/candidate/OMNICHAT_UPDATED';
export const CANDIDATE_REGISTRATION_COMPLETED = 'eva/candidate/CANDIDATE_REGISTRATION_COMPLETED';

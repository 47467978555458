import omit from 'lodash/omit';
import set from 'lodash/set';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { fromJS } from 'immutable';

import { safeLocalStorage } from '@eva/emf/app/shared/constants';
import { windowLocationReload } from '@eva/emf/app/shared/functions';
import { messagesPageSize, userTypes } from 'shared/constants';
import { momentify, getParsedUser, prepareMessage, stringifyError, wipeStorage, logError } from 'shared/functions';

import { SIGN_IN_SUCCESS, SIGN_UP_SUCCESS, SIGN_OUT } from 'containers/App/constants';

import {
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_SUCCESS,
  LOAD_USER_DETAILS_ERROR,
  LOAD_CHATS,
  LOAD_CHATS_SUCCESS,
  LOAD_CHATS_ERROR,
  SEARCH_CANDIDATES,
  SEARCH_CANDIDATES_SUCCESS,
  SEARCH_CANDIDATES_ERROR,
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  LOAD_MESSAGES_ERROR,
  LOAD_JOB,
  LOAD_JOB_SUCCESS,
  LOAD_JOB_ERROR,
  LOAD_PIPELINES,
  LOAD_PIPELINES_SUCCESS,
  LOAD_PIPELINES_ERROR,
  MARK_PIPELINES_READ,
  MARK_PIPELINES_READ_SUCCESS,
  MARK_PIPELINES_READ_ERROR,
  LOAD_CANDIDATES_FILTER,
  LOAD_CANDIDATES_FILTER_SUCCESS,
  LOAD_CANDIDATES_FILTER_ERROR,
  DELETE_CANDIDATE,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_ERROR,
  LOAD_EDIT_OPTIONS,
  LOAD_EDIT_OPTIONS_SUCCESS,
  LOAD_EDIT_OPTIONS_ERROR,
  MARK_LOCAL_READ,
  CHAT_SELECTED,
  SEND_MESSAGE,

  // IO
  NEW_MESSAGE,
  MESSAGE_DELETED,
  CONTENT_UPDATED,
  UPDATING_PROFILE,
  PROFILE_UPDATED,
  PIPELINE_UPDATED,
  CHANGE_STATUS,
  AI_PAUSED,
  MESSAGE_UPDATED,
  CANDIDATE_PROFILE_UPDATED,
  CONVERSATION_WORKFLOW_CHANGED,
  OMNICHAT_UPDATED,
  CANDIDATE_REGISTRATION_COMPLETED,
} from './constants';

let storageUser;
let isAgent;
const setStorageUser = () => {
  // @ts-expect-error
  storageUser = safeLocalStorage.user ? getParsedUser() : {};
  isAgent = storageUser.userType === userTypes.agent;
};

setStorageUser();

const sortMessagesByDate = (candidateId, messages) =>
  messages
    .map((message) => ({
      ...message,
      ...prepareMessage(candidateId, message),
    }))
    .sort((first, next) => {
      const sorter = first.sentOn._i === next.sentOn._i ? 'messageId' : 'sentOn';
      return first[sorter] > next[sorter] ? 1 : -1;
    });

const updateOrPushMessage = (candidateId, messages, message) => {
  const preparedMessage = prepareMessage(candidateId, message);
  const index = messages.findIndex(
    (item) =>
      (message.randomId && item.randomId === message.randomId) ||
      (message.messageId && item.messageId === message.messageId),
  );
  if (index === -1) {
    return [...messages, preparedMessage];
  }
  messages[index] = preparedMessage;
  return [...messages];
};

export const updateChat = (chats, candidateId, assignment) => {
  // @ts-expect-error
  const chat = Object.values(chats).find((value) => value.userId === candidateId);
  return chat
    ? {
        ...chats,
        // @ts-expect-error
        [chat.conversationId]: {
          // @ts-expect-error
          ...chat,
          ...assignment,
        },
      }
    : chats;
};
export const updateChatByConversationId = (chats, conversationId, assignment) => {
  const chat = chats[conversationId];
  return chat
    ? {
        ...chats,
        [chat.conversationId]: {
          ...chat,
          ...assignment,
        },
      }
    : chats;
};

const keepChatsOnSearch = (action) => action.offset || (action.searchQuery || {}).conversationId;

const deletedContent = 'Message deleted';
const generalLoadError = 'General load error';

const initialState = fromJS({
  loadingUserDetails: false,
  loadingChats: false,
  chatsLoaded: false,
  chatsLoadError: '',
  loadingMessages: {},
  messagesError: '',
  loadingJob: true,
  loadJobError: '',
  loadingPipelines: false,
  pipelinesLoaded: false,
  loadPipelinesError: '',
  deletingCandidate: false,
  deleteCandidateSuccess: {},
  deleteCandidateError: '',
  updatingProfile: '',
  loadingCandidatesFilter: false,
  loadingEditOptions: false,
})
  // @ts-expect-error
  .set('userDetails', {})
  .set('chats', {})
  .set('messages', {})
  .set('job', {})
  .set('candidatesFilter', {
    states: [],
    hrAgents: [],
  })
  .set('searchQuery', {})
  .set('editOptions', {})
  .set('pipelines', []);

// eslint-disable-next-line import/no-default-export
export default (state = initialState, action) => {
  // eslint-disable-line complexity
  if (action.chat) {
    action.chat = action.chat.toString();
  }
  switch (action.type) {
    case LOAD_USER_DETAILS:
      return state.set('userDetails', {}).set('loadingUserDetails', true);
    case LOAD_USER_DETAILS_SUCCESS:
      return state.set('userDetails', action.payload).set('loadingUserDetails', false);
    case LOAD_USER_DETAILS_ERROR:
      logError(action);
      return state.set('loadUserDetailsError', true);

    case LOAD_CHATS: // eslint-disable-line no-case-declarations
      const keepChats = action.startingFrom || action.chat;
      return state
        .set('loadingChats', true)
        .set('chatsLoaded', false)
        .set('chatsLoadError', '')
        .update('messages', (messages) => (keepChats ? messages : {}))
        .update('chats', (chats) => (keepChats ? chats : {}));
    case LOAD_CHATS_SUCCESS:
      if (action.payload.resetToken) {
        wipeStorage();
        windowLocationReload();
        return state;
      }
      return state
        .set('loadingChats', false)
        .set('chatsLoaded', !action.payload.chats.length)
        .update('messages', (messages) =>
          action.payload.chats.reduce(
            (prev, cur) => ({
              ...prev,
              [cur.conversationId]: messages[cur.conversationId] || [],
            }),
            messages,
          ),
        )
        .update('chats', (chats) =>
          action.payload.chats.reduce((prev, cur) => {
            const omnichatMessage = action.payload.messages.find(
              (message) => message.omnichatId === cur.conversationId,
            );
            return {
              ...prev,
              [cur.conversationId]: {
                ...cur,
                ...(omnichatMessage ? prepareMessage(cur.userId, omnichatMessage) : {}),
                searchMode: false,
                recentMode: true,
              },
            };
          }, chats),
        );
    case LOAD_CHATS_ERROR:
      return state.set('chatsLoadError', action.error.payload.error || generalLoadError).set('loadingChats', false);

    case SEARCH_CANDIDATES:
      return state
        .set('searchQuery', action.searchQuery)
        .set('loadingChats', true)
        .set('chatsLoaded', false)
        .set('chatsLoadError', '')
        .update('messages', (messages) => (keepChatsOnSearch(action) ? messages : {}))
        .update('chats', (chats) => (keepChatsOnSearch(action) ? chats : {}));
    case SEARCH_CANDIDATES_SUCCESS: // eslint-disable-line no-case-declarations
      const singleSearch = !!(action.searchQuery || {}).conversationId;
      const chatsFound = !!action.payload.chats.length;

      const updateMessages = (messages) =>
        action.payload.chats.reduce(
          (prev, cur) => ({
            ...prev,
            [cur.conversationId]: [],
          }),
          messages,
        );

      const updateChats = (chats) =>
        action.payload.chats.reduce((prev, cur) => {
          const lastMessage = action.payload.messages.find((message) => message.omnichatId === cur.conversationId);
          return {
            ...prev,
            [cur.conversationId]: {
              ...cur,
              ...(lastMessage
                ? prepareMessage(cur.userId, lastMessage)
                : {
                    sentOn: null,
                  }),
              searchMode: true,
              serverSorting: action.searchQuery.conversationId ? -1 : Object.keys(prev).length,
            },
          };
        }, chats);

      return state
        .set('loadingChats', false)
        .update('chatsLoaded', (chatsLoaded) => (singleSearch ? chatsLoaded : !chatsFound))
        .update('searchQuery', (searchQuery) => (singleSearch ? {} : searchQuery))
        .update('messages', updateMessages)
        .update('chats', updateChats);
    case SEARCH_CANDIDATES_ERROR:
      return state.set('chatsLoadError', action.error.payload.message || generalLoadError).set('loadingChats', false);

    case LOAD_MESSAGES:
      return state
        .update('loadingMessages', (loadingMessages) => ({
          ...loadingMessages,
          [action.chat]: true,
        }))
        .set('messagesError', '');
    case LOAD_MESSAGES_SUCCESS:
      return state
        .update('loadingMessages', (loadingMessages) => ({
          ...loadingMessages,
          [action.chat]: false,
        }))
        .update('messages', (messages) => {
          let updatedMessages;
          if (action.preceding) {
            updatedMessages = [...action.messages, ...(messages[action.chat] || [])];
          } else {
            updatedMessages = action.messages;
          }
          return {
            ...messages,
            [action.chat]: sortMessagesByDate((state.get('chats')[action.chat] || {}).userId, updatedMessages),
          };
        })
        .update('chats', (chats) =>
          chats[action.chat]
            ? {
                ...chats,
                [action.chat]: {
                  ...chats[action.chat],
                  loaded: action.messages.length < messagesPageSize,
                  channelTypes: action.channelTypes,
                },
              }
            : chats,
        );
    case LOAD_MESSAGES_ERROR:
      return state.set('messagesError', action.error).update('loadingMessages', (loadingMessages) => ({
        ...loadingMessages,
        [action.chat]: false,
      }));

    case LOAD_JOB:
      return state.set('loadingJob', true).set('loadJobError', '').set('job', {});
    case LOAD_JOB_ERROR:
      return state.set('loadingJob', false).set('loadJobError', stringifyError(action.error));
    case LOAD_JOB_SUCCESS:
      return state.set('loadingJob', false).set('job', action.payload);

    case LOAD_PIPELINES:
      return state.set('loadingPipelines', true).set('loadPipelinesError', '').set('pipelines', []);
    case LOAD_PIPELINES_ERROR:
      return state.set('loadingPipelines', false).set('loadPipelinesError', action.error || generalLoadError);
    case LOAD_PIPELINES_SUCCESS:
      return state.set('loadingPipelines', false).set('pipelinesLoaded', true).set('pipelines', action.payload);

    case MARK_PIPELINES_READ:
      return state
        .get('pipelines')
        .filter((pipeline) => action.pipelinesIds.includes(pipeline.pipelineId) && !pipeline.readOn).length
        ? state.update('pipelines', (pipelines) =>
            pipelines.map((pipeline) =>
              action.pipelinesIds.includes(pipeline.pipelineId)
                ? {
                    ...pipeline,
                    readOn: true,
                  }
                : pipeline,
            ),
          )
        : state;
    case MARK_PIPELINES_READ_ERROR:
      return state.set('markPipelinesReadError', action.error || generalLoadError);
    case MARK_PIPELINES_READ_SUCCESS:
      return state;

    case LOAD_CANDIDATES_FILTER:
      return state.set('loadingCandidatesFilter', true);
    case LOAD_CANDIDATES_FILTER_SUCCESS:
      return state.set('loadingCandidatesFilter', false).set('candidatesFilter', {
        states: Object.values(action.payload.states).map((item) => ({
          // @ts-expect-error
          id: item.code,
          // @ts-expect-error
          label: startCase(item.code),
          // @ts-expect-error
          name: item.name,
        })),
        hrAgents: action.payload.hrAgents.map(({ userId, name: { displayName } }) => ({
          userId,
          displayName,
        })),
      });
    case LOAD_CANDIDATES_FILTER_ERROR:
      return state
        .set('loadingCandidatesFilter', false)
        .set('candidatesFilterLoadError', action.error || generalLoadError);

    case DELETE_CANDIDATE:
      return state.set('deletingCandidate', true);
    case DELETE_CANDIDATE_SUCCESS:
      return state
        .update('chats', (chats) => omit(chats, action.chat))
        .update('messages', (messages) => omit(messages, action.chat))
        .set('deleteCandidateSuccess', action.payload)
        .set('deletingCandidate', false);
    case DELETE_CANDIDATE_ERROR:
      return state
        .set('deleteCandidateError', action.error.payload.error || generalLoadError)
        .set('deletingCandidate', false);

    case LOAD_EDIT_OPTIONS:
      return state.set('loadingEditOptions', true);
    case LOAD_EDIT_OPTIONS_SUCCESS:
      return state.set('editOptions', action.payload).set('loadingEditOptions', false);
    case LOAD_EDIT_OPTIONS_ERROR:
      return state
        .set('loadEditOptionsError', action.error.payload.error || generalLoadError)
        .set('loadingEditOptions', false);

    case SIGN_IN_SUCCESS:
    case SIGN_UP_SUCCESS:
      setTimeout(() => setStorageUser());
      return state.set('userDetails', action.payload.userDetails || {});
    case SIGN_OUT:
      return initialState;

    case MARK_LOCAL_READ:
      return state.update('messages', (messages) => {
        const chatMessages = messages[action.chat];
        if (chatMessages) {
          let wasUpdated;
          const lastMessage = chatMessages.reduce((prev, cur) => (prev.sentOn > cur.sentOn ? prev : cur), {});
          const lastSentMine =
            (lastMessage.sender || lastMessage.text?.sender).userId.toString() === storageUser.userId.toString();
          const updatedUnreads = chatMessages.map((message) => {
            if (!message.readOn) {
              const shouldUpdate =
                lastSentMine ||
                chatMessages.find((item) => item.sentOn > message.sentOn && message.sender_id == storageUser.userId); // eslint-disable-line eqeqeq
              wasUpdated = wasUpdated || shouldUpdate;
              return shouldUpdate
                ? {
                    ...message,
                    readOn: moment(),
                  }
                : message;
            }
            return message;
          });
          return wasUpdated
            ? {
                ...messages,
                [action.chat]: updatedUnreads,
              }
            : messages;
        }
        return messages;
      });

    case CHAT_SELECTED:
      return state.update('chats', (chats) => ({
        ...chats,
        [action.chat]: {
          ...chats[action.chat],
          readOn: chats[action.chat].readOn || moment(),
        },
      }));

    case NEW_MESSAGE:
      return state
        .update('chats', (chats) => ({
          ...chats,
          [action.chat]: {
            photos: [],
            cvs: [],
            employmentTypes: [],
            positions: [],
            certifications: [],
            conversationId: action.chat,
            ...chats[action.chat],
            ...prepareMessage((chats[action.chat] || {}).userId, action.payload.message),
            recentMode: true,
          },
        }))
        .update('messages', (messages) => {
          const actionChatMessages = messages[action.chat] || [];
          let updatedMessages;
          if (actionChatMessages && (actionChatMessages.length || !isAgent)) {
            updatedMessages = updateOrPushMessage(
              (state.get('chats')[action.chat] || {}).userId,
              actionChatMessages,
              action.payload.message,
            );
          } else {
            updatedMessages = [prepareMessage((state.get('chats')[action.chat] || {}).userId, action.payload.message)];
          }
          return {
            ...messages,
            [action.chat]: updatedMessages.sort((prev, next) => (prev.sentOn.isAfter(next.sentOn) ? 1 : -1)),
          };
        });

    case SEND_MESSAGE:
      return state
        .update('chats', (chats) => ({
          ...chats,
          [action.chat]: {
            ...chats[action.chat],
            recentMode: true,
          },
        }))
        .update('messages', (messages) => ({
          ...messages,
          [action.chat]: [
            ...messages[action.chat],
            prepareMessage((state.get('chats')[action.chat] || {}).userId, action.payload.message),
          ],
        }));

    case MESSAGE_DELETED:
      return state
        .update('messages', (messages) =>
          messages[action.conversationId]
            ? {
                ...messages,
                [action.conversationId]: messages[action.conversationId].filter(
                  (message) => message.omnichatMessageId !== action.messageId,
                ),
              }
            : messages,
        )
        .update('chats', (chats) =>
          (chats[action.conversationId] || {}).omnichatMessageId === action.messageId
            ? {
                ...chats,
                [action.conversationId]: {
                  ...chats[action.conversationId],
                  content: deletedContent,
                },
              }
            : chats,
        );

    case CONTENT_UPDATED: // eslint-disable-line no-case-declarations
      action.message = Object.assign({}, action.message, action.message || {});
      const messageIndex = state
        .get('messages')
        [action.chat].findIndex(
          (item) =>
            (item.messageId || item.omnichatMessageId) ===
            (action.message.messageId || action.message.omnichatMessageId),
        );

      return state
        .update('messages', (messages) => ({
          ...messages,
          [action.chat]: [
            ...messages[action.chat].slice(0, messageIndex),
            {
              ...messages[action.chat][messageIndex],
              content: action.content,
              edited_on: true, // eslint-disable-line camelcase
            },
            ...messages[action.chat].slice(messageIndex + 1),
          ],
        }))
        .update('chats', (chats) =>
          chats[action.chat].messageId === action.message
            ? {
                ...chats,
                [action.chat]: {
                  ...chats[action.chat],
                  content: action.content,
                },
              }
            : chats,
        );

    case UPDATING_PROFILE:
      return state.set('updatingProfile', action.chat);

    case PROFILE_UPDATED:
      return state.set('updatingProfile', '').update('chats', (chats) =>
        Object.keys(chats).reduce((prev, cur) => {
          prev[cur] =
            chats[cur].cats_id === action.profile.cats_id
              ? {
                  ...chats[cur],
                  ...action.profile,
                }
              : chats[cur];
          return prev;
        }, {}),
      );

    case PIPELINE_UPDATED:
      return state
        .update('job', (job) => {
          if (job.jobId !== action.pipeline.job.jobId) {
            return job;
          }
          if ((job.pipelines || []).length) {
            job.pipelines[0] = action.pipeline;
          }
          return { ...job };
        })
        .update('pipelines', (pipelines) => {
          const pipelineIndex = pipelines.findIndex((pipeline) => pipeline.pipelineId === action.pipeline.pipelineId);
          if (pipelineIndex === -1) {
            return [...pipelines, action.pipeline];
          }
          return [...pipelines.slice(0, pipelineIndex), action.pipeline, ...pipelines.slice(pipelineIndex + 1)];
        });

    case CHANGE_STATUS:
      return state.update('chats', (chats) =>
        Object.keys(chats).reduce((prev, cur) => {
          if (action.payload.rooms.includes(cur)) {
            chats[cur] = {
              ...chats[cur],
              // @ts-expect-error
              [action.payload.online ? 'online' : 'offline']: momentify(),
              isOnline: action.payload.online,
            };
            return { ...prev };
          }
          return prev;
        }, chats),
      );

    case AI_PAUSED: // eslint-disable-line no-case-declarations
      const pausedRoom = action.payload.conversationId.toString();
      return state.update('chats', (chats) =>
        chats[pausedRoom]
          ? {
              ...chats,
              [pausedRoom]: {
                ...chats[pausedRoom],
                aiPaused: action.payload.pause,
              },
            }
          : chats,
      );

    case MESSAGE_UPDATED: // eslint-disable-line no-case-declarations
      let updatedChat;
      action.payload = Object.assign({}, action.payload, action.payload.message || {});
      return state
        .update('messages', (messages) => {
          const messagesUpdated = Object.entries(messages).some((entry) => {
            if (!Array.isArray(entry[1])) {
              logError(`Wrong messages object format!`, { entry });
              entry[1] = [];
            }
            // @ts-expect-error
            const updatedMessageIndex = entry[1].findIndex(
              (item) =>
                (item.messageId || item.omnichatMessageId) ===
                (action.payload.messageId || action.payload.omnichatMessageId),
            );
            if (updatedMessageIndex > -1) {
              updatedChat = action.payload.conversationId || action.payload.omnichatId;
              entry[1][updatedMessageIndex] = prepareMessage((state.get('chats')[updatedChat] || {}).userId, {
                ...action.payload,
                updatedAt: Date.now(),
              });
              // @ts-expect-error
              messages[entry[0]] = [...entry[1]];
              return true;
            }
          });
          return messagesUpdated ? { ...messages } : messages;
        })
        .update('chats', (chats) =>
          updatedChat
            ? {
                ...chats,
                [updatedChat]: {
                  ...chats[updatedChat],
                  updatedAt: Date.now(),
                },
              }
            : chats,
        );

    case CANDIDATE_PROFILE_UPDATED:
      return state.update('chats', (chats) => updateChat(chats, action.candidateId, action.updatedSections));

    case CONVERSATION_WORKFLOW_CHANGED:
      return state.update('chats', (chats) =>
        updateChatByConversationId(chats, action.conversationId, {
          workflowStateConversation: action.workflowState,
        }),
      );

    case OMNICHAT_UPDATED:
      return state.update('chats', (chats) =>
        updateChatByConversationId(chats, action.omnichat.omnichatId, action.omnichat),
      );

    case CANDIDATE_REGISTRATION_COMPLETED:
      return state.update('userDetails', (userDetails) => {
        set(userDetails, 'rw.workflow_state.code', 'registered');
        return { ...userDetails };
      });

    default:
      return state;
  }
};

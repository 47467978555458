import PropTypes from 'prop-types';
import React from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment';

import { availabilitySlotsNumber } from 'shared/constants';

import TimeRow from './TimeRow';
import EventSelector from './EventSelector';

const weekDaysKeys = Array.from(Array(7).keys());

class WeekScheduler extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    days: this.props.currentSchedule,
    startingCell: null,
    currentEvent: this.props.availabilityTypes[1],
    oldDays: this.props.currentSchedule,
  };
  unmounted: boolean;
  weekTable: any;
  debouncedHandleSelectEvent: (...args: any[]) => any;

  UNSAFE_componentWillMount() {
    // @ts-expect-error
    this.weekdaysShort = moment.weekdaysShort();
    // @ts-expect-error
    this.weekdaysShort.push(this.weekdaysShort.shift());

    this.debouncedHandleSelectEvent = debounce(this.handleSelectEvent.bind(this), 20);
    this.initWeek();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentSchedule } = nextProps;
    if (this.props.currentSchedule !== currentSchedule) {
      this.setState(
        {
          days: currentSchedule,
          oldDays: currentSchedule,
        },
        () => this.initWeek(),
      );
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onMouseDown = (evt) => {
    evt.preventDefault();
    const rowNum = evt.target.getAttribute('data-row');
    const dayNum = evt.target.getAttribute('data-day');
    this.setState({
      startingCell: {
        day: parseInt(dayNum, 10),
        time: parseInt(rowNum, 10),
      },
    });
    this.weekTable.addEventListener('mouseover', this.onMouseOver);
    window.addEventListener('mouseup', this.onMouseUp);
  };

  onMouseUp = () => {
    const { updateEvents } = this.props;
    const { days, changesMade } = this.state as any;
    for (let i = 0; i < availabilitySlotsNumber; i += 1) {
      for (let j = 0; j < 7; j += 1) {
        delete days[j][i].dragging;
      }
    }
    if (this.weekTable) {
      this.weekTable.removeEventListener('mouseover', this.onMouseOver);
    }
    window.removeEventListener('mouseup', this.onMouseUp);
    if (this.unmounted) {
      return;
    }
    this.setState({
      oldDays: days,
      changesMade: false,
    });
    if (changesMade) {
      updateEvents(days);
    }
  };

  onMouseOver = (e) => {
    const rowNum = e.target.getAttribute('data-row');
    const dayNum = e.target.getAttribute('data-day');
    this.handleDragOver(parseInt(dayNum, 10), parseInt(rowNum, 10));
  };

  setupTimeRows() {
    const { availabilityTypes, formatAvailabilityTime } = this.props;
    const { days } = this.state;
    const rows = [];
    for (let i = 0; i < availabilitySlotsNumber; i += 1) {
      const row = [];
      for (let j = 0; j < 7; j += 1) {
        row.push(days[j] ? days[j][i] : availabilityTypes[0]);
      }
      rows.push(row);
    }
    return rows.map((tRow, index) => (
      <TimeRow
        key={index}
        rowNumber={index}
        dayItems={tRow}
        defaultEvent={availabilityTypes[0]}
        formatAvailabilityTime={formatAvailabilityTime}
      />
    ));
  }

  handleSelectEvent(eventSelected) {
    const { currentEvent } = this.state;
    if (eventSelected.event !== currentEvent.event) {
      this.setState({ currentEvent: eventSelected });
    }
  }

  handleDragOver(dayNum, rowNum) {
    const { startingCell, currentEvent, oldDays } = this.state;

    const dayDiff = dayNum - startingCell.day;
    const timeDiff = rowNum - startingCell.time;
    const newDays = [];
    const draggingCurrentEvent = {
      ...currentEvent,
      dragging: true,
    };

    for (let j = 0; j < 7; j += 1) {
      newDays.push(oldDays[j].slice());
    }
    if (dayDiff) {
      const dayStart = startingCell.day < dayNum ? startingCell.day : dayNum;
      const dayEnd = startingCell.day < dayNum ? dayNum : startingCell.day;
      const timeStart = startingCell.time < rowNum ? startingCell.time : rowNum;
      const timeEnd = startingCell.time < rowNum ? rowNum : startingCell.time;
      for (let j = dayStart; j <= dayEnd; j += 1) {
        if (timeDiff) {
          for (let i = timeStart; i <= timeEnd; i += 1) {
            // eslint-disable-line max-depth
            newDays[j][i] = draggingCurrentEvent;
          }
        } else {
          newDays[j][startingCell.time] = draggingCurrentEvent;
        }
      }
    } else if (timeDiff) {
      const timeStart = startingCell.time < rowNum ? startingCell.time : rowNum;
      const timeEnd = startingCell.time < rowNum ? rowNum : startingCell.time;
      for (let j = timeStart; j <= timeEnd; j += 1) {
        newDays[startingCell.day][j] = draggingCurrentEvent;
      }
    }
    this.setState({
      days: newDays,
      changesMade: !!(dayDiff || timeDiff),
    });
  }

  initWeek(props = this.props) {
    const {
      startOfWeek,
      settings: {
        dateFormats: { dateFormat },
      },
    } = props;
    if (startOfWeek) {
      const startOfWeekClone = startOfWeek.clone();
      this.setState({
        startOfWeek: startOfWeekClone,
        weekDays:
          startOfWeekClone.add(-1, 'day') && weekDaysKeys.map(() => startOfWeekClone.add(1, 'day').format(dateFormat)),
      });
    }
  }

  renderShiftButton(shift) {
    const { shiftWeek } = this.props;
    return (
      <button type="button" className="btn btn-sm btn-box-tool" onClick={() => shiftWeek(shift)}>
        <i className={` lnr text-primary ${shift === -1 ? 'lnr-chevron-left-circle' : 'lnr-chevron-right-circle'}`} />
      </button>
    );
  }

  render() {
    const { daysData, availabilityTypes, updateDaysData } = this.props;
    const { currentEvent, weekDays } = this.state as any;

    return (
      <div id="WeekSchedulerTable">
        <EventSelector
          availabilityTypes={availabilityTypes}
          selectedEvent={currentEvent}
          selectEvent={this.debouncedHandleSelectEvent}
        />
        {weekDays && (
          <div className="pull-right">
            {this.renderShiftButton(-1)}
            <span className="display-inline" style={{ padding: '10px 2px' }}>
              {weekDays[0]} - {weekDays[6]}
            </span>
            {this.renderShiftButton(1)}
          </div>
        )}
        <table>
          <thead>
            <tr>
              <th />
              {/* @ts-expect-error */}
              {this.weekdaysShort.map((weekday, index) => (
                <th key={weekday} className="text-center">
                  <span className="long control-label">{weekday}</span>
                  <span className="short">{weekday}</span>
                  {weekDays && <div className="small">{weekDays[index]}</div>}
                  {!!daysData[index] && (
                    <div className="small">
                      <label>
                        <input
                          type="checkbox"
                          checked={daysData[index].flexibleHours}
                          onChange={(evt) =>
                            updateDaysData({
                              ...daysData,
                              [index]: {
                                ...daysData[index],
                                flexibleHours: evt.target.checked,
                              },
                            })
                          }
                        />
                        &nbsp;
                        {translate('Flexible hours')}
                      </label>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        </table>
        <div className="scroll-table-body">
          <table>
            <tbody ref={(ref) => (this.weekTable = ref)} className="week-table" onMouseDown={this.onMouseDown}>
              {this.setupTimeRows()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
WeekScheduler.propTypes = {
  settings: PropTypes.object.isRequired,
  availabilityTypes: PropTypes.array.isRequired,
  currentSchedule: PropTypes.array.isRequired,
  daysData: PropTypes.object.isRequired,
  updateEvents: PropTypes.func.isRequired,
  updateDaysData: PropTypes.func,
  formatAvailabilityTime: PropTypes.func.isRequired,
  startOfWeek: PropTypes.object,
  shiftWeek: PropTypes.func,
};

// eslint-disable-next-line import/no-default-export
export default WeekScheduler;

import { memo } from 'react';

import RecommendedJobsList, { type RecommendedJobsListProps } from './RecommendedJobsList';

const MenuRecommendedWidget = (props: RecommendedJobsListProps) => (
  <RecommendedJobsList
    mobileMode={props.mobileMode}
    parentId={props.parentId}
    workflowId={props.workflowId}
    onSelectJob={props.onSelectJob}
    onJobNotFound={props.onJobNotFound}
    setCentralContentJobDetails={props.setCentralContentJobDetails}
  />
);

// eslint-disable-next-line import/no-default-export
export default memo(MenuRecommendedWidget);

import PropTypes from 'prop-types';
import React from 'react';

let translatedSkip;

function SuggestionsButtons(props) {
  const { suggestions, emitSendMessage, selectedSuggestions, setSelectedSuggestions, multiSelect } = props;

  const [multiSelectAny, setMultiSelectAny] = React.useState(false);

  React.useEffect(() => {
    translatedSkip = translate('Skip');
  }, [suggestions]);

  const onSuggestionClick = (suggestion) => {
    if (!multiSelect) {
      return emitSendMessage(suggestion);
    }

    setSelectedSuggestions(
      selectedSuggestions.includes(suggestion)
        ? selectedSuggestions.filter((selectedSuggestion) => selectedSuggestion !== suggestion)
        : [...selectedSuggestions, suggestion],
    );

    setMultiSelectAny(false);
  };

  // eslint-disable-next-line react/prop-types
  const SuggestionButton = ({ suggestion, suggestionIndex }) => {
    const suggestionSelected = selectedSuggestions.includes(suggestion);

    return (
      <button
        key={suggestionIndex}
        type="button"
        className={`btn btn-sm btn-suggestion ${suggestionSelected ? 'active' : ''}`}
        onClick={() => onSuggestionClick(suggestion)}
      >
        {multiSelect && suggestionSelected && <i className="fa fa-check margin-right text-muted" />}
        {suggestion}
      </button>
    );
  };

  return (
    <div>
      {suggestions.map((suggestion, suggestionIndex) => (
        <SuggestionButton key={suggestionIndex} suggestion={suggestion} suggestionIndex={suggestionIndex} />
      ))}

      {multiSelect && (
        <button
          type="button"
          className={`btn btn-sm btn-suggestion ${multiSelectAny ? 'active' : ''}`}
          onClick={() => {
            setSelectedSuggestions(suggestions.filter((item) => item !== translatedSkip && !multiSelectAny));

            setMultiSelectAny(!multiSelectAny);
          }}
        >
          {translate('Any')}
        </button>
      )}
    </div>
  );
}

SuggestionsButtons.propTypes = {
  suggestions: PropTypes.array.isRequired,
  selectedSuggestions: PropTypes.array.isRequired,
  setSelectedSuggestions: PropTypes.func.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default SuggestionsButtons;

import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

import { requestBackend } from '@eva/emf/app/utils/request';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { stringifyError } from 'shared/functions';

// eslint-disable-next-line import/no-default-export
export default class ModalTermsAndConditions extends React.Component<any, any> {
  state = {
    show: false,
  };

  // eslint-disable-next-line react/sort-comp
  ok = () => {
    this.setState({ show: false });
    // @ts-expect-error
    return this.resolve();
  };

  cancel = (status) => {
    this.setState({ show: false });

    return this.reject(status);
  };

  hide = () => {
    this.setState({ show: false });
  };
  // eslint-disable-next-line react/sort-comp
  unmounted: any;
  promise: any;
  reject: (reason?: any) => void;
  resolve: (value: unknown) => void;
  static defaultProps: { size: string };
  // eslint-disable-next-line react/sort-comp
  static propTypes: { size: PropTypes.Requireable<string> };

  open(editable) {
    this.setState({
      show: true,
      editable,
      termsAndConditions: '',
    });

    this.loadTermsAndConditions();

    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    return this.promise;
  }

  loadTermsAndConditions() {
    this.setState({
      loading: true,
      error: '',
    });
    requestBackend('/cbe/settings/terms-and-conditions').then(
      ({ termsAndConditions }) => {
        if (this.unmounted) {
          return;
        }
        this.setState({
          loading: false,
          termsAndConditions,
        });
      },
      (err) => {
        if (this.unmounted) {
          return;
        }
        this.setState({
          loading: false,
          err: stringifyError(err),
        });
      },
    );
  }

  render() {
    const { size } = this.props;
    const { show, editable, loading, termsAndConditions } = this.state as any;
    return (
      <Modal show={show} onHide={this.hide} bsSize={size}>
        <form onSubmit={this.ok}>
          <Modal.Header closeButton>
            <Modal.Title>{translate('Terms and Conditions')}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: '50vh',
              overflow: 'auto',
            }}
          >
            <div>
              <div className="terms">
                {loading && (
                  <div className="text-center padding">
                    <Spinner />
                  </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className={`btn btn-sm btn-${editable ? 'success' : 'default'}`} onClick={this.ok}>
              {translate(editable ? 'Accept' : 'Close')}
            </button>
            {editable && (
              <button type="button" className="btn btn-sm btn-default" onClick={() => this.cancel('Decline')}>
                {translate('Decline')}
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

ModalTermsAndConditions.defaultProps = {
  size: 'large',
};

ModalTermsAndConditions.propTypes = {
  size: PropTypes.string,
};

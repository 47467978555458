import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { prepareSelector } from 'shared/functions';

import { updateUserProfile } from 'containers/App/actions';

import SectionPaneShared from './SectionPaneShared';

class ProfileSection extends React.Component<any, any> {
  state = {
    editMode: {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeSection } = nextProps;

    if (this.props.activeSection !== activeSection) {
      this.setState({
        editMode: false,
      });
    }
  }

  switchEditMode = (index, value) => {
    const { editMode } = this.state;

    // @ts-expect-error
    if (this.unmounted) {
      return;
    }

    this.setState({
      editMode: {
        ...editMode,
        [index]: value,
      },
    });
  };

  updateEntity = (entityUpdate) => {
    const { dispatchUpdateUserProfile } = this.props;

    dispatchUpdateUserProfile(entityUpdate);
  };

  render() {
    const { editMode } = this.state;
    const { userProfile, activeSection, editOptions, mobileMode } = this.props;

    return (
      <div className="profile-section-wrapper" id={`${activeSection.sectionKey}`}>
        {activeSection.paneSettings.map((settings, index) => (
          <SectionPaneShared
            key={`side-${index}`}
            entity={userProfile}
            paneIndex={index}
            formName={`${activeSection.sectionKey}-${index}`}
            paneSettings={settings}
            updateEntity={this.updateEntity}
            editMode={editMode[index] || ''}
            editOptions={editOptions}
            mobileMode={mobileMode}
            switchEditMode={(value) => this.switchEditMode(index, value)}
          />
        ))}
      </div>
    );
  }
}

// @ts-expect-error
ProfileSection.propTypes = {
  userProfile: PropTypes.object.isRequired,
  activeSection: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  switchToChat: PropTypes.func.isRequired,
  loadingEditOptions: PropTypes.bool.isRequired,
  mobileMode: PropTypes.bool,
  dispatchUpdateUserProfile: PropTypes.func.isRequired,
};

const prepareGlobalSelector = (value) => prepareSelector('global', value);
const prepareMessengerSelector = (value) => prepareSelector('candidate', value);
const mapStateToProps = createStructuredSelector({
  userProfile: prepareGlobalSelector('userProfile'),
  editOptions: prepareMessengerSelector('editOptions'),
  loadingEditOptions: prepareMessengerSelector('loadingEditOptions'),
});

const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error
  dispatchUpdateUserProfile: (...args) => dispatch(updateUserProfile(...args)),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection);

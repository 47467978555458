/* eslint-disable no-restricted-properties */
import { call, put, takeLatest } from 'redux-saga/effects';

import { requestBackend } from '@eva/emf/app/utils/request';
import { messagesPageSize } from 'shared/constants';
import { replaceCompanyName, logError } from 'shared/functions';

import {
  loadUserDetailsSuccess,
  loadUserDetailsError,
  loadChatsSuccess,
  loadChatsError,
  loadMessagesSuccess,
  loadMessagesError,
  loadJobSuccess,
  loadJobError,
  loadPipelinesSuccess,
  loadPipelinesError,
  markPipelinesReadSuccess,
  markPipelinesReadError,
  loadCandidatesFilterSuccess,
  loadCandidatesFilterError,
  searchCandidatesSuccess,
  searchCandidatesError,
  deleteCandidateSuccess,
  deleteCandidateError,
  loadEditOptionsSuccess,
  loadEditOptionsError,
} from './actions';
import {
  LOAD_USER_DETAILS,
  LOAD_CHATS,
  LOAD_MESSAGES,
  LOAD_JOB,
  LOAD_PIPELINES,
  MARK_PIPELINES_READ,
  LOAD_CANDIDATES_FILTER,
  SEARCH_CANDIDATES,
  DELETE_CANDIDATE,
  LOAD_EDIT_OPTIONS,
} from './constants';

let searchMode: any;

export function* getUserDetails() {
  try {
    const payload = yield call(requestBackend, '/utils/user');
    yield put(loadUserDetailsSuccess(payload));
  } catch (err) {
    logError(err);
    yield put(loadUserDetailsError(err));
  }
}

export function* loadUserDetails() {
  yield takeLatest(LOAD_USER_DETAILS, getUserDetails);
}

export function* getChats({ startingFrom, chat }) {
  try {
    if (!(searchMode && (chat || {}).full)) {
      searchMode = false;
      const payload = yield call(
        requestBackend,
        // eslint-disable-next-line no-useless-concat
        `/my/webchats${chat ? `/${chat}?full=true` : ''}` + `${startingFrom ? `?starting_from=${startingFrom}` : ''}`,
      );
      if (!searchMode) {
        if (localStorage.emptyChatsMode) {
          // eslint-disable-line max-depth
          payload.messages = [];
        }
        yield put(loadChatsSuccess(payload));
      }
    }
  } catch (err) {
    logError(err);
    yield put(loadChatsError(err));
  }
}

export function* loadChats() {
  // @ts-expect-error
  yield takeLatest(LOAD_CHATS, getChats);
}

export function* getCandidates({ searchQuery, offset = '' }) {
  try {
    searchMode = true;
    const { state = '', agents = '', name = '', conversationId = '' } = searchQuery;
    const payload = yield call(
      requestBackend,
      `/chats/search?state=${state}` +
        `&agents=${agents}&name=${encodeURIComponent(name)}&conversation=${conversationId}&offset=${offset}`,
    );
    if (searchMode) {
      if (localStorage.emptyChatsMode) {
        payload.messages = [];
      }
      yield put(searchCandidatesSuccess(payload, searchQuery));
    }
  } catch (err) {
    logError(err);
    yield put(searchCandidatesError(err));
  }
}

export function* searchCandidates() {
  // @ts-expect-error
  yield takeLatest(SEARCH_CANDIDATES, getCandidates);
}

export function* getMessages({ chat, preceding = '', companyName }) {
  try {
    const { items: messages } = yield call(
      requestBackend,
      `/webchats/messages?limit=${messagesPageSize}&preceding=${preceding}`,
    );
    if (localStorage.emptyChatsMode) {
      messages.length = 0;
    }
    const companyNameReplacer = replaceCompanyName(companyName);
    messages.forEach(companyNameReplacer);
    const channelTypes = {
      webchat: {
        isAllowed: true,
      },
    };
    yield put(loadMessagesSuccess(messages, channelTypes, chat, preceding));
  } catch (err) {
    logError(err);
    yield put(loadMessagesError(err));
  }
}

export function* loadMessages() {
  // @ts-expect-error
  return yield takeLatest(LOAD_MESSAGES, getMessages);
}

export function* getJob({ jobId }) {
  try {
    const job = yield call(requestBackend, `/public-jobs/my/${jobId}`);
    yield put(loadJobSuccess(job));
  } catch (err) {
    logError(err);
    yield put(loadJobError(err));
  }
}

export function* loadJob() {
  // @ts-expect-error
  return yield takeLatest(LOAD_JOB, getJob);
}

export function* getPipelines() {
  try {
    const items = yield call(requestBackend, '/my/pipelines');
    yield put(loadPipelinesSuccess(items));
  } catch (err) {
    logError(err);
    yield put(loadPipelinesError(err));
  }
}

export function* loadPipelines() {
  return yield takeLatest(LOAD_PIPELINES, getPipelines);
}

export function* postMarkPipelinesRead({ pipelinesIds }) {
  try {
    yield call(requestBackend, '/pipelines/mark-read', {
      method: 'PUT',
      body: JSON.stringify({ pipelinesIds }),
    });
    yield put(markPipelinesReadSuccess());
  } catch (err) {
    logError(err);
    yield put(markPipelinesReadError(err));
  }
}

export function* markPipelinesRead() {
  // @ts-expect-error
  return yield takeLatest(MARK_PIPELINES_READ, postMarkPipelinesRead);
}

export function* getCandidatesFilter() {
  try {
    const payload = yield call(requestBackend, '/chats/filter-options');
    yield put(loadCandidatesFilterSuccess(payload));
  } catch (err) {
    logError(err);
    yield put(loadCandidatesFilterError(err));
  }
}

export function* loadCandidatesFilter() {
  return yield takeLatest(LOAD_CANDIDATES_FILTER, getCandidatesFilter);
}

export function* callDeleteCandidate({ chat, candidateId }) {
  try {
    const payload = yield call(requestBackend, `/candidates/${candidateId}`, { method: 'DELETE' });
    yield put(deleteCandidateSuccess(chat, payload));
  } catch (err) {
    logError(err);
    yield put(deleteCandidateError(err));
  }
}

export function* deleteCandidate() {
  // @ts-expect-error
  return yield takeLatest(DELETE_CANDIDATE, callDeleteCandidate);
}

export function* callLoadEditOptions() {
  try {
    const payload = yield call(requestBackend, '/my/candidate-profile/edit-options');
    yield put(loadEditOptionsSuccess(payload));
  } catch (err) {
    logError(err);
    yield put(loadEditOptionsError(err));
  }
}

export function* loadEditOptions() {
  return yield takeLatest(LOAD_EDIT_OPTIONS, callLoadEditOptions);
}

// eslint-disable-next-line import/no-default-export
export default [
  loadUserDetails,
  loadChats,
  searchCandidates,
  loadMessages,
  loadJob,
  loadPipelines,
  markPipelinesRead,
  loadCandidatesFilter,
  deleteCandidate,
  loadEditOptions,
];

import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

const input = 'input';
const excludedKeys = ['default', 'enableoutput', input];
const attributeString = (attributeEntry) => `${attributeEntry[0]}="${attributeEntry[1]}"`;

class RadioButtons extends AIUIComponent {
  state = {
    value: '',
  };

  UNSAFE_componentWillMount() {
    const { attributes, content } = this.props;

    const attributesKeys = Object.entries(attributes)
      .sort((prev, next) => (content.indexOf(attributeString(prev)) > content.indexOf(attributeString(next)) ? 1 : -1))
      .map(([key]) => key);
    this.setState({
      radioButtons: attributesKeys
        .filter((key) => !excludedKeys.includes(key))
        .map((key) => ({
          key,
          label: attributes[key],
        })),
      input: attributesKeys.includes(input),
    });
  }

  submitResult(result) {
    this.setState({
      submitted: true,
    });
    this.props.resultingContent(result);
  }

  handleContentKeyPress(evt) {
    if (evt.key === 'Enter') {
      const { value } = this.state;
      if (evt.ctrlKey || evt.shiftKey) {
        const target = evt.target;
        if (typeof target.selectionStart === 'number' && typeof target.selectionEnd === 'number') {
          const start = target.selectionStart;
          const newValue = `${target.value.slice(0, start)}\n${target.value.slice(evt.target.selectionEnd)}`;
          this.setState({ value: newValue }, () => (target.selectionStart = target.selectionEnd = start + 1));
        }
        evt.preventDefault();
        return false;
      }
      if (value) {
        this.submitResult(value);
      }
    }
  }

  render() {
    const { editable, attributes } = this.props;
    // @ts-expect-error
    const { radioButtons, submitted, value } = this.state;

    if (!editable || submitted) {
      return null;
    }

    return (
      <div className="input-group">
        {radioButtons.map((radioButton) => (
          <button
            type="button"
            key={radioButton.key}
            className="bubble me bubble-with-input"
            disabled={submitted || !editable}
            onClick={() => this.submitResult(radioButton.label)}
          >
            <span className="bubble-content">
              <span className="message-content">{radioButton.label}</span>
            </span>
          </button>
        ))}
        {'enableoutput' in attributes && (
          <div
            style={{
              display: 'inline-table',
              minWidth: '35%',
            }}
          >
            <Textarea
              className="form-control messenger-text"
              placeholder={translate('Type your message')}
              value={value}
              minRows={1}
              maxRows={5}
              onChange={(evt) => this.setState({ value: evt.target.value })}
              onKeyPress={(evt) => this.handleContentKeyPress(evt)}
            />
            <span className="input-group-btn">
              <button
                type="submit"
                disabled={!value}
                className="btn btn-primary btn-send"
                onClick={() => this.submitResult(value)}
              >
                <i className="ll-icon ll-send" />
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
RadioButtons.propTypes = {
  attributes: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default RadioButtons;

import PropTypes from 'prop-types';
import React from 'react';

import { requestBackend } from '@eva/emf/app/utils/request';
import { agentPrefix, yellowHighlight } from 'shared/constants';
import { stringifyError } from 'shared/functions';

import ModalPrompt from 'containers/sharedModals/ModalPrompt';

const qnaHref = `${agentPrefix}/eva-settings?code=qnaSkill&entityModal=1&section=toneOfVoice&tab=basicSkills`;

// eslint-disable-next-line import/no-default-export
export default class MessageQnA extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  modalPrompt: ModalPrompt;
  static propTypes: { message: PropTypes.Validator<object> };

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  addAnswer() {
    const {
      message: { metadata },
    } = this.props;
    this.setState({
      addingAnswer: true,
    });
    const disableAddingMode = () => {
      // @ts-expect-error
      if (this.unmounted) {
        return;
      }
      this.setState({
        addingAnswer: false,
      });
    };

    this.modalPrompt.open(translate('Add answer to Q&A')).then((text) => {
      requestBackend(`/eva/skills/qnaSkill/tone-of-voice/${metadata.qnaIntent}`, {
        method: 'POST',
        body: JSON.stringify({
          messages: [
            {
              text,
            },
          ],
        }),
      }).then(disableAddingMode, (err) => {
        // @ts-expect-error
        if (this.unmounted) {
          return;
        }
        this.setState({
          addingAnswer: false,
          error: stringifyError(err),
        });
      });
    }, disableAddingMode);
  }

  render() {
    const {
      message: { metadata },
    } = this.props;
    const { addingAnswer, error } = this.state as any;

    return (
      <div
        className="text-center text-muted"
        style={{
          background: addingAnswer ? yellowHighlight : '',
        }}
      >
        {error && <div className="text-danger">{error}</div>}
        <ModalPrompt ref={(ref) => (this.modalPrompt = ref)} />
        <small>
          {metadata.hasAnswer && (
            <div>
              <i className="fa fa-code-fork margin-right" />
              {translate('You can see the added answer in the')}&nbsp;
              <a href={qnaHref} target="_blank" rel="noreferrer">
                {translate('Q&A skills')}
              </a>
            </div>
          )}
          {!metadata.hasAnswer && (
            <div>
              <i className="fa fa-code-fork margin-right" />
              {translate('There is no standard answer to this question in the system.')}&nbsp;
              <a onClick={() => this.addAnswer()}>{translate('Add an answer')}</a>
            </div>
          )}
        </small>
      </div>
    );
  }
}

MessageQnA.propTypes = {
  message: PropTypes.object.isRequired,
};

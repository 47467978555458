import React from 'react';

type SkillType = {
  name: string;
  skillProficiencyName: string | null;
};

export const Skill: React.FC<SkillType> = ({ name, skillProficiencyName }) => (
  <span className="job-skill-item">
    {name}
    {skillProficiencyName !== null && `(${skillProficiencyName})`}
    {'; '}
  </span>
);

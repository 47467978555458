import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment/moment';
import GoogleMapReact from 'google-map-react';

import { googleApiKey } from 'shared/constants';
import { logError } from 'shared/functions';

import marker from 'assets/images/marker.png';

const Marker = () => <img src={marker} alt="" />;

class LocationMap extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  static propTypes: { job: PropTypes.Validator<object> };

  UNSAFE_componentWillMount() {
    const { job } = this.props;
    if (job) {
      this.initLocation();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { job } = nextProps;
    if (this.props.job !== job && job.code) {
      this.initLocation(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  initLocation(props = this.props) {
    this.setState({
      locationString: '',
      duration: '',
    });
    if (!props.job.location) {
      return;
    }
    const firstLocation = props.job.location;
    const locationDuration = (firstLocation.metadata || {}).duration;
    const locationString = firstLocation.displayAddress;
    if (!locationString) {
      return;
    }
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${locationString}&key=${googleApiKey}`)
      .then((response) => response.json())
      .then(
        (json) => {
          // @ts-expect-error
          if (this.unmounted) {
            return;
          }
          if (json.results.length) {
            this.setState({
              location: json.results[0].geometry.location,
              locationString,
              duration: locationDuration ? moment.duration(locationDuration, 'seconds').humanize() : '',
            });
          }
        },
        (err) => logError(err),
      );
    if (props.job.userAddress) {
      // @ts-expect-error
      const service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [props.job.userAddress],
          destinations: [firstLocation.toString()],
          travelMode: 'TRANSIT',
        },
        (res) => {
          if (res.rows.length) {
            const elements = res.rows[0].elements;
            if (elements[0].duration) {
              const seconds = elements.reduce((prev, next) => prev + next.duration.value, 0);
              // @ts-expect-error
              const days = moment.duration(seconds * 1000)._data.days;
              const duration = `${days ? `${days} d ` : ''}${moment.utc(seconds * 1000).format('HH [h] mm [m]')}`;
              this.setState({ locationString, duration });
            }
          }
        },
      );
    }
  }

  renderGoogleMap() {
    const { location } = this.state as any;
    return (
      <GoogleMapReact center={location} zoom={16}>
        {/* @ts-expect-error */}
        <Marker lat={location.lat} lng={location.lng}>
          123
        </Marker>
      </GoogleMapReact>
    );
  }

  render() {
    const { locationString, duration } = this.state as any;
    if (!locationString) {
      return null;
    }
    return (
      <div className="job-info-block" id="job-location-address">
        <div className="job-detail-container">
          <h3>{translate('Location')}</h3>
          <p>
            <span className="margin-right" id="job-address">
              <i className="lnr lnr-map-marker text-muted" /> {locationString}
            </span>
            {!!duration && (
              <span className="margin-right" id="job-duration">
                <i className="lnr lnr-bus text-muted" />{' '}
                {duration && translate('{{duration}} away from your house', { duration })}
              </span>
            )}
          </p>
        </div>
        <div className="map margin-top">{this.renderGoogleMap()}</div>
      </div>
    );
  }
}

LocationMap.propTypes = {
  job: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default LocationMap;

import { useCallback, useMemo, useRef } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import ms from 'ms';

import { getPagedDataTotal, getPagedDataReceivedLength, deleteFromPagedData } from '@eva/emf/app/shared/functions-ts';
import { cacheNames } from 'shared/constants';
import type { JobDto } from '@eva/types/dto';

import { fetchRecommendedJobById, fetchRecommendedJobsByWorkflowId, fetchRecommendedJobsWorkflows } from './services';
import { buildCacheName, appendExistingIds, getNextJob, filterPage } from './functions';

const cacheTimeDefault = ms('5m');
const staleTimeDefault = ms('1h');

export const useRecommendedJobsWorkflows = () => {
  const query = useQuery(cacheNames.jobWorkflows, fetchRecommendedJobsWorkflows, {
    staleTime: staleTimeDefault,
    cacheTime: cacheTimeDefault,
    suspense: true,
  });

  const jobsWorkflows = useMemo(
    () => query.data?.items?.sort?.((jw1, jw2) => jw1.sortOrder - jw2.sortOrder),
    [query.data?.items],
  );

  return {
    jobsWorkflows,
    ...query,
  };
};

const recommendedJobsPageSize = 10;

export const useRecommendedJobs = (pipelineWorkflowId: number) => {
  const queryClient = useQueryClient();
  const existingJobIds = useRef<{ [key: number]: number }>({});

  const { data, hasNextPage, fetchNextPage, ...query } = useInfiniteQuery(
    buildCacheName(pipelineWorkflowId),
    async ({ pageParam }) => {
      const page = await fetchRecommendedJobsByWorkflowId({
        pipelineWorkflowIds: [pipelineWorkflowId],
        limit: pageParam?.limit || recommendedJobsPageSize,
        offset: pageParam?.offset,
      });

      const filteredPage = filterPage(page, (job) => !existingJobIds.current[job.jobId]);

      return filteredPage;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        existingJobIds.current = appendExistingIds(lastPage.items, existingJobIds.current, ({ jobId }) => jobId);

        const total = getPagedDataTotal(allPages);
        const received = getPagedDataReceivedLength(allPages);

        // Since one job can be mixed-in we need to unshift offset by 1
        const offset = received - (received % recommendedJobsPageSize ? 1 : 0);

        const param = received < total ? { offset, limit: recommendedJobsPageSize } : undefined;

        return param;
      },
      suspense: true,
      staleTime: staleTimeDefault,
      cacheTime: cacheTimeDefault,
    },
  );

  const removeJobFromCache = useCallback(
    (job: JobDto) => {
      const pagesWithoutAppliedJob = data.pages?.map((page) => deleteFromPagedData(page, job, 'jobId'));

      queryClient.setQueryData(buildCacheName(pipelineWorkflowId), {
        pages: pagesWithoutAppliedJob,
      });
    },
    [pipelineWorkflowId, queryClient, data?.pages],
  );

  const selectNextJob = useCallback(
    async (currentJob: JobDto, onSelectNextJob: (job: JobDto) => void) => {
      let flatJobsList = data.pages?.flatMap?.((page) => page.items);
      const nextJob = getNextJob(flatJobsList, currentJob.jobId);

      removeJobFromCache(currentJob);

      if (nextJob) {
        onSelectNextJob(nextJob);
        return nextJob;
      }

      if (!hasNextPage) {
        return null;
      }

      const nextPageResult = await fetchNextPage();

      flatJobsList = nextPageResult.data.pages?.flatMap?.((page) => page.items);

      const firstJobNextPage = flatJobsList?.[0];

      if (!firstJobNextPage) {
        return null;
      }

      onSelectNextJob(firstJobNextPage);

      return firstJobNextPage;
    },
    [data.pages, hasNextPage, fetchNextPage, removeJobFromCache],
  );

  return {
    ...query,
    data,
    hasNextPage,
    fetchNextPage,
    selectNextJob,
    removeJobFromCache,
    total: getPagedDataTotal(data?.pages),
    received: data ? getPagedDataReceivedLength(data?.pages) : 0,
  } as const;
};

export const useGetJobById = () => {
  const queryClient = useQueryClient();

  return (jobId: number) =>
    queryClient.fetchQuery([cacheNames.candidateJob, jobId], () => fetchRecommendedJobById(jobId));
};

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { EntityImage } from '@eva/emf/app/shared/ui/EntityImage';
import { valuesWithDelimiter } from 'shared/functions';
import { entitiesTypes } from 'shared/constants';

// eslint-disable-next-line import/no-default-export
export default class PreviewHeader extends React.PureComponent<any, any> {
  render() {
    const { isAllowedOperation } = this.context;
    const { entity, readOnly } = this.props;

    const profilePhoto = get(entity, 'photos', []).find((item) => item.isAvatar) || {};

    return (
      <div className="candidate-details-header">
        {isAllowedOperation('myProfile-avatar-view') && (
          <PopperTooltip placement="top" overlay={translate(readOnly ? 'View profile' : 'Upload photo')}>
            <div className="candidate-avatar-wrapper">
              <EntityImage
                avatar={profilePhoto}
                entityId={entity.userId}
                entitiesType={entitiesTypes.candidates}
                submitPath="/my/candidate-profile/avatar"
                permissionEntityType="myProfile"
                readOnly={readOnly}
                personMode
              />
            </div>
          </PopperTooltip>
        )}
        {isAllowedOperation('myProfile-name-view') && <h3>{entity.name.displayName}</h3>}
        {isAllowedOperation('myProfile-currentPosition-view') && (
          <p>{valuesWithDelimiter([entity.currentPosition.position, entity.currentPosition.employer])}</p>
        )}
      </div>
    );
  }
}

// @ts-expect-error
PreviewHeader.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

// @ts-expect-error
PreviewHeader.propTypes = {
  entity: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

import { useQuery } from 'react-query';

import { useSkillsSocket } from '@eva/emf/app/entities/skillTaxonomy/hooks/useSkillsSocket';

import { fetchSkillTaxonomyTree } from '../api';

export const getSkillTaxonomyTreeCacheKey = (skillTaxonomyId: number) => ['skillTaxonomyTree', skillTaxonomyId];

type useFetchSkillTaxonomyTreeType = {
  skillTaxonomyId: number;
  rootGroupId?: string | null;
};

export const useFetchSkillTaxonomyTree = ({ skillTaxonomyId, rootGroupId = 'null' }: useFetchSkillTaxonomyTreeType) => {
  const queryKey = getSkillTaxonomyTreeCacheKey(skillTaxonomyId);

  useSkillsSocket(getSkillTaxonomyTreeCacheKey(skillTaxonomyId));

  return useQuery({
    queryKey,
    queryFn: () => fetchSkillTaxonomyTree({ skillTaxonomyId, rootGroupId }),
  });
};

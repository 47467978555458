import React, { Suspense, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Fallback from '@eva/emf/app/shared/ui/Fallback';
import { getPagedDataFirstItem } from '@eva/emf/app/shared/functions-ts';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import type { JobDto } from '@eva/types/dto';

import { useGetJobById, useRecommendedJobs } from '../hooks';
import { applyToRecommendedJob } from '../services';
import { showApplyToJobFailed, showApplyToJobSuccess } from '../functions';
import RecommendedJobItem from '../RecommendedJobItem';
import RecommendedJobsPlaceholder from '../RecommendedJobsPlaceholder';

import { getSelectedJob, getJobIdFromUrl } from './functions';

import './RecommendedJobsList.scss';

export interface RecommendedJobsListProps {
  mobileMode: boolean;
  parentId: string;
  workflowId: number;
  onJobNotFound: () => void;
  onSelectJob: (job: JobDto, onApply: (job: JobDto) => Promise<any>) => void;
  setCentralContentJobDetails: (jobCode: string, onApply: (job: JobDto) => Promise<any>) => void;
}

const RecommendedJobsList = ({
  mobileMode,
  parentId,
  workflowId,
  onSelectJob,
  onJobNotFound,
  setCentralContentJobDetails,
}: RecommendedJobsListProps) => {
  const [selectedJob, setSelectedJob] = useState<JobDto>();
  const [isLoading, setIsLoading] = useState(false);

  const { selectNextJob, removeJobFromCache, ...query } = useRecommendedJobs(workflowId);

  const getJobById = useGetJobById();

  const onApplyJob = useCallback(
    async (job: JobDto) => {
      setIsLoading(true);
      try {
        await applyToRecommendedJob(job.code);
        showApplyToJobSuccess(workflowId, job);
        return selectNextJob(job, (nextJob) => {
          setSelectedJob(nextJob);
          onSelectJob(nextJob, onApplyJob);
        });
      } catch (err) {
        showApplyToJobFailed();
        return Promise.reject(err);
      } finally {
        setIsLoading(false);
      }
    },
    [workflowId, selectNextJob, onSelectJob],
  );

  const handleSelectJob = useCallback(
    (job: JobDto) => {
      setSelectedJob(job);
      onSelectJob(job, onApplyJob);
    },
    [onSelectJob, onApplyJob],
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const job = getSelectedJob(query.data?.pages);
    if (job) {
      handleSelectJob(job);
      return;
    }

    /* JobId is in the URL
    getJobById if not exist then central content job not found */
    const jobIdFromURL = getJobIdFromUrl();
    if (jobIdFromURL) {
      (async () => {
        const job = await getJobById(jobIdFromURL);
        job ? setCentralContentJobDetails(job.code, onApplyJob) : onJobNotFound();
      })();
      return;
    }

    // do not select first item in the mobile mode because
    // it will make the list dissapear
    if (mobileMode) {
      return;
    }

    const firstJob = getPagedDataFirstItem(query.data?.pages);

    if (!firstJob) {
      onJobNotFound();
      return;
    }

    handleSelectJob(firstJob);
  }, [
    mobileMode,
    query.data?.pages,
    handleSelectJob,
    onJobNotFound,
    onSelectJob,
    onApplyJob,
    getJobById,
    setCentralContentJobDetails,
    isLoading,
  ]);

  if (!query.total) {
    return <RecommendedJobsPlaceholder />;
  }

  return (
    <InfiniteScroll
      loader=<Spinner />
      scrollableTarget={parentId}
      dataLength={query.received}
      hasMore={query.hasNextPage}
      next={query.fetchNextPage}
      className="recommended-jobs-list"
    >
      {query.data?.pages?.map((page) =>
        page.items.map((job, i) => (
          <div key={job.jobId} className="recommended-jobs-list__item">
            <RecommendedJobItem job={job} isActive={selectedJob?.jobId === job.jobId} onSelect={handleSelectJob} />
          </div>
        )),
      )}
    </InfiniteScroll>
  );
};

// eslint-disable-next-line import/no-default-export
export default (props: any) => (
  <Suspense fallback={<Fallback />}>
    <RecommendedJobsList {...props} />
  </Suspense>
);

import PropTypes from 'prop-types';
import React from 'react';

function AttachedFile(props) {
  const { file, fileUrl, progress, resetFile, sendMessage } = props;

  if (!file) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
      }}
    >
      {progress === 1 && (
        <div>
          {translate('File')}
          <a className="margin-left margin-right" href={fileUrl} target="_blank" rel="noreferrer">
            {file.name}
          </a>

          {translate('attached')}

          <i
            className="fa fa-trash"
            style={{
              padding: '0 7px',
              cursor: 'pointer',
            }}
            onClick={resetFile}
          />

          <button type="submit" className="btn btn-sm btn-primary" onClick={(evt) => sendMessage(evt)}>
            {translate('Send file')}
          </button>
        </div>
      )}

      {progress !== 1 && (
        <div>
          {translate('Uploading')} {file.name}
          <div
            style={{
              display: 'inline-block',
              width: '100px',
              height: '1em',
              border: '1px solid gray',
              margin: '0 7px',
            }}
          >
            <div
              style={{
                width: `${progress * 100}%`,
                backgroundColor: 'gray',
                height: '1em',
              }}
            ></div>
          </div>
          {(progress * 100).toFixed(0)}%
        </div>
      )}
    </div>
  );
}

AttachedFile.propTypes = {
  resetFile: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default AttachedFile;

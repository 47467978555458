import type { SkillTaxonomyDto } from '@eva/types/dto';

export const getSkillProficiencies = (skillTaxonomyId: number, skillTaxonomies: SkillTaxonomyDto[]) => {
  const skillTaxonomy = skillTaxonomies.find((taxonomy) => taxonomy.skillTaxonomyId === skillTaxonomyId);
  return skillTaxonomy.proficiencies;
};

export const getSkillProficiencyName = (
  skillProficiencies: SkillTaxonomyDto['proficiencies'],
  skillProficiencyValue?: number | null,
) => {
  if (!skillProficiencyValue || skillProficiencyValue === null) {
    return null;
  }
  const skillProficiency = skillProficiencies.find((skill) => skill.sortOrder === skillProficiencyValue);
  return skillProficiency.name;
};

/* eslint-disable no-restricted-properties */
import get from 'lodash/get';
import merge from 'lodash/merge';
import { fromJS } from 'immutable';

import { userTypes, messages, messagesLevelsTypes } from 'shared/constants';
import {
  getParsedUser,
  stringifyActionError,
  updateFirstName,
  wipeStorage,
  redirectToExternalUrl,
} from 'shared/functions';

import {
  SIGN_OUT,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  LOAD_USER_PROFILE,
  LOAD_USER_PROFILE_SUCCESS,
  LOAD_USER_PROFILE_ERROR,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_ERROR,
  RESTORE_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  LOAD_PERMISSIONS,
  LOAD_PERMISSIONS_SUCCESS,
  LOAD_PERMISSIONS_ERROR,
  LOAD_SETTINGS,
  LOAD_SETTINGS_ERROR,
  LOAD_SETTINGS_SUCCESS,
  UPDATE_SETTINGS,
  UPDATE_USER_PROFILE,
  CHANGE_LOCALE,
  defaultLocale,
} from './constants';

const errorMessage = (error, type) => {
  const errorPayload = error.payload || {};
  const payloadError = errorPayload.error || errorPayload.message;
  if (payloadError) {
    return {
      type: 'danger',
      label: payloadError,
    };
  } else if (messages[type]) {
    return messages[type];
  }
  return {
    type: 'danger',
    label: 'Unknown error',
  };
};

const updatedUserState = (state, action) => {
  let stateUser = {};
  const { user, token, wrongAccountType, afterSignIn } = action.payload;

  if (user && !wrongAccountType) {
    stateUser = {
      ...user,
      userId: parseInt(user.userId, 0) || 0,
      userType: user.userType ? parseInt(user.userType, 0) : userTypes.candidate,
      userTypes: typeof user.userTypes === 'string' ? decodeURIComponent(user.userTypes).split(',') : user.userTypes,
    };
    localStorage.token = token;
    localStorage.user = JSON.stringify(stateUser);
    if (afterSignIn) {
      setTimeout(afterSignIn);
    }
  }
  return state.set('user', stateUser);
};

const initialState = fromJS({
  authenticating: false,
  restoringPassword: false,
  authError: null,
  authMessage: null,
  wrongAccountType: 0,
  resettingPassword: false,
  resetPasswordError: '',
  loadingSettings: true,
  loadSettingsError: '',
  loadingConfig: false,
})
  // @ts-expect-error
  .set('user', getParsedUser())
  .set('userProfile', {})
  .set('allowedOperationsNew', [])
  .set('settings', {
    locale: defaultLocale,
    general: {},
  });

// eslint-disable-next-line import/no-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_OUT: // eslint-disable-line no-case-declarations
      wipeStorage();

      const redirect =
        window.location.search.includes('redirect=') || window.location.search.includes('url=')
          ? ''
          : `?redirect=${window.location.pathname}${window.location.search}`;

      setTimeout(() => redirectToExternalUrl(`/auth/sign-in${redirect}`));
      return state.set('user', {}).set('signingOut', true);
    case SIGN_IN:
      return state.set('wrongAccountType', 0).set('authError', null).set('authenticating', true);
    case SIGN_IN_ERROR:
      // @ts-expect-error
      return state.set('authError', errorMessage(action.error)).set('authenticating', false);
    case SIGN_IN_SUCCESS:
      window.sendGAEvent('CMSignInOK', 'ReactMagic', 'CMSignIn');
      return updatedUserState(
        state.set('wrongAccountType', action.payload.wrongAccountType).set('authenticating', false),
        action,
      );

    case SIGN_UP:
      return state.set('wrongAccountType', 0).set('authError', null).set('authenticating', true);
    case SIGN_UP_ERROR:
      return state.set('authError', errorMessage(action.error, 'A010')).set('authenticating', false);
    case SIGN_UP_SUCCESS:
      return updatedUserState(state.set('authenticating', false), action);

    case LOAD_USER_PROFILE:
      return state.set('userProfile', {}).set('loadingUserProfile', true);
    case LOAD_USER_PROFILE_SUCCESS:
      updateFirstName(action.payload.name || {});
      return state.set('userProfile', action.payload).set('loadingUserProfile', false);
    case LOAD_USER_PROFILE_ERROR:
      return state;

    case RESTORE_PASSWORD:
      return state.set('authMessage', null).set('authError', null).set('restoringPassword', true);
    case RESTORE_PASSWORD_ERROR:
      return state
        .set('authError', {
          label: get(action, 'error.payload.error', messages.A008.label),
          type: messagesLevelsTypes.danger,
        })
        .set('restoringPassword', false);
    case RESTORE_PASSWORD_SUCCESS:
      return state.set('authMessage', null).set('restoringPassword', false);

    case RESET_PASSWORD:
      return state.set('resetPasswordError', '').set('resettingPassword', true);
    case RESET_PASSWORD_ERROR:
      return state
        .set('resetPasswordError', get(action, 'error.payload.error', 'Unknown error'))
        .set('resettingPassword', false);
    case RESET_PASSWORD_SUCCESS:
      return state.set('resetPasswordError', '').set('resettingPassword', false);

    case LOAD_PERMISSIONS:
      return state.set('loadPermissionsError', '').set('loadingPermissions', true);
    case LOAD_PERMISSIONS_ERROR:
      return state.set('loadPermissionsError', stringifyActionError(action)).set('loadingPermissions', false);
    case LOAD_PERMISSIONS_SUCCESS: // eslint-disable-line no-case-declarations
      const { allowedOperationsNew, userGroupIds, entityListIds } = action.payload;
      return state
        .set('allowedOperationsNew', allowedOperationsNew)
        .set('userGroupIds', userGroupIds)
        .set('entityListIds', entityListIds)
        .set('loadPermissionsError', '')
        .set('loadingPermissions', false);

    case LOAD_SETTINGS:
      return state.set('loadSettingsError', '').set('loadingSettings', true);
    case LOAD_SETTINGS_ERROR:
      return state
        .set('loadSettingsError', get(action, 'error.payload.error', 'Unknown error'))
        .set('loadingSettings', false);
    case LOAD_SETTINGS_SUCCESS:
      return state.set('settings', action.payload).set('loadSettingsError', '').set('loadingSettings', false);

    case UPDATE_SETTINGS:
      return state.update('settings', (currentSettings) =>
        action.replace
          ? {
              ...Object.assign(currentSettings, action.payload),
            }
          : {
              ...merge(currentSettings, action.payload),
            },
      );

    case UPDATE_USER_PROFILE:
      return state.update('userProfile', (userProfile) => ({
        ...userProfile,
        ...action.payload,
      }));

    case CHANGE_LOCALE:
      return state.update('settings', (currentSettings) => ({
        ...currentSettings,
        locale: action.locale,
      }));

    default:
      return state;
  }
};

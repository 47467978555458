import get from 'lodash/get';

import { copy, normalizeSalaryValue, nullifySalary, objectifySalary, whitelistLocation } from 'shared/functions';

import { profileSections } from './constants';

export const prepareValues = (values) =>
  Object.values(profileSections).reduce(
    (prev, cur) => ({
      ...prev,
      [cur]: values[cur],
    }),
    {},
  );
export const loadEmploymentInitialValues = (entity) => {
  const entityCopy = JSON.parse(
    JSON.stringify(
      [profileSections.currentPosition, profileSections.currentSalary, profileSections.workLocation].reduce(
        (prev, cur) => ({ ...prev, [cur]: entity[cur] }),
        {},
      ),
    ),
  );
  Object.keys(entityCopy).forEach((key) => delete entityCopy[key].metadata);

  entityCopy.currentSalary = objectifySalary(entityCopy.currentSalary);

  return entityCopy;
};
export const prepareEmploymentValues = (values) => {
  values.currentPosition.isCurrent = !!values.currentPosition.isCurrent;
  values.workLocation = whitelistLocation(values.workLocation);
  values.currentSalary = normalizeSalaryValue(nullifySalary(values.currentSalary));
  return prepareValues(values);
};
export const loadContactInitialValues = (entity) => {
  const entityCopy = copy(
    [profileSections.address, profileSections.emails, profileSections.phones].reduce(
      (prev, cur) => ({ ...prev, [cur]: entity[cur] }),
      {},
    ),
  );

  Object.keys(entityCopy).forEach((key) => {
    if (key === profileSections.emails) {
      entityCopy[key] = {
        primary: get(entity, 'emails.primary.emailAddress') || '',
        secondary: get(entity, 'emails.secondary.emailAddress') || '',
      };
    } else {
      delete entityCopy[key].metadata;
    }
  });

  entityCopy.phones = Object.entries(entityCopy.phones).reduce((prev, [key, value]) => {
    let phoneValue;
    if (value === null) {
      phoneValue = {
        number: '',
        note: '',
      };
    } else {
      phoneValue = value;
    }
    return {
      ...prev,
      [key]: phoneValue,
    };
  }, {});

  return entityCopy;
};
export const prepareContactValues = (values) => {
  values.phones = Object.entries(values.phones).reduce((prev, [key, value]) => {
    let phoneValue;
    if (value === null) {
      phoneValue = value;
    } else {
      // @ts-expect-error
      const { number, note } = value || {};
      phoneValue =
        number || note
          ? {
              number: number || '',
              note: note || null,
            }
          : null;
    }
    return {
      ...prev,
      [key]: phoneValue,
    };
  }, {});
  return prepareValues(values);
};

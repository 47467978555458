import classNames from 'classnames';
import React from 'react';

import type { ReactNode } from 'react';

type WorkflowButtonPropsType = {
  disabled: boolean;
  color: string;
  onClick: () => void;
  buttonName: ReactNode;
  block?: boolean;
};

export const WorkflowButton = ({ disabled, onClick, block, buttonName, color }: WorkflowButtonPropsType) => (
  <button
    type="submit"
    className={classNames('btn btn-success btn-sm margin-right', {
      'btn-block': block,
    })}
    disabled={disabled}
    style={{
      background: color,
    }}
    onClick={onClick}
  >
    <i className="ll-icon" />
    {buttonName}
  </button>
);

import ScreeningQuestion from './ScreeningQuestion';

interface ScreeningQuestionsListProps {
  title: string;
  jobQuestions: any[];
  isAllowedToAnswer: boolean;
  pipelineId: number;
  backToJobDetails: () => void;
}

const ScreeningQuestionsList: React.FC<ScreeningQuestionsListProps> = ({
  backToJobDetails,
  jobQuestions,
  isAllowedToAnswer,
  title,
  pipelineId,
}) => (
  <div className="job-info-block" id="screening-questions">
    <div className="job-detail-container">
      <button
        onClick={(e) => {
          e.preventDefault();
          backToJobDetails();
        }}
        type="button"
        className="btn btn-sm btn-tool-box pull-right no-padding"
      >
        <i className="lnr lnr-arrow-left margin-right" />
        {translate('Back')}
      </button>
      <h3>{title}</h3>
      <div className="clearfix" />
      <div className="question-wrapper">
        {jobQuestions.map((question) => (
          <ScreeningQuestion
            key={question.jobScreeningQuestionId}
            question={question}
            isAllowedToAnswer={isAllowedToAnswer}
            pipelineId={pipelineId}
          />
        ))}
      </div>
    </div>
  </div>
);

// eslint-disable-next-line import/no-default-export
export default ScreeningQuestionsList;

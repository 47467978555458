import PropTypes from 'prop-types';
import React from 'react';

import PopperPopover from '@eva/emf/app/shared/ui/Popper/PopperPopover';
import { agentPrefix } from 'shared/constants';

const SharedPhoneLink = ({ number }) => (
  <PopperPopover
    delayHide={1000}
    placement="top"
    overlay={
      <div className="text-center small">
        This phone number is also linked
        <br />
        to{' '}
        <a target="_blank" rel="noreferrer" href={`${agentPrefix}/search?q=${number}`}>
          other records
        </a>
      </div>
    }
  >
    <span className="text-dark-blue shared-phone">
      <i className="lnr lnr-link" />
    </span>
  </PopperPopover>
);

SharedPhoneLink.propTypes = {
  number: PropTypes.string.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default SharedPhoneLink;

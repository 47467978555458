import { memo } from 'react';

import emptyResultImage from 'assets/images/empty-search-result.svg';

const JobNotFound = () => (
  <div className="info-message">
    <div className="text-center">
      <h3>Sorry we couldn't find anything for your request</h3>
      <img
        width="50%"
        style={{ maxWidth: '400px' }}
        src={emptyResultImage}
        className="margin-top margin-bottom"
        alt="Job is not found"
        aria-label="Job is not found"
      />
    </div>
  </div>
);

// eslint-disable-next-line import/no-default-export
export default memo(JobNotFound);

interface SignInVIewProps {
  img: string;
  title: string;
  body: JSX.Element;
}

const SignInFormView: React.FC<SignInVIewProps> = ({ img, title, body }) => (
  <div className="text-center candidate-chat-menu">
    <h1 style={{ marginTop: '50px' }}>{title}</h1>
    {body}
    <img
      src={img}
      alt=""
      width="75%"
      className="margin-top margin-bottom"
      style={{
        margin: '50px 0',
      }}
    />
  </div>
);

// eslint-disable-next-line import/no-default-export
export default SignInFormView;

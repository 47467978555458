import { acceptCvTypes, acceptImageTypes } from 'shared/constants';

export const uploadFileType = {
  cv: 'cv',
  image: 'image',
};

export const acceptTypes = {
  [uploadFileType.cv]: acceptCvTypes,
  [uploadFileType.image]: acceptImageTypes,
};

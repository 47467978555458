import PropTypes from 'prop-types';
import React from 'react';

class AIUIComponent extends React.Component<any, any> {
  componentDidUpdate() {
    this.props.delayScrollDown();
  }
}

// @ts-expect-error
AIUIComponent.propTypes = {
  delayScrollDown: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default AIUIComponent;

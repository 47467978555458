import PropTypes from 'prop-types';
import React from 'react';

import { loadWorkHistory } from 'containers/CandidatePaneNew/functions';

class PreviewGeneralWorkHistory extends React.PureComponent<any, any> {
  state = {};

  UNSAFE_componentWillMount() {
    const {
      userProfile: { workHistory },
    } = this.props;
    this.loadWorkHistory(workHistory);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      userProfile: { workHistory },
    } = nextProps;
    if (this.props.userProfile.workHistory !== workHistory) {
      this.loadWorkHistory(workHistory);
    }
  }

  loadWorkHistory(workHistory) {
    const preparedWorkHistory = loadWorkHistory(workHistory, {});
    this.setState({
      ...preparedWorkHistory,
      lastItems: preparedWorkHistory.fullHistory.reduce((prev, cur) => {
        if (prev.filter((item) => !item.gap).length === 3) {
          return prev;
        }
        return [...prev, cur];
      }, []),
    });
  }

  render() {
    // @ts-expect-error
    const { lastItems, totalExperience } = this.state;

    return (
      <div>
        {!!lastItems.length && (
          <div className="panel-menu-item-summary">
            {totalExperience} {translate('of experience')}
          </div>
        )}
        {!lastItems.length && <div className="panel-menu-item-summary">{translate('No work history recorded')}</div>}
      </div>
    );
  }
}

// @ts-expect-error
PreviewGeneralWorkHistory.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default PreviewGeneralWorkHistory;

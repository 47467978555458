import React from 'react';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { copy } from 'shared/functions';

import { yearAndMonthToDate } from '../CandidatePaneNew/functions';

import { eventTypes } from './constants';

export const renderEntitySection = (...args) => {
  const [rowsOptions, parent, onClick, entity, entitiesType] = args;
  return (sectionsKey) => {
    if (!rowsOptions) {
      throw new Error('Rows options should be present!');
    } else if (!onClick) {
      throw new Error('Method onClick should be present!');
    } else if (!entity) {
      throw new Error('Entity should be present!');
    }
    const section = rowsOptions[sectionsKey];
    if (!section) {
      throw new Error(`Section ${sectionsKey} not found!`);
    } else if (section.showCondition && !section.showCondition(entity, parent)) {
      return null;
    }

    const Renderer = section.render;

    return (
      <div
        key={sectionsKey}
        id={sectionsKey}
        className={`media panel-menu-item ${sectionsKey === parent.state.section ? 'active' : ''}`}
        onClick={() => onClick(sectionsKey, entity, parent.props)}
      >
        <div className="media-left">
          <i className={section.icon} />
        </div>
        <div className="media-body">
          <h4>{section.label}</h4>
          {section.render && (
            <Renderer
              entity={entity}
              parent={parent}
              entitiesType={entitiesType}
              updateEntity={parent.updateEntity || parent.props.updateEntity}
              {...(section.sharedProps || {})}
            />
          )}
        </div>
      </div>
    );
  };
};

export const renderField = (...fields) => (
  <div className="form-group row">
    {Array.isArray(fields[0]) &&
      fields[0].map((fieldItems, index) => (
        <div key={index} className="col-xs-6" id={fieldItems[2]}>
          <label className="control-label">{fieldItems[0]}</label>
          <div>
            <span>{fieldItems[1]}</span>
          </div>
        </div>
      ))}
    {!Array.isArray(fields[0]) && (
      <div className="col-xs-12" id={fields[2]}>
        <label className={`control-label ${fields[0] ? '' : 'hide'}`}>{fields[0]}</label>
        <div>
          <span>{fields[1]}</span>
        </div>
      </div>
    )}
  </div>
);

export const renderSection = (label, content) => (
  <div className="section">
    <h4 className={`${label ? '' : 'hide'}`}>{label}</h4>
    {content}
  </div>
);

export const renderTopSubmitBlock = (submitting, switchEditMode, value, closePane) => (
  <div className="pull-right">
    <PopperTooltip placement="top" overlay={translate('Submit')}>
      <button
        type="submit"
        disabled={submitting}
        className="btn btn-sm btn-box-tool text-success"
        style={{
          padding: 0,
          lineHeight: '1.3em',
          fontSize: '15px',
        }}
      >
        <i className="lnr lnr-checkmark-circle" />
      </button>
    </PopperTooltip>
    <PopperTooltip placement="top" overlay={translate('Cancel')}>
      <button
        type="button"
        className="btn btn-sm btn-box-tool margin-left"
        onClick={() => (closePane ? closePane() : switchEditMode(value || '', value ? false : undefined))}
        style={{
          padding: 0,
          lineHeight: '1.3em',
          fontSize: '15px',
        }}
      >
        <i className="lnr lnr-cross-circle" />
      </button>
    </PopperTooltip>
  </div>
);

export const renderBottomSubmitBlock = (submitting, switchEditMode, value, closePane) => (
  <div className="row margin-bottom">
    <div className="col-xs-12">
      <div className="pull-right">
        <button type="submit" className="btn btn-sm btn-success margin-right submit" disabled={submitting}>
          {translate(submitting ? 'Submitting' : 'Submit')}
        </button>
        {!closePane && (
          <button
            type="button"
            className="btn btn-sm btn-default cancel"
            onClick={() => switchEditMode(value || '', value ? false : undefined)}
            disabled={submitting}
          >
            {translate('Cancel')}
          </button>
        )}
        {closePane && (
          <button type="button" className="btn btn-sm btn-default cancel" onClick={closePane} disabled={submitting}>
            {translate('Close')}
          </button>
        )}
      </div>
    </div>
  </div>
);

export const renderCardEditButton = (switchEditMode, value?) =>
  switchEditMode ? (
    <span className="pull-right">
      <PopperTooltip placement="top" overlay={translate('Edit')}>
        <button
          type="button"
          className="btn-box-tool btn btn-sm pencil-edit-btn"
          onClick={() => switchEditMode(value || '1', value ? true : undefined)}
          style={{
            padding: '0 5px',
            lineHeight: '1.5em',
            height: 'auto',
          }}
        >
          <i className="lnr lnr-pencil" />
        </button>
      </PopperTooltip>
    </span>
  ) : null;

export const isRteField = (uiSchema, key) => {
  const uiData = uiSchema[key];
  return uiData && typeof uiData === 'object' && uiData['ui:widget'] === 'rte';
};

//***************************************************************************************
// Languages
//***************************************************************************************

export const updateLanguages = (initialLanguages, eventType, eventData) => {
  const languages = {
    ...copy(initialLanguages),
    initialValues: initialLanguages,
  };
  const findItemIndex = (candidateLanguageId, throwError = true) => {
    const itemIndex = languages.items.findIndex((item) => item.candidateLanguageId === candidateLanguageId);
    if (itemIndex === -1 && throwError) {
      throw new Error(`languages item with id ${candidateLanguageId} not found!`);
    }
    return itemIndex;
  };
  const itemIndex = findItemIndex(eventData.candidateLanguageId, false);
  if (eventType === eventTypes.languageCreated) {
    if (itemIndex === -1) {
      languages.items.unshift(eventData);
    } else {
      languages.items[itemIndex] = eventData;
    }
  } else if (itemIndex > -1) {
    if (eventType === eventTypes.languageDeleted) {
      languages.items.splice(itemIndex, 1);
    } else if (eventType === eventTypes.languageUpdated) {
      languages.items[itemIndex] = eventData;
    } else {
      throw new Error(`Unknown updateLanguages event type ${eventType}`);
    }
  }
  return languages;
};

export const validateFromAndTo = (errors, startDate, endDate, isCurrent = false) => {
  errors.startDate =
    !startDate || !startDate.year
      ? {
          year: translate('Start year is required'),
        }
      : null;

  if (!isCurrent) {
    errors.endDate =
      !endDate || !endDate.year
        ? {
            year: translate('End year is required'),
          }
        : null;

    if (startDate?.year && endDate?.year && yearAndMonthToDate(startDate) > yearAndMonthToDate(endDate)) {
      const message = translate('Start date should be before end date');
      errors.startDate = {
        ...(errors.startDate || {}),
        month: startDate.month ? message : null,
        year: startDate.month ? null : message,
      };
    }
  }

  return errors;
};

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import { valuesWithDelimiter } from 'shared/functions';

import { prepareEducationItem } from 'containers/CandidatePaneNew/functions';

const shortCount = 2;

class PreviewEducation extends React.PureComponent<any, any> {
  state = {};

  UNSAFE_componentWillMount() {
    this.loadEducation();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      userProfile: { education },
    } = nextProps;
    if (this.props.userProfile.education !== education) {
      this.loadEducation(nextProps);
    }
  }

  loadEducation(props = this.props) {
    const {
      userProfile: { education },
    } = props;

    let highestDegreeType = {
      order: 0,
    };
    const fullHistory = get(education, 'degrees', [])
      .sort((prev, next) => (prev.endDate > next.endDate ? -1 : 1))
      .reduce((prev, cur) => {
        if (cur.degreeType && (!highestDegreeType.order || cur.degreeType.order > highestDegreeType.order)) {
          highestDegreeType = cur.degreeType;
        }
        return [...prev, prepareEducationItem(cur)];
      }, []);
    const currentHistory = fullHistory.slice(0, shortCount);
    this.setState({
      fullHistory,
      currentHistory,
      highestDegreeType,
      initialValues: {
        education,
      },
      confirmingSection: false,
    });
  }

  render() {
    // @ts-expect-error
    const { fullHistory, currentHistory, highestDegreeType } = this.state;

    const recentEducation = currentHistory.length && currentHistory[0];

    return (
      <div>
        {!!recentEducation && (
          <div className="panel-menu-item-summary">
            {valuesWithDelimiter([
              highestDegreeType.name
                ? translate('Highest education level: {{ degreeName }}', {
                    degreeName: highestDegreeType.name,
                  })
                : '',
              `${fullHistory.length} ${translate('education item(s)')}`,
            ])}
          </div>
        )}
        {!currentHistory.length && (
          <div className="panel-menu-item-summary">{translate('No education history recorded')}</div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
PreviewEducation.contextTypes = {
  socket: PropTypes.object.isRequired,
};

// @ts-expect-error
PreviewEducation.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default PreviewEducation;

import { string, bool, array, func, number } from 'prop-types';

import { PageSpinner } from '@eva/emf/app/shared/ui/Spinner';

import screeningQuestionsImage from '../../../assets/images/screening-form-pic.svg';

import ScreeningQuestionsList from './ScreeningQuestionsList';

function ScreeningQuestionsForm({
  showQuestionsForm,
  isAllowedToAnswer,
  jobQuestions,
  isLoading = false,
  title,
  text,
  textButton,
  pipelineId,
  error = '',
  renderQuestionForm,
  backToJobDetails,
}) {
  if (showQuestionsForm) {
    return (
      <ScreeningQuestionsList
        title={title}
        jobQuestions={jobQuestions}
        isAllowedToAnswer={isAllowedToAnswer}
        pipelineId={pipelineId}
        backToJobDetails={backToJobDetails}
      />
    );
  }

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <div className="job-info-block" id="screening-questions">
      <div className="job-detail-container">
        <h3>{title}</h3>
        {error && <div className="text-danger">{error}</div>}
        <div className="row">
          <div className="col-xs-12">
            <div className="alert alert-info media">
              <div className="media-left hidden-sm hidden-xs">
                <img role="presentation" src={screeningQuestionsImage} />
              </div>
              <div className="media-body">
                <p className="margin-bottom" dangerouslySetInnerHTML={{ __html: text }} />
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    renderQuestionForm();
                  }}
                >
                  {textButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ScreeningQuestionsForm.propTypes = {
  pipelineId: number,
  error: string,
  title: string,
  text: string,
  textButton: string,
  isAllowedToAnswer: bool,
  isLoading: bool,
  jobQuestions: array,
  showQuestionsForm: bool,
  renderQuestionForm: func,
  backToJobDetails: func,
};

// eslint-disable-next-line import/no-default-export
export default ScreeningQuestionsForm;

import { SkillTaxonomyWidget } from 'containers/AIUI/SkillTaxonomyWidget/SkillTaxonomyWidget';

import AddressAutocomplete from './AddressAutocomplete';
import AddressFromPostcode from './AddressFromPostcode';
import Authenticate from './Authenticate';
import Checkboxes from './Checkboxes';
import DateSelector from './DateSelector';
import MultiSelect from './MultiSelect';
import Notification from './Notification';
import RadioButtons from './RadioButtons';
import Radios from './Radios';
import SalaryAndPeriod from './SalaryAndPeriod';
import TnC from './TnC';
import UploadFile from './UploadFile';

// eslint-disable-next-line import/no-default-export
export default {
  addressplaces: AddressAutocomplete,
  address: AddressFromPostcode,
  email: Authenticate,
  checkboxes: Checkboxes,
  dateselector: DateSelector,
  multiselect: MultiSelect,
  notification: Notification,
  radiobuttons: RadioButtons,
  salaryandperiod: SalaryAndPeriod,
  radios: Radios,
  skilltaxonomywidget: SkillTaxonomyWidget,
  tnc: TnC,
  uploadfile: UploadFile,
};

import { useQuery } from 'react-query';

import { fetchSkillTaxonomyTrees } from '../api';

export const fetchSkillTaxonomyTreesCacheKey = (code: string) => ['skillTaxonomyTreesCacheKey', code];

export const useFetchSkillTaxonomyTrees = ({ code }) =>
  useQuery({
    queryKey: fetchSkillTaxonomyTreesCacheKey(code),
    queryFn: () => fetchSkillTaxonomyTrees({ code }),
  });

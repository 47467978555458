import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

export const ModalSkillTaxonomyLoader = () => (
  <div
    className="text-center"
    style={{
      paddingTop: '50px',
    }}
  >
    <Spinner />
  </div>
);

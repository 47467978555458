import PropTypes from 'prop-types';
import React from 'react';

import { availabilitySlotsMinutes, availabilityDayStart } from 'shared/constants';

import TimeCell from './TimeCell';

const daySlots = 60 / availabilitySlotsMinutes;

const TimeRow = ({ dayItems, formatAvailabilityTime, rowNumber, defaultEvent }) => {
  let rowHour;
  const isRowHeader = rowNumber % daySlots === 0;
  if (isRowHeader) {
    rowHour = Math.floor((rowNumber + availabilityDayStart) / daySlots);
    if (rowHour > 24) {
      rowHour -= 24;
    }
  }
  return (
    <tr>
      {isRowHeader && (
        <td className="hour" rowSpan={daySlots}>
          <span>{formatAvailabilityTime(`${rowHour}:00`)}</span>
        </td>
      )}
      {dayItems.map((day, index) => (
        <TimeCell
          key={index}
          dayNum={index}
          rowNum={rowNumber}
          bgColor={day.color}
          borderColor={day.color === defaultEvent.color ? '' : day.color}
          timeInterval={day.timeInterval || ''}
          dragging={day.dragging}
        />
      ))}
    </tr>
  );
};

TimeRow.propTypes = {
  rowNumber: PropTypes.number.isRequired,
  dayItems: PropTypes.array.isRequired,
  formatAvailabilityTime: PropTypes.func.isRequired,
  defaultEvent: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default TimeRow;

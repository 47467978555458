import PropTypes from 'prop-types';
import React from 'react';
import startCase from 'lodash/startCase';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

/* eslint-disable camelcase */
const componentForm = {
  street_number: {
    type: 'short_name',
    result: 'street_number',
  },
  route: {
    type: 'long_name',
    result: 'street',
  },
  locality: {
    type: 'long_name',
    result: 'city',
  },
  administrative_area_level_1: {
    type: 'short_name',
    result: 'area',
  },
  administrative_area_level_2: {
    type: 'short_name',
    result: 'county',
  },
  country: {
    type: 'long_name',
    result: 'country',
  },
  postal_code: {
    type: 'long_name',
    result: 'postal_code',
  },
};
/* eslint-enable camelcase */

const requiredFields = ['street_number', 'street', 'city', 'postal_code'];

class AddressAutocomplete extends AIUIComponent {
  state = {
    errors: 'Start typing your address',
    selectedAddress: '',
  };
  autocomplete: any;
  addressInput: any;
  static propTypes: {
    answer: PropTypes.Validator<string>;
    attributes: PropTypes.Validator<object>;
    editable: PropTypes.Validator<boolean>;
    resultingContent: PropTypes.Validator<(...args: any[]) => any>;
  };

  UNSAFE_componentWillMount() {
    // @ts-expect-error
    this.setState({ address: Object.keys(componentForm).reduce((prev, cur) => ({ ...prev, [cur]: '' })) });
  }

  parseAddress() {
    this.setState({ selectedAddress: this.addressInput.value });
    const address: any = {};
    this.autocomplete.getPlace().address_components.forEach((component) => {
      const addressType = component.types[0];
      const formElement = componentForm[addressType];
      if (formElement) {
        address[formElement.result] = component[formElement.type];
      }
    });
    const missingFields = requiredFields.reduce(
      // @ts-expect-error
      (prev, cur) => [...prev, ...(address[cur] ? [] : [startCase(cur)])],
      {},
    );
    this.setState({
      // @ts-expect-error
      errors: missingFields.length ? `Please provide the following data: ${missingFields.join(', ')}` : '',
      address:
        `${address.street_number} ${address.street}, ${address.city}, ${address.county}, ` +
        `${address.postal_code}, ${address.country}`,
    });
  }

  submitResult() {
    const { address } = this.state as any;
    this.setState({ submitted: true });
    this.props.resultingContent(address);
  }

  render() {
    const { editable, answer } = this.props;
    const { submitted, errors, selectedAddress } = this.state as any;
    const disabled = !editable || submitted;

    return (
      <div>
        {answer && <div>{answer}</div>}
        {!answer && (
          <div>
            <input
              ref={(ref) => {
                if (!this.autocomplete) {
                  // @ts-expect-error
                  this.autocomplete = new google.maps.places.Autocomplete(ref, { types: ['geocode'] });
                  this.autocomplete.addListener('place_changed', this.parseAddress.bind(this));
                  this.addressInput = ref;
                }
              }}
              onBlur={() => (this.addressInput.value = selectedAddress)}
              style={{
                minWidth: '400px',
              }}
              placeholder="Address"
            />
            {errors && <div className="text-danger">{errors}</div>}
          </div>
        )}
        <div
          className="text-center"
          style={{
            paddingTop: '10px',
          }}
        >
          <button
            type="submit"
            className="btn btn-primary"
            disabled={disabled || errors}
            onClick={() => this.submitResult()}
          >
            {translate('Submit')}
          </button>
        </div>
      </div>
    );
  }
}

AddressAutocomplete.propTypes = {
  answer: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default AddressAutocomplete;

import { useQueryClient } from 'react-query';

import { updateEntityTree } from '../api';

type UpdateEntityTree = (data: {
  skillTaxonomyId: number;
}) => ({ skills }: { skills: any[] }) => ReturnType<typeof updateEntityTree>;

const getEntityUpdateTreeCacheKey = (skillTaxonomyId: number) => ['updateTreeSkills', skillTaxonomyId];

export const useUpdateEntityTree: UpdateEntityTree = ({ skillTaxonomyId }) => {
  const queryClient = useQueryClient();

  return ({ skills }) =>
    queryClient.fetchQuery(getEntityUpdateTreeCacheKey(skillTaxonomyId), () =>
      updateEntityTree({
        skillTaxonomyId,
        skills,
      }),
    );
};

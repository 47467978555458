import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

// eslint-disable-next-line import/no-default-export
export default class ModalPrompt extends React.Component<any, any> {
  state = {
    show: false,
    title: '',
    body: '',
  };

  // eslint-disable-next-line react/sort-comp
  ok = (evt) => {
    const { body } = this.state;
    evt.preventDefault();
    this.setState({ show: false });

    return this.resolve(body);
  };

  cancel = () => {
    this.setState({ show: false });

    return this.reject();
  };
  // eslint-disable-next-line react/sort-comp
  reject: (reason?: any) => void;
  promise: any;
  resolve: (value: unknown) => void;
  static propTypes: { size: PropTypes.Requireable<string> };

  open(title) {
    this.setState({
      show: true,
      title: title || 'Eva system',
    });

    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    return this.promise;
  }

  render() {
    const { show, title, body } = this.state;
    const { size } = this.props;
    return (
      <Modal show={show} onHide={this.cancel} bsSize={size || 'small'}>
        <form onSubmit={this.ok}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              value={body}
              className="form-control"
              placeholder="Enter your answer"
              onChange={(evt) => this.setState({ body: evt.target.value })}
            />
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" onClick={this.ok} disabled={!body} className="btn btn-success btn-sm">
              {translate('Save')}
            </button>
            <button type="button" onClick={this.cancel} className="btn btn-default btn-sm">
              {translate('Cancel')}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

ModalPrompt.propTypes = {
  size: PropTypes.string,
};

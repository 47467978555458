import { useEffect, useState, useCallback, useMemo } from 'react';

import { requestBackend } from '@eva/emf/app/utils/request';
import { stringifyError } from '@eva/emf/app/shared/functions';

import ScreeningQuestionsForm from './ScreeningQuestionsForm';

interface ScreeningQuestionsProps {
  pipelineId: number;
  isAllowedToAnswer: boolean;
  showQuestionsForm: boolean;
  renderQuestionForm: () => void;
  backToJobDetails: () => void;
}

const ScreeningQuestions: React.FC<ScreeningQuestionsProps> = ({
  pipelineId,
  showQuestionsForm,
  isAllowedToAnswer = false,
  renderQuestionForm,
  backToJobDetails,
}) => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const config = useMemo(
    () =>
      isAllowedToAnswer
        ? {
            title: translate('Provide Answers to the Following Job Specific Questions'),
            text:
              'To complete your application, you must respond to the job specific questions.' +
              'Begin by clicking on the "Provide Answers" button. After providing your answers, click on the "Submit Application" button on the top right.',
            textButton: 'Provide Answers',
          }
        : {
            title: translate('Application Questions & Answers'),
            text: 'Review My Application Questions & Answers',
            textButton: 'Review',
          },
    [isAllowedToAnswer],
  );

  const loadQuestions = useCallback(() => requestBackend(`/my/pipelines/${pipelineId}/questions`), [pipelineId]);

  useEffect(() => {
    setIsLoading(true);
    loadQuestions().then(
      (questions: any) => {
        setIsLoading(false);
        setQuestions(questions);
      },
      (err) => {
        setIsLoading(false);
        setError(stringifyError(err));
      },
    );
  }, [loadQuestions]);

  return (
    <ScreeningQuestionsForm
      title={translate(config.title)}
      text={translate(config.text)}
      textButton={translate(config.textButton)}
      isAllowedToAnswer={isAllowedToAnswer}
      jobQuestions={questions}
      error={error}
      isLoading={isLoading}
      showQuestionsForm={showQuestionsForm}
      pipelineId={pipelineId}
      renderQuestionForm={renderQuestionForm}
      backToJobDetails={backToJobDetails}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default ScreeningQuestions;

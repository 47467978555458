export const workLocationPreferenceTypes = {
  remote: 'remote',
  travelToSite: 'travelToSite',
  officeBased: 'officeBased',
  partiallyRemote: 'partiallyRemote',
  other: 'other',
};

export const existsFields = ['drivingLicense', 'rightToWork'];

export const eventTypes = {
  languageCreated: 'language-created',
  languageUpdated: 'language-updated',
  languageDeleted: 'language-deleted',
};

import { useContext } from 'react';

import { GenericContext } from '@eva/emf/app/shared/constants';
import type { GenericContextType } from '@eva/emf/app/types/generic-context';
import { SkillTaxonomiesListEditable } from '@eva/emf/app/widgets/skillTaxonomies/SkillTaxonomiesListEditable';
import { SkillTaxonomiesList } from '@eva/emf/app/widgets/skillTaxonomies/SkillTaxonomiesList';
import type { SkillTaxonomySection } from '@eva/types/dto';
import type { SectionCards } from '@eva/emf/app/types/FSEM/sectionsCards';
import { candidateOperations } from 'shared/operations';
import { entitiesTypes } from 'shared/constants';

import {
  useFetchSkillTaxonomyOptions,
  useFilterSkillTaxonomiesOptions,
  useFetchSkillTaxonomyTree,
} from '../../../entities/SkillTaxonomies/hooks';

import { useUpdateSkillTaxonomyNote } from './hooks/useUpdateSkillTaxonomyNote';
import { useUpdateEntityTree } from './hooks/useUpdateEntityTree';
import { keyMaxNumberOfSelectedSkills } from './constants';

export const SkillTaxonomiesTreeList = (
  props: SectionCards<{
    skillTaxonomies: SkillTaxonomySection[];
  }>,
) => {
  const { isAllowedOperation } = useContext(GenericContext as React.Context<GenericContextType>);
  const isEditAllowed = isAllowedOperation(candidateOperations.myProfileSkillsUpdateAllowed);

  const { skillTaxonomies } = props.entity;

  if (!isEditAllowed) {
    return (
      <SkillTaxonomiesList
        skillTaxonomies={skillTaxonomies}
        hooks={{
          useFetchSkillTaxonomyOptions: () => useFilterSkillTaxonomiesOptions(useFetchSkillTaxonomyOptions()),
          useFetchSkillTaxonomyTree: (skillTaxonomyId: number, rootGroupId?: string | null) =>
            useFetchSkillTaxonomyTree({
              skillTaxonomyId,
              rootGroupId,
            }),
        }}
      />
    );
  }

  return (
    <SkillTaxonomiesListEditable
      skillTaxonomies={skillTaxonomies}
      keyMaxNumberOfSelectedSkills={keyMaxNumberOfSelectedSkills[entitiesTypes.candidates]}
      hooks={{
        useFetchSkillTaxonomyOptions: () => useFilterSkillTaxonomiesOptions(useFetchSkillTaxonomyOptions()),
        useFetchSkillTaxonomyTree: (skillTaxonomyId: number, rootGroupId?: string | null) =>
          useFetchSkillTaxonomyTree({
            skillTaxonomyId,
            rootGroupId,
          }),
        useUpdateSkillTaxonomyNote: (skillTaxonomyId: number) => useUpdateSkillTaxonomyNote({ skillTaxonomyId }),
        useUpdateEntityTree: (skillTaxonomyId: number) => useUpdateEntityTree({ skillTaxonomyId }),
      }}
    />
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { requestBackend } from '@eva/emf/app/utils/request';
import { prepareSelector, stringifyError } from 'shared/functions';

import CardGenericPermanentAvailability from 'containers/AvailabilityEditor/CardPermanentAvailability';

const mapLegendBlock = (block) => (
  <p key={block.label}>
    <span
      className="legend-item"
      style={{
        background: block.background,
      }}
    />{' '}
    {block.label}
  </p>
);

class CardPermanentAvailability extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  availabilityTypes: any;
  modalAvailability: any;
  unmounted: any;
  modalPermanentAvailability: any;
  availabilityLegendBlocks: any;
  static contextTypes: {
    settings: PropTypes.Validator<object>;
    isAllowedOperation: PropTypes.Validator<(...args: any[]) => any>;
  };
  modalInterimOpener: () => any;

  UNSAFE_componentWillMount() {
    const { pipelines, updateEntity } = this.props;

    this.setState({
      entityJobs: pipelines.map(({ job }) => job),
    });

    this.availabilityTypes = [
      {
        event: translate('Not defined'),
        color: '#D6DFE4',
        lightenColor: '#EDEDED',
      },
      {
        event: translate('Available'),
        color: '#57AF58',
        lightenColor: '#C8E6C9',
      },
      {
        event: translate('Not available'),
        color: '#E2574C',
        lightenColor: '#ffccbc',
      },
      {
        event: translate('Required unknown'),
        color: 'repeating-linear-gradient(-55deg, #FFF59D, #FFF59D 2px, #FFEB3B 2px, #FFEB3B 4px)',
        lightenColor: 'repeating-linear-gradient(-55deg, #FFF59D, #FFF59D 2px, #FFEB3B 2px, #FFEB3B 4px)',
        special: true,
      },
    ];
    const availabilityLegendItems = {
      leftRow: [
        {
          background: this.availabilityTypes[1].lightenColor,
          label: translate('Available'),
        },
        {
          background: this.availabilityTypes[2].lightenColor,
          label: translate('Not available'),
        },
        {
          background: this.availabilityTypes[0].lightenColor,
          label: translate('Not defined'),
        },
      ],
      rightRow: [
        {
          background: this.availabilityTypes[1].color,
          label: translate('Match with job time'),
        },
        {
          background: this.availabilityTypes[2].color,
          label: translate('Do not match with job time'),
        },
        {
          background: this.availabilityTypes[3].color,
          label: translate('Needed job time'),
        },
      ],
    };

    this.availabilityLegendBlocks = [
      availabilityLegendItems.leftRow.map(mapLegendBlock),
      availabilityLegendItems.rightRow.map(mapLegendBlock),
    ];
    // @ts-expect-error
    this.modalPermanentOpener = () =>
      this.modalAvailability.open().then((permanentAvailability) => {
        if (!this.unmounted) {
          updateEntity({ permanentAvailability });
        }
      });
    this.modalInterimOpener = () =>
      this.modalAvailability.open().then((interimAvailability) => {
        if (!this.unmounted) {
          updateEntity({ interimAvailability });
        }
      });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  saveEntity = (params) => {
    const { updateEntity } = this.props;

    return requestBackend('/my/candidate-profile', {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(
      (result) => updateEntity(result),
      (err) => {
        if (!this.unmounted) {
          this.setState({
            error: stringifyError(err),
          });
        }
      },
    );
  };

  render() {
    const { isAllowedOperation, settings } = this.context;
    const { entity, mobileMode } = this.props;
    const { error } = this.state as any;

    const editAllowed = !mobileMode && isAllowedOperation('myProfile-calendarWorkingHours-update');
    const matchWithJobTime = get(settings, 'features.candidate.workProfile.preferredWorkingHours.matchWithJobTime');

    return (
      <div className="preview-panel">
        <div>
          {error && <div className="text-center text-danger">{error}</div>}
          <div>
            <div className="pull-right">
              {editAllowed && (
                <span>
                  <PopperTooltip placement="top" overlay={translate('Edit')}>
                    <button
                      type="button"
                      className="btn-box-tool btn btn-sm pencil-edit-btn"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        this.modalPermanentAvailability.modalOpener();
                      }}
                      style={{
                        padding: '0 5px',
                        lineHeight: '1.5em',
                        height: 'auto',
                      }}
                    >
                      <i className="lnr lnr-pencil" />
                    </button>
                  </PopperTooltip>
                </span>
              )}
            </div>
            <div className="section">
              <h3>{translate('Preferred working hours')}</h3>
              <div>
                <CardGenericPermanentAvailability
                  key="modalPermanentAvailability"
                  ref={(ref) => (this.modalPermanentAvailability = ref)}
                  availabilityTypes={this.availabilityTypes}
                  availabilityLegendBlocks={this.availabilityLegendBlocks}
                  savePermanentAvailability={this.saveEntity}
                  profile={entity}
                  showDescription
                  extended
                />
                <hr className="dotted" />
                <div className="row small legend-box">
                  <div className="col-xs-4">
                    {settings.scheduleSlots.map((scheduleSlot) => (
                      <p key={scheduleSlot.name}>
                        <strong>
                          {scheduleSlot.start} - {scheduleSlot.end}
                        </strong>{' '}
                        {scheduleSlot.name}
                      </p>
                    ))}
                  </div>
                  <div className="col-xs-3">{this.availabilityLegendBlocks[0]}</div>
                  {matchWithJobTime && <div className="col-xs-5">{this.availabilityLegendBlocks[1]}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardPermanentAvailability.contextTypes = {
  settings: PropTypes.object.isRequired,
  isAllowedOperation: PropTypes.func.isRequired,
};

// @ts-expect-error
CardPermanentAvailability.propTypes = {
  interimBackendPath: PropTypes.string,
  entity: PropTypes.object.isRequired,
  pipelines: PropTypes.array.isRequired,
  jobPermanentAvailability: PropTypes.object,
  jobInterimAvailability: PropTypes.object,
  editOptions: PropTypes.object.isRequired,
  selectedJob: PropTypes.object,
  updateEntity: PropTypes.func,
  modalOpener: PropTypes.func,
  showDescription: PropTypes.bool,
  mobileMode: PropTypes.bool,
};

const prepareMessengerSelector = (value) => prepareSelector('candidate', value);
const mapStateToProps = createStructuredSelector({
  pipelines: prepareMessengerSelector('pipelines'),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, () => ({}))(CardPermanentAvailability);

import { useQueryClient } from 'react-query';

import { updateSkillTaxonomyNote } from '../api';

type UpdatesSkillTaxonomyNote = (data: {
  skillTaxonomyId: number;
}) => (note: string) => ReturnType<typeof updateSkillTaxonomyNote>;

const getSkillTaxonomyNoteUpdateCacheKey = (skillTaxonomyId: number) => ['updateSkillTaxonomyNote', skillTaxonomyId];

export const useUpdateSkillTaxonomyNote: UpdatesSkillTaxonomyNote = ({ skillTaxonomyId }) => {
  const queryClient = useQueryClient();

  return (note) =>
    queryClient.fetchQuery(getSkillTaxonomyNoteUpdateCacheKey(skillTaxonomyId), () =>
      updateSkillTaxonomyNote({
        skillTaxonomyId,
        note,
      }),
    );
};

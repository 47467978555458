import { useQuery } from 'react-query';

import { useSkillTaxonomiesSocket } from '@eva/emf/app/entities/skillTaxonomy/hooks/useSkillTaxonomiesSocket';

import { fetchSkillTaxonomiesOptions } from '../api';

export const getSkillTaxonomiesOptionsCacheKey = (...args: (string | number)[]) => [...args, 'skillTaxonomyOptions'];

export const useFetchSkillTaxonomyOptions = () => {
  const cacheName = getSkillTaxonomiesOptionsCacheKey();

  useSkillTaxonomiesSocket(cacheName);

  return useQuery({
    queryKey: cacheName,
    queryFn: fetchSkillTaxonomiesOptions,
  });
};

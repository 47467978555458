import React, { useEffect, useState } from 'react';

import { removePathsFromError } from '@eva/emf/app/shared/functions';
import { PageSpinner } from '@eva/emf/app/shared/ui/Spinner';
import { stringifyError } from 'shared/functions';
import type { JobDto } from '@eva/types/dto';

import { useFetchSkillTaxonomyTrees } from '../../../entities/SkillTaxonomies/hooks';

import { RequirementsAndSkillsSection } from './RequirementsAndSkillsSection';

type RequirementsAndSkillsType = {
  job: JobDto;
  code: string;
};

export const RequirementsAndSkills = (props: RequirementsAndSkillsType) => {
  const [isAvailableSection, setIsAvailable] = useState(false);
  const [isSkillTaxonomyTrees, setIsSkillTaxonomyTrees] = useState(false);

  const { data, isLoading, isError, error } = useFetchSkillTaxonomyTrees({
    code: props.code,
  });

  useEffect(() => {
    const isSkillTaxonomyTrees = data && data.length > 0;
    setIsSkillTaxonomyTrees(isSkillTaxonomyTrees);
  }, [data]);

  useEffect(() => {
    const {
      job: { languages, qualification, degreeTypes },
    } = props;

    const isSkillTaxonomyTrees = data && data.length > 0;

    const isAvailable =
      isSkillTaxonomyTrees || !!languages.items.length || !!qualification || !!degreeTypes.items.length;

    setIsAvailable(isAvailable);
  }, [data, props]);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (isError) {
    const errorWithoutPath = removePathsFromError(error);
    return <h1>{stringifyError(errorWithoutPath)}</h1>;
  }

  return isAvailableSection ? (
    <RequirementsAndSkillsSection {...props} isSkillTaxonomyTrees={isSkillTaxonomyTrees} />
  ) : null;
};

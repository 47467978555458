import { memo } from 'react';

import emptyListImage from 'assets/images/empty-list.svg';

const RecommendedJobsPlaceholder = () => (
  <div className="info-message">
    <div className="text-center">
      <h3 className="text-primary">No jobs found</h3>
      <p>
        You don't currently have any job recommendations,
        <br />
        why&nbsp;not&nbsp;chat with an&nbsp;agent?
      </p>
      <img src={emptyListImage} alt="" width="60%" className="margin-top margin-bottom" />
    </div>
  </div>
);

// eslint-disable-next-line import/no-default-export
export default memo(RecommendedJobsPlaceholder);

import classnames from 'classnames';

import './SkillTaxonomyTree.scss';

import { Skill } from './Skill';
import { SkillGroup } from './SkillGroup';

export const SkillTaxonomyTree = ({ node }) => {
  const classNames = classnames(['node']);

  return (
    <div>
      {node.skillGroupId ? <SkillGroup group={node} /> : node.skillId ? <Skill skill={node} /> : null}
      {node.children && node.children.length > 0 && (
        <div className={classNames}>
          {node.children.map((child) => (
            <SkillTaxonomyTree key={`${child.name}${child.skillId || child.skillGroupId}`} node={child} />
          ))}
        </div>
      )}
    </div>
  );
};

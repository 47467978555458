import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import Slider from '@eva/emf/app/shared/ui/Slider';
import { getMissingStages, setQueryVariables } from 'shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

import emptyListImage from 'assets/images/empty-list.svg';
import JobDetails from 'containers/JobDetails';
import { showChatMessageNotification } from 'containers/JobDetails/functions';

import { mainMenuKeys } from '../constants';

import ActivePipelines from './ActivePipelines';
import ArchivedPipelines from './ArchivedPipelines';
import {
  filterPipelineWorkflows,
  getActivePipelines,
  getArchivedPipelines,
  getSelectedPipeline,
  getSelectedWorkflow,
  getSortedPipelines,
} from './functions';

const eventTypes = {
  webchatMessageSent: 'webchat-message-sent',
};

// eslint-disable-next-line import/no-default-export
export default class MenuMyJobs extends React.Component<any, any> {
  state = {
    loading: false,
    activePipelines: [],
    archivedPipelines: [],
    pipelineWorkflows: [],
    selectedPipelineWorkflow: null,
  };

  UNSAFE_componentWillMount() {
    this.initPipelines();
  }

  componentDidMount() {
    this.onConnectSocket();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pipelines } = nextProps;

    if (this.props.pipelines !== pipelines) {
      this.initPipelines(nextProps);
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.selectedPipelineWorkflow?.workflowId !== prevState.selectedPipelineWorkflow?.workflowId) {
      this.initPipelines();
    }
  }

  componentWillUnmount() {
    this.onDisconnectSocket();
  }

  onConnectSocket() {
    const { socket } = this.context;

    socket.on(eventTypes.webchatMessageSent, this.onChatMessage);
  }

  onDisconnectSocket() {
    const { socket } = this.context;

    socket.off(eventTypes.webchatMessageSent, this.onChatMessage);
  }

  onChatMessage = (payload) => {
    if (!this.props.mobileMode) {
      return;
    }

    if (!payload?.message?.ai) {
      return;
    }

    showChatMessageNotification(payload.message);
  };

  initPipelines(props = this.props) {
    const { openOnSwitch, pipelines, mobileMode } = props;

    if (!pipelines?.length) {
      this.setState({
        sortedPipelines: [],
        activePipelines: [],
        archivedPipelines: [],
        pipelineWorkflows: [],
        selectedPipeline: null,
        selectedPipelineWorkflow: null,
      });
      return;
    }

    const unknownWorkflowId = 'unknown';
    const missingStages = getMissingStages();

    const [sortedPipelines, usedPipelineWorkflows] = getSortedPipelines(pipelines, unknownWorkflowId, missingStages);

    let pipelineWorkflows = sortBy(Object.values(usedPipelineWorkflows), 'sortOrder');
    pipelineWorkflows = filterPipelineWorkflows(pipelineWorkflows, sortedPipelines);

    let selectedPipelineWorkflow = getSelectedWorkflow(pipelineWorkflows) || pipelineWorkflows?.[0];

    const activePipelines = getActivePipelines(sortedPipelines, selectedPipelineWorkflow?.workflowId);

    const archivedPipelines = getArchivedPipelines(sortedPipelines, selectedPipelineWorkflow?.workflowId);

    const defaultPipeline = mobileMode ? null : activePipelines?.[0];

    const selectedPipeline = getSelectedPipeline(pipelines, openOnSwitch) || defaultPipeline;

    if (selectedPipeline) {
      selectedPipelineWorkflow =
        usedPipelineWorkflows[get(selectedPipeline, 'workflowProcess.workflow.workflowId') || unknownWorkflowId];
    }

    this.setState(
      {
        sortedPipelines,
        activePipelines,
        archivedPipelines,
        pipelineWorkflows,
        selectedPipelineWorkflow,
      },
      () => {
        this.markPipelinesAsRead();
        if (selectedPipeline) {
          this.selectPipeline(selectedPipeline);
        }
        setQueryVariables({
          workflowId: selectedPipelineWorkflow?.workflowId,
        });
      },
    );
  }

  markPipelinesAsRead = () => {
    const { dispatchMarkPipelinesRead } = this.props;
    // @ts-expect-error
    const { sortedPipelines } = this.state;

    const mappedPipelines = sortedPipelines
      .filter((pipeline) => !pipeline.readOn)
      .map((pipeline) => pipeline.pipelineId);
    if (mappedPipelines.length) {
      dispatchMarkPipelinesRead(mappedPipelines);
    }
  };

  selectPipeline = (selectedPipeline) => {
    const { setCentralContent, emitSendMessage, switchToChat } = this.props;

    this.setState({
      selectedPipeline,
    });

    setQueryVariables({
      jobId: selectedPipeline.job.jobId,
    });

    setCentralContent(
      <div className="job-central-panel">
        <JobDetails
          socket={this.context.socket}
          jobCode={selectedPipeline.job.code}
          selectedPipeline={selectedPipeline}
          emitSendMessage={emitSendMessage}
          closeJobDetails={switchToChat}
        />
      </div>,
    );
  };

  discussJob = (evt, jobCode) => {
    const { emitSendMessage, switchToChat } = this.props;

    evt.preventDefault();
    evt.stopPropagation();
    emitSendMessage(
      translate('{{ prefix }}I would like to discuss a job I have applied for:', {
        prefix: `/postjob ${jobCode}#`,
      }),
    );
    switchToChat();
  };

  render() {
    const { pipelines, loadingPipelines, mobileMode, switchToChat, switchMenuItem } = this.props;
    // @ts-expect-error
    const { activePipelines, archivedPipelines, pipelineWorkflows, selectedPipelineWorkflow, selectedPipeline } =
      this.state;

    return (
      <div className="text-center">
        {loadingPipelines && <Spinner />}

        {!loadingPipelines && (
          <div>
            <div className="clearfix" />
            {(activePipelines.length > 0 || archivedPipelines.length > 0) && (
              <Slider width={0}>
                <ul className="nav nav-tabs">
                  {pipelineWorkflows.map((pipelineWorkflow) => (
                    <li
                      key={pipelineWorkflow.workflowId}
                      className={`${
                        pipelineWorkflow?.workflowId === selectedPipelineWorkflow?.workflowId ? 'active' : ''
                      }`}
                    >
                      <a
                        onClick={() => {
                          setQueryVariables({
                            workflowId: pipelineWorkflow.workflowId,
                          });
                          if (pipelineWorkflow?.workflowId !== selectedPipelineWorkflow?.workflowId) {
                            setQueryVariables({
                              jobId: undefined,
                            });
                          }
                          this.setState({
                            selectedPipelineWorkflow: pipelineWorkflow,
                          });
                        }}
                      >
                        {pipelineWorkflow.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </Slider>
            )}

            {!pipelines.length && (
              <div className="info-message">
                <div>
                  <h3>{translate('No jobs in progress')}</h3>
                  {translate(
                    'You don’t currently have any job in progress, why not check out your personalised ' +
                      'job recommendations or chat with an agent?',
                  )}
                </div>
                {mobileMode && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-block margin-top margin-bottom"
                      onClick={() => switchToChat()}
                    >
                      {translate('Chat with an agent')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-block margin-top margin-bottom hide"
                      onClick={() => switchMenuItem(mainMenuKeys.recommended)}
                    >
                      {translate('View recommendations')}
                    </button>
                  </div>
                )}
                <img src={emptyListImage} alt="" width="60%" className="margin-top margin-bottom" />
              </div>
            )}
            <div>
              {activePipelines.length > 0 && (
                <ActivePipelines
                  sortedPipelines={activePipelines}
                  selectedPipeline={selectedPipeline}
                  discussJob={this.discussJob}
                  selectPipeline={this.selectPipeline}
                />
              )}
              {archivedPipelines.length > 0 && (
                <ArchivedPipelines
                  sortedPipelines={archivedPipelines}
                  selectedPipeline={selectedPipeline}
                  discussJob={this.discussJob}
                  selectPipeline={this.selectPipeline}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
MenuMyJobs.contextTypes = {
  socket: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
MenuMyJobs.propTypes = {
  openOnSwitch: PropTypes.number.isRequired,
  pipelines: PropTypes.array.isRequired,
  loadingPipelines: PropTypes.bool.isRequired,
  mobileMode: PropTypes.bool.isRequired,
  setCentralContent: PropTypes.func.isRequired,
  switchToChat: PropTypes.func.isRequired,
  switchMenuItem: PropTypes.func.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  dispatchMarkPipelinesRead: PropTypes.func.isRequired,
};

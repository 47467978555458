import PropTypes from 'prop-types';
import React from 'react';

import { renderSection } from '@eva/emf/app/shared/functions';

class CardCoverLetter extends React.PureComponent<any, any> {
  state = {
    showMore: {},
  };

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  render() {
    const {
      entity: { coverLetter },
    } = this.props;

    return (
      <div className="section" id="cover-letter">
        <h3 className="text-primary">{translate('Cover letter')}</h3>
        {renderSection(
          '',
          <div
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {coverLetter.coverLetter}
          </div>,
        )}
      </div>
    );
  }
}

// @ts-expect-error
CardCoverLetter.propTypes = {
  entity: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default CardCoverLetter;

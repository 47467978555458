import type { FC } from 'react';

import { processHtml } from './functions';

type PublicJobBodyTemplateType = {
  html: string;
};

export const PublicJobBodyTemplate: FC<PublicJobBodyTemplateType> = (props) => {
  const { html } = props;

  const updatedHtml = processHtml(html);

  return <div dangerouslySetInnerHTML={{ __html: updatedHtml }} />;
};

import PropTypes from 'prop-types';
import React from 'react';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

const input = 'input';
const excludedKeys = ['default', input];

class Radios extends AIUIComponent {
  state = {
    value: '',
  };

  UNSAFE_componentWillMount() {
    const { attributes } = this.props;
    const defaultKey = attributes.default || Object.keys(attributes)[0];
    const attributesKeys = Object.keys(attributes);
    this.setState({
      name: Date.now(),
      radios: attributesKeys
        .filter((key) => !excludedKeys.includes(key))
        .map((key) => ({
          key,
          label: attributes[key],
        })),
      value: defaultKey,
      input: attributesKeys.includes(input),
    });
  }

  submitResult() {
    // @ts-expect-error
    const { selected } = this.state;
    this.setState({ submitted: true });
    this.props.resultingContent(selected.length ? selected.join(', ') : 'None of these');
  }

  render() {
    const { editable } = this.props;
    // @ts-expect-error
    const { name, radios, submitted, value } = this.state;

    if (!editable) {
      return null;
    }

    return (
      <div
        style={{
          display: 'inline',
        }}
      >
        {radios.map((radio) => (
          <label
            key={radio.key}
            style={{
              paddingLeft: '20px',
              display: 'block',
            }}
          >
            <input
              name={name}
              type="radio"
              disabled={submitted}
              checked={radio.key === value}
              onChange={() => this.setState({ value: radio.key })}
            />{' '}
            {radio.label}
          </label>
        ))}
        {input && (
          <div>
            <input
              name={name}
              type="radio"
              disabled={submitted}
              checked={value === input}
              onChange={() => this.setState({ value: input })}
            />
            <input type="text" disabled={submitted} onChange={(evt) => this.setState({ value: evt.target.value })} />
          </div>
        )}
        <button
          type="submit"
          className="btn btn-primary pull-right"
          disabled={submitted}
          onClick={() => this.submitResult()}
        >
          Send
        </button>
      </div>
    );
  }
}

// @ts-expect-error
Radios.propTypes = {
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default Radios;

import React from 'react';

import { stringifyError } from '@eva/emf/app/shared/functions';
import { getMessageOptions, logError } from 'shared/functions';

import Suggestions from '../Suggestions';

/** @deprecated TODO remove in DEV-25601 skillsSet logic */
function CustomMessage(props) {
  const { message, emitSendMessage, isLastMessage } = props;

  const [CustomMessageComponent, setCustomMessageComponent] = React.useState(null);
  const [error, setError] = React.useState(null);

  /** @deprecated TODO remove in DEV-25601 skillsSet logic */
  const messageOptions = getMessageOptions(message);

  /** @deprecated TODO remove in DEV-25601 skillsSet logic */
  React.useLayoutEffect(() => {
    if (messageOptions.customMessage) {
      if (messageOptions.customFrontend === 'eucf') {
        /** @deprecated TODO remove in DEV-25601 skillsSet logic */
        import('@eva/eucf/app/containers/candidateMessages').then(
          (candidateMessages) => {
            const MessageComponent = candidateMessages.default[messageOptions.customMessage];
            if (MessageComponent) {
              setCustomMessageComponent(() => MessageComponent);
            } else {
              logError(`Requested custom form [${messageOptions.customMessage}] was not found`);
            }
          },
          (err) => setError(stringifyError(err)),
        );
      } else {
        setError(`Custom frontend ${messageOptions.customFrontend} not found`);
      }
    }
  }, [messageOptions.customFrontend, messageOptions.customMessage]);

  if (!isLastMessage) {
    return null;
  } else if (error) {
    return <div className="text-danger">{error}</div>;
  } else if (messageOptions.suggestions) {
    return <Suggestions message={message} emitSendMessage={emitSendMessage} />;
  } else if (CustomMessageComponent) {
    return <CustomMessageComponent message={message} emitSendMessage={emitSendMessage} isLastMessage={isLastMessage} />;
  }
  return null;
}

// eslint-disable-next-line import/no-default-export
export default CustomMessage;

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import Select from '@eva/emf/app/containers/Select';

function SuggestionsSelect(props) {
  const { suggestions, selectedSuggestions, setSelectedSuggestions, multiSelect } = props;

  const options = useMemo(
    () =>
      suggestions.map((suggestion) => ({
        value: suggestion,
        label: suggestion,
      })),
    [suggestions],
  );

  const onChange = useCallback(
    (updatedValue) => {
      if (Array.isArray(updatedValue)) {
        return setSelectedSuggestions(updatedValue.map(({ value }) => value));
      }
      setSelectedSuggestions(updatedValue ? [updatedValue.value] : []);
    },
    [setSelectedSuggestions],
  );

  return (
    <div>
      <Select
        value={multiSelect ? selectedSuggestions : selectedSuggestions[0]}
        options={options}
        onChange={onChange}
        multi={multiSelect}
      />
    </div>
  );
}

SuggestionsSelect.propTypes = {
  suggestions: PropTypes.array.isRequired,
  selectedSuggestions: PropTypes.array.isRequired,
  setSelectedSuggestions: PropTypes.func.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default SuggestionsSelect;

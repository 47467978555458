/* eslint-disable max-len */
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

import { requestBackend } from '@eva/emf/app/utils/request';
import { processValidationErrors } from 'shared/functions';

// eslint-disable-next-line import/no-default-export
export default class ModalSLAAccepted extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    show: false,
  };
  resolve: (value: unknown) => void;
  promise: any;
  reject: (reason?: any) => void;

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  ok = () =>
    requestBackend('/my/candidate-profile', {
      method: 'PUT',
      body: JSON.stringify({
        slaAccepted: true,
      }),
    }).then(() => {
      // @ts-expect-error
      if (this.unmounted) {
        return;
      }
      this.setState({
        downloading: false,
        show: false,
      });
      // @ts-expect-error
      this.resolve();
    }, processValidationErrors());

  open() {
    this.setState({
      show: true,
    });
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    return this.promise;
  }

  render() {
    const { show } = this.state;

    return (
      <Modal show={show} bsSize="large">
        <form onSubmit={this.ok}>
          <Modal.Header>
            <Modal.Title>You need to accept SLA to continue</Modal.Title>
          </Modal.Header>
          <Modal.Body className="job-list">
            <div className="sla-content">
              <h3>SOFTWARE - GENERAL CONDITIONS</h3>
              <p>
                BY USING OR ACCESSING EVA.AI, YOU ACKNOWLEDGE YOUR ACCEPTANCE OF BOTH THESE TERMS OF USE AND OUR PRIVACY
                POLICY, INCORPORATED BY REFERENCE IN THESE TERMS. IF YOU DO NOT AGREE TO ALL OF THESE TERMS OR
                CONDITIONS THEN YOU MAY NOT USE EVA.AI.{' '}
              </p>
              <h4>1 TERMS & APPLICATION</h4>
              <p className="margin-left">
                <strong>1.1</strong> EVA.AI Ltd (Company Number 08700214) of Martlet House, E1 Yeoman Gate Yeoman Way,
                Worthing, West Sussex, BN13 3QZ grants you a non-exclusive, limited and revocable licence to use and
                access the EVA.AI (www.eva.ai) (<strong>Website</strong>) and related software (<strong>App</strong>)
                (together, the <strong>Platform</strong>) subject to these <strong>Terms</strong>.
              </p>
              <h4>2 AGREEMENT</h4>
              <p className="margin-left">
                <strong>2.1</strong> These General Conditions (<strong>Conditions</strong>) apply to the supply and all
                use of its software (as defined below) (<strong>Software</strong>) and any related services (
                <strong>Services</strong>) provided by EVA.AI LIMITED (Company number 08700214) (<strong>EVA.AI</strong>
                ) to each of client (<strong>Client</strong>) and each and any of their related agencies, subsidiaries,
                holding companies or independant business departments (<strong>Agencies</strong>). EVA.AI may require
                that each Agency enter into a separate agreement with EVA.AI and prescribe Terms & Conditions which
                shall apply to the use of the Platform by each individual agent, employee, contractor or customer (
                <strong>User</strong>).
              </p>
              <p className="margin-left">
                <strong>2.2</strong> These Conditions form a binding contract between EVA.AI and each Client and Agency
                in respect of the Software in priority to any other agreement between the parties, unless expressly
                agreed otherwise under a Software License Agreement executed by the parties (<strong>Agreement</strong>
                ). The terms of any Software License Agreement shall supersede these Conditions only to the extent of
                any conflict and with regard to the component(s) to which the Software License Agreement applies. If
                Client does not accept any Software License Agreement accompanying any particular component of Software,
                Client is not authorised to copy, install, or otherwise use the relevant component.
              </p>
              <p className="margin-left">
                <strong>2.3</strong> The Agreement shall be deemed effective upon the earlier of the Client accepting
                both of these terms of use and privacy policy and pay any or all the applicable License Fees, accessing
                the Software, or accepting any written proposal from EVA.AI.
              </p>
              <h4>3 DEFINITIONS</h4>
              <p className="margin-left">
                <strong>3.1</strong> In these Conditions, capitalised terms take the meaning defined below:
                <ul>
                  <li>
                    <strong>(a)</strong> <strong>Administrator:</strong> those Users approved by EVA.AI to exercise
                    application administration rights as defined by EVA.AI;
                  </li>
                  <li>
                    <strong>(b)</strong> <strong>Agencies:</strong> such company, subsidiary, partnership, sole trader
                    or other associate directly related with Client authorised by EVA.AI and who shall have an
                    individualised login accounts and passwords for use of the Platform;{' '}
                  </li>
                  <li>
                    <strong>(c)</strong> <strong>Users:</strong> such number of employees or contractors of Client who
                    have an individualised login identification and password and have been approved and licensed by
                    EVA.AI to have access to, use of and enter data using the Software on the System Environment;
                  </li>
                  <li>
                    <strong>(d)</strong> <strong>Intellectual Property Rights:</strong> copyright, patents, database
                    rights, and rights in trademarks, business or domain names, inventions, designs, know-how,
                    confidential information mask-work rights, tools, methods, metadata, machine learning training data,
                    natural language processing examples, understanding and training data, synonym, antonym and other
                    such data sets and related data, trade secrets, moral rights, author's rights, algorithms, rights in
                    packaging, goodwill, corporate, trade and product branding (whether registered or unregistered),
                    applications for registration, and the right to apply for registration, extension and renewal for
                    any of these rights and all other intellectual and industrial property rights and equivalent or
                    similar forms of protection existing anywhere in the world;
                  </li>
                  <li>
                    <strong>(e)</strong> <strong>Project Plan:</strong> shared project management plan (if any) provided
                    to the Client (and as amended);
                  </li>
                  <li>
                    <strong>(f)</strong> <strong>Software:</strong> unless otherwise specified, execution code only of
                    EVA.AI software applications and processes specified in these Conditions or the Agreement (and as
                    otherwise agreed in writing) to be supplied and installed by EVA.AI on the System Environment or on
                    mobile devices owned or controlled by Client or Users under this Agreement;
                  </li>
                  <li>
                    <strong>(g)</strong> <strong>Start Date:</strong> the commencement date for use of the Software
                    specified in the Agreement (which in default shall be 7 days of effective date of the Agreement);
                  </li>
                  <li>
                    <strong>(h)</strong> <strong>System Environment:</strong> the system of integrated computer
                    hardware, operating systems, software, computer peripherals and facilities within the ownership,
                    possession or control of Client and provided or procured for EVA.AI for the purposes of these
                    Conditions and upon which Client has authorised EVA.AI to install, test, develop, operate and
                    support the Software.
                  </li>
                </ul>
              </p>
              <h4>4 LICENSE & CONDITIONS</h4>
              <p className="margin-left">
                <strong>4.1</strong> EVA.AI shall use reasonable endeavours to provide the Software and to meet the
                performance dates specified in the Project Plan (if any), but any such dates shall be estimates only and
                time shall not be of the essence.
              </p>
              <p className="margin-left">
                <strong>4.2</strong> Conditional upon payment of all Fees or otherwise agreed and compliance with these
                Conditions, EVA.AI grants the Client a non-exclusive, non-transferable and conditional license to use
                the Software under the Agreement (<strong>License</strong>):
                <ul>
                  <li>
                    <strong>(a) </strong> From the Start Date for the duration of the Agreement, to use the Software for
                    the normal business purposes of the Client;
                  </li>
                  <li>
                    <strong>(b) </strong> To install and configure relevant components of the Software on the System
                    Environment;
                  </li>
                  <li>
                    <strong>(c) </strong> To sub-license Users to use and configure the Software; and
                  </li>
                  <li>
                    <strong>(d) </strong> To sub-license Agencies to use and configure the Software subject to the End
                    User Terms.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>4.3 </strong> All other rights are reserved to EVA.AI and no rights to modify, develop or
                customise the Software or any component thereof are granted to any person under these Conditions. This
                License is not a transfer of title and EVA.AI retains ownership of the Software.{' '}
              </p>
              <p className="margin-left">
                <strong>4.4 </strong> Except as otherwise expressly provided under the Agreement, the Client shall:
                <ul>
                  <li>
                    <strong>(a) </strong> Not use the Software other than for Users and Agencies in accordance with
                    operational guidelines and procedures notified by EVA.AI;
                  </li>
                  <li>
                    <strong>(b) </strong> Not rent, lease, sell, transfer, assign or sublicense the Software or its
                    license rights under these Conditions to any other person or entity, and the Client acknowledges
                    that any such attempted dealing will be void;
                  </li>
                  <li>
                    <strong>(c) </strong> Not allow the Software to become the subject of any charge, lien or
                    encumbrance;
                  </li>
                  <li>
                    <strong>(d) </strong> Not make corrections to or otherwise modify or adapt the Software, create
                    derivative works based upon the Software, or permit third parties to do so;
                  </li>
                  <li>
                    <strong>(e) </strong> Not copy, disassemble, decompile, reverse-engineer or create derivative works
                    based on the whole or any part of the Software or attempt to do any such thing;
                  </li>
                  <li>
                    <strong>(f) </strong> Not use or permit the Software to be used to perform services for third
                    parties without the express written authorisation of EVA.AI or otherwise in a manner not permitted
                    by the Agreement;
                  </li>
                  <li>
                    <strong>(g) </strong> Not use or permit or procure any other person to use any rights granted to it
                    pursuant to these Conditions to develop, market, publish, sell or otherwise deal in any software or
                    other intellectual property so that any source code forming part of the Software is made available
                    or is required to be made available to the general public under any open source or general public
                    license conditions;
                  </li>
                  <li>
                    <strong>(h) </strong> Not disclose, provide or otherwise make available any confidential information
                    or trade secrets contained within the Software in any form to any third party without the prior
                    written consent of EVA.AI;
                  </li>
                  <li>
                    <strong>(i) </strong> Not remove or interfere with any trademarks, copyright, trade mark or related
                    notices affixed or installed by EVA.AI or any licensor of EVA.AI on any service or copy of the
                    Software or EVA.AI Intellectual Property Rights;
                  </li>
                  <li>
                    <strong>(j) </strong> Not deal in any other manner with any or all of its rights and obligations
                    under the Agreement;
                  </li>
                  <li>
                    <strong>(k) </strong> Take all such other reasonable steps to protect the confidential information
                    and intellectual property rights of EVA.AI in EVA.AI Intellectual Property in its possession or
                    control from access use or copying not authorised by these Conditions.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>4.5</strong> To the extent that any reduction of the Software to human readable form (whether by
                reverse engineering, decompilation or disassembly) cannot be prohibited because they are essential (by
                virtue of section 296A of the Copyright, Designs and Patents Act 1988) for the purposes of integrating
                the operation of the Software with the operation of other software or systems used by the Client, EVA.AI
                shall (i) carry out such action at a reasonable commercial fee, or (ii) elect to provide the information
                and instructions necessary to achieve such integration within a reasonable period (and the Client shall
                meet EVA.AI's reasonable costs in providing that information) before undertaking any such reduction.
              </p>
              <p className="margin-left">
                <strong>4.6</strong> The Client agrees to:
                <ul>
                  <li>
                    <strong>(a)</strong> Use the Software in commercial service at any one time and not to make copies
                    of the Software for archive, back-up and disaster recovery purposes (provided copyright or
                    proprietary rights notices relating to the Software are reproduced on such copies) and to use only
                    one such copy in commercial service on servers when the primary copy has been rendered unusable or
                    inaccessible by reason of matters beyond Clientâs control;
                  </li>
                  <li>
                    <strong>(b) </strong> Permit only one copy of the Software to be used by each User at any one time
                    (provided that the Software may be opened across multiple browser tabs during any session);
                  </li>
                  <li>
                    <strong>(c) </strong> Prevent sharing of licensed accounts by recording details of authorised Users
                    and ensuring that login details, including passwords, are kept private;
                  </li>
                  <li>
                    <strong>(d) </strong> Record the number and location of all copies of the Software and take steps to
                    prevent unauthorised copying by any Client User or third party;
                  </li>
                  <li>
                    <strong>(e) </strong> Ensure that all Users return to EVA.AI or delete all copies of the Software
                    (if any) when the license for such software is terminated for any reason.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>4.7</strong> EVA.AI shall provide the Client with updates to the Software, that encompass error
                corrections, faults, maintenance, improvements, extensions, or other changes that are logical
                improvements or extensions of the original version of the Software, provided that EVA.AI may separately
                license (and charge for) any bespoke version of the Software provided by EVA.AI and generally accepted
                in the marketplace as constituting a new product.
              </p>
              <p className="margin-left">
                <strong>4.8</strong> If the Client requests customisations of the Software, EVA.AI shall, within a
                reasonable time, provide a written estimate to the Client of:
                <ul>
                  <li>
                    <strong>(a)</strong> the likely time required to implement the change;
                  </li>
                  <li>
                    <strong>(b)</strong> any variations to the Fees arising from the customisations;
                  </li>
                  <li>
                    <strong> (c) </strong> the likely effect of the customisations; and
                  </li>
                  <li>
                    <strong>(d) </strong> any other impact of the customisations on the terms of this Agreement.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>4.9</strong> If the Client wishes EVA.AI to proceed with customisations, EVA.AI shall do so when
                the parties have agreed in writing on the necessary variations, the associated cost and third-party
                charges, and take other relevant terms of the Agreement into account.
              </p>
              <h4>5 CLIENT RESPONSIBILITIES</h4>
              <p className="margin-left">
                <strong>5.1</strong> The Client hereby warrants and represents that:
                <ul>
                  <li>
                    <strong> (a) </strong> use of the Software by the Client and Users shall be for legitimate business
                    purposes in compliance with all applicable laws and regulations, and
                  </li>
                  <li>
                    <strong>(b) </strong> the Client has obtained all necessary approvals, consents and permissions from
                    any relevant authority or third party necessary or desirable for use of the Software.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>5.2</strong> Client shall, subject to and in accordance with these Conditions:
                <ul>
                  <li>
                    <strong>(a)</strong> procure and/or supply, and if necessary, install, support and maintain all
                    software, licenses, hardware, network infrastructure, services and environmental and operational
                    conditions required from any Software API or other connection of the Software to the System
                    Environment for the Software to use and operate within the System Environment and required for the
                    Software to be used and operate upon authorised mobile devices, in accordance with this Agreement
                    and any applicable Project Plan;
                  </li>
                  <li>
                    <strong>(b)</strong> maintain at all times such number of personnel each with a level of knowledge
                    necessary to carry out the Clientâs obligations under these Conditions;
                  </li>
                  <li>
                    <strong>(c)</strong> ensure that only adequately trained and authorised persons are permitted to use
                    the System Environment and that Users operate the Software in accordance with this Agreement and
                    operating procedures, guidelines, codes of conduct and processes reasonably specified by EVA.AI;
                  </li>
                  <li>
                    <strong>(d)</strong> ensure that each User does not use any other login or identity or any
                    unauthorised or inadequately licensed computer, device or facility to operate, access or use the
                    Software and that only approved Administrators exercise administrator rights and privileges with
                    respect to the Software;
                  </li>
                  <li>
                    <strong>(e) </strong> test and evaluate the Software as closely as practicable under normal expected
                    operating conditions and shall correlate and report test data as reasonably requested by EVA.AI; and
                  </li>
                  <li>
                    <strong>(f) </strong> provide or procure for the authorised personnel of EVA.AI during normal
                    working hours or as otherwise agreed access to any agreed Client or System Environment at such
                    premises as may be reasonably requested by EVA.AI for the delivery, installation, implementation and
                    testing of the Software, provided that the (i) Client shall comply with its obligations under
                    applicable health and safety regulations with respect to the provision of such access and facilities
                    to EVA.AI; and (ii) EVA.AI will take all practical steps to ensure that its personnel will, whenever
                    on Client or System Environment premises, obey all security and health and safety standards,
                    procedures and directions notified to it by Client.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>5.3 </strong> The Client shall maintain a written, up to date list of all authorised Users and
                provide such list to EVA.AI within 5 Business Days of EVA.AI's written request and permit EVA.AI to
                audit use of the Software in order to establish the number of Users and compliance with these Conditions
                (or End User Terms), provided that such audit shall be conducted not more than once per quarter, or as
                reasonably necessary to confirm any actual or suspected breach of these Conditions, with reasonable
                prior notice and in such a manner as not to substantially interfere with the Clientâs normal conduct of
                business.
              </p>
              <h4>6 CLIENT DATA</h4>
              <p className="margin-left">
                <strong>6.1</strong> The Client shall own all data, files, content and information processed during use
                of the Software and furnished to EVA.AI for the development or testing of or integration into or use
                with or communication through the Software or System Environment (<strong>Client Data</strong>) except
                to the extent constituting an enhancement or improvement to the Software and related Intellectual
                Property Rights.
              </p>
              <p className="margin-left">
                <strong>6.2 </strong> The Client accepts sole responsibility for Client Data and warrants and represents
                that any Client Data:
                <ul>
                  <li>
                    <strong>(a)</strong> shall be delivered in a timely manner to EVA.AI for inclusion in the Software;
                  </li>
                  <li>
                    <strong>(b)</strong> is owned by Client or that Client has permission from the rightful owner to use
                    such Client Data in connection with the Software or System Environment in the manner and for the
                    purposes required or contemplated by the Agreement; and
                  </li>
                  <li>
                    <strong>(c) </strong> is in no way whatsoever a violation or infringement of any third party
                    Intellectual Property Rights, right of privacy or publicity or any other rights of any person and
                    that it is not obscene, libellous or defamatory or in any other way unlawful and will not in any way
                    inhibit restrict or impair the free and/or unrestricted performance by EVA.AI of any rights or
                    obligations it has under this Agreement.
                  </li>
                </ul>
              </p>
              <h4>7 DELIVERY & ACCEPTANCE</h4>
              <p className="margin-left">
                <strong>7.1</strong> If EVA.AI's performance of its obligations under the Agreement is prevented or
                delayed by any act or omission of the Client or any User, the Client shall be liable to pay to EVA.AI on
                demand all reasonable costs, charges or losses sustained or incurred by it (including, without
                limitation, any direct or indirect consequential losses, loss of profit and loss of reputation, loss or
                damage to property, injury to or death of any person and loss of opportunity to deploy resources
                elsewhere), subject to EVA.AI confirming such costs, charges and losses to the Client in writing.
              </p>
              <p className="margin-left">
                <strong>7.2</strong> Where this Agreement includes installation of the Software, EVA.AI shall install on
                the System Environment the Software by the Start Date and the following conditions shall apply:
                <ul>
                  <li>
                    <strong>(a)</strong> Within ten business days of installation or service provision, the Client shall
                    supply data (<strong>Test Data</strong>) to EVA.AI suitable to test whether the Software operates in
                    accordance with any Acceptance Testing Specification together with the results (
                    <strong>Test Results</strong>) it reasonably expects to be achieved by processing the Test Data
                    using the Software;
                  </li>
                  <li>
                    <strong>(b)</strong> Within seven days of receipt of suitable Test Data and Test Results, EVA.AI
                    shall carry out Acceptance Testing in the presence of the Administrator;
                  </li>
                  <li>
                    <strong>(c)</strong> If the initial Acceptance Testing fails, EVA.AI shall, within fourteen business
                    days of the Acceptance Testing and at its cost, correct the errors so disclosed and repeat the
                    Acceptance Testing in the presence of the Administrator;
                  </li>
                  <li>
                    <strong>(d)</strong> If the subsequent Acceptance Testing fails, the Client may require EVA.AI to
                    repeat the actions described above as often as the Client wishes (subject to the limitation
                    specified below).
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>7.3</strong> The Client shall be deemed to have accepted the Software if:
                <ul>
                  <li>
                    <strong>(a)</strong> the Acceptance Testing is certified by EVA.AI to be successful;
                  </li>
                  <li>
                    <strong>(b)</strong> the Client fails to provide the Test Data and Test Results within the time
                    limits set out above (as to which time shall be of the essence); or
                  </li>
                  <li>
                    <strong>(c)</strong> the Client commences operational use of the Software.
                  </li>
                </ul>
              </p>
              <h4>8 FEES, INVOICING & PAYMENT</h4>
              <p className="margin-left">
                <strong>8.1</strong> The Fees payable for use of the Software and Services are specified in the
                Agreement (or otherwise under an invoice agreed with Client) and are payable in advance or otherwise in
                accordance with the agreed payment terms (which in default shall be 7 days from invoice) without any set
                off or deduction.
              </p>
              <p className="margin-left">
                <strong>8.2 </strong> Unless expressly agreed otherwise, EVA.AI may notify variations to the Fees on one
                (1) month notice and on renewal, including as necessary to account for changes to the number of Users,
                or features of the Software.
              </p>
              <p className="margin-left">
                <strong>8.3 </strong> If the License or Agreement is terminated, EVA.AI may determine in its absolute
                discretion whether to refund any Fees already paid (which shall exclude any costs that are not
                reimbursed by third parties).
              </p>
              <p className="margin-left">
                <strong>8.4</strong> Without prejudice to any other right or remedy that EVA.AI may have, if the Client
                or Agency fails to pay EVA.AI on the due date LUCKLINKY may:
                <ul>
                  <li>
                    <strong>(a)</strong> charge interest on such sum from the due date for payment at the annual rate of
                    4% above the base lending rate from time to time of Barclays Bank, accruing on a daily basis and
                    being compounded quarterly until payment is made, whether before or after any judgment; and
                  </li>
                  <li>
                    <strong>(b)</strong> charge additional administration costs (both before and after judgment) on the
                    amount unpaid at the rate for the time being that would be applicable if the debt were a qualifying
                    debt under the Late Payment of Commercial Debts (Interest) Act 1998; and/or
                  </li>
                  <li>
                    <strong>(c)</strong> suspend the License and all Services until payment has been made in full.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>8.5 </strong> EVA.AI will render a valid tax invoice to the Client. Unless expressly stated
                otherwise, all amounts payable under the Agreement are exclusive of VAT (if any). If any payment
                pursuant to the Agreement constitutes the whole or any part of the consideration for a taxable or deemed
                taxable supply, the supplier shall increase that payment by an amount equal to the VAT which is
                chargeable in respect of the taxable or deemed taxable supply, provided that the recipient delivered a
                valid VAT invoice in respect of such VAT.
              </p>
              <p className="margin-left">
                <strong>8.6 </strong> All payments payable to EVA.AI under the Agreement shall become due immediately on
                termination of the Agreement, despite any other provision. This condition is without prejudice to any
                right to claim for interest under the law, or any such right under the Agreement.
              </p>
              <p className="margin-left">
                <strong>8.7 </strong> EVA.AI shall be entitled to set off or withhold any amount owed to the Client
                under the Agreement against any amount payable by the Client to EVA.AI. Time is of the essence for
                payment of Fees.
              </p>
              <h4>9 LIABILITY & INSURANCE</h4>
              <p className="margin-left">
                <strong>9.1</strong> EVA.AI shall obtain insurance cover for the performance of this Agreement and shall
                provide on request from the Client its insurance policy and certificate of currency for cover against
                professional and public negligence, online activities for data protection, privacy, hacking and
                interruptions of the Software.
              </p>
              <p className="margin-left">
                <strong>9.2 </strong> Subject to the specific terms of the Agreement or any subsequent Service Level
                Agreement (if any), EVA.AI: (i) does not guarantee any specific results from use of the Software, (ii)
                does not warrant that the use of the Software will be uninterrupted or error-free, and (iii) has no
                obligation to provide any support or correct any bugs, errors or discrepancies in the Software.
              </p>
              <p className="margin-left">
                <strong>9.3</strong> To the extent permitted by law, EVA.AIâs liability for breach of the Agreement or
                otherwise in connection with the Software, as well as any implied warranty or condition that cannot be
                excluded, is restricted at the option of EVA.AI to the re-supply of services, or payment of the cost of
                re-supply of services, the applicable Fees. In no circumstances will either party be liable for any
                consequential or indirect damages, loss of profits, or any other similar or analogous loss resulting
                from use of the Services or Software, whether based on warranty, contract, tort, negligence, in equity
                or any other type of law.
              </p>
              <p className="margin-left">
                <strong>9.4 </strong> The Client shall indemnify EVA.AI for, and hold it harmless against any loss,
                damage, costs, expenses, liability, deduction, contribution, assessment or claim (including reasonable
                legal and preparation costs) arising in connection with: (i) Any breach of its obligations under this
                Agreement; (ii) Any third party claims that may arise from use or misuse of the Software by Users; and
                (iii) Any tax, penalty, fine or interest incurred or payable in connection with the Software or in
                consequence of breach of this Agreement. EVA.AI may at its option satisfy such indemnity (whether in
                whole or in part) by way of deduction from any payments due to be paid to it under this Agreement.
              </p>
              <p className="margin-left">
                <strong>9.5</strong> If any claim or action is brought against the Client alleging that the possession
                or use of the Software (or any part thereof) in accordance with these Conditions infringes the
                Intellectual Property Rights of a third party, or in EVA.AI's reasonable opinion is likely to be made (
                <strong>Claim</strong>), EVA.AI may at its sole option and expense:
                <ul>
                  <li>
                    <strong>(a)</strong> procure for the Client the right to continue to use the Software (or any part
                    thereof) in accordance with the terms of this license;
                  </li>
                  <li>
                    <strong>(b)</strong> modify the Software so that it ceases to be infringing;
                  </li>
                  <li>
                    <strong>(c)</strong> replace the Software with non-infringing software; or
                  </li>
                  <li>
                    <strong>(d)</strong> terminate the License immediately by notice in writing to the Client and refund
                    any of the Fee paid by the Client as at the date of termination (less a reasonable sum in respect of
                    the Client's use of the Software to the date of termination) on return of the Software and all
                    copies thereof.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>9.6</strong> In no event shall EVA.AI, its employees, agents and sub-contractors have any
                obligations or be liable to the Client to the extent that the alleged infringement is based on:
                <ul>
                  <li>
                    <strong>(a)</strong> Any use of the Software in breach of these Conditions or reasonable
                    instructions given to the Client by EVA.AI;
                  </li>
                  <li>
                    <strong>(b)</strong> Any modification of the Software by anyone other than EVA.AI; or
                  </li>
                  <li>
                    <strong>(c)</strong> Any use of the Software after notice of any Claim or any alleged or actual
                    infringement from EVA.AI or any appropriate authority.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>9.7</strong> Nothing in these Conditions shall limit or exclude the liability of either party
                for death or personal injury resulting from its negligence, fraud or fraudulent misrepresentation.{' '}
              </p>
              <h4>10 INTELLECTUAL PROPERTY</h4>
              <p className="margin-left">
                <strong>10.1</strong> Client acknowledges that all Intellectual Property Rights relating to the
                Software, and any related services or materials provided to or accessed by the Client under this
                Agreement are and shall remain the property of EVA.AI (or its third party licensors). All existing
                goodwill in the Intellectual Property Rights vests in EVA.AI or its third party licensors, and, on
                creation, all future goodwill in the Software vests in EVA.AI or its third party licensors (as
                applicable).
              </p>
              <p className="margin-left">
                <strong>10.2</strong> Other than any rights specifically granted to Client under this Agreement, EVA.AI
                reserves all rights to the Software and related Intellectual Property Rights and nothing in this
                Agreement shall grant or convey to Client any interest in or transfer any Intellectual Property Rights
                or related to the business of EVA.AI.
              </p>
              <p className="margin-left">
                <strong>10.3</strong> Client must promptly notify EVA.AI upon becoming aware of any actual, threatened
                or suspected infringement or passing off in respect of its Intellectual Property Rights. EVA.AI has the
                sole right to take action against third parties in respect of the Software and related Intellectual
                Property Rights, if required to do so by EVA.AI, Client must co-operate fully with any such action, the
                costs and expenses (including legal costs and expenses) of which will be borne by EVA.AI unless
                otherwise agreed by the parties.
              </p>
              <p className="margin-left">
                <strong>10.4 </strong> Each party has the moral and registered rights in its own trademarks and the
                other party agrees not to copy, alter, use or otherwise deal in the marks without prior written consent.
                Client specifically undertakes never to use any sign, logo or trademark that is the same as or could be
                considered confusingly similar to EVA.AI and its marks.
              </p>
              <h4>11 CONFIDENTIALITY</h4>
              <p className="margin-left">
                <strong>11.1</strong> Except as necessary under or in connection with for performance of this Agreement,
                each party shall not disclosed and must maintain in confidence any written or verbal information (
                <strong>Confidential Information</strong>) that: (i) details the terms of this Agreement; (ii) details
                the business of the other party; (iii) is identified by either party as confidential and/or proprietary,
                other than information that the relevant party can establish: (A) was in the public domain at the time
                it was disclosed; (B) was already in the possession of a party when given, without having been acquired
                (directly or indirectly) from the other party; or (C) was received from another person who had the
                unrestricted legal right to disclose that information free from any confidentiality obligation.
              </p>
              <p className="margin-left">
                <strong>11.2</strong> Each party must not: (i) use any of the Confidential Information except to the
                extent necessary to exercise its rights and perform its obligations under this Agreement; (ii)
                jeopardise, counteract and prohibit the development and success of the other party by disclosing any of
                the Confidential Information to unauthorised third parties or competitors; or (iii) disclose any of the
                Confidential Information, provided that each party may disclose Confidential Information to the extent
                that disclosure is required: (A) by law or by order of any court or tribunal of competent jurisdiction;
                (B) by any Government Agency, stock exchange or other regulatory body; or (C) to its personnel and
                advisors, where the party informs the recipient of the obligations in relation to the Confidential
                Information under this Agreement; or (D) by EVA.AI to payment processing companies or credit providers
                for due diligence or operational reasons.
              </p>
              <p className="margin-left">
                <strong>11.3</strong> If a party is required to make a disclosure under this clause, that party must:
                (i) to the extent possible, notify the other party if it anticipates that it may be required to disclose
                any of the Confidential Information; and (ii) only disclose Confidential Information to the extent
                necessary to comply.
              </p>
              <p className="margin-left">
                <strong>11.4</strong> The obligations under this clause continue in full force and effect after this
                Agreement ends.
              </p>
              <h4>12 DATA PROCESSING & PROTECTION</h4>
              <p className="margin-left">
                <strong>12.1</strong> Each party warrants and represents that it has adopted and implements a privacy
                policy compliant with the requirements under the Data Protection Act 1998 and other applicable
                legislation in respect of all personal data provided to the other party in connection with the Software
                or otherwise under this Agreement. Without limitation, all necessary consent will have been obtained by
                the Client from individuals for the purposes of Users using the Software.
              </p>
              <p className="margin-left">
                <strong>12.2</strong> The Client or the User (as the case may be) shall own all right, title and
                interest in and to all of the data inputted into the Software and shall have sole responsibility for the
                legality, reliability, integrity, accuracy and quality of such data.
              </p>
              <p className="margin-left">
                <strong>12.3</strong> If EVA.AI processes any personal data on behalf of the Client when providing the
                Software, the parties record their intention that the Client shall be the data controller and EVA.AI
                shall be a data processor and in any such case:
                <ul>
                  <li>
                    <strong>(a)</strong> Unless expressly agreed otherwise, the Client acknowledges and agrees that the
                    personal data may be transferred or stored outside the EEA or the country where the Client and the
                    Users are located in order to carry out the Services and EVA.AI's other obligations under this
                    agreement;
                  </li>
                  <li>
                    <strong>(b)</strong> The Client warrants that the relevant personal data can be transferred to
                    EVA.AI such that EVA.AI may lawfully use, process and transfer the personal data on behalf of the
                    Client;
                  </li>
                  <li>
                    <strong>(c)</strong> The Client shall ensure that the relevant third parties have been informed of,
                    and have given their consent to, such use, processing, and transfer as required by all applicable
                    data protection legislation;
                  </li>
                  <li>
                    <strong>(d)</strong> EVA.AI shall process the personal data only in accordance with the terms of the
                    Agreement and any lawful instructions reasonably given by the Client from time to time; and
                  </li>
                  <li>
                    <strong>(e)</strong> Each party shall take appropriate technical and organisational measures against
                    unauthorised or unlawful processing of the personal data or its accidental loss, destruction or
                    damage.
                  </li>
                </ul>
              </p>
              <h4>13 TERMINATION & FORCE MAJEURE</h4>
              <p className="margin-left">
                <strong>13.1</strong> EVA.AI may terminate the License summarily by giving the Client 7 days written
                notice if the Client: (i) Has not remedied a breach after being given at least 30 days notice; (ii) Has
                committed a breach incapable of remedy; (iii) Is insolvent, bankrupt or otherwise incapable of paying
                its debts; or (iv) Has been appointed a controller, receiver or other administrator. Upon any
                termination, the Client must pay any outstanding Fees and charges payable under this Agreement. The
                parties acknowledge that any accrued rights shall not be affected and shall survive as necessary for the
                enforcement and discharge of such liabilities.
              </p>
              <p className="margin-left">
                <strong>13.2</strong> EVA.AI shall not be liable for any failure or delay to performance of obligations
                under the Agreement if such failure or delay results from any cause that is beyond the reasonable
                control of EVA.AI including power failure, industrial action, civil unrest, fire, flood, storms,
                earthquakes, acts of terrorism, acts of war, governmental action or any other event that is beyond the
                control of EVA.AI. In the event that either party cannot perform their obligations hereunder as a result
                of force majeure for a continuous period of 2 months, the other party may at its discretion terminate
                the Agreement by written notice at the end of that period.
              </p>
              <p className="margin-left">
                <strong>13.3</strong> Termination of this agreement shall not affect any rights, remedies, obligations
                or liabilities of the parties that have accrued up to the date of termination, including the right to
                claim damages in respect of any breach of the agreement which existed at or before the date of
                termination.
              </p>
              <h4>14 GENERAL</h4>
              <p className="margin-left">
                <strong>14.1</strong> <strong>Amendment.</strong> These Conditions can only be amended, supplemented,
                replaced or novated by another agreement executed by the parties.
              </p>
              <p className="margin-left">
                <strong>14.2 </strong>
                <strong> Assignment.</strong> EVA.AI may assign or subcontract its obligations under this Agreement at
                its discretion. The Client may only assign or otherwise create an interest in their rights under this
                Agreement to Agencies with the written consent of EVA.AI.
              </p>
              <p className="margin-left">
                <strong>14.3</strong> <strong>Third Parties:</strong> EVA.AI may engage third parties to perform
                services relating to the Software and the Client consents to any arrangements entered into by EVA.AI
                with such third parties.
              </p>
              <p className="margin-left">
                <strong>14.4</strong> <strong>Non-Compete & Non-Solicitation:</strong> For the duration of the License
                and for 12 months after termination, the Client must not: (i) be directly or indirectly involved within
                the Territory in the development or distribution of any product which has functionality the same as or
                similar to the Software; nor (ii) solicit for employment either directly or indirectly, any person who
                is employed or contracted by EVA.AI. 6.5Any consent given by LUCKLYING for this purpose shall be subject
                to payment to EVA.AI of a sum equivalent to 20% of the higher of the then current annual remuneration of
                the relevant employee or sub-contractor or the annual remuneration to be paid by the Client.
              </p>
              <p className="margin-left">
                <strong>14.5</strong> <strong>Entire Agreement.</strong> Each party acknowledges that it has not relied
                on any representation, warranty or statement made by any other party, other than as set out in the
                Agreement.
              </p>
              <p className="margin-left">
                <strong>14.6</strong> <strong>Relationship.</strong> The relationship of the parties to this Agreement
                does not form any joint venture, partnership, employment, trust or agency. EVA.AI is an independent
                contractor to the Client and the Software is provided for the sole benefit of the Client unless
                expressly agreed otherwise.
              </p>
              <p className="margin-left">
                <strong>14.7 </strong> <strong>Third party rights.</strong> A person who is not a party to this
                Agreement has no right under the Contracts (Rights of Third Parties) Act 1999 to enforce this Agreement,
                provided that this clause does not affect a right or remedy of a person which otherwise exists or is
                available.
              </p>
              <p className="margin-left">
                <strong>14.8</strong> <strong>Waiver.</strong> No clause of this Agreement will be deemed waived and no
                breach excused unless such waiver or consent is provided in writing. A single or partial exercise of a
                right, power or remedy does not prevent another or further exercise of that or another right, power or
                remedy.
              </p>
              <p className="margin-left">
                <strong>14.9</strong> <strong>Further Action.</strong> Each party must do anything reasonably required
                (including executing agreements and documents) to give full effect to this Agreement, including in
                connection with any claim or proceedings brought against a part as a result of any breach of this
                Agreement.
              </p>
              <p className="margin-left">
                <strong>14.10 </strong> <strong>Liability for Expenses.</strong> Each party must pay for its own
                expenses incurred in negotiating, executing, stamping and registering this Agreement.
              </p>
              <p className="margin-left">
                <strong>14.11</strong> <strong>Severability.</strong> Any provision in these Conditions, which are
                invalid or unenforceable, will be ineffective to the extent of the invalidity or unenforceability
                without affecting the remaining provisions.
              </p>
              <p className="margin-left">
                <strong>14.12</strong> <strong>Survival.</strong> Any provision of these Conditions that expressly or by
                its nature intended to come into or continue in force on or after termination or expiry of this
                agreement shall remain in full force and effect.
              </p>
              <p className="margin-left">
                <strong>14.13</strong> <strong>Notices:</strong> The parties acknowledge and agree that any consent,
                notice or communication under the Agreement is effective if conveyed by electronic communication, having
                the meaning given in the Electronic Communications Act 2000 and Electronic Signatures Regulations 2002.
              </p>
              <p className="margin-left">
                <strong>14.14</strong> <strong>Interpretation.</strong> The following rules apply unless the context
                requires otherwise:
                <ul>
                  <li>
                    <strong>(a)</strong> Headings are only for convenience and do not affect interpretation.
                  </li>
                  <li>
                    <strong>(b)</strong> The singular includes the plural and the opposite also applies.
                  </li>
                  <li>
                    <strong>(c)</strong> If a word or phrase is defined, any other grammatical form of that word or
                    phrase has a corresponding meaning.
                  </li>
                  <li>
                    <strong>(d)</strong> A reference to a clause refers to clauses in this Agreement.
                  </li>
                  <li>
                    <strong>(e)</strong> A reference to legislation is to the latest version of the legislation as
                    amended, reenacted or replaced, and includes any subordinate legislation issued under it.
                  </li>
                  <li>
                    <strong>(f)</strong> Mentioning anything after includes, including, or similar expressions, does not
                    limit anything else that might be included.
                  </li>
                  <li>
                    <strong>(g)</strong> A reference to a party to this Agreement or another agreement or document
                    includes that party and anyone else that might be included.
                  </li>
                  <li>
                    <strong>(g)</strong> A reference to a person, corporation, trust, partnership, unincorporated body
                    or other entity includes any of them.
                  </li>
                  <li>
                    <strong>(g)</strong> A reference to information is to information of any kind in any form or medium,
                    whether formal or informal, written or unwritten, for example, computer software or programs, data,
                    drawings, ideas, knowledge, procedures, source codes or object codes, technology or trade secrets.
                  </li>
                </ul>
              </p>
              <p className="margin-left">
                <strong>14.15 </strong> <strong>Governing Law.</strong> These Conditions, the Agreement, any
                non-contractual and other matters relating to the Software and Services is governed by the laws of
                England & Wales. Each of the parties hereby submits to the non-exclusive jurisdiction of courts in
                England.
              </p>
              <h4>END CONDITIONS</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={this.ok} className="btn btn-sm btn-primary">
              Accept
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import SalaryFormatter from '@eva/emf/app/shared/ui/SalaryFormatter';
import { salaryRateLabel } from 'shared/functions';

// eslint-disable-next-line import/no-default-export
export default class CardCurrentlyWorking extends React.Component<any, any> {
  render() {
    const { settings } = this.context;
    const { entity, jobEmploymentType } = this.props;

    const { currentPosition = {}, currentSalary = {}, workLocation = {} } = entity;

    const currencyValue = get(settings, 'currency.value', '£');
    const features = settings.features;

    return (
      <div className="section">
        <h3 className="text-primary">{translate('My work profile')}</h3>
        <h4 className="text-primary">{translate('Currently working')}</h4>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <label className="control-label">{translate('Current position')}</label>
              <div>{currentPosition.position}</div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <label className="control-label">{translate('Current employer')}</label>
              <div>{currentPosition.employer}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <label className="control-label">
                {translate('Current {{salaryLabel}}', {
                  salaryLabel: salaryRateLabel(jobEmploymentType),
                })}
              </label>
              <div>
                <SalaryFormatter currencyValue={currencyValue} salary={currentSalary.salary} withCurrency />
              </div>
            </div>
          </div>
        </div>
        {features.ui.candidateProfile.workLocation && workLocation && (
          <div>
            <hr className="dotted" />
            <div className="form-group candidate-address">
              <label className="control-label">{translate('Work location')}</label>
              <div>{workLocation.displayAddress}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
CardCurrentlyWorking.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
CardCurrentlyWorking.propTypes = {
  entity: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  jobEmploymentType: PropTypes.string,
};

import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

import { syntaxHighlight } from 'shared/functions';
import { logInfo } from '@eva/emf/app/shared/functions';
import './ModalSystemError.css';

export class ModalSystemError extends React.PureComponent<any, any> {
  unmounted: boolean;
  resolve: any;
  promise: any;
  state = {
    show: false,
    error: '',
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  ok = () => {
    this.setState({ show: false });
    return this.resolve();
  };

  open(error) {
    logInfo(error);
    if (this.unmounted) {
      return;
    }
    this.setState({
      show: true,
      error,
    });

    this.promise = new Promise((resolve) => (this.resolve = resolve));
    return this.promise;
  }

  render() {
    const { show, error } = this.state;

    return (
      <Modal show={show} onHide={this.ok} bsSize="large">
        <form onSubmit={this.ok}>
          <Modal.Header closeButton>
            <Modal.Title>System Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {typeof error === 'object' && <pre dangerouslySetInnerHTML={syntaxHighlight(error)} />}
            {typeof error !== 'object' && error}
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={this.ok} className="btn btn-primary">
              {translate('OK')}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

import JobDetails from 'containers/JobDetails';

import type { FC } from 'react';
import type { JobDetailsWrapperType } from './types';

export const JobDetailsWrapper: FC<JobDetailsWrapperType> = (props) => {
  const { match, socket } = props;

  if (!match) {
    return <div>Error: Job code is not defined.</div>;
  }

  return <JobDetails jobCode={match.params.jobCode} socket={socket} wrapped />;
};

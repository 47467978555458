import get from 'lodash/get';

import { normalizeSalaryValue, nullifySalary, objectifySalary, textToBoolean } from 'shared/functions';

import { profileSections } from 'containers/CandidatePaneNew/constants';
import {
  loadContactInitialValues,
  loadEmploymentInitialValues,
  prepareContactValues,
  prepareEmploymentValues,
  prepareValues,
} from 'containers/CandidatePaneNew/sectionsFunctions';

import { candidateOperations } from '../../shared/operations';
import { SkillTaxonomiesTreeList } from '../../widgets/SkillTaxonomies/SkillTaxonomiesTreeList';

import PreviewSkills from './PreviewSkills';
import CardLanguages from './CardLanguages';
import CardAvailabilityDetails from './CardAvailabilityDetails';
import CardCvAndAttachments from './CardCvAndAttachments';
import CardContactInformation from './CardContactInformation';
import CardCoverLetter from './CardCoverLetter';
import CardCurrentlyWorking from './CardCurrentlyWorking';
import CardGeneralInformation from './CardGeneralInformation';
import CardEducation from './CardEducation';
import CardLookingFor from './CardLookingFor';
import CardPermanentAvailability from './CardPermanentAvailability';
import CardSettings from './CardSettings';
import CardSocialLinks from './CardSocialLinks';
import CardWorkHistory from './CardWorkHistory';
import FormAvailabilityDetails from './FormAvailabilityDetails';
import FormContactDetails from './FormContactDetails';
import FormCoverLetter from './FormCoverLetter';
import FormCurrentlyWorking from './FormCurrentlyWorking';
import FormGeneralInformation from './FormGeneralInformation';
import FormLookingFor from './FormLookingFor';
import FormSocialLinks from './FormSocialLinks';
import PreviewCvAndAttachments from './PreviewCvAndAttachments';
import PreviewEducation from './PreviewEducation';
import PreviewHeader from './PreviewHeader';
import PreviewMyDetails from './PreviewMyDetails';
import PreviewSettings from './PreviewSettings';
import PreviewWorkHistory from './PreviewWorkHistory';
import PreviewWorkProfile from './PreviewWorkProfile';
import { CardCustomFields } from './CardCustomFields';
import { PreviewCustomFields } from './PreviewCustomFields';
import { existsFields } from './constants';

export const candidateProfileSectionKeys = {
  myDetails: 'myDetails',
  workHistory: 'workHistory',
  workProfile: 'workProfile',
  education: 'education',
  cvAndAttachments: 'cvAndAttachments',
  skills: 'skills',
  customFields: 'customFields',
  settings: 'settings',
};

export const getCandidateProfileSections = () => [
  {
    sectionKey: candidateProfileSectionKeys.myDetails,
    icon: 'll-icon ll-info',
    label: translate('My details'),
    Preview: PreviewMyDetails,
    paneSettings: [
      {
        Cards: PreviewHeader,
      },
      {
        Cards: CardGeneralInformation,
        Forms: FormGeneralInformation,
        viewPermission: 'frontend-myProfile-basicData-view',
        editPermission: 'frontend-myProfile-basicData-update',
        loadInitialValues: (entity) => {
          const entityCopy = JSON.parse(
            JSON.stringify(
              [
                profileSections.dateOfBirth,
                profileSections.name,
                profileSections.nationality,
                profileSections.nationalInsuranceNumber,
                profileSections.drivingLicense,
                profileSections.rightToWork,
              ].reduce((prev, cur) => ({ ...prev, [cur]: entity[cur] }), {}),
            ),
          );

          Object.keys(entityCopy).forEach((key) => delete entityCopy[key].metadata);

          if (entityCopy.nationality) {
            entityCopy.nationality = { nationalityId: entityCopy.nationality.nationalityId };
          }
          if ((entityCopy.noticePeriod || {}).noticePeriod) {
            entityCopy.noticePeriod.noticePeriod = {
              noticePeriodId: entityCopy.noticePeriod.noticePeriod.noticePeriodId,
            };
          }

          existsFields.forEach((existsField) => {
            if (get(entityCopy, `${existsField}.exists`) !== undefined) {
              entityCopy[existsField].exists = `${entityCopy[existsField].exists}`;
            }
          });

          return entityCopy;
        },
        prepareValues: (values) => {
          values.dateOfBirth.dateOfBirth = values.dateOfBirth.dateOfBirth || null;
          existsFields.forEach((existsField) => {
            if (get(values, `${existsField}.exists`)) {
              // @ts-expect-error
              values[existsField].exists = textToBoolean(values[existsField].exists);
            }
          });
          return prepareValues(values);
        },
      },
      {
        Cards: CardContactInformation,
        Forms: FormContactDetails,
        viewPermission: 'frontend-myProfile-contactInformation-view',
        editPermission: 'frontend-myProfile-contactInformation-update',
        loadInitialValues: loadContactInitialValues,
        prepareValues: prepareContactValues,
      },
      {
        Cards: CardSocialLinks,
        Forms: FormSocialLinks,
        viewPermission: 'myProfile-socialMediaLinks-view',
        editPermission: 'myProfile-socialMediaLinks-update',
        loadInitialValues: ({ socialMediaLinks }) => ({ socialMediaLinks }),
      },
      {
        Cards: CardCoverLetter,
        Forms: FormCoverLetter,
        viewPermission: 'myProfile-coverLetter-view',
        editPermission: 'myProfile-coverLetter-update',
        loadInitialValues: ({ coverLetter }) => ({ coverLetter }),
        prepareValues: (values) => ({
          coverLetter: {
            coverLetter: values.coverLetter.coverLetter || null,
          },
        }),
      },
    ],
  },
  {
    sectionKey: candidateProfileSectionKeys.skills,
    icon: 'fa fa-id-card-o',
    label: translate('My skills and languages'),
    Preview: PreviewSkills,
    paneSettings: [
      {
        Cards: SkillTaxonomiesTreeList,
        viewPermission: candidateOperations.myProfileSkillsViewAllowed,
      },
      {
        Cards: CardLanguages,
        viewPermission: 'myProfile-languages-view',
      },
    ],
  },
  {
    sectionKey: candidateProfileSectionKeys.workProfile,
    viewPermission: 'frontend-myWorkProfile-view',
    icon: 'lnr lnr-briefcase',
    label: translate('My work profile'),
    Preview: PreviewWorkProfile,
    paneSettings: [
      {
        Cards: CardCurrentlyWorking,
        Forms: FormCurrentlyWorking,
        viewPermission: 'frontend-myProfile-currentWorkProfile-view',
        editPermission: 'frontend-myProfile-currentWorkProfile-update',
        loadInitialValues: loadEmploymentInitialValues,
        prepareValues: prepareEmploymentValues,
      },
      {
        Cards: CardAvailabilityDetails,
        Forms: FormAvailabilityDetails,
        viewPermission: 'frontend-myProfile-availabilityDetails-view',
        editPermission: 'frontend-myProfile-availabilityDetails-update',
        loadInitialValues: ({ noticePeriod, availability: { currentAvailability, availabilityFollowUp } }) => ({
          noticePeriod,
          availability: {
            currentAvailability: `${currentAvailability}`,
            availabilityFollowUp:
              typeof availabilityFollowUp === 'string' ? availabilityFollowUp.substr(0, 10) : availabilityFollowUp,
          },
        }),
        prepareValues: ({ noticePeriod, availability: { currentAvailability, availabilityFollowUp } }) => {
          const noticePeriodId = get(noticePeriod, 'noticePeriod.noticePeriodId', null);
          let updatedNoticePeriod = null;
          if (noticePeriod.fixedDate) {
            updatedNoticePeriod = {
              noticePeriod: null,
              fixedDate: noticePeriod.fixedDate,
            };
          } else {
            updatedNoticePeriod = {
              noticePeriod: {
                noticePeriodId,
              },
              fixedDate: null,
            };
          }
          return {
            noticePeriod: updatedNoticePeriod,
            availability: {
              currentAvailability: textToBoolean(currentAvailability, null),
              availabilityFollowUp: availabilityFollowUp || null,
            },
          };
        },
      },
      {
        Cards: CardLookingFor,
        Forms: FormLookingFor,
        viewPermission: 'frontend-myProfile-lookingForDetails-view',
        editPermission: 'frontend-myProfile-lookingForDetails-update',
        loadInitialValues: (entity) => {
          const {
            desiredPositions: { description },
            employmentTypes: { items },
            desiredSalary,
            workLocationPreference: { workLocationPreferenceTypes },
          } = entity;

          return {
            desiredPositions: {
              description,
            },
            employmentTypes: {
              items,
            },
            desiredSalary: objectifySalary(desiredSalary),
            workLocationPreference: {
              workLocationPreferenceTypes: workLocationPreferenceTypes.map((workLocationPreferenceType) =>
                typeof workLocationPreferenceType === 'object'
                  ? workLocationPreferenceType
                  : { code: workLocationPreferenceType },
              ),
            },
          };
        },
        prepareValues: (entity) => {
          const { desiredPositions, employmentTypes, desiredSalary, workLocationPreference } = entity;
          return {
            desiredPositions,
            employmentTypes: {
              items: employmentTypes.items.map(({ code }) => ({ code })),
            },
            desiredSalary: normalizeSalaryValue(nullifySalary(desiredSalary)),
            workLocationPreference: {
              workLocationPreferenceTypes: workLocationPreference.workLocationPreferenceTypes.map(
                (workLocationPreferenceType) =>
                  typeof workLocationPreferenceType === 'object'
                    ? workLocationPreferenceType.code
                    : workLocationPreferenceType,
              ),
            },
          };
        },
      },
      {
        Cards: CardPermanentAvailability,
        viewPermission: 'myProfile-calendarWorkingHours-view',
      },
    ],
  },
  {
    sectionKey: candidateProfileSectionKeys.workHistory,
    icon: 'lnr lnr-history',
    viewPermission: 'myProfile-workHistory-view',
    label: translate('Work history'),
    Preview: PreviewWorkHistory,
    paneSettings: [
      {
        Cards: CardWorkHistory,
      },
    ],
  },
  {
    sectionKey: candidateProfileSectionKeys.education,
    icon: 'lnr lnr-graduation-hat',
    viewPermission: 'myProfile-education-view',
    label: translate('Education'),
    Preview: PreviewEducation,
    paneSettings: [
      {
        Cards: CardEducation,
      },
    ],
  },
  {
    sectionKey: candidateProfileSectionKeys.customFields,
    icon: 'fa fa-list',
    label: translate('Additional information'),
    viewPermission: 'frontend-myProfile-customFields-view',
    Preview: PreviewCustomFields,
    paneSettings: [
      {
        Cards: CardCustomFields,
      },
    ],
  },
  {
    sectionKey: candidateProfileSectionKeys.cvAndAttachments,
    icon: 'lnr lnr-paperclip',
    label: translate('CV and files'),
    Preview: PreviewCvAndAttachments,
    paneSettings: [
      {
        Cards: CardCvAndAttachments,
      },
    ],
  },
  {
    sectionKey: candidateProfileSectionKeys.settings,
    icon: 'lnr lnr-cog',
    label: translate('Settings'),
    Preview: PreviewSettings,
    paneSettings: [
      {
        Cards: CardSettings,
      },
    ],
  },
];

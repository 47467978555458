import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import PreviewGeneralWorkHistory from './PreviewGeneralWorkHistory';
import PreviewInterimWorkHistory from './PreviewInterimWorkHistory';

class PreviewWorkHistory extends React.PureComponent<any, any> {
  render() {
    const { userProfile } = this.props;

    // TODO Not this iteration
    const hasInterimWorkHistory = false; // !!userProfile.interimWorkHistory.items.length;
    const hasGeneralWorkHistory = !!get(userProfile, 'workHistory.items.length');
    const showInterimWorkHistory = hasInterimWorkHistory && !hasGeneralWorkHistory;

    return (
      <div>
        {showInterimWorkHistory && <PreviewInterimWorkHistory key="preview-interim-work-history" {...this.props} />}
        {!showInterimWorkHistory && <PreviewGeneralWorkHistory key="preview-general-work-history" {...this.props} />}
      </div>
    );
  }
}

// @ts-expect-error
PreviewWorkHistory.contextTypes = {
  socket: PropTypes.object.isRequired,
};

// @ts-expect-error
PreviewWorkHistory.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default PreviewWorkHistory;

export const processHtml = (html: string): string => {
  let updatedHtml = html.replace(/table,\s*td\s*{[^}]*color:[^}]*}/g, 'table, td { color: inherit !important; }');

  updatedHtml = updatedHtml.replace(/(<[^>]*\bclass="[^"]*\bu-row\b[^"]*"[^>]*)style="[^"]*"/gi, '$1');

  updatedHtml = updatedHtml.replace(/\.u-row\s*{[^}]*}/g, '');

  updatedHtml = updatedHtml.replace(/style="([^"]*?)font-family:[^;"]*;?/gi, 'style="$1font-family: inherit;');

  updatedHtml = updatedHtml.replace(
    /(<[^>]+style=")([^"]*?)(?!font-family: inherit;)([^"]*?")/gi,
    (match, p1, p2, p3) => {
      if (!/font-family:\s*inherit;/.test(match)) {
        return `${p1}${p2}font-family: inherit; ${p3}`;
      }
      return match;
    },
  );

  return updatedHtml;
};

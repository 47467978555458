import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

import Calendar from './Calendar';

const outputFormat = 'D MMM YYYY';

class DateSelector extends AIUIComponent {
  state = {};

  UNSAFE_componentWillMount() {
    const { answer } = this.props;
    const answerDate = moment(answer, outputFormat, true);
    this.setState({
      selectedDate: moment(answerDate.isValid() ? answerDate : undefined)
        .startOf('d')
        .format(outputFormat),
    });
  }

  submitResult() {
    // @ts-expect-error
    const { selectedDate } = this.state;
    this.setState({ submitted: true });
    this.props.resultingContent(selectedDate);
  }

  render() {
    const { editable } = this.props;
    // @ts-expect-error
    const { submitted, selectedDate } = this.state;

    const disabled = !editable || submitted;

    return (
      <div className="margin-top">
        <Calendar
          editable={editable}
          outputFormat={outputFormat}
          selectedDate={selectedDate}
          selectDate={(date) => this.setState({ selectedDate: date })}
        />
        <button
          type="submit"
          className="btn btn-primary btn-block margin-top"
          disabled={disabled}
          onClick={() => this.submitResult()}
        >
          <i className="ll-icon ll-send margin-right" />
          {translate('Send')}
        </button>
      </div>
    );
  }
}

// @ts-expect-error
DateSelector.propTypes = {
  answer: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default DateSelector;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form/immutable';

import FormAddressCore from '@eva/emf/app/containers/FormAddressCore';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';
import { validateRequired } from 'shared/functions';
import { Input } from '@eva/emf/app/shared/ui/Input';
import { TextArea } from '@eva/emf/app/shared/ui/TextArea/TextArea';

import CalendarSelector from './components/CalendarSelector';
import { validateFromAndTo } from './functions';

const validate = (immutableValues, props) => {
  const values = immutableValues.toJS();
  const errors = {};
  if (!Object.keys(values).length) {
    return errors;
  }
  const { isCurrent, startDate, endDate } = values;
  if (props.isCurrent !== isCurrent) {
    props.updateIsCurrent(props.form, isCurrent);
  }

  validateFromAndTo(errors, startDate, endDate, isCurrent);

  return errors;
};

const FormWorkHistory = ({ isCurrent, initialValues, submitting, handleSubmit, setItemEditMode }) => {
  const [fromToTouched, setFromToTouched] = useState(false);

  return (
    <form className="edit-user" onSubmit={handleSubmit}>
      <hr className="dotted" />
      <div>
        <Field component={Input} name="title" label={translate('Position')} validate={validateRequired} />
        <Field component={Input} name="employer" label={translate('Company')} validate={validateRequired} />
        <div className="form-group">
          <label className="control-label">
            <span>
              <Field
                type="checkbox"
                label={null}
                formClass="inline-div"
                valueClass="inline-div"
                name="isCurrent"
                component={Input}
              />
            </span>
            &nbsp;{translate('I am currently working in this role')}
          </label>
        </div>
        <div>
          <label className="control-label">{translate('from')}</label>
          <Field name="startDate" touched={fromToTouched} component={CalendarSelector} setTouched={setFromToTouched} />
        </div>
        <div>
          <label className="control-label">{translate('to')}</label>
          {isCurrent && (
            <div className="margin-bottom">
              <span>{translate('Present')}</span>
            </div>
          )}
          {!isCurrent && (
            <Field name="endDate" touched={fromToTouched} component={CalendarSelector} setTouched={setFromToTouched} />
          )}
        </div>
        {/* @ts-expect-error */}
        <FormAddressCore entity={initialValues.toJS()} name="location" hideNote />
        <Field component={TextArea} name="description" label={translate('Description')} nullify />
      </div>
      <div className="text-right">
        <button type="submit" id="submit-work-history" className="btn btn-sm btn-success" disabled={submitting}>
          {submitting && <SpinnerSmall />} {translate('Submit')}
        </button>
        <button
          id="cancel-edit-work-history"
          type="button"
          className="btn btn-sm btn-default margin-left"
          disabled={submitting}
          onClick={() => {
            const { workHistoryId } = initialValues.toJS();
            setItemEditMode(workHistoryId || null, false);
          }}
        >
          {translate('Cancel')}
        </button>
      </div>
    </form>
  );
};

FormWorkHistory.propTypes = {
  initialValues: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setItemEditMode: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  // eslint-disable-line no-class-assign
  validate,
})(FormWorkHistory);

import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line import/no-default-export
export default class ErrorViewSimple extends React.Component<any, any> {
  render() {
    const { error } = this.props;

    return (
      <div
        className="container"
        style={{
          background: 'red',
          color: 'white',
        }}
      >
        <div>An error occurred</div>
        <div>{error.message}</div>
      </div>
    );
  }
}

// @ts-expect-error
ErrorViewSimple.propTypes = {
  error: PropTypes.object,
  info: PropTypes.object,
};

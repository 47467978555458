import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { renderSection } from '@eva/emf/app/shared/functions';
import { phoneTypesKeys } from 'shared/constants';
import { invalidBounce, renderEmail } from 'shared/functions';

// eslint-disable-next-line import/no-default-export
export default class CardGeneralInformation extends React.PureComponent<any, any> {
  state = {};

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  render() {
    const { isAllowedOperation } = this.context;
    const { entity } = this.props;

    // @ts-expect-error
    const { error } = this.state;

    const { phones = {}, emails = {}, address } = entity;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}

        {renderSection(
          translate('Contact information'),
          <div>
            <div className="row phones">
              {phoneTypesKeys.map((phoneTypesKey) => (
                <div key={phoneTypesKey} className="col-xs-12 col-md-6 form-group">
                  <span className="control-label" id={`${phoneTypesKey}-phone`}>
                    <span>{translate(`${startCase(phoneTypesKey)} Phone`)}</span>
                  </span>
                  {phones[phoneTypesKey] && <div>{phones[phoneTypesKey].number}</div>}
                </div>
              ))}
            </div>
            <hr className="dotted" />
            <div className="row">
              {['primary', 'secondary'].map((emailType) => (
                <div key={emailType} id={`${emailType}-email`} className="col-xs-12 col-md-6 form-group">
                  <span className="control-label">
                    <span>
                      {translate(`${startCase(emailType)} email`)}
                      <PopperTooltip overlay={translate('Invalid email')}>
                        <i
                          className={`fa fa-warning text-danger margin-left ${
                            invalidBounce(emails, emailType) ? '' : 'hide'
                          }`}
                        />
                      </PopperTooltip>
                    </span>
                  </span>
                  <div>{renderEmail(emails[emailType])}</div>
                </div>
              ))}
            </div>
            <hr className="dotted" />
            {address && isAllowedOperation('myProfile-location-view') && (
              <div className="form-group candidate-address">
                <div className="control-label">
                  {translate('Address')}
                  {get(address, 'hasError') && (
                    <PopperTooltip overlay={translate('Invalid address')}>
                      <i className="fa fa-warning text-danger margin-left" />
                    </PopperTooltip>
                  )}
                </div>
                <div>{address.displayAddress}</div>
              </div>
            )}
          </div>,
        )}
      </div>
    );
  }
}

// @ts-expect-error
CardGeneralInformation.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

// @ts-expect-error
CardGeneralInformation.propTypes = {
  entity: PropTypes.object.isRequired,
};

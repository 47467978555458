import { workflowsCandidateCodes } from 'shared/constants';

export const statusesSteps = {
  [workflowsCandidateCodes.qualifying]: 1,
  [workflowsCandidateCodes.qualifying2]: 2,
  [workflowsCandidateCodes.interviewing]: 3,
  [workflowsCandidateCodes.offered]: 3,
  [workflowsCandidateCodes.placed]: 4,
};
export const endedStatusesSteps = {
  [workflowsCandidateCodes.declined1]: 3,
  [workflowsCandidateCodes.declined2]: 3,
};
export const mainMenuKeys = {
  home: 'home',
  myProfile: 'myProfile',
  chat: 'chat',
  recommended: 'recommended',
  myJobs: 'myJobs',
};

export const mainMenuKeysCamelCased = {
  'my-jobs': 'myJobs',
  'my-profile': 'myProfile',
};

export const mainMenuKeysKebabCased = {
  myProfile: 'my-profile',
  myJobs: 'my-jobs',
};

export const restartWorkflowCodes = ['invited', 'not_invited', 'registering', 'hanged_registration'];

import { memo } from 'react';

import type { MenuRecommendedProps } from './MenuRecommended';

import MenuRecommended from './MenuRecommended';

const MenuRecommendedWidget = (props: MenuRecommendedProps) => (
  <MenuRecommended
    mobileMode={props.mobileMode}
    parentId={props.parentId}
    switchToChat={props.switchToChat}
    setCentralContent={props.setCentralContent}
  />
);

// eslint-disable-next-line import/no-default-export
export default memo(MenuRecommendedWidget);

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import moment from 'moment';

import PopperPopover from '@eva/emf/app/shared/ui/Popper/PopperPopover';
import { yearFirstDateFormat, zeroTime } from 'shared/constants';
import { copy, noop } from 'shared/functions';

import ModalInterimAvailabilityEditor from './ModalInterimAvailabilityEditor';
import { dateTypes } from './constants';
import { slotByTime, mapSlotDates } from './functions';

const slotDateFormat = `${yearFirstDateFormat} HH:mm`;
const timeToMinutes = (time) => {
  if (!time) {
    throw new Error('Wrong time!');
  }
  const splittedTime = time.substr(-5).split(':');
  return splittedTime[0] * 60 + parseInt(splittedTime[1], 0);
};
const fullTimeToMinutes = (time) => {
  if (!time) {
    throw new Error('Wrong time!');
  }
  return timeToMinutes(time.substr(-5));
};
const mergeDaySlots = (scheduleSlot, jobInterimSlots, initialInterimSlots = []) => {
  const filterSlotsByPeriod = (slot) => slot.endAt > scheduleSlot.startDate && slot.startAt < scheduleSlot.endDate;
  const jobSlots = copy(jobInterimSlots.filter(filterSlotsByPeriod));
  const candidateSlots = copy(initialInterimSlots.filter(filterSlotsByPeriod));
  [jobSlots, candidateSlots].forEach((slots) => {
    if (slots.length) {
      const firstSlot = slots[0];
      const lastSlot = slots[slots.length - 1];
      firstSlot.startAt = firstSlot.startAt < scheduleSlot.startDate ? scheduleSlot.startDate : firstSlot.startAt;
      lastSlot.endAt = lastSlot.endAt > scheduleSlot.endDate ? scheduleSlot.endDate : lastSlot.endAt;
    }
  });
  const slotsTimes = uniq([...jobSlots.flatMap(mapSlotDates), ...candidateSlots.flatMap(mapSlotDates)]).sort(
    (prev, cur) => (prev > cur ? 1 : -1),
  );
  const events = [];
  let currentJobSlot;
  let currentCandidateSlot;
  slotsTimes.forEach((slotTime) => {
    const startedJobSlot = slotByTime(jobSlots, dateTypes.startAt, slotTime);
    const startedCandidateSlot = slotByTime(candidateSlots, dateTypes.startAt, slotTime);
    const endedJobSlot = slotByTime(jobSlots, dateTypes.endAt, slotTime);
    const endedCandidateSlot = slotByTime(candidateSlots, dateTypes.endAt, slotTime);

    const currentEvent = events[events.length - 1];
    if (currentEvent) {
      currentEvent.endAt = slotTime;
    }
    currentJobSlot = endedJobSlot ? null : currentJobSlot;
    currentCandidateSlot = endedCandidateSlot ? null : currentCandidateSlot;
    currentJobSlot = startedJobSlot || currentJobSlot;
    currentCandidateSlot = startedCandidateSlot || currentCandidateSlot;
    const anyCurrentSlot = currentCandidateSlot || currentJobSlot;
    if (anyCurrentSlot) {
      const gap = !!currentJobSlot && !currentCandidateSlot;
      events.push({
        startAt: slotTime,
        availabilityType: gap ? 3 : anyCurrentSlot.availabilityType,
        overlap: !!currentJobSlot && !!currentCandidateSlot,
      });
    }
  });
  return events;
};

// eslint-disable-next-line import/no-default-export
export default class CardInterimAvailability extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    extended: false,
    schedulesWeights: [],
    interimAvailability: {},
  };
  modalInterimAvailabilityEditor: any;
  weekdaysShort: any;
  static contextTypes: { settings: PropTypes.Validator<object> };

  UNSAFE_componentWillMount() {
    const {
      profile: { interimAvailability = {} },
      modalOpener,
      updateEntity,
    } = this.props;

    this.weekdaysShort = moment.weekdaysShort();

    this.weekdaysShort.push(this.weekdaysShort.shift());
    // @ts-expect-error
    this.modalOpener = () => {
      if (modalOpener) {
        return modalOpener();
      }
      const { settings } = this.context;
      const {
        profile: { pipelines },
      } = this.props;
      this.modalInterimAvailabilityEditor
        .open(settings, this.state.interimAvailability, Array.isArray(pipelines) ? pipelines : undefined)
        .then((result) => {
          const update = { interimAvailability: result };
          this.setState(update, () => this.initAvailabilitySlots());
          if (updateEntity) {
            updateEntity(update);
          }
        }, noop);
    };
    const dayLabelFormat = 'D MMM';
    const monthFormat = 'MMMM';
    const currentMoment = moment().startOf('isoWeek');
    const todayFormatted = moment().startOf('day').format(dayLabelFormat);
    const currentMonthFormatted = moment().format(monthFormat);
    const weeks = [];
    for (let week = 0; week <= 5; week++) {
      const days = [];
      for (let day = 0; day < 7; day++) {
        const label = currentMoment.format(dayLabelFormat);
        days.push({
          label,
          startAt: currentMoment.format(yearFirstDateFormat),
          endAt: currentMoment.clone().add(1, 'day').format(yearFirstDateFormat),
          monthStart: label === currentMoment.clone().startOf('month').format(dayLabelFormat),
          currentMonth: currentMoment.format(monthFormat) === currentMonthFormatted,
          today: label === todayFormatted,
          comment: [],
        });
        currentMoment.add(1, 'day');
      }
      weeks.push(days);
    }
    this.setState(
      {
        interimAvailability,
        weeks,
        currentMonthFormatted,
      },
      () => this.initAvailabilitySlots(),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      profile,
      jobInterimAvailability,
      profile: { interimAvailability },
    } = nextProps;
    if (
      this.props.profile !== profile ||
      this.props.jobInterimAvailability !== jobInterimAvailability ||
      this.props.profile.interimAvailability !== interimAvailability
    ) {
      this.setState({ interimAvailability }, () => this.initAvailabilitySlots(nextProps));
    }
  }

  initAvailabilitySlots(props = this.props) {
    const {
      settings: {
        scheduleSlots,
        dateFormats: { timeFormat },
      },
    } = this.context;
    const { jobInterimAvailability, availabilityTypes } = props;
    const { weeks, interimAvailability } = this.state as any;

    const schedulesWeights = [];
    scheduleSlots.forEach((scheduleSlot) => {
      const scheduleStart = timeToMinutes(scheduleSlot.start);
      const scheduleEnd = timeToMinutes(scheduleSlot.end) || 24 * 60;
      const scheduleLength = scheduleEnd - scheduleStart;
      schedulesWeights.push({
        scheduleStart,
        scheduleEnd,
        scheduleLength,
        weight: Math.floor((scheduleLength / 24 / 60) * 100),
      });
    });

    const jobInterimSlots = get(jobInterimAvailability, 'slots', []);
    const formatSlotTime = (time) => moment(time, 'HH:mm').format(timeFormat);

    weeks.forEach((week) => {
      week.forEach((day) => {
        const preparedScheduleSlots = scheduleSlots.map((scheduleSlot) => {
          const startDate = moment(`${day.startAt} ${scheduleSlot.start}`).format(slotDateFormat);
          const endDate = moment(
            scheduleSlot.end === zeroTime ? day.endAt : `${day.startAt} ${scheduleSlot.end}`,
          ).format(slotDateFormat);
          return {
            ...scheduleSlot,
            startDate,
            endDate,
          };
        });

        day.slots = preparedScheduleSlots.map((preparedScheduleSlot, preparedScheduleSlotIndex) => {
          const slotWeight = (length) =>
            Math.floor((length / schedulesWeights[preparedScheduleSlotIndex].scheduleLength) * 100);
          const daySlots = [];
          let currentSlotEnd = fullTimeToMinutes(preparedScheduleSlot.startDate);
          mergeDaySlots(preparedScheduleSlot, jobInterimSlots, interimAvailability.slots).forEach((slot) => {
            const start = fullTimeToMinutes(
              slot.startAt > preparedScheduleSlot.startDate ? slot.startAt : preparedScheduleSlot.startDate,
            );
            const end =
              fullTimeToMinutes(
                slot.endAt < preparedScheduleSlot.endDate ? slot.endAt : preparedScheduleSlot.endDate,
              ) || 24 * 60;
            if (currentSlotEnd < start) {
              daySlots.push({
                availabilityType: 0,
                start: currentSlotEnd,
                end: start,
                weight: slotWeight(start - currentSlotEnd),
              });
            }
            daySlots.push({
              ...slot,
              start,
              end,
              weight: slotWeight(end - start),
            });
            currentSlotEnd = end;
          });
          const end = fullTimeToMinutes(preparedScheduleSlot.endDate) || 24 * 60;
          if (currentSlotEnd < end) {
            daySlots.push({
              availabilityType: 0,
              start: currentSlotEnd,
              end,
              weight: slotWeight(end - currentSlotEnd),
            });
          }
          return daySlots;
        });

        day.comment = get(interimAvailability, 'slots', [])
          .filter((slot) => slot.startAt < day.endAt && slot.endAt > day.startAt)
          .map((slot) => {
            const slotStart = formatSlotTime(slot.startAt > day.startAt ? slot.startAt.slice(11) : '00:00');
            const slotEnd = formatSlotTime(slot.endAt < day.endAt ? slot.endAt.slice(11) : '00:00');
            return `${slotStart} - ${slotEnd} - ${availabilityTypes[slot.availabilityType].event}`;
          });
      });
    });

    this.setState({
      weeks,
      schedulesWeights,
    });
  }

  drawAvailabilityDay(day, key) {
    const { availabilityTypes } = this.props;
    const { schedulesWeights } = this.state;

    return (
      <div
        className={`a-day a-date ${day.today ? 'current-date' : ''} ${day.currentMonth ? 'current-month' : ''}`}
        id={`day ${day.label}`}
        key={`day ${day.label}`}
      >
        <small>{day.label}</small>
        <PopperPopover
          key={`day ${day.label}`}
          placement="top"
          overlay={
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {day.comment.length ? day.comment.join('\n') : availabilityTypes[0].event}
            </div>
          }
        >
          <div className="a-item">
            {schedulesWeights.map((schedulesWeight, schedulesWeightIndex) => (
              <span
                key={schedulesWeightIndex}
                className="with-shadow"
                style={{
                  width: `${schedulesWeight.weight}%`,
                  background: availabilityTypes[0].lightenColor,
                  display: 'inline-block',
                }}
              >
                {day.slots[schedulesWeightIndex].map((daySlot, daySlotIndex) => (
                  <span
                    key={daySlotIndex}
                    className={`${daySlot.availabilityType ? '' : 'no-background'}`}
                    style={{
                      background:
                        availabilityTypes[daySlot.availabilityType][daySlot.overlap ? 'color' : 'lightenColor'],
                      width: `${daySlot.weight}%`,
                      display: 'inline-block',
                    }}
                  >
                    &nbsp;
                  </span>
                ))}
              </span>
            ))}
          </div>
        </PopperPopover>
      </div>
    );
  }

  render() {
    const { extended, availabilityTypes, availabilityLegendBlocks, selectedJob, showDescription } = this.props;

    const {
      currentMonthFormatted,
      interimAvailability: { description },
      weeks,
    } = this.state as any;

    return (
      <div>
        <ModalInterimAvailabilityEditor
          ref={(ref) => (this.modalInterimAvailabilityEditor = ref)}
          availabilityTypes={availabilityTypes}
          availabilityLegendBlocks={availabilityLegendBlocks}
          job={selectedJob}
        />
        <div className="collapse-panel">
          <div className="availability-line-wrapper">
            <div className="pull-left a-month">{currentMonthFormatted}</div>
            <div className="clearfix" />
            {this.weekdaysShort.map((weekdayShort) => (
              <div id={weekdayShort} key={weekdayShort} className="a-day" style={{ minHeight: 0 }}>
                <small>{weekdayShort}</small>
              </div>
            ))}
            <div className="clearfix" />
            {weeks.map((week, index) => (
              <div key={index}>
                {week.map((day) => this.drawAvailabilityDay(day, `${index} ${day.label}`))}
                <div className="clearfix" />
              </div>
            ))}
          </div>
          <div className="clearfix" />
          <div className={`${extended && showDescription ? 'margin-top' : 'hide'}`}>
            <div className="row margin-bottom" id="interim-availability-description">
              <div className="col-xs-4 text-right">
                <label className="control-label">{translate('Calendar note')}</label>
              </div>
              <div className="col-xs-8">{description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardInterimAvailability.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
CardInterimAvailability.propTypes = {
  profile: PropTypes.object.isRequired,
  jobInterimAvailability: PropTypes.object,
  availabilityTypes: PropTypes.array.isRequired,
  availabilityLegendBlocks: PropTypes.array.isRequired,
  modalOpener: PropTypes.func,
  showDescription: PropTypes.bool,
  selectedJob: PropTypes.object,
  updateEntity: PropTypes.func,
  extended: PropTypes.bool.isRequired,
};

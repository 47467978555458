import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
// @ts-expect-error
import { submit } from 'redux-form/immutable';

import { requestBackend } from '@eva/emf/app/utils/request';
import { processValidationErrors } from '@eva/emf/app/shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { copy, whitelistLocation, whitelistName, whitelistPayment, whitelistSalary } from 'shared/functions';

import { renderCardEditButton, renderTopSubmitBlock, renderBottomSubmitBlock } from './functions';

const entitiesDescriptor = {
  entityIdKey: 'userId',
};

const returnValues = (values) => values;
const promiseResolve = () => Promise.resolve();

// eslint-disable-next-line import/no-default-export
export default class SectionPaneShared extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    initialValues: {},
  };
  static contextTypes: { isAllowedOperation: PropTypes.Validator<(...args: any[]) => any> };

  UNSAFE_componentWillMount() {
    this.loadInitialValues();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity, paneSettings } = nextProps;

    if (this.props.entity !== entity || this.props.paneSettings !== paneSettings) {
      this.loadInitialValues(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  onSubmit = (immutableValues) => {
    const { entity, entitiesType, switchEditMode, updateEntity, paneSettings } = this.props;
    const { entityIdKey } = entitiesDescriptor;
    const {
      onSubmit,
      onBeforeSubmit = promiseResolve,
      submitPath = `/${entitiesType}`,
      prepareValues = returnValues,
      submitBody = {},
    } = paneSettings;

    if (!(onSubmit || submitPath)) {
      throw new Error('Wrong paneSettings object - onSubmit or submitPath missing!');
    }

    const jsValues = immutableValues.toJS ? immutableValues.toJS() : immutableValues;
    const values = prepareValues(copy(jsValues));
    values.name = whitelistName(values.name);

    if (values.location) {
      values.location = whitelistLocation(values.location);
    }
    if (values.address) {
      values.address = whitelistLocation(values.address);
    }
    if (values.currentSalary?.salary) {
      values.currentSalary.salary = whitelistSalary(get(values, 'currentSalary.salary'));
    }
    if (values.desiredSalary?.salary) {
      values.desiredSalary.salary = whitelistSalary(get(values, 'desiredSalary.salary'));
    }

    if (values.payment) {
      values.payment = whitelistPayment(values.payment);
      values.payment = Object.values(values.payment).find((item) => item) ? values.payment : null;
    }

    // We need to load object, but NOT put it in the list, but without change it will still be object
    if (values.companyId && typeof values.companyId === 'object') {
      values.companyId = values.companyId.companyId;
    }
    if (values.employerId && typeof values.employerId === 'object') {
      values.employerId = values.employerId.userId || null; // Null for empty employer - remove after BE update
    }

    if (values.tags) {
      values.tags = values.tags.map(({ tagId }) => tagId);
    }

    delete values.created; // See created: !entityId below

    this.setState({ submitting: true });

    const entityId = entity[entityIdKey];

    return onBeforeSubmit(this, values).then(
      () =>
        requestBackend('/my/candidate-profile', {
          method: 'PUT',
          body: JSON.stringify({
            ...submitBody,
            ...values,
          }),
        }).then(
          (updatedEntity) => {
            // @ts-expect-error
            if (!this.unmounted) {
              this.setState({ submitting: false });
            }
            updateEntity({
              [entityIdKey]: entityId,
              created: !entityId,
              // @ts-expect-error
              ...updatedEntity,
            });
            switchEditMode('');
          },
          (err) => {
            // @ts-expect-error
            if (this.unmounted) {
              return;
            }
            this.setState({ submitting: false });
            processValidationErrors(values, jsValues)(err);
          },
        ),
      () => {
        // @ts-expect-error
        if (this.unmounted) {
          return;
        }
        this.setState({ submitting: false });
      },
    );
  };

  submitForm = (evt) => {
    const { formName } = this.props;
    evt.preventDefault();
    // @ts-expect-error
    window.store.dispatch(submit(formName));
  };

  loadInitialValues(props = this.props) {
    const { isAllowedOperation } = this.context;
    const { entity, paneSettings } = props;
    const { Forms, loadInitialValues } = paneSettings;

    if (!Forms) {
      return;
    } else if (!paneSettings) {
      throw new Error('paneSettings object missing!');
    } else if (paneSettings.viewPermission && !isAllowedOperation(paneSettings.viewPermission)) {
      return;
    }

    this.setState({
      entity,
      initialValues: loadInitialValues ? loadInitialValues(entity) : {},
    });
  }

  render() {
    const { isAllowedOperation } = this.context;
    const {
      settings,
      entity,
      selectedEntity,
      formName,
      paneSettings,
      entitiesType,
      editOptions,
      updateEntity,
      editMode,
      mobileMode,
      closePane,
      loadEntity,
    } = this.props;
    const { initialValues, submitting } = this.state as any;
    const { entityIdKey } = entitiesDescriptor;

    const { Cards, Forms, viewPermission, editPermission } = paneSettings;

    if (viewPermission && !isAllowedOperation(viewPermission)) {
      return null;
    }

    const switchEditMode =
      Forms && (!editPermission || isAllowedOperation(editPermission)) ? this.props.switchEditMode : null;
    const submitArgs = [submitting, switchEditMode, undefined, !entity[entityIdKey] && closePane];

    return (
      <div className="cards-candidate">
        {!editMode && Cards && (
          <div>
            {switchEditMode && renderCardEditButton(switchEditMode)}
            <Cards
              settings={settings}
              entity={entity}
              selectedEntity={selectedEntity}
              entitiesType={entitiesType}
              switchEditMode={switchEditMode}
              editOptions={editOptions}
              updateEntity={updateEntity}
              loadEntity={loadEntity}
              mobileMode={mobileMode}
            />
          </div>
        )}
        {editMode && Forms && (
          <form className="edit-user" onSubmit={this.submitForm} style={{ opacity: submitting ? 0.5 : 1 }}>
            {submitting && (
              <div className="text-center padding">
                <Spinner />
              </div>
            )}
            {/* @ts-expect-error */}
            {renderTopSubmitBlock(...submitArgs)}
            <Forms
              form={formName}
              onSubmit={this.onSubmit}
              entitiesType={entitiesType}
              initialValues={initialValues}
              switchEditMode={switchEditMode}
              editOptions={editOptions}
              entity={entity}
              updateEntity={updateEntity}
              mobileMode={mobileMode}
            />
            {/* @ts-expect-error */}
            {renderBottomSubmitBlock(...submitArgs)}
          </form>
        )}
      </div>
    );
  }
}

SectionPaneShared.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

// @ts-expect-error
SectionPaneShared.propTypes = {
  entity: PropTypes.object.isRequired,
  selectedEntity: PropTypes.object,
  settings: PropTypes.object,
  paneSettings: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  listId: PropTypes.number,
  configSettings: PropTypes.object,
  parentOptions: PropTypes.object,
  entitiesType: PropTypes.string,
  editOptions: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
  editMode: PropTypes.string.isRequired,
  mobileMode: PropTypes.bool,
  loadEntity: PropTypes.func,
  switchEditMode: PropTypes.func,
  closePane: PropTypes.func,
};

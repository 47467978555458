import get from 'lodash/get';

import { employmentTypes } from 'shared/constants';

export const slotByTime = (slots, timeType, slotTime) => slots.find((item) => item[timeType] === slotTime);
export const mapSlotTimes = (item) => [item.startTime, item.endTime];
export const mapSlotDates = (item) => [item.startAt, item.endAt];

const filterPipeline = (pipeline) =>
  pipeline.pipelineId &&
  !pipeline.workflowProcess?.workflowState.isTerminal &&
  get(pipeline, 'job.employmentType.code') === employmentTypes.interimTemporary;

export const getJobsFromCandidatePipeline = (candidatePipelines) =>
  candidatePipelines.filter(filterPipeline).map((pipeline) => pipeline.job);

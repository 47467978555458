import { employmentTypes } from 'shared/constants';

export const profilesSourcesIds = {
  sovren: 1,
  catsResume: 2,
  catsWebhook: 3,
  candidate: 4,
  agent: 5,
  totaljobs: 6,
  jobsite: 15,
  eMedCareers: 17,
};

export const socialProviders = ['linkedIn', 'facebook'];

export const employmentIcons = {
  [employmentTypes.permanentPartTime]: 'hourglass-half',
  [employmentTypes.permanentFullTime]: 'hourglass',
  [employmentTypes.interimTemporary]: 'calendar-check-o',
  [employmentTypes.fixedTermContract]: 'calendar',
};

export const profileSections = {
  name: 'name',
  nationality: 'nationality',
  languages: 'languages',
  dateOfBirth: 'dateOfBirth',
  subscription: 'subscription',
  phones: 'phones',
  emails: 'emails',
  address: 'address',
  nationalInsuranceNumber: 'nationalInsuranceNumber',
  drivingLicense: 'drivingLicense',
  rightToWork: 'rightToWork',
  currentPosition: 'currentPosition',
  currentSalary: 'currentSalary',
  desiredPositions: 'desiredPositions',
  employmentTypes: 'employmentTypes',
  desiredSalary: 'desiredSalary',
  noticePeriod: 'noticePeriod',
  socialMediaLinks: 'socialMediaLinks',
  workLocation: 'workLocation',
  skills: 'skills',
};

export const eventTypes = {
  aiPaused: 'ai-paused',
  aiResumed: 'ai-resumed',
  candidateUpdated: 'candidate-updated',
  workHistoryCreated: 'work-history-created',
  workHistoryUpdated: 'work-history-updated',
  workHistoryDeleted: 'work-history-deleted',
  interimWorkHistoryCreated: 'interim-work-history-created',
  interimWorkHistoryUpdated: 'interim-work-history-updated',
  interimWorkHistoryDeleted: 'interim-work-history-deleted',
  educationCreated: 'education-created',
  educationUpdated: 'education-updated',
  educationDeleted: 'education-deleted',
};

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import min from 'lodash/min';
import uniq from 'lodash/uniq';
import moment from 'moment';
import Modal from 'react-bootstrap/lib/Modal';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { copy, endifyTime, loadJobPermanentAvailability, stringifyError, logError } from 'shared/functions';

import PermanentAvailabilityEditor from 'containers/AvailabilityEditor/PermanentAvailabilityEditor';

import { timeTypes, weekDaysKeys } from './constants';
import { getJobsFromCandidatePipeline, mapSlotTimes, slotByTime } from './functions';

const dayEndTime = '24:00';
const mergeDaySlots = (jobDaySlots, candidateDaySlots) => {
  const jobSlots = copy(jobDaySlots);
  const candidateSlots = copy(candidateDaySlots);
  const slotsTimes = uniq([...jobSlots.flatMap(mapSlotTimes), ...candidateSlots.flatMap(mapSlotTimes)]).sort(
    (prev, cur) => (prev > cur ? 1 : -1),
  );
  const events = [];
  let currentJobSlot;
  let currentCandidateSlot;
  let anyCurrentSlot;
  slotsTimes.forEach((slotTime) => {
    const startedJobSlot = slotByTime(jobSlots, timeTypes.startTime, slotTime);
    const startedCandidateSlot = slotByTime(candidateSlots, timeTypes.startTime, slotTime);
    const endedJobSlot = slotByTime(jobSlots, timeTypes.endTime, slotTime);
    const endedCandidateSlot = slotByTime(candidateSlots, timeTypes.endTime, slotTime);

    const lastEvent = events[events.length - 1];
    if (lastEvent && lastEvent.endTime > slotTime) {
      events[events.length - 1].endTime = slotTime;
    }
    currentJobSlot = endedJobSlot && !startedJobSlot ? null : startedJobSlot || currentJobSlot;
    currentCandidateSlot =
      endedCandidateSlot && !startedCandidateSlot ? null : startedCandidateSlot || currentCandidateSlot;
    anyCurrentSlot = currentCandidateSlot || currentJobSlot;
    if (anyCurrentSlot) {
      const gap = !!currentJobSlot && !currentCandidateSlot;
      events.push({
        startTime: slotTime,
        endTime: min([get(currentCandidateSlot, 'endTime', dayEndTime), get(currentJobSlot, 'endTime', dayEndTime)]),
        availabilityType: gap ? 3 : anyCurrentSlot.availabilityType,
        overlap: !!currentJobSlot && !!currentCandidateSlot,
      });
    }
  });
  return events;
};
const slotFields = ['startTime', 'endTime', 'availabilityType'];

// eslint-disable-next-line import/no-default-export
export default class ModalPermanentAvailabilityEditor extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    permanentAvailability: {},
    candidatePermanentAvailability: {},
    holidayAllowance: false,
    description: '',
    settings: {},
    jobs: [],
    selectedJobs: {},
    permanentAvailabilities: {},
    error: '',
  };
  promise: Promise<unknown>;
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
  unmounted: any;
  static propTypes: {
    savePermanentAvailability: PropTypes.Validator<(...args: any[]) => any>;
    availabilityTypes: PropTypes.Validator<any[]>;
    availabilityLegendBlocks: PropTypes.Validator<any[]>;
    job: PropTypes.Requireable<object>;
  };

  UNSAFE_componentWillMount() {
    // @ts-expect-error
    this.weekdaysCodes = moment.weekdays();
    // @ts-expect-error
    this.weekdaysCodes.push(this.weekdaysCodes.shift());
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onEnableJob(jobId, enabled) {
    const { selectedJobs } = this.state;
    const enabledKeys = Object.keys(selectedJobs).filter((key) => selectedJobs[key]);
    if (!enabled && enabledKeys.length === 1 && enabledKeys[0] === jobId.toString()) {
      return;
    }
    this.setState(
      {
        selectedJobs: {
          ...selectedJobs,
          [jobId]: enabled,
        },
      },
      () => this.mergeJobAvailabilities(),
    );
  }

  mergeJobAvailabilities() {
    const { selectedJobs, permanentAvailabilities, candidatePermanentAvailability } = this.state;

    const availabilitiesToMerge = Object.entries(permanentAvailabilities)
      // @ts-expect-error
      .filter((entry) => selectedJobs[entry[0]] && entry[1] && !entry[1].loading)
      // @ts-expect-error
      .map((entry) => entry[1].days.map((day) => day.slots));
    if (!availabilitiesToMerge.length) {
      return this.setState({
        permanentAvailability: candidatePermanentAvailability,
      });
    }
    const mergedJobSlots = weekDaysKeys
      .map((key) =>
        // @ts-expect-error
        uniq(availabilitiesToMerge.flatMap((item) => item[key])).sort((prev, cur) => prev.startTime > cur.startTime),
      )
      .map((daySlots, index) => {
        let currentEvent;
        const jobSlots = copy(daySlots).reduce((prev, cur) => {
          cur.endTime = endifyTime(cur.endTime);
          if (currentEvent) {
            if (currentEvent.endTime < cur.startTime) {
              currentEvent = cur;
              return [...prev, cur];
            } else if (currentEvent.endTime < cur.endTime) {
              currentEvent.endTime = cur.endTime;
            }
            return prev;
          }
          currentEvent = cur;
          return [...prev, currentEvent];
        }, []);
        // @ts-expect-error
        return mergeDaySlots(jobSlots, candidatePermanentAvailability.days[index].slots);
      });

    const permanentAvailability = copy(candidatePermanentAvailability);
    permanentAvailability.days.forEach((day, index) => (day.slots = mergedJobSlots[index]));

    this.setState({ permanentAvailability, mergedJobSlots });
  }

  ok = () => {
    const { savePermanentAvailability } = this.props;
    const { changesApplied, description, holidayAllowance } = this.state as any;

    const permanentAvailability = {
      description,
      holidayAllowance,
      // @ts-expect-error
      days: this.state.permanentAvailability.days.map((day) => ({
        ...day,
        slots: copy(day.slots)
          .filter((slot) => slot.availabilityType < 3)
          .reduce((prev, cur) => {
            Object.keys(cur).forEach((key) => {
              if (!slotFields.includes(key)) {
                delete cur[key];
              }
            });
            if (prev.length) {
              const lastEvent = prev[prev.length - 1];
              if (lastEvent.availabilityType === cur.availabilityType && lastEvent.endTime === cur.startTime) {
                lastEvent.endTime = cur.endTime;
                return prev;
              }
            }
            return [
              ...prev,
              {
                ...cur,
                endTime: endifyTime(cur.endTime),
              },
            ];
          }, []),
      })),
    };

    const closeAndReturn = () => {
      if (!this.unmounted) {
        this.setState({
          saving: false,
          show: false,
        });
        this.resolve(permanentAvailability);
      }
    };
    if (!changesApplied) {
      return closeAndReturn();
    }
    this.setState({
      saving: true,
      error: '',
    });
    savePermanentAvailability({ permanentAvailability })
      .then(() => closeAndReturn())
      .catch((err) => {
        logError(err);
        this.setState({
          error: stringifyError(err),
          saving: false,
        });
      });
  };

  cancel = () => {
    this.setState({ show: false });
  };

  loadJobs(jobs) {
    const { settings } = this.state;

    const matchWithJobTime = get(settings, 'features.candidate.workProfile.preferredWorkingHours.matchWithJobTime');
    if (!matchWithJobTime) {
      return;
    }

    this.setState({
      permanentAvailabilities: jobs.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.jobId]: {
            loading: true,
          },
        }),
        {},
      ),
    });

    jobs.forEach(({ jobId }) =>
      loadJobPermanentAvailability(jobId).then(
        (permanentAvailability) =>
          !this.unmounted &&
          this.setState(
            {
              permanentAvailabilities: {
                ...this.state.permanentAvailabilities,
                [jobId]: permanentAvailability,
              },
            },
            () => this.mergeJobAvailabilities(),
          ),
      ),
    );
  }

  open(settings, permanentAvailability, candidatePipelines = []) {
    const { job } = this.props;

    const candidatePermanentAvailability = permanentAvailability.days
      ? {
          ...permanentAvailability,
          days: permanentAvailability.days.map((day) => ({
            ...day,
            slots: day.slots.map((slot) => ({
              ...slot,
              endTime: endifyTime(slot.endTime),
            })),
          })),
        }
      : {
          holidayAllowance: false,
          description: '',
          // @ts-expect-error
          days: this.weekdaysCodes.map((weekDay) => ({
            weekDay,
            flexibleHours: false,
            slots: [],
          })),
        };

    const jobs = getJobsFromCandidatePipeline(candidatePipelines);

    if (job) {
      jobs.push(job);
    }

    this.loadJobs(jobs);

    this.setState({
      show: true,
      settings,
      jobs,
      selectedJobs: jobs.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.jobId]: {
            loading: true,
          },
        }),
        {},
      ),
      candidatePermanentAvailability,
      permanentAvailability: candidatePermanentAvailability,
      description: candidatePermanentAvailability.description,
      holidayAllowance: candidatePermanentAvailability.holidayAllowance,
      changesApplied: false,
      error: '',
      saving: false,
    });
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    return this.promise;
  }

  updateAvailability = (days) => {
    const { selectedJobs, candidatePermanentAvailability } = this.state;
    this.setState(
      {
        candidatePermanentAvailability: {
          ...candidatePermanentAvailability,
          days: days.map((day) => ({
            ...day,
            slots: day.slots
              .filter((slot) => slot.availabilityType < 3)
              .map((slot) => ({
                ...slot,
                endTime: endifyTime(slot.endTime),
              })),
          })),
        },
        changesApplied: true,
      },
      () =>
        Object.values(selectedJobs).some((item) => item)
          ? this.mergeJobAvailabilities()
          : this.setState({ permanentAvailability: this.state.candidatePermanentAvailability }),
    );
  };

  renderJob = (job) => {
    const { permanentAvailabilities, selectedJobs } = this.state;

    const jobLoading = (permanentAvailabilities[job.jobId] || {}).loading;
    const enabledKeys = Object.keys(selectedJobs).filter((key) => selectedJobs[key]);
    const disableSingle = enabledKeys.length === 1 && enabledKeys[0] === job.jobId.toString();

    return (
      <label key={job.jobId}>
        <div
          className="media job-list-item"
          style={{
            padding: '5px 10px',
          }}
        >
          <div className="media-left">
            <input
              type="checkbox"
              disabled={jobLoading || disableSingle}
              checked={!!selectedJobs[job.jobId]}
              onChange={(evt) => this.onEnableJob(job.jobId, evt.target.checked)}
              style={{ margin: 0 }}
            />
          </div>
          <div className="media-body">
            <div>
              <h3 className="job-role">{job.title || 'Job title is missing'}</h3>
            </div>
            <div>{get(job, 'company.name')}</div>
          </div>
          <div className="media-right" style={{ minWidth: '40px' }}>
            {jobLoading && (
              <div>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </label>
    );
  };

  render() {
    const { availabilityTypes, availabilityLegendBlocks } = this.props;
    const { show, permanentAvailability, description, holidayAllowance, settings, jobs, error, saving } = this
      .state as any;

    const matchWithJobTime = get(settings, 'features.candidate.workProfile.preferredWorkingHours.matchWithJobTime');
    const holidayAllowanceFlag = get(settings, 'features.candidate.workProfile.preferredWorkingHours.holidayAllowance');

    return (
      <Modal show={show} className="full-screen-modal" onHide={this.cancel} bsSize="large">
        <form onSubmit={this.ok}>
          <Modal.Header closeButton>
            <Modal.Title>{translate('Working hours')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="availability-block">
              <div className="col-xs-9" style={{ paddingLeft: 0 }}>
                <PermanentAvailabilityEditor
                  settings={settings}
                  permanentAvailability={permanentAvailability}
                  updateAvailability={this.updateAvailability}
                  availabilityTypes={availabilityTypes}
                />
              </div>
              <div className="col-xs-3">
                {error && <div className="alert alert-danger margin-top">{error}</div>}
                {holidayAllowanceFlag && (
                  <div className="form-group margin-top">
                    <label className="control-label">
                      <input
                        type="checkbox"
                        checked={holidayAllowance}
                        onChange={(evt) =>
                          this.setState({
                            holidayAllowance: evt.target.checked,
                            changesApplied: true,
                          })
                        }
                      />
                      &nbsp; {translate('Holiday Allowance')}
                    </label>
                  </div>
                )}
                <div className="form-group">
                  <label className="control-label">{translate('Working hours note')}</label>
                  <textarea
                    rows={4}
                    value={description}
                    onChange={(evt) =>
                      this.setState({
                        description: evt.target.value,
                        changesApplied: true,
                      })
                    }
                    className="form-control"
                  />
                </div>
                {matchWithJobTime && !!jobs.length && (
                  <div className="job-list availability-edit-list">{jobs.map(this.renderJob)}</div>
                )}
                <div className="row small margin-top legend-box">
                  <div className="col-xs-5">{availabilityLegendBlocks[0]}</div>
                  {matchWithJobTime && <div className="col-xs-7">{availabilityLegendBlocks[1]}</div>}
                </div>
                <div className="text-center padding">{saving && <Spinner />}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" disabled={saving} onClick={this.ok} className="btn btn-sm btn-success">
              {translate('Submit')}
            </button>
            <button type="button" disabled={saving} onClick={this.cancel} className="btn btn-sm btn-default">
              {translate('Cancel')}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

ModalPermanentAvailabilityEditor.propTypes = {
  savePermanentAvailability: PropTypes.func.isRequired,
  availabilityTypes: PropTypes.array.isRequired,
  availabilityLegendBlocks: PropTypes.array.isRequired,
  job: PropTypes.object,
};

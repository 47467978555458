import PropTypes from 'prop-types';
import React from 'react';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

class Checkboxes extends AIUIComponent {
  state = {
    checked: {},
  };

  UNSAFE_componentWillMount() {
    const { attributes, answer } = this.props;
    const answers = answer.split(', ');
    this.setState({
      checked: Object.keys(attributes).reduce(
        (prev, cur) => ({
          ...prev,
          [cur]: answers.includes(attributes[cur]),
        }),
        {},
      ),
      checkboxes: Object.keys(attributes).map((key) => ({
        key,
        label: attributes[key],
      })),
    });
  }

  checkboxChecked(evt, key) {
    const { attributes } = this.props;
    const checked = {
      ...this.state.checked,
      [key]: evt.target.checked,
    };
    this.setState({
      checked,
      selected: Object.keys(checked)
        .filter((item) => checked[item])
        .map((item) => attributes[item]),
    });
  }

  submitResult() {
    // @ts-expect-error
    const { selected } = this.state;
    this.setState({ submitted: true });
    this.props.resultingContent(selected.length ? selected.join(', ') : 'None of these');
  }

  render() {
    const { editable } = this.props;
    // @ts-expect-error
    const { checked, checkboxes, submitted } = this.state;

    return (
      <div
        style={{
          display: 'inline',
        }}
      >
        {checkboxes.map((checkbox) => (
          <label
            key={checkbox.key}
            style={{
              paddingLeft: '20px',
              display: 'block',
            }}
          >
            <input
              type="checkbox"
              disabled={!editable || submitted}
              checked={checked[checkbox.key]}
              onChange={(evt) => this.checkboxChecked(evt, checkbox.key)}
            />{' '}
            {checkbox.label}
          </label>
        ))}
        <button
          type="submit"
          className="btn btn-primary pull-right"
          disabled={!editable || submitted || !Object.values(checked).find((item) => item)}
          onClick={() => this.submitResult()}
        >
          Send
        </button>
      </div>
    );
  }
}

// @ts-expect-error
Checkboxes.propTypes = {
  answer: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default Checkboxes;

import { getConnectedSocket } from '@eva/emf/app/utils/socket';
import { windowLocationReload } from '@eva/emf/app/shared/functions';
import { userAccountTypes } from '@eva/emf/app/shared/constants';

export const connectSocket = (self) => {
  const { dispatchUpdateUserProfile } = self.props;

  const socket = getConnectedSocket(self, userAccountTypes.candidate);

  if (!socket) {
    return;
  }

  socket
    .on('unauthorized', () => {
      self.setState({
        socket: null,
      });
      self.props.dispatchSignOut();
    })
    .on('candidate-updated', dispatchUpdateUserProfile);

  self.setState({ socket });
};

export const genericWrapper = (content) => (
  <h2
    id="loading-settings"
    className="text-center"
    style={{
      padding: '50px',
    }}
  >
    {content}
  </h2>
);

export function storageEvent(evt) {
  if (evt.key === 'token' && evt.oldValue !== evt.newValue) {
    windowLocationReload();
  }
}

import get from 'lodash/get';
import { memo } from 'react';
import PropTypes from 'prop-types';

import { grayColor } from 'shared/constants';
import { getMissingStages } from 'shared/functions';

import noCompanyImage from 'assets/images/no-company.png';

const ActivePipelines = ({ sortedPipelines, selectedPipeline, discussJob, selectPipeline }) => {
  let currentStage: any;
  const missingStages = getMissingStages();

  return sortedPipelines.map((pipeline: any) => {
    let currentStageRenderer: any;

    if (
      (!pipeline.workflowStage || currentStage !== pipeline.workflowStage) &&
      pipeline.workflowProcess?.workflowState?.workflow?.showStagesToCandidate
    ) {
      const { workflowStage = missingStages.unidentifiedStage } = pipeline;
      currentStage = workflowStage;
      currentStageRenderer = (
        <div
          key={`${pipeline.pipelineId}-${currentStage.name}`}
          className="pipeline-group text-left"
          style={{
            background: currentStage.color,
          }}
        >
          <h4>{currentStage.candidateLabel || currentStage.name}</h4>
        </div>
      );
    }

    const workflowState = get(pipeline, 'workflowProcess.workflowState');

    return (
      <div key={pipeline.pipelineId}>
        {currentStageRenderer}
        <div
          className={`job-pipeline job-item ${
            selectedPipeline?.pipelineId === pipeline.pipelineId ? 'active' : ''
          } media`}
          key={pipeline.pipelineId}
          onClick={() => selectPipeline(pipeline)}
        >
          <div className="media-left">
            <div className="company-img-crop">
              <img
                className="media-object"
                role="presentation"
                src={get(pipeline, 'job.avatar.url') || get(pipeline, 'job.company.avatar.url') || noCompanyImage}
              />
            </div>
          </div>
          <div className="media-body">
            <div className="text-left">
              <i
                className="ll-icon ll-chat fa-2x pull-right text-dark-blue"
                style={{
                  fontSize: '16px',
                }}
                onClick={(evt) => discussJob(evt, pipeline.job.code)}
              />
              <h3>{pipeline.job.title || '(No title)'}</h3>
              <div className="pull-right margin-top">
                <span
                  className="badge"
                  style={{
                    background: workflowState.color || grayColor,
                  }}
                >
                  {workflowState?.candidateLabel || 'No label'}
                </span>
              </div>
              <div className="job-company margin-top">{get(pipeline, 'job.company.name')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

ActivePipelines.propTypes = {
  selectPipeline: PropTypes.func.isRequired,
  discussJob: PropTypes.func.isRequired,
  sortedPipelines: PropTypes.array.isRequired,
  selectedPipeline: PropTypes.object,
};

// eslint-disable-next-line import/no-default-export
export default memo(ActivePipelines);

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import noImage from 'assets/images/no-company.png';

import Suggestions from './Suggestions';

import './style.css';

// eslint-disable-next-line import/no-default-export
export default class MessageJob extends React.Component<any, any> {
  state = {
    comment: '',
  };

  UNSAFE_componentWillMount() {
    const {
      message: { content },
    } = this.props;

    const splittedContent = content.split('#');
    this.setState({ comment: splittedContent[1] || '' });
  }

  render() {
    const { settings } = this.context;
    const { message, job, emitSendMessage, isLastMessage, isNewMessage } = this.props;
    const { comment } = this.state;

    const fromMe = !message.agentSide;

    return (
      <div>
        <div className={`bubble-wrapper ${fromMe ? 'me' : 'you'}`}>
          <div className="message-header">
            {!fromMe && <span className="participant-name">{get(message, 'sender.name.displayName')}</span>}
            <span className="chatsend-time">
              {message.sentOn.format(settings.dateFormats.timeFormat)}
              <i
                className={`ll-icon ll-done-all pull-right ${fromMe ? '' : 'hide'} ${
                  message.readOn ? 'text-info' : 'text-mute'
                }`}
                style={{
                  margin: '4px 0 0 5px',
                  fontSize: '14px',
                }}
              />
            </span>
          </div>
          <div className={`dropdown bubble ${fromMe ? 'me' : 'you'}`}>
            <div className="bubble-content">
              {comment && (
                <div className="job-interview">
                  <div className="interview-title" dangerouslySetInnerHTML={{ __html: comment }} />
                </div>
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href={`/job-details/${message.content.split('#')[0]}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="job-content media">
                  <div className="media-left">
                    <div className="company-img-crop">
                      <img
                        className="media-object"
                        role="presentation"
                        src={job?.avatar?.url || job?.company?.avatar?.url || noImage}
                      />
                    </div>
                  </div>
                  <div className="media-body">
                    <p className="title">{job.title}</p>
                    <p className="company">{job?.company?.name}</p>
                    <p className="margin-min-vertical">
                      {job.startDate && (
                        <span>
                          <i className="lnr lnr-calendar-full text-muted" /> {job.startDate}
                        </span>
                      )}
                      {job.endDate && <span>&nbsp;- {job.endDate}</span>}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        {(isNewMessage || isLastMessage) && !fromMe && get(message, 'options.suggestions') && (
          <Suggestions message={message} emitSendMessage={emitSendMessage} />
        )}
        <div className="clearfix" />
      </div>
    );
  }
}

// @ts-expect-error
MessageJob.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
MessageJob.propTypes = {
  message: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  isLastMessage: PropTypes.bool,
  isNewMessage: PropTypes.bool,
};

import some from 'lodash/some';

import type { PipelineDto, WorkflowDto, WorkflowStageDto } from '@eva/types/dto';
import { isEntityArchived, isEntityNotArchived } from '@eva/emf/app/shared/functions-ts';
import { copy, getQueryVariables } from 'shared/functions';

export interface WithPipelineWorkflow {
  pipelineWorkflow: WorkflowDto;
}

export interface PipelineDtoWithWorkflow extends PipelineDto, WithPipelineWorkflow {}

export const getSelectedWorkflow = (pipelineWorkflows: WorkflowDto[]) => {
  const query = getQueryVariables() as any;

  const workwflowIdFromQuery = Number(query.workflowId);

  if (!workwflowIdFromQuery) {
    return undefined;
  }

  return pipelineWorkflows.find((workflow) => workflow.workflowId === workwflowIdFromQuery);
};

export const getSelectedPipeline = (pipelines: PipelineDto[], pipelineId: number) => {
  const query = getQueryVariables() as any;

  const jobIdFromQuery = Number(query.jobId);

  return pipelines.find((pipeline) =>
    some([pipelineId === pipeline.pipelineId, pipeline.job.jobId === jobIdFromQuery]),
  );
};

export const filterPipelineWorkflows = (pipelineWorkflows: WorkflowDto[], pipelines: PipelineDto[]) =>
  pipelineWorkflows.filter((pipelineWorkflow) => {
    if (pipelineWorkflow.showStagesToCandidate) {
      return true;
    }
    return !!pipelines.find(
      (pipeline) =>
        pipeline.workflowProcess.workflow.workflowId === pipelineWorkflow.workflowId &&
        !pipeline.workflowProcess.workflowState?.isTerminal,
    );
  });

export const getActivePipelines = (pipelines: PipelineDtoWithWorkflow[], pipelineWorkflowId?: number) =>
  pipelines
    .filter(({ pipelineWorkflow }) => pipelineWorkflow?.workflowId === pipelineWorkflowId)
    .filter(isEntityNotArchived)
    .filter(
      ({ workflowProcess }) =>
        workflowProcess.workflowState?.workflow?.showStagesToCandidate || !workflowProcess.workflowState?.isTerminal,
    );

export const getArchivedPipelines = (pipelines: PipelineDtoWithWorkflow[], pipelineWorkflowId?: number) =>
  pipelines
    .filter(({ pipelineWorkflow }) => pipelineWorkflow?.workflowId === pipelineWorkflowId)
    .filter(isEntityArchived);

export const getSortedPipelines = (pipelines: PipelineDto[], unknownWorkflowId: string, missingStages) => {
  const usedWorkflowStages = {};
  const usedPipelineWorkflows: { [key: number]: WorkflowDto } = {};

  const sortedPipelines = pipelines
    .map((pipeline) => {
      const workflowState = pipeline?.workflowProcess?.workflowState;

      let workflowStage: WorkflowStageDto;

      if (!workflowState) {
        workflowStage = missingStages.unknownState;
      } else if (workflowState.isTerminal) {
        workflowStage = missingStages.rejectedStage;
      } else if (!workflowState.workflowStage) {
        workflowStage = missingStages.noStage;
      } else {
        const workflowStageId = workflowState?.workflowStage?.workflowStageId;

        if (workflowStageId) {
          usedWorkflowStages[workflowStageId] = usedWorkflowStages[workflowStageId] || workflowState.workflowStage;
          workflowStage = usedWorkflowStages[workflowStageId];
        } else {
          workflowStage = missingStages.noStage;
        }
      }

      const pipelineWorkflow = pipeline?.workflowProcess?.workflow || {
        name: 'Unknown pipeline workflow',
        workflowId: unknownWorkflowId,
      };

      usedPipelineWorkflows[pipelineWorkflow.workflowId] =
        usedPipelineWorkflows[pipelineWorkflow.workflowId] || pipelineWorkflow;

      return {
        ...copy(pipeline),
        workflowStage,
        pipelineWorkflow: usedPipelineWorkflows[pipelineWorkflow.workflowId],
      };
    })
    .sort((prev, next) => {
      if (prev.workflowStage.workflowStageId && !next.workflowStage.workflowStageId) {
        return -1;
      }

      if (!prev.workflowStage.workflowStageId && next.workflowStage.workflowStageId) {
        return 1;
      }

      if (!prev.workflowStage.workflowStageId && !next.workflowStage.workflowStageId) {
        return prev.updatedAt > next.updatedAt ? 1 : -1;
      }

      if (prev.workflowStage !== next.workflowStage) {
        if (prev.workflowStage === missingStages.rejectedStage || next.workflowStage === missingStages.rejectedStage) {
          return prev.workflowStage === missingStages.rejectedStage ? 1 : -1;
        } else if (
          prev.workflowStage === missingStages.unknownState ||
          next.workflowStage === missingStages.unknownState
        ) {
          return prev.workflowStage === missingStages.unknownState ? 1 : -1;
        } else if (
          prev.workflowStage === missingStages.unknownStage ||
          next.workflowStage === missingStages.unknownStage
        ) {
          return prev.workflowStage === missingStages.unknownStage ? 1 : -1;
        }
      }
      if (prev.workflowStage.sortOrder !== next.workflowStage.sortOrder) {
        return prev.workflowStage.sortOrder > next.workflowStage.sortOrder ? 1 : -1;
      }

      return prev.updatedAt > next.updatedAt ? 1 : -1;
    });

  return [sortedPipelines, usedPipelineWorkflows] as const;
};

import { acceptCvExtensions, acceptImagesExtensions } from 'shared/constants';
import { logError } from 'shared/functions';

import { uploadFileType } from './constants';

export const getUploadFileType = (acceptExtensions: typeof acceptCvExtensions | typeof acceptImagesExtensions) => {
  const extensions = acceptExtensions.split(',');

  if (extensions.some((extension) => acceptCvExtensions.includes(extension))) {
    return uploadFileType.cv;
  }

  if (extensions.some((extension) => acceptImagesExtensions.includes(extension))) {
    return uploadFileType.image;
  }

  logError('Unsupported extensions', {
    acceptExtensions,
  });

  return null;
};

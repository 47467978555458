import { findInPagedData } from '@eva/emf/app/shared/functions-ts';
import type { ListedResponse } from '@eva/emf/app/types/common-types';
import type { JobDto } from '@eva/types/dto';
import { getQueryVariables } from 'shared/functions';

export const getJobIdFromUrl = () => {
  const searchQuery = getQueryVariables() as any;

  return Number(searchQuery.jobId);
};

export const getSelectedJob = (pages: ListedResponse<JobDto>[]) => {
  const jobId = getJobIdFromUrl();

  return jobId && pages ? findInPagedData(pages, (job) => job.jobId === jobId) : undefined;
};

import PropTypes from 'prop-types';
import React from 'react';

import { valuesWithDelimiter, localDate } from 'shared/functions';

const PreviewMyDetails = (props) => {
  const {
    userProfile: { cvs = [], attachments = [] },
  } = props;

  const sortedCvs = cvs.sort((prev, next) =>
    (prev.updatedAt || prev.createdAt) > (next.updatedAt || next.createdAt) ? -1 : 1,
  );
  const lastCvUpdated = sortedCvs[0] && localDate(sortedCvs[0].updatedAt || sortedCvs[0].createdAt);

  return (
    <div>
      {valuesWithDelimiter([
        `${cvs.length || translate('No')} ${translate('cv(s)')} ${
          // @ts-expect-error
          lastCvUpdated && lastCvUpdated.isValid ? `- ${translate('last update')} ${lastCvUpdated.fromNow()}` : ''
        }`,
        `${attachments.length || translate('No')} ${translate('file(s)')}`,
      ])}
    </div>
  );
};

PreviewMyDetails.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default PreviewMyDetails;

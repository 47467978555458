import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';

import { requestBackend } from '@eva/emf/app/utils/request';
import { dayOfMonthFormat, evaSpinnerSmall, messagesTypes } from 'shared/constants';

import MessageJob from './MessageJob';
import MessageQnA from './MessageQnA';
import MessageText from './MessageText';

import './style.css';

const dateDivider = (index, cur, prev) => !index || !cur.sentOn.isSame(prev.sentOn, 'day');
const newMessagesDivider = (index, cur, prev) => {
  if (cur.readOn || !cur.agentSide) {
    return false;
  } else if (index) {
    if (!prev.agentSide) {
      return true;
    }
    return !!prev.readOn;
  }
  return !cur.readOn;
};
const timeRegex = /(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

// eslint-disable-next-line import/no-default-export
export default class Message extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    loading: false,
    job: null,
    mergedMessage: {},
  };
  static contextTypes: { settings: PropTypes.Validator<object> };

  UNSAFE_componentWillMount() {
    const { message } = this.props;
    if (!message.messageType || message.messageType.messageTypeId === messagesTypes.text) {
      const { text = {}, ...rest } = message;
      const mergedMessage = Object.assign({}, text, rest);
      this.setState({ mergedMessage });
      if (mergedMessage.isJob) {
        this.setState({
          loading: true,
          isJob: true,
        });
        requestBackend(`/public-jobs/my/${mergedMessage.content.split('#')[0]}`).then(
          (job) =>
            // @ts-expect-error
            this.unmounted
              ? null
              : this.setState({
                  loading: false,
                  job,
                }),
          (err) => {
            // @ts-expect-error
            if (this.unmounted) {
              return;
            }
            this.setState({
              loading: false,
              job: {
                title: err.status === 404 ? translate('Job not found') : translate('Unknown server error'),
                company: {},
              },
            });
          },
        );
      }
    }
  }

  componentDidUpdate() {
    this.props.delayScrollDown();
  }

  componentWillUnmount() {
    // @ts-expect-error
    this.unmounted = true;
  }

  render() {
    const { settings } = this.context;
    const { messages, message, index, emitSendMessage, isLastMessage } = this.props;

    // @ts-expect-error
    const { loading, job, isJob, mergedMessage } = this.state;

    if (loading) {
      return evaSpinnerSmall;
    }

    const sentOnDateFormat = `${dayOfMonthFormat}${message.sentOn.isSame(moment(), 'year') ? '' : ', YYYY'}`;
    const previousMessage = messages[index - 1];
    const { messageTypeId } = message.messageType || {};
    const textMessage = !message.error && !isJob && (!messageTypeId || messageTypeId === messagesTypes.text);
    const messageKey = `${index}${message.updatedAt}`;
    const messageCalendar = message.sentOn.calendar(null, { sameElse: sentOnDateFormat });
    const isNewMessage = newMessagesDivider(index, message, previousMessage);

    const previousSenderId = get(previousMessage, 'sender.userId');
    const previousSenderIsSame = previousSenderId && previousSenderId === get(message, 'sender.userId');
    const hideSender = previousSenderIsSame && message.sentOn.diff(previousMessage.sentOn, 'minutes') < 3;

    return (
      <div key={index} className="message-wrapper" data-date={message.sentOn.format(sentOnDateFormat)}>
        {dateDivider(index, message, previousMessage) && (
          <div className="conversation-start">
            <div>
              {timeRegex.test(messageCalendar)
                ? messageCalendar.replace(timeRegex, message.sentOn.format(settings.dateFormats.timeFormat))
                : messageCalendar}
            </div>
          </div>
        )}
        {isNewMessage && (
          <div className="conversation-start">
            <span className="badge bg-primary">{translate('New messages')}</span>
          </div>
        )}
        {message.error && (
          <div className="text-center text-danger">
            <small>
              {message.sentOn.format(settings.dateFormats.timeFormat)}: {message.content}
            </small>
          </div>
        )}
        {messageTypeId === messagesTypes.genericSystem && (
          <div className="text-center text-muted">
            <small>
              {message.sentOn.format(settings.dateFormats.timeFormat)}: {message.genericSystem.content}
            </small>
          </div>
        )}
        {isJob && (
          <div>
            <MessageJob
              key={messageKey}
              message={mergedMessage}
              job={job}
              emitSendMessage={emitSendMessage}
              isLastMessage={isLastMessage}
              isNewMessage={isNewMessage}
            />
            <div className="clearfix" />
          </div>
        )}
        {messageTypeId === messagesTypes.qnaMissedAnswer && <MessageQnA key={messageKey} message={message} />}
        {textMessage && (
          <MessageText
            key={messageKey}
            message={mergedMessage}
            topProps={this.props}
            isNewMessage={isNewMessage}
            hideSender={hideSender}
          />
        )}
      </div>
    );
  }
}

Message.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
Message.propTypes = {
  message: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  showOptionsMode: PropTypes.bool.isRequired,
  isLastMessage: PropTypes.bool.isRequired,
  updateContent: PropTypes.func.isRequired,
  setMessageMenu: PropTypes.func.isRequired,
  delayScrollDown: PropTypes.func.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  showNotes: PropTypes.bool,
};

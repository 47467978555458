import { safeLocalStorage } from '@eva/emf/app/shared/constants';
import { supportedLocales } from 'shared/constants';

export const SIGN_OUT = 'eva/global/SIGN_OUT';

export const SIGN_IN = 'eva/global/SIGN_IN';
export const SIGN_IN_SUCCESS = 'eva/global/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'eva/global/SIGN_IN_ERROR';

export const SIGN_UP = 'eva/global/SIGN_UP';
export const SIGN_UP_SUCCESS = 'eva/global/SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'eva/global/SIGN_UP_ERROR';

export const LOAD_USER_PROFILE = 'eva/global/LOAD_USER_PROFILE';
export const LOAD_USER_PROFILE_SUCCESS = 'eva/global/LOAD_USER_PROFILE_SUCCESS';
export const LOAD_USER_PROFILE_ERROR = 'eva/global/LOAD_USER_PROFILE_ERROR';

export const RESTORE_PASSWORD = 'eva/global/RESTORE_PASSWORD';
export const RESTORE_PASSWORD_SUCCESS = 'eva/global/RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_ERROR = 'eva/global/RESTORE_PASSWORD_ERROR';

export const RESET_PASSWORD = 'eva/global/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'eva/global/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'eva/global/RESET_PASSWORD_ERROR';

export const LOAD_PERMISSIONS = 'eva/global/LOAD_PERMISSIONS';
export const LOAD_PERMISSIONS_SUCCESS = 'eva/global/LOAD_PERMISSIONS_SUCCESS';
export const LOAD_PERMISSIONS_ERROR = 'eva/global/LOAD_PERMISSIONS_ERROR';

export const LOAD_SETTINGS = 'eva/global/LOAD_SETTINGS';
export const LOAD_SETTINGS_SUCCESS = 'eva/global/LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_ERROR = 'eva/global/LOAD_SETTINGS_ERROR';

export const UPDATE_SETTINGS = 'eva/global/UPDATE_SETTINGS';
export const UPDATE_USER_PROFILE = 'eva/global/UPDATE_USER_PROFILE';
export const CHANGE_LOCALE = 'eva/global/CHANGE_LOCALE';

// @ts-expect-error
export const defaultLocale = safeLocalStorage.locale || supportedLocales.en;

import PropTypes from 'prop-types';
import React from 'react';

import AIUIComponent from 'containers/AIUI/AIUIComponent';
import ModalTermsAndConditions from 'containers/ModalTermsAndConditions';

class TnC extends AIUIComponent {
  state = {
    answerOptions: [],
  };

  UNSAFE_componentWillMount() {
    this.setState({
      answerOptions: [translate('Accept'), translate('Decline')],
    });
  }

  openConfirmationModal() {
    const { resultingContent, editable } = this.props;
    const { answerOptions } = this.state;
    // @ts-expect-error
    this.termsAndConditionsModal.open(editable).then(
      () => (editable ? resultingContent(answerOptions[0]) : ''),
      (decline) => (editable && decline ? resultingContent(answerOptions[1]) : ''),
    );
  }

  submitResult(result) {
    this.setState({ submitted: true });
    this.props.resultingContent(result);
  }

  render() {
    const {
      editable,
      attributes: { label },
    } = this.props;
    // @ts-expect-error
    const { submitted, answerOptions } = this.state;

    if (!editable || submitted) {
      return null;
    }

    return (
      <div
        style={{
          display: 'inline',
        }}
      >
        <ModalTermsAndConditions
          // @ts-expect-error
          ref={(ref) => (this.termsAndConditionsModal = this.termsAndConditionsModal || ref)}
          size="large"
        />
        <a className="tnc_link" onClick={() => this.openConfirmationModal()}>
          {label}
        </a>
        {answerOptions.map((option) => (
          <button
            type="submit"
            key={option}
            className={'bubble me'}
            disabled={submitted || !editable}
            onClick={() => this.submitResult(option)}
          >
            <span className="bubble-content">
              <span className="message-content">{option}</span>
            </span>
          </button>
        ))}
      </div>
    );
  }
}

// @ts-expect-error
TnC.propTypes = {
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default TnC;

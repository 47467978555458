import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { fromJS } from 'immutable';
import { reduxForm, Field } from 'redux-form/immutable';
import moment from 'moment';
import { connect } from 'react-redux';

import { RenderSelect } from '@eva/emf/app/shared/ui/RenderSelect';
import { DatePicker } from '@eva/emf/app/shared/ui/DatePicker/DatePicker';

let availabilityOptions;
const getAvailabilityOptions = () => {
  if (availabilityOptions) {
    return availabilityOptions;
  }
  availabilityOptions = [
    {
      label: translate('Available'),
      value: 'true',
    },
    {
      label: translate('Not available'),
      value: 'false',
    },
    {
      label: translate('Unknown'),
      value: 'null',
    },
  ];
  return getAvailabilityOptions();
};

class FormAvailabilityDetails extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {};
  noticePeriodTypes: any;

  UNSAFE_componentWillMount() {
    const { initialValues } = this.props;
    const initialValuesJS = initialValues.toJS ? initialValues.toJS() : {};
    this.setState({
      relativeNoticePeriod: !!get(initialValuesJS, 'noticePeriod.noticePeriod.noticePeriodId'),
    });

    this.noticePeriodTypes = [translate('Fixed'), translate('Relative')];
  }

  onChangeNoticePeriodType(relativeNoticePeriod) {
    const { change } = this.props;

    this.setState({ relativeNoticePeriod }, () =>
      change(
        'noticePeriod',
        fromJS({
          [relativeNoticePeriod ? 'noticePeriod' : 'fixedDate']: null,
          ...(relativeNoticePeriod
            ? {
                noticePeriod: {
                  noticePeriodId: null,
                },
              }
            : {}),
        }),
      ),
    );
  }

  renderNoticePeriod = ({ meta: { error } }) => {
    const { editOptions } = this.props;
    // @ts-expect-error
    const { relativeNoticePeriod } = this.state;

    const fieldWrapper = (field) => (
      <div>
        {field}
        {error && <div className="text-danger">{error}</div>}
      </div>
    );

    if (relativeNoticePeriod) {
      return fieldWrapper(
        <Field
          name="noticePeriod.noticePeriod.noticePeriodId"
          component={RenderSelect}
          options={editOptions.noticePeriods}
          valueKey="noticePeriodId"
          labelKey="name"
        />,
      );
    }
    return fieldWrapper(
      <Field name="noticePeriod.fixedDate" component={DatePicker} min={moment().add(1, 'd').format('YYYY-MM-DD')} />,
    );
  };

  render() {
    const { currentAvailability, handleSubmit, change } = this.props;
    // @ts-expect-error
    const { relativeNoticePeriod } = this.state;

    return (
      <div className="section" onSubmit={handleSubmit}>
        <h4 className="text-primary">{translate('Availability details')}</h4>
        <div className="row">
          <div className="col-xs-12 col-md-6"></div>
          <div className="col-xs-12 col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              name="availability.currentAvailability"
              component={RenderSelect}
              label={translate('Current availability')}
              options={getAvailabilityOptions()}
              onChangeValue={(value) => {
                if (value !== 'true') {
                  change('availability.availabilityFollowUp', null);
                }
              }}
              valueKey="value"
              labelKey="label"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            {currentAvailability === 'false' && (
              <div className="form-group">
                <label className="control-label">{translate('Follow up')}</label>
                <Field
                  name="availability.availabilityFollowUp"
                  component={DatePicker}
                  min={moment().add(1, 'd').format('YYYY-MM-DD')}
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <label className="control-label">
                {translate('Notice period')}
                {this.noticePeriodTypes.map((label, index) => (
                  <label key={label} className="margin-left label-clear-style small">
                    <input
                      type="radio"
                      id={label}
                      name="relativeNoticePeriod"
                      checked={relativeNoticePeriod === !!index}
                      onChange={() => this.onChangeNoticePeriodType(!!index)}
                    />{' '}
                    {label}
                  </label>
                ))}
              </label>
              <Field name="noticePeriod" component={this.renderNoticePeriod} />
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

// @ts-expect-error
FormAvailabilityDetails.propTypes = {
  currentAvailability: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  switchEditMode: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default connect((state, ownProps) => {
  // @ts-expect-error
  const formData = state.get('form').get(ownProps.form);
  if (!formData || !formData.get('values')) {
    return {};
  }
  const formDataValues = formData.get('values').toJS();
  return {
    currentAvailability: get(formDataValues, 'availability.currentAvailability'),
  };
})(
  reduxForm({
    enableReinitialize: true,
    // @ts-expect-error
  })(FormAvailabilityDetails),
);

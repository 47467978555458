import { formatEndDate, formatStartDate } from './functions';

export interface JobDatesProps {
  startDate?: string;
  endDate?: string;
  dateFormat: string;
}

export const JobDates = ({ startDate, endDate, dateFormat = 'YYYY-MM-DD' }: JobDatesProps) => {
  if (!startDate && !endDate) {
    return null;
  }

  const startDateFormatted = formatStartDate(startDate, dateFormat);
  const endDateFormatted = endDate ? ` — ${formatEndDate(endDate, dateFormat)}` : '';

  const stringifiedInterval = `${startDateFormatted}${endDateFormatted}`;

  return (
    <div>
      <label className="control-label">
        <i className="lnr lnr-calendar-full" />
      </label>
      <span className="margin-left">{stringifiedInterval}</span>
    </div>
  );
};

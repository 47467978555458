import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import { addXhrListeners, onDropFile } from 'shared/functions';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

import { acceptTypes } from './constants';
import { getUploadFileType } from './functions';

class UploadFile extends AIUIComponent {
  state = {};

  componentDidMount() {
    addXhrListeners.bind(this)();
  }

  onDropFile = (files) => {
    const { attributes, acceptExtensions } = this.props;

    const uploadType = getUploadFileType(acceptExtensions || attributes.extensions);

    onDropFile.bind(this)(files, {
      acceptTypes: acceptTypes[uploadType],
    });
  };

  submitResult(result, file, fileUrl) {
    this.setState({ submitted: true });
    this.props.resultingContent(result, file, fileUrl);
  }

  render() {
    const { editable, attributes, acceptExtensions } = this.props;
    // @ts-expect-error
    const { submitted, progress, fileUrl, file } = this.state;

    return (
      <div>
        {editable && !submitted && (
          <div>
            <Dropzone
              key="dropzone"
              onDrop={this.onDropFile}
              // @ts-expect-error
              ref={(ref) => (this.dropzone = ref)}
              multiple={false}
              accept={acceptExtensions || attributes.extensions}
              style={{
                display: 'none',
              }}
            >
              <div>Try dropping some files here, or click to select files to upload.</div>
            </Dropzone>
            {!file && (
              <div key="file">
                {/* @ts-expect-error */}
                <button type="button" className="bubble me" onClick={() => this.dropzone.open()}>
                  <span className="bubble-content">
                    <span className="message-content">
                      <i
                        className="ll-icon ll-paperclip"
                        style={{
                          fontSize: '18px',
                          margin: '3px 10px 0 -5px',
                          float: 'left',
                          opacity: '0.8',
                        }}
                      />
                      {attributes.confirm}
                    </span>
                  </span>
                </button>
                {attributes.reject && (
                  <button
                    type="button"
                    className="bubble me margin-left"
                    // @ts-expect-error
                    onClick={() => this.submitResult(attributes.reject)}
                  >
                    <span className="bubble-content">
                      <span className="message-content">{attributes.reject}</span>
                    </span>
                  </button>
                )}
              </div>
            )}
            {!!file && (
              <div key="progress">
                {progress === 1 && (
                  <div>
                    {translate('File')}
                    <a className="margin-left margin-right" href={fileUrl} target="_blank" rel="noreferrer">
                      {file.name}
                    </a>
                    {translate('attached')}
                    <i
                      className="fa fa-trash"
                      style={{
                        padding: '0 7px',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.setState({ file: null })}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={() => this.submitResult('', file, fileUrl)}
                    >
                      {translate('Send file')}
                    </button>
                  </div>
                )}
                {progress !== 1 && (
                  <div>
                    {translate('Uploading')} {file.name}
                    <div
                      style={{
                        display: 'inline-block',
                        width: '100px',
                        height: '1em',
                        border: '1px solid gray',
                        margin: '0 7px',
                      }}
                    >
                      <div
                        style={{
                          width: `${progress * 100}%`,
                          backgroundColor: 'gray',
                          height: '1em',
                        }}
                      ></div>
                    </div>
                    {(progress * 100).toFixed(0)}%
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
UploadFile.propTypes = {
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
  acceptExtensions: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export
export default UploadFile;

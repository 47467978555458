import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form/immutable';

import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import { linkifyError } from 'shared/functions';

import FormContactDetailsCore from './FormContactDetailsCore';

class FormContactDetails extends React.Component<any, any> {
  render() {
    const { submitting, error } = this.props;

    return (
      <div className="candidate-contact-info">
        <FormContactDetailsCore {...this.props} />
        <div>
          {error && <div className="alert-danger alert">{linkifyError(error)}</div>}
          <div className="text-center">{submitting && <Spinner />}</div>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
FormContactDetails.propTypes = {
  entity: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  jobEmploymentType: PropTypes.string,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  switchEditMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default reduxForm({
  enableReinitialize: true,
  // @ts-expect-error
})(FormContactDetails);

import PropTypes from 'prop-types';
import React from 'react';

import { requestBackend } from '@eva/emf/app/utils/request';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

import AIUIComponent from 'containers/AIUI/AIUIComponent';

const addressString = (address) => {
  const splittedAddress = address.split(', ');
  return [splittedAddress[0], splittedAddress[1], splittedAddress[5]].join(', ');
};

class AddressFromPostcode extends AIUIComponent {
  state = {
    street: '',
  };

  UNSAFE_componentWillMount() {
    const {
      editable,
      attributes: { postcode },
    } = this.props;
    if (editable) {
      this.setState({
        error: null,
        loading: true,
      });
      requestBackend(`/utils/address/${postcode}`).then(
        (response) => {
          // @ts-expect-error
          const addresses = response.Addresses.map(addressString);
          this.setState({
            addresses,
            address: addresses[0],
            street: '',
            error: '',
            loading: false,
          });
        },
        () =>
          this.setState({
            addresses: null,
            error: `Can't retrieve info for post code ${postcode}`,
            loading: false,
          }),
      );
    }
  }

  submitResult(evt) {
    evt.preventDefault();
    const {
      attributes: { postcode },
    } = this.props;
    // @ts-expect-error
    const { address } = this.state;
    this.setState({ submitted: true });
    this.props.resultingContent(`${address}, ${postcode}`);
  }

  render() {
    const {
      attributes: { postcode },
    } = this.props;
    // @ts-expect-error
    const { addresses, address, error, loading } = this.state;

    return (
      <div>
        {error && <span className="text-danger">{error}</span>}
        {loading && (
          <span>
            <Spinner /> Searching for {postcode} addresses...
          </span>
        )}
        {addresses && (
          <div className="input-group">
            <div
              className="custom-select"
              style={{
                width: '100%',
              }}
            >
              <select className="form-control" onChange={(evt) => this.setState({ address: evt.target.value })}>
                {addresses.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <span className="input-group-btn">
              <button
                type="submit"
                disabled={!address}
                className="btn btn-primary"
                onClick={(evt) => this.submitResult(evt)}
              >
                <i className="ll-icon ll-send" />
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

// @ts-expect-error
AddressFromPostcode.propTypes = {
  answer: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  resultingContent: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default AddressFromPostcode;

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form/immutable';
import get from 'lodash/get';

import { salaryRateLabel } from 'shared/functions';
import { Input } from '@eva/emf/app/shared/ui/Input';
import { RenderSelect } from '@eva/emf/app/shared/ui/RenderSelect';

import FormLocationCore from './FormLocationCore';

// eslint-disable-next-line import/no-default-export
export default class FormEmploymentCore extends React.Component<any, any> {
  static contextTypes: { settings: PropTypes.Validator<object> };
  render() {
    const { settings } = this.context;
    const { jobEmploymentType, editOptions, handleSubmit } = this.props;

    const currencyValue = get(settings, 'currency.value', '£');
    const features = settings.features;
    const currencyPer = `${translate(currencyValue)} ${translate('per')}`;

    return (
      <div onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field component={Input} name="currentPosition.position" label={translate('Current position')} />
          </div>
          <div className="col-xs-12 col-md-6">
            <Field component={Input} name="currentPosition.employer" label={translate('Current employer')} />
          </div>
          <div className="col-xs-12 col-md-6">
            <Field
              type="checkbox"
              valueClass="pull-left margin-right"
              component={Input}
              name="currentPosition.isCurrent"
              label={translate('Is current')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <label className="control-label">
              {translate('Current {{salaryLabel}}', {
                salaryLabel: salaryRateLabel(jobEmploymentType),
              })}
            </label>
            <div className="row group-fields">
              <div className="col-xs-7">
                <Field
                  component={Input}
                  name="currentSalary.salary.value"
                  labelClass="hide"
                  type="number"
                  step="0.1"
                  min="0"
                  nullify
                  postfix={currencyPer}
                />
              </div>
              <div className="col-xs-5">
                <Field
                  name="currentSalary.salary.period"
                  component={RenderSelect}
                  options={editOptions.paymentPeriods}
                  valueKey="code"
                  labelKey="name"
                />
              </div>
            </div>
          </div>
        </div>
        {features.ui.candidateProfile.workLocation && (
          <div>
            <hr className="dotted" />
            <label className="control-label">{translate('Work location')}</label>
            {/* @ts-expect-error */}
            <Field component={FormLocationCore} addressField="workLocation" />
          </div>
        )}
      </div>
    );
  }
}

FormEmploymentCore.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
FormEmploymentCore.propTypes = {
  jobEmploymentType: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  switchEditMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

import React from 'react';

import type { SkillTaxonomyDto } from '@eva/types/dto';

import { maxSkillsToShow } from '../constants';
import { getSkillProficiencyName } from '../functions';

import { Skill } from './Skill';

type SkillTaxonomyItemType = {
  name: string;
  skills: {
    name: string;
    skillProficiencyValue?: number;
    skillId: number;
  }[];
  skillProficiencies: SkillTaxonomyDto['proficiencies'];
};

export const SkillTaxonomyItem: React.FC<SkillTaxonomyItemType> = ({ name, skills, skillProficiencies }) => {
  const visibleSkills = skills.slice(0, maxSkillsToShow);

  return (
    <div className="margin-top job-recommendation-skills">
      <label className="control-label margin-right">{name}:</label>
      {visibleSkills.map((skill) => (
        <Skill
          key={skill.skillId}
          name={skill.name}
          skillProficiencyName={getSkillProficiencyName(skillProficiencies, skill.skillProficiencyValue)}
        />
      ))}
      {skills.length > maxSkillsToShow && (
        <>
          <span>{'... '}</span>
          <span className="badge">{`+ ${skills.length - maxSkillsToShow} more`}</span>
        </>
      )}
    </div>
  );
};

import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { requestBackend } from '@eva/emf/app/utils/request';
import { stringifyError } from 'shared/functions';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

// @ts-expect-error
const FormNote = ({ defaultNote, previewMode, onFinishEdit, onUpdateEntity, getBody } = {}) => {
  const [note, setNote] = useState(defaultNote);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setNote(defaultNote);
  }, [defaultNote]);

  const onChange = useCallback((evt) => setNote(evt.target.value), []);

  const onCancel = useCallback(() => {
    setNote(defaultNote);
    if (typeof onFinishEdit === 'function') {
      onFinishEdit(defaultNote);
    }
  }, [defaultNote, onFinishEdit]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);

    const body = getBody(note);

    return requestBackend('/my/candidate-profile', {
      method: 'PUT',
      body: JSON.stringify(body),
    }).then(
      (result) => {
        setSubmitting(false);

        setError(null);

        if (typeof onUpdateEntity === 'function') {
          onUpdateEntity(result);
        }

        if (typeof onFinishEdit === 'function') {
          onFinishEdit(note);
        }
      },
      (err) => setError(stringifyError(err)),
    );
  }, [note, onFinishEdit, onUpdateEntity, getBody]);

  if (error) {
    return <h3 className="text-danger">{error}</h3>;
  }

  if (previewMode) {
    return (
      note && (
        <div className="margin-bottom margin-top">
          <span className="small text-muted">{note}</span>
        </div>
      )
    );
  }

  return (
    <div>
      <input value={note || ''} className="form-control" placeholder={translate('Add note')} onChange={onChange} />
      <div className="text-right margin-top margin-bottom">
        <button
          type="button"
          id="submit-note"
          onClick={onSubmit}
          disabled={submitting}
          className="btn btn-sm btn-success"
        >
          {submitting && <SpinnerSmall />} {translate('Submit')}
        </button>
        <button
          type="button"
          disabled={submitting}
          id="cancel-edit-language"
          className="btn btn-sm btn-default margin-left"
          onClick={onCancel}
        >
          {submitting && <SpinnerSmall />} {translate('Cancel')}
        </button>
      </div>
    </div>
  );
};

FormNote.propTypes = {
  defaultNote: PropTypes.string,
  getBody: PropTypes.func.isRequired,
  previewMode: PropTypes.bool,
  onFinishEdit: PropTypes.func,
  onUpdateEntity: PropTypes.func,
};

// eslint-disable-next-line import/no-default-export
export default memo(FormNote);

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import 'containers/WeekScheduler/react-week-scheduler.css';
import {
  availabilityDayStart,
  availabilitySlotsMinutes,
  availabilitySlotsNumber,
  hoursMinutesDateFormat,
  yearFirstDateFormat,
} from 'shared/constants';
import { copy, logError } from 'shared/functions';

import WeekScheduler from 'containers/WeekScheduler';

const daysData = {};
const fullDateFormat = `${yearFirstDateFormat} ${hoursMinutesDateFormat}`;

// eslint-disable-next-line import/no-default-export
export default class InterimAvailabilityEditor extends React.Component<any, any> {
  state = {
    events: [],
    currentSchedule: [],
    formatAvailabilityTime: (time) => time,
    startOfWeek: moment()
      .startOf('isoWeek')
      .add(availabilityDayStart * availabilitySlotsMinutes, 'minutes'),
  };

  UNSAFE_componentWillMount() {
    this.initSettings();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { interimAvailability, settings } = nextProps;
    if (this.props.settings !== settings) {
      this.initSettings(nextProps);
    } else if (this.props.interimAvailability !== interimAvailability) {
      this.eventsToSchedule(nextProps);
    }
  }

  initSettings(props = this.props) {
    const { settings } = props;
    const formatAvailabilityTimeTime = (format) => (workHour, full) =>
      workHour ? moment(workHour, full ? fullDateFormat : hoursMinutesDateFormat).format(format) : '--';
    this.setState(
      {
        formatAvailabilityTime: formatAvailabilityTimeTime(settings.dateFormats.timeFormat),
      },
      () => this.eventsToSchedule(props),
    );
  }

  eventsToSchedule(props = this.props) {
    const {
      interimAvailability: { slots },
      availabilityTypes,
    } = props;
    const { formatAvailabilityTime, startOfWeek } = this.state;

    const dayMoment = startOfWeek.clone();
    const defaultSlot = availabilityTypes[0];
    const slotsCopy = copy(slots);

    const currentSchedule = [];
    const weekStartDate = dayMoment.format(fullDateFormat);
    let dayEvent = slotsCopy.find((slot) => slot.startAt < weekStartDate && slot.endAt > weekStartDate);

    for (let i = 0; i < 7; i += 1) {
      const day = [];
      for (let j = 0; j < availabilitySlotsNumber; j += 1) {
        const time = dayMoment.format(fullDateFormat);
        if (dayEvent && dayEvent.endAt === time) {
          dayEvent = undefined;
        }
        const startedEvent = slotsCopy.find((item) => item.startAt === time);
        if (startedEvent) {
          if (dayEvent) {
            logError('New event started before previous is over!', {
              dayEvent,
              startedEvent,
              slots,
            });
          }
          dayEvent = startedEvent;
        }
        const eventType = dayEvent && availabilityTypes[dayEvent.availabilityType];
        const timeInterval =
          dayEvent && (dayEvent.startAt === time || !(i + j))
            ? // @ts-expect-error
              `${formatAvailabilityTime(dayEvent.startAt, true)} - ${formatAvailabilityTime(dayEvent.endAt, true)}`
            : '';
        day.push(
          dayEvent
            ? {
                ...eventType,
                timeInterval,
                color:
                  typeof dayEvent.overlap !== 'boolean' || dayEvent.overlap ? eventType.color : eventType.lightenColor,
              }
            : defaultSlot,
        );
        dayMoment.add(availabilitySlotsMinutes, 'minutes');
      }
      currentSchedule.push(day);
    }

    this.setState({ currentSchedule });
  }

  updateEvents = (data) => {
    const { availabilityTypes, initialInterimSlots, updateAvailability } = this.props;
    const { startOfWeek } = this.state;

    const slotsCopy = copy(initialInterimSlots);
    const dataCopy = copy(data);
    const defaultSlot = availabilityTypes[0];
    const mergedSlot = availabilityTypes[3] || {};

    const eventOf = (event) => availabilityTypes[event.availabilityType].event;
    const dayMoment = startOfWeek.clone();
    const weekStartDate = startOfWeek.format(fullDateFormat);
    const weekEndDate = startOfWeek.clone().add(1, 'week').format(fullDateFormat);

    const currentWeekSlots = [];
    let currentEvent;

    for (let i = 0; i < 7; i += 1) {
      for (let j = 0; j < availabilitySlotsNumber; j += 1) {
        const time = dayMoment.format(fullDateFormat);
        const currentDataSlot = dataCopy[i][j];
        if (currentEvent) {
          if (eventOf(currentEvent) !== currentDataSlot.event) {
            // eslint-disable-line max-depth
            currentEvent.endAt = time;
            currentEvent = undefined;
          }
        }
        if (!currentEvent && ![defaultSlot.event, mergedSlot.event].includes(currentDataSlot.event)) {
          currentEvent = {
            startAt: time,
            endAt: '',
            availabilityType: availabilityTypes.findIndex((eventType) => eventType.event === currentDataSlot.event),
          };
          currentWeekSlots.push(currentEvent);
        }
        dayMoment.add(availabilitySlotsMinutes, 'minutes');
      }
    }

    if (currentEvent && !currentEvent.endAt) {
      currentEvent.endAt = weekEndDate;
    }

    const previousEvents = copy(slotsCopy.filter((slotCopy) => slotCopy.startAt < weekStartDate));
    const lastPreviousEvent = previousEvents.length && previousEvents[previousEvents.length - 1];
    if (lastPreviousEvent && lastPreviousEvent.endAt > weekStartDate) {
      lastPreviousEvent.endAt = weekStartDate;
    }
    const followingEvents = copy(slotsCopy.filter((slotCopy) => slotCopy.endAt > weekEndDate));
    const firstFollowingEvent = followingEvents[0];
    if (firstFollowingEvent && firstFollowingEvent.startAt < weekEndDate) {
      firstFollowingEvent.startAt = weekEndDate;
    }

    if (currentWeekSlots.length) {
      const previousToEnd = lastPreviousEvent && lastPreviousEvent.endAt === weekStartDate;
      const followingFromBeginning = firstFollowingEvent && firstFollowingEvent.startAt === weekEndDate;
      const currentEventFullWeek =
        currentWeekSlots.length === 1 &&
        // @ts-expect-error
        currentWeekSlots.startAt === weekStartDate &&
        // @ts-expect-error
        currentWeekSlots.endAt === weekEndDate;
      if (currentEventFullWeek && previousToEnd && followingFromBeginning) {
        lastPreviousEvent.endAt = firstFollowingEvent.endAt;
        followingEvents.shift();
      } else {
        if (previousToEnd) {
          const firstCurrentEvent = currentWeekSlots[0];
          if (
            firstCurrentEvent.startAt === weekStartDate && // eslint-disable-line max-depth
            eventOf(lastPreviousEvent) === eventOf(firstCurrentEvent)
          ) {
            firstCurrentEvent.startAt = lastPreviousEvent.startAt;
            previousEvents.splice(-1, 1);
          }
        }
        if (followingFromBeginning) {
          const lastCurrentEvent = currentWeekSlots[currentWeekSlots.length - 1];
          // eslint-disable-next-line max-depth
          if (lastCurrentEvent.endAt === weekEndDate && eventOf(firstFollowingEvent) === eventOf(lastCurrentEvent)) {
            lastCurrentEvent.endAt = firstFollowingEvent.endAt;
            followingEvents.shift();
          }
        }
      }
    }

    updateAvailability([...previousEvents, ...currentWeekSlots, ...followingEvents]);
  };

  shiftWeek = (shift) => {
    const { startOfWeek } = this.state;
    this.setState(
      {
        startOfWeek: startOfWeek.add(shift, 'week'),
      },
      () => this.eventsToSchedule(),
    );
  };

  render() {
    const { settings, availabilityTypes } = this.props;
    const { currentSchedule, formatAvailabilityTime, startOfWeek } = this.state;

    return (
      <WeekScheduler
        settings={settings}
        availabilityTypes={availabilityTypes}
        currentSchedule={currentSchedule}
        daysData={daysData}
        updateEvents={this.updateEvents}
        formatAvailabilityTime={formatAvailabilityTime}
        startOfWeek={startOfWeek}
        shiftWeek={this.shiftWeek}
      />
    );
  }
}

// @ts-expect-error
InterimAvailabilityEditor.propTypes = {
  settings: PropTypes.object.isRequired,
  interimAvailability: PropTypes.object.isRequired,
  initialInterimSlots: PropTypes.array.isRequired,
  updateAvailability: PropTypes.func.isRequired,
  availabilityTypes: PropTypes.array.isRequired,
};

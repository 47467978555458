import type { SkillTaxonomyDto } from '@eva/types/dto';
import { getUserGroupIds } from 'shared/functions/user';

import type { UseQueryResult } from 'react-query';

import { filterSkillTaxonomiesByUserGroupId } from '../functions';

export const useFilterSkillTaxonomiesOptions = (
  dataQueryResult: UseQueryResult<{
    skillTaxonomies: SkillTaxonomyDto[];
  }>,
) => {
  const userGroupIds = getUserGroupIds();

  if (dataQueryResult.data && dataQueryResult.data.skillTaxonomies) {
    dataQueryResult.data = {
      skillTaxonomies: filterSkillTaxonomiesByUserGroupId(dataQueryResult.data.skillTaxonomies, userGroupIds),
    };
  }

  return dataQueryResult;
};

import ReactHelmet from 'react-helmet';

const Helmet = ({ candidateBranding }) => (
  <ReactHelmet
    meta={[{ name: 'description', content: translate('Eva System') }]}
    style={[
      candidateBranding
        ? {
            cssText: candidateBranding,
          }
        : {},
    ]}
    defer={false}
  />
);

// eslint-disable-next-line import/no-default-export
export default Helmet;

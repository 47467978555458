import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { noop } from 'shared/functions';

import { loadInterimWorkHistory } from 'containers/CandidatePaneNew/functions';

class PreviewInterimWorkHistory extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    showMore: {},
    deleteModeRows: {},
  };
  static defaultProps: { updateEntity: () => void };
  static propTypes: {
    userProfile: PropTypes.Validator<object>;
    updateEntity: PropTypes.Validator<(...args: any[]) => any>;
  };

  UNSAFE_componentWillMount() {
    const {
      userProfile: { interimWorkHistory },
    } = this.props;
    // @ts-expect-error
    this.loadWorkHistory(interimWorkHistory);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userProfile } = nextProps;
    if (this.props.userProfile !== userProfile) {
      // @ts-expect-error
      this.loadWorkHistory(userProfile.interimWorkHistory);
    }
  }

  loadWorkHistory(interimWorkHistory, open) {
    // @ts-expect-error
    this.setState(loadInterimWorkHistory({}, interimWorkHistory), () => open && this.extendPanel());
  }

  render() {
    // @ts-expect-error
    const { currentHistory, newInterimWorkHistoryItem, totalExperienceHours, saving, error } = this.state;

    if (saving) {
      return (
        <div>
          <p>{translate('Saving work history')}...</p>
        </div>
      );
    }

    return (
      <div id="work-history-panel">
        {error && <p className="text-danger">{error}</p>}
        {totalExperienceHours && (
          <div className="panel-menu-item-summary">
            {totalExperienceHours} {translate('hour(s) of experience')}
          </div>
        )}
        {!totalExperienceHours && !newInterimWorkHistoryItem && !currentHistory.length && (
          <div className="panel-menu-item-summary">{translate('No work history recorded')}</div>
        )}
      </div>
    );
  }
}

PreviewInterimWorkHistory.defaultProps = {
  updateEntity: noop,
};

PreviewInterimWorkHistory.propTypes = {
  userProfile: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default connect()(PreviewInterimWorkHistory);

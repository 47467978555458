import React from 'react';
import invert from 'lodash/invert';

import {
  agentPrefix,
  candidatePrefix,
  messengerPrefix,
  authPrefix,
  baseEndpoint,
  browserHistory,
  userAccountTypes,
  skillTaxonomiesPrefix,
  acceptImagesExtensions,
  acceptCvExtensions,
  acceptCvTypes,
  acceptImageTypes,
} from '@eva/emf/app/shared/constants';

export {
  baseEndpoint,
  browserHistory,
  agentPrefix,
  candidatePrefix,
  messengerPrefix,
  authPrefix,
  skillTaxonomiesPrefix,
  userAccountTypes,
  acceptImagesExtensions,
  acceptCvExtensions,
  acceptCvTypes,
  acceptImageTypes,
};

export const routesNames = {
  home: 'home',
  redirector: 'redirector',
  messenger: 'messenger',
  signInLocal: 'signInLocal',
  candidate: 'candidate',
  jobDetails: 'job-details',
  recommended: 'recommended',
  myJobs: 'my-jobs',
  myProfile: 'my-profile',
};

export const publicJobsPrefix = '/public-jobs';

export const hostnames = {
  local: '127.0.0.1',
  localhost: 'localhost',
  dev: 'dev.eva.ai',
  uat: 'uat.eva.ai',
};

export const environments = {
  prd: 'prd',
  uat: 'uat',
  dev: 'dev',
};

export const localHostnames = [hostnames.local, hostnames.localhost];
export const localDevelopment = window.location.hostname === hostnames.local;
export const filesEndpoint = 'https://s3-eu-west-1.amazonaws.com';
export const iframeMode = window.self !== window.top;
export const caches = {};
export const messagesPageSize = 30;
export const maxFileSize = 10485760;
const login = 'login';

export const userTypes = {
  agent: 1,
  employer: 2,
  candidate: 3,
};
export const invertedUserTypes = invert(userTypes);
export const typingDelay = 5000;
export const messagesLevelsTypes = {
  success: 'success',
  danger: 'danger',
  info: 'info',
};
export const messages = {
  A002: {
    type: messagesLevelsTypes.success,
    label: 'Welcome back!',
  },
  A003: {
    type: messagesLevelsTypes.success,
    label: 'Thanks for joining!',
  },
  A004: {
    type: messagesLevelsTypes.danger,
    label: 'Oh no! Facebook had a problem! Please try again.',
  },
  A006: {
    type: messagesLevelsTypes.danger,
    label: 'Oh no! Google had a problem! Please try again.',
  },
  A008: {
    type: messagesLevelsTypes.danger,
    label: "Oh no! We can't find your email. Please try again.",
  },
  A009: {
    type: messagesLevelsTypes.success,
    label: 'Thanks, we have sent you an email containing instruction to reset your password.',
  },
  A010: {
    type: messagesLevelsTypes.danger,
    label: 'Oh no! There was a problem with your registration. Please try again.',
  },
  A011: {
    type: messagesLevelsTypes.success,
    label: 'Thanks, we have successfully reset your password. You can now sign in.',
  },
  A012: {
    type: messagesLevelsTypes.danger,
    label: 'Token invalid',
  },
};
export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len

export const evaSpinner = (
  <div className="eva-spinner">
    <div>
      <i className="ll-icon ll-logo-eva-small" />
      <i className="lnr lnr-briefcase" />
      <i className="lnr lnr-user" />
      <div />
    </div>
  </div>
);
export const evaSpinnerSmall = (
  <div className="eva-spinner small-spinner">
    <div>
      <div />
    </div>
  </div>
);

export const workflowsCandidateCodes = {
  invited: 'invited',
  applied: 'applied',
  declined: 'declined',
  declined1: 'declined1',
  declined2: 'declined2',
  qualifying: 'qualifying',
  qualifying2: 'qualifying2',
  interviewing: 'interviewing',
  offered: 'offered',
  placed: 'placed',
};
export const leftMenuModes = {
  menu: 1,
  signIn: 2,
  restorePassword: 3,
};
export const googleApiKey = 'AIzaSyBQ0q3vMP0fgH_UZZdAAecUem8Mnjylmk4';
const aiNodesKeys = ['cv', 'tnc', 'allGood', 'finish'];
export const aiNodes = aiNodesKeys.reduce(
  (prev, cur) => ({
    ...prev,
    [cur]: cur,
  }),
  {},
);
export const jobStates = {
  active: 'active',
  lead: 'lead',
  onHold: 'onHold',
  cancelled: 'cancelled',
  closed: 'closed',
  filled: 'filled',
};
export const messagesTypes = {
  text: 1,
  genericSystem: 2,
  call: 3,
  sentEmail: 4,
  receivedEmail: 5,
  pipelineStatusChange: 6,
  sms: 7,
  note: 8,
  facebookMessage: 9,
  qnaMissedAnswer: 10,
};
export const jobsStatuses = {
  active: {
    ids: [jobStates.active, jobStates.lead],
    icon: 'lnr-checkmark-circle',
    color: '#5cb85c',
  },
  onHold: {
    ids: [jobStates.onHold],
    icon: 'lnr-warning',
    color: '#f0ad4e',
  },
  closed: {
    ids: [jobStates.cancelled, jobStates.closed, jobStates.filled],
    icon: 'lnr-cross-circle',
    color: '#d9534f',
  },
  other: {
    ids: [],
    icon: 'lnr-question-circle',
    color: '#7A8E92',
  },
};
export const candidateRegistrationWorkflowCodes = {
  registering: 'registering',
  registered: 'registered',
  hangedRegistration: 'hanged_registration',
};
export const chromeExtensionIds = ['dbbphjhklckdnlapiiecnagcmdhkcjgk', 'kkbenjalnbdpknahknlnieciokkpfijg'];
export const emailTypes = {
  primary: 1,
  secondary: 2,
  [login]: 3,
};
export const emailTemplates = [];
export const messageTimeFormat = 'h:mm a';
export const sentEmailStatuses = {
  sending: {
    id: 0,
  },
  sent: {
    id: 1,
  },
  ignore: {
    id: 2,
  },
  error: {
    id: 3,
  },
  delivered: {
    id: 4,
    eventType: 'Delivered',
  },
  bounced: {
    id: 5,
    eventType: 'Bounced',
  },
  opened: {
    id: 6,
    eventType: 'Opened',
  },
  clicked: {
    id: 7,
    eventType: 'LinkClicked',
  },
  transient: {
    id: 8,
    eventType: 'Transient',
  },
};
export const cpPaneModes = {
  cv: 'cv',
  files: 'files',
  chat: 'chat',
};
export const containersContexts = {
  chat: 'chat',
  match: 'match',
  screenMeet: 'screenMeet',
  search: 'search',
  candidates: 'candidates',
  entities: 'entities',
};
export const imagesExtensions = /\.(gif|jpg|jpeg|tiff|png)$/;
export const cvExtensions = /\.(doc|docx|dot|rtf|pdf|odt|txt|htm|html)$/;
export const pageSizes = [5, 10, 20, 50, 100];
export const defaultPageSize = pageSizes[2];
export const base64Prefix = /^data.+;base64,/;
export const phoneTypes = {
  cell: 1,
  home: 2,
  work: 3,
};
export const noteTypes = {
  panel: 'panel',
  chat: 'chat',
  pdf: 'pdf',
};
export const noteTypesCodes = {
  [noteTypes.panel]: 1,
  [noteTypes.chat]: 2,
  [noteTypes.pdf]: 3,
};
export const zeroTime = '00:00';
export const endTime = '24:00';
export const availabilitySlotsNumber = 48;
export const availabilitySlotsMinutes = 30;
export const availabilityDayStart = (60 / availabilitySlotsMinutes) * 6; // 6 AM
export const conversationStates = {
  stale: 'stale',
  unread: 'unread',
};
export const yearFirstDateFormat = 'YYYY-MM-DD';
export const hoursMinutesDateFormat = 'HH:mm';
export const overlayTriggerClick = ['click'];
export const overlayTriggerHoverFocus = ['hover', 'focus'];
export const calendarPeriodTemplates = ['month', 'quarter', 'year'];
export const emailTypesKeys = Object.keys(emailTypes).filter((key) => key !== login);
export const phoneTypesKeys = Object.keys(phoneTypes);
export const fullCalendarDistUrl =
  'https://rawgit.com/fullcalendar/fullcalendar/354812a73980168681506274d90810da85f8f573/dist';
export const schedulerDistUrl =
  'https://rawgit.com/fullcalendar/fullcalendar-scheduler/a1e0dbd8a24a936b980c5ab624f8bc0181f8f97c/dist';

export const pipelineActionTypes = {
  email: 0,
};

export const agencyTypes = {
  agency: 'agency',
  internal: 'internal',
};

export const defaultBulkSelection = {
  bulkSelected: {},
  bulkErrors: {},
  bulkPaneCandidates: [],
};

export const emailTemplateTypes = {
  candidateManual: 'candidateManual',
  contactManual: 'contactManual',
  system: 'system',
  reengagement: 'reengagement',
};

export const intermediate = 'intermediate';

export const employmentTypes = {
  permanentPartTime: 'permanent_part_time',
  permanentFullTime: 'permanent_full_time',
  interimTemporary: 'interim_temporary',
  fixedTermContract: 'fixed_term_contract',
};

export const minPasswordLength = 8;

export const errorTypes = {
  missingField: 'missingField',
  serverError: 'serverError',
  permissionError: 'permissionError',
  quotaError: 'quotaError',
  quotaNotification: 'quotaNotification',
  errorWithoutPath: 'errorWithoutPath',
  wrongErrorFormat: 'wrongErrorFormat',
};

export const socialMediaProviders = ['linkedIn', 'facebook'];
export const entitiesTypes = {
  candidates: 'candidates',
  externalCandidates: 'externalCandidates',
  companies: 'companies',
  contacts: 'contacts',
  jobs: 'jobs',
  jobRequests: 'jobRequests',
  skills: 'skills',
  evaSkills: 'evaSkills',
  reengagementCampaigns: 'reengagementCampaigns',
  callTypes: 'callTypes',
  callOutcomes: 'callOutcomes',
  pipelineWorkflows: 'pipelineWorkflows',
  entityLists: 'entityLists',
  tags: 'tags',
};
export const entityTypeIds = {
  candidates: 1,
  companies: 3,
  contacts: 4,
  jobs: 2,
  agents: 7,
};
export const emailTemplateEditorModes = {
  html: 'html',
  simple: 'simple',
};
export const communicationDirections = {
  inbound: 'Inbound',
  outbound: 'Outbound',
};
export const omnichatChannels = [
  {
    label: 'sms',
    icon: 'lnr lnr-smartphone',
  },
  {
    label: 'facebook',
    icon: 'fa fa-facebook',
  },
  {
    label: 'webchat',
    icon: 'll-icon ll-logo-eva-small',
  },
  {
    label: 'email',
    icon: 'lnr lnr-envelope',
  },
];
export const supportedLocales = {
  en: 'en',
  fr: 'fr',
};
export const providersTypes = {
  luckylink: 0,
  totaljobs: 1,
  caterer: 2,
  reed: 3,
  // indeed: 4, ??
  jobsite: 18,
  totaljobsGroup: 19,
  eMedCareers: 20,
  cvLibrary: 21,
};
export const providersLabels = {
  [providersTypes.luckylink]: 'Internal Database',
  [providersTypes.totaljobs]: 'Totaljobs',
  [providersTypes.caterer]: 'Caterer',
  [providersTypes.reed]: 'Reed.co.uk',
  [providersTypes.jobsite]: 'Jobsite',
  [providersTypes.eMedCareers]: 'eMedCareers',
  [providersTypes.totaljobsGroup]: 'Totaljobs Group',
  [providersTypes.cvLibrary]: 'CV-Library',
};
export const yellowHighlight = '#fdf4f4';

export const clearableMessengerQueryParams = ['workflowId', 'screeningForm', 'jobId', 'section', 'staticPageId'];
export const messengerQueryParams = ['error', 'name', 'filterState', 'agents', 'pj', ...clearableMessengerQueryParams];
export const subscriptionTypes = {
  legitimateInterest: 'legitimateInterest',
  consentObtained: 'consentObtained',
  unsubscribeAutomatic: 'unsubscribeAutomatic',
  unsubscribeEmails: 'unsubscribeEmails',
  unsubscribeCommunications: 'unsubscribeCommunications',
};
export const fileExtensions = {
  image: 'image',
  document: 'document',
  txt: 'txt',
  pdf: 'pdf',
  rtf: 'rtf',
};
export const grayColor = '#B2C3CB';
export const locationKeys = ['street', 'city', 'state', 'postalCode'];
export const milesInKm = 0.621371;
export const dayOfMonthFormat = 'Do MMM';
export const backendDateFormat = 'YYYY.MM.DD';
export const jsonSchemaTypes = {
  string: 'string',
  number: 'number',
  integer: 'integer',
  boolean: 'boolean',
};
export const recognitionStatuses = {
  correct: 'correct',
  notCorrect: 'notCorrect',
};

export const workflowTransitionEntryPoints = {
  addingInMatch: 'addingInMatch',
  addingInMatchExternal: 'addingInMatchExternal',
  addingJobManuallyFromPanel: 'addingJobManuallyFromPanel',
  addingJobManuallyFromCandidatePipelines: 'addingJobManuallyFromCandidatePipelines',
  addingFromCvInbox: 'addingFromCvInbox',
  applyingFromFacebook: 'applyingFromFacebook',
  applyingFromPublicJobLink: 'applyingFromPublicJobLink',
  applyingFromJobPortal: 'applyingFromJobPortal',
  recommendation: 'recommendation',
  rejectingInMatch: 'rejectingInMatch',
  newApplicationEmail: 'newApplicationEmail',
  newApplicantInvitedToChat: 'newApplicantInvitedToChat',
};
export const greyColor = '#B2C3CB';

export const workflowTransitionActionTypes = {
  sendEmail: 'sendEmail',
  sendSMS: 'sendSMS',
  inApplicationNotification: 'inApplicationNotification',
  addTags: 'addTags',
  addToList: 'addToList',
};
export const workflowTransitionActionTypeLabels = {
  [workflowTransitionActionTypes.sendEmail]: 'Send email',
  [workflowTransitionActionTypes.sendSMS]: 'Send SMS',
  [workflowTransitionActionTypes.inApplicationNotification]: 'in-application notification',
  [workflowTransitionActionTypes.addTags]: 'Add tags',
  [workflowTransitionActionTypes.addToList]: 'Add to list',
};
export const workflowTransitionActionTypeCodes = {
  [workflowTransitionActionTypes.sendEmail]: 1,
  [workflowTransitionActionTypes.sendSMS]: 2,
  [workflowTransitionActionTypes.inApplicationNotification]: 3,
  [workflowTransitionActionTypes.addTags]: 4,
  [workflowTransitionActionTypes.addToList]: 5,
};
export const reverseWorkflowTransitionActionTypeCodes = Object.entries(workflowTransitionActionTypeCodes).reduce(
  (prev, [key, value]) => ({
    ...prev,
    [value]: key,
  }),
  {},
);

export const workflowTransitionEntryPointsLabels = {
  [workflowTransitionEntryPoints.addingInMatch]: 'Adding in Match',
  [workflowTransitionEntryPoints.addingInMatchExternal]: 'Adding in Match (external)',
  [workflowTransitionEntryPoints.addingJobManuallyFromPanel]: 'Adding job manually from job panel',
  [workflowTransitionEntryPoints.addingJobManuallyFromCandidatePipelines]:
    'Adding job manually from candidate pipelines',
  [workflowTransitionEntryPoints.addingFromCvInbox]: 'Adding from CV inbox',
  [workflowTransitionEntryPoints.applyingFromFacebook]: 'Applying from Facebook',
  [workflowTransitionEntryPoints.applyingFromPublicJobLink]: 'Applying from Public Job link',
  [workflowTransitionEntryPoints.applyingFromJobPortal]: 'applyingFromJobPortal',
  [workflowTransitionEntryPoints.recommendation]: 'Recommendation',
  [workflowTransitionEntryPoints.rejectingInMatch]: 'Rejecting in Match',
};
export const socialProviders = ['linkedIn', 'facebook', 'twitter', 'skype'];

export const agentContactAccountTypes = [userAccountTypes.agent, userAccountTypes.contact];

export const alwaysAllowedOperations = ['institution-create'];

export const builtInQaSuggestions = ['skip'];

export const cacheNames = {
  jobWorkflows: 'job-workflows',
  candidateJobs: 'candidate-jobs',
  candidateJob: 'candidate-job',
};

export const screeningStatuses = {
  notExists: 'notExists',
  notStarted: 'notStarted',
  inProgress: 'inProgress',
  completed: 'completed',
};

export const jobDetailsForms = {
  screeningForm: '1',
};

export const prefixMyCandidate = '/my/candidate';
